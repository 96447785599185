// THIS FILE describes the constants and endpoints that can be used for CCAdmin
export const PARAMETER_TYPES = Object.freeze({
  // MENU: "MENU",
  SCRIPT: "SCRIPT",
  APPLICATION: "APPLICATION",
  // CALENDAR: "CALENDAR",
  CUSTOM: "CUSTOM",
  BOOLEAN: "BOOLEAN",
});

export const CCADMIN_VERSION = "3.0.6-SNAPSHOT";

export const PARAMETER_RENDER_TYPES = {
  LIST: "LIST",
  DROPDOWN: "DROPDOWN",
  TEXT: "TEXT",
  INTEGER: "INTEGER",
  FLOAT: "FLOAT",
  RADIO: "RADIO",
};

export const constants = Object.freeze({
  SCHEDULE_TYPES: {
    WEEKLY: 1,
    SPECIAL: 0,
  },
  PERMISSION_TYPES: {
    ALL: "ALL",
    VIEW: "VIEW",
  },
  // ACTION_TYPES: {
  //   // MENU: "MENU",
  //   APPLICATION: "APPLICATION",
  //   DISCONNECT: "DISCONNECT",
  //   // SCRIPT: "SCRIPT",
  //   TRANSFER: "TRANSFER",
  //   DEFAULT: "DEFAULT",
  //   DEFAULT_CLOSED: "DEFAULT_CLOSED",
  //   DEFAULT_OPEN: "DEFAULT_OPEN",
  //   DEFAULT_ORG: "DEFAULT_ORG",
  //   DEFAULT_ORG_CLOSED: "DEFAULT_ORG_CLOSED",
  //   DEFAULT_ORG_OPEN: "DEFAULT_ORG_OPEN",
  // },
  ACTION_TYPES: {
    // MENU: "MENU",
    APPLICATION: {
      name: "APPLICATION",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: true,
      showInOrg: true,
    },
    DISCONNECT: {
      name: "DISCONNECT",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: true,
      showInOrg: true,
    },
    SCRIPT: {
      name: "SCRIPT",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: true,
      showInOrg: true,
    },
    TRANSFER: {
      name: "TRANSFER",
      enableDefaultOption: true,
      enableValueField: true,
      valueTypeField: "text",
      showApplication: true,
      showInOrg: true,
    },
    MENU: {
      name: "MENU",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: true,
      showInOrg: true,
    },
    DEFAULT: {
      name: "DEFAULT",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: true,
      showInOrg: true,
    },
    DEFAULT_CLOSED: {
      name: "DEFAULT_CLOSED",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: true,
      showInOrg: true,
    },
    DEFAULT_OPEN: {
      name: "DEFAULT_OPEN",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: true,
      showInOrg: true,
    },
    DEFAULT_ORG: {
      name: "DEFAULT_ORG",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: false,
      showInOrg: true,
    },
    DEFAULT_ORG_CLOSED: {
      name: "DEFAULT_ORG_CLOSED",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: false,
      showInOrg: true,
    },
    DEFAULT_ORG_OPEN: {
      name: "DEFAULT_ORG_OPEN",
      enableDefaultOption: true,
      enableValueField: true,
      showApplication: false,
      showInOrg: true,
    },
  },
  PARAMETER_TYPES: PARAMETER_TYPES,
  OPEN_CLOSE_ACTION_TYPE_OPTIONS: [
    {
      value: "SCRIPT",
      text: "Script",
    },
    { value: "TRANSFER", text: "Transfer" },
    // { value: "MENU", text: "Menu" },
    {
      value: "APPLICATION",
      text: "Application",
    },
    { value: "DISCONNECT", text: "Disconnect" },
  ],
  ADHOC_TYPE_OPTIONS: [
    { value: "Disable", text: "Disable" },
    { value: "ActivForEver", text: "Active - Permanent" },
    { value: "ActiveRestOfDay", text: "Active - The rest of the day" },
    { value: "ActiveSpecial", text: "Active - Based on date" },
  ],
  WEEK_DAYS: [
    { value: "MONDAY", text: "Monday" },
    { value: "TUESDAY", text: "Tuesday" },
    { value: "WEDNESDAY", text: "Wednesday" },
    { value: "THURSDAY", text: "Thursday" },
    { value: "FRIDAY", text: "Friday" },
    { value: "SATURDAY", text: "Saturday" },
    { value: "SUNDAY", text: "Sunday" },
  ],
  DATE_FORMAT: "yyyy-MM-dd",
  TIME_FORMAT: "HH:mm",
  DATETIME_FORMAT: "yyyy-MM-dd HH:mm:ss.SSSZZ",
  PARAMETER_RENDER_TYPES: PARAMETER_RENDER_TYPES,
  CUSTOM_PARAMETER_TYPE_PREFIX: "CCADMIN_COMPONENT_",
  CUSTOM_PARAMETER_TYPES: {
    TEXT: "text",
    INTEGER: "integer",
    FLOAT: "float",
    BOOLEAN_RADIO: "booleanRadio",
    BOOLEAN_DROPDOWN: "booleanDropDown",
    BOOLEAN_LIST: "booleanList",
    CUST_DROP: "custDrop",
    CUST_SINGLE_LIST: "custSingleList",
    CUST_MULTI_LIST: "custMultiList",
    CUST_RADIO: "custRadio",
    // CAL_DROP_ALL: "CalDropAll",
    // CAL_DROP_CUST: "CalDropCust",
    // CAL_SINGLE_LIST_ALL: "CalSingleListAll",
    // CAL_SINGLE_LIST_CUST: "CalSingleListCust",
    // CAL_MULTI_LIST_ALL: "CalMultiListAll",
    // CAL_MULTI_LIST_CUST: "CalMultiListCust",
    APPL_DROP_ALL: "ApplDropAll",
    APPL_DROP_CUST: "ApplDropCust",
    APPL_SINGLE_LIST_ALL: "ApplSingleListAll",
    APPL_SINGLE_LIST_CUST: "ApplSingleListCust",
    APPL_MULTI_LIST_ALL: "ApplMultiListAll",
    APPL_MULTI_LIST_CUST: "ApplMultiListCust",
    // SCRIPT_DROP_ALL: "ScriptDropAll",
    // SCRIPT_DROP_CUST: "ScriptDropCust",
    SCRIPT_SINGLE_LIST_ALL: "ScriptSingleListAll",
    SCRIPT_SINGLE_LIST_CUST: "ScriptSingleListCust",
    // SCRIPT_MULTI_LIST_ALL: "ScriptMultiListAll",
    // SCRIPT_MULTI_LIST_CUST: "ScriptMultiListCust"
  },
  CUSTOM_PARAMETER_TYPES_CREATE: {
    TEXT: {
      name: "text",
      type: PARAMETER_RENDER_TYPES.TEXT,
      multi: false,
      valueType: null,
      specify: false,
    },
    INTEGER: {
      name: "integer",
      type: PARAMETER_RENDER_TYPES.INTEGER,
      multi: false,
      valueType: null,
      specify: false,
    },
    FLOAT: {
      name: "float",
      type: PARAMETER_RENDER_TYPES.FLOAT,
      multi: false,
      valueType: null,
      specify: false,
    },
    BOOLEAN_RADIO: {
      name: "booleanRadio",
      type: PARAMETER_RENDER_TYPES.RADIO,
      multi: false,
      valueType: PARAMETER_TYPES.BOOLEAN,
      specify: false,
    },
    // BOOLEAN_DROPDOWN: {
    //     name: "booleanDropDown",
    //     type: PARAMETER_RENDER_TYPES.DROPDOWN,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.BOOLEAN,
    //     specify: false
    // },
    // BOOLEAN_LIST: {
    //     name: "booleanList",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.BOOLEAN,
    //     specify: false
    // },
    CUST_DROP: {
      name: "custDrop",
      type: PARAMETER_RENDER_TYPES.DROPDOWN,
      multi: false,
      valueType: PARAMETER_TYPES.CUSTOM,
      specify: true,
    },
    // CUST_SINGLE_LIST: {
    //     name: "custSingleList",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.CUSTOM,
    //     specify: true
    // },
    // CUST_MULTI_LIST: {
    //     name: "custMultiList",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: true,
    //     valueType: PARAMETER_TYPES.CUSTOM,
    //     specify: true
    // },
    // CUST_RADIO: {
    //     name: "custRadio",
    //     type: PARAMETER_RENDER_TYPES.RADIO,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.CUSTOM,
    //     specify: true
    // },
    APPL_DROP_ALL: {
      name: "ApplDropAll",
      type: PARAMETER_RENDER_TYPES.DROPDOWN,
      multi: false,
      valueType: PARAMETER_TYPES.APPLICATION,
      specify: false,
    },
    // APPL_DROP_CUST: {
    //     name: "ApplDropCust",
    //     type: PARAMETER_RENDER_TYPES.DROPDOWN,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.APPLICATION,
    //     specify: true
    // },
    // APPL_SINGLE_LIST_ALL: {
    //     name: "ApplSingleListAll",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.APPLICATION,
    //     specify: false
    // },
    // APPL_SINGLE_LIST_CUST: {
    //     name: "ApplSingleListCust",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.APPLICATION,
    //     specify: true
    // },
    // APPL_MULTI_LIST_ALL: {
    //     name: "ApplMultiListAll",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: true,
    //     valueType: PARAMETER_TYPES.APPLICATION,
    //     specify: false
    // },
    // APPL_MULTI_LIST_CUST: {
    //     name: "ApplMultiListCust",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: true,
    //     valueType: PARAMETER_TYPES.APPLICATION,
    //     specify: true
    // },
    // CAL_DROP_ALL: {
    //     name: "CalDropAll",
    //     type: PARAMETER_RENDER_TYPES.DROPDOWN,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.CALENDAR,
    //     specify: false
    // },
    // CAL_DROP_CUST: {
    //     name: "CalDropCust",
    //     type: PARAMETER_RENDER_TYPES.DROPDOWN,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.CALENDAR,
    //     specify: true
    // },
    // CAL_SINGLE_LIST_ALL: {
    //     name: "CalSingleListAll",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.CALENDAR,
    //     specify: false
    // },
    // CAL_SINGLE_LIST_CUST: {
    //     name: "CalSingleListCust",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: false,
    //     valueType: PARAMETER_TYPES.CALENDAR,
    //     specify: true
    // },
    // CAL_MULTI_LIST_ALL: {
    //     name: "CalMultiListAll",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: true,
    //     valueType: PARAMETER_TYPES.CALENDAR,
    //     specify: false
    // },
    // CAL_MULTI_LIST_CUST: {
    //     name: "CalMultiListCust",
    //     type: PARAMETER_RENDER_TYPES.LIST,
    //     multi: true,
    //     valueType: PARAMETER_TYPES.CALENDAR,
    //     specify: true
    // },
    // SCRIPT_DROP_ALL: {
    //   name: "ScriptDropAll",
    //   type: PARAMETER_RENDER_TYPES.DROPDOWN,
    //   multi: false,
    //   valueType: PARAMETER_TYPES.SCRIPT,
    //   specify: false
    // },
    // SCRIPT_DROP_CUST: {
    //   name: "ScriptDropCust",
    //   type: PARAMETER_RENDER_TYPES.DROPDOWN,
    //   multi: false,
    //   valueType: PARAMETER_TYPES.SCRIPT,
    //   specify: true
    // },
    // SCRIPT_SINGLE_LIST_ALL: {
    //   name: "ScriptDropCust",
    //   type: PARAMETER_RENDER_TYPES.LIST,
    //   multi: false,
    //   valueType: PARAMETER_TYPES.SCRIPT,
    //   specify: true
    // },
    // SCRIPT_SINGLE_LIST_CUST: {
    //   name: "ScriptSingleListCust",
    //   type: PARAMETER_RENDER_TYPES.LIST,
    //   multi: true,
    //   valueType: PARAMETER_TYPES.SCRIPT,
    //   specify: true
    // },
    // SCRIPT_MULTI_LIST_ALL: {
    //   name: "ScriptMultiListAll",
    //   type: PARAMETER_RENDER_TYPES.LIST,
    //   multi: true,
    //   valueType: PARAMETER_TYPES.SCRIPT,
    //   specify: false
    // },
    // SCRIPT_MULTI_LIST_CUST: {
    //   name: "ScriptMultiListCust",
    //   type: PARAMETER_RENDER_TYPES.LIST,
    //   multi: true,
    //   valueType: PARAMETER_TYPES.SCRIPT,
    //   specify: true
    // }
  },
  APPLICATION: {
    LINKS: {
      DEFAULT_MENU_ACTION_PROMPT_HANDLING: "DefaultMenuActionPromptHandling",
      APPLICATION_CALENDAR: "ApplicationCalendar",
      CLOSED_PROMPT_HANDLING: "ClosedPromptHandling",
      OPEN_PROMPT_HANDLING: "OpenPromptHandling",
      DEFAULT_APPLICATION_ACTION_PROMPT_HANDLING:
        "DefaultApplicationActionPromptHandling",
      DEFAULT_DISCONNECT_ACTION_PROMPT_HANDLING:
        "DefaultDisconnectActionPromptHandling",
      DEFAULT_TRANSFER_ACTION_PROMPT_HANDLING:
        "DefaultTransferActionPromptHandling",
      DEFAULT_SCRIPT_ACTION_PROMPT_HANDLING:
        "DefaultScriptActionPromptHandling",
    },
  },
  maxInputLength: 50,
  endPoints: {
    APPLICATIONS_BASE: "/api/applications/",
    APPLICATIONS_ORG: "withonlyorg",
    AUTHENTICATION: "/api/authentication/authenticate",
    APPLICATIONS_SCHEDULE: "/schedule/",
    APPLICATIONS_SCHEDULE_NO_SLASH: "/schedule",
    APPLICATIONS_SCHEDULEBULK_NO_SLASH: "/schedulebulk",
    APPLICATIONS_ACTION: "/action/",
    APPLICATIONS_SCHEDULES: "/SchedulesWithActions",
    APPLICATIONS_ACTIVE_SCHEDULE: "/activeschedule/",
    APPLICATIONS_ACTIVE_SCHEDULE_NOW: "now",
    APPLICATIONS_ADHOC_PROMPT: "adhoc",
    APPLICATIONS_TRIGGERS: "getAllTriggers",
    PARAMETERS_BASE: "/api/parameters/",
    PARAMETER_GROUP_DELETE: "deleteParameterGroup/",
    PARAMETER_GROUPS_BASE: "/api/parametergroups/",
    PARAMETER_GROUP_DETAIL: "parametergroupwithapplication/",
    PARAMETER_GROUP_REFERENCE: "verifyreference/",
    PARAMETER_OPTIONS: "parameterOptions/",
    PARAMETERS: "/parameters",
    PROMPT_BASE: "/prompts/",
    PROMPT_MERGE_ACTION: "mergeactionprompt/action/",
    MENUS_BASE: "/api/menus/",
    MENUS_EVENT: "/event",
    MENUS_EVENT_MULTI_DELETE: "/event/deletes",
    SCRIPTS_BASE: "/api/scripts/",
    CALENDAR_BASE: "/api/calendars/public/",
    CALENDAR_SCHEDULES: "/schedules/",
    KEYCLOAK_CONFIG: "/unsecure/api/keycloak/config",
    FRONTEND_CONFIG: "/unsecure/api/frontend/config",
    PERMISSIONS: "/api/frontend/permissions",
    ACTION_BASE: "/api/actions",
    INHERITED_ACTION_APPLICATIONS: "references/",
    LICENSE_BASE: "/api/system/licenseinfo",
    LANGUAGE_BASE: "/unsecure/api/language",
  },
});

export const CUSTOM_PARAMETERS_KEY_VALUE = (__) => {
  let options = [];
  for (let value of Object.values(constants.CUSTOM_PARAMETER_TYPES_CREATE)) {
    options.push({
      value: value,
      text: value.name,
      title: value.name,
    });
  }
  return options;
};

export const APPLICATION_ACTION_TYPE_OPTIONS = (__) => {
  let options = [];
  for (let valueObject of Object.values(constants.ACTION_TYPES)) {
    if (valueObject.showApplication) {
      options.push({
        value: valueObject,
        text: valueObject.name,
      });
    }
  }
  return options;
};

export const ACTION_TYPE_OPTIONS_NO_DEFAULTS = (__) => {
  let options = [];
  options.push({
    value: constants.ACTION_TYPES.APPLICATION,
    text: constants.ACTION_TYPES.APPLICATION.name,
  });
  options.push({
    value: constants.ACTION_TYPES.DISCONNECT,
    text: constants.ACTION_TYPES.DISCONNECT.name,
  });
  options.push({
    value: constants.ACTION_TYPES.TRANSFER,
    text: constants.ACTION_TYPES.TRANSFER.name,
  });
  options.push({
    value: constants.ACTION_TYPES.SCRIPT,
    text: constants.ACTION_TYPES.SCRIPT.name,
  });
  options.push({
    value: constants.ACTION_TYPES.MENU,
    text: constants.ACTION_TYPES.MENU.name,
  });
  return options;
};

export const ACTION_TYPE_NO_DEFAULTS_ORGANISATION = (__) => {
  let options = ACTION_TYPE_OPTIONS_NO_DEFAULTS();
  options.push({
    value: constants.ACTION_TYPES.DEFAULT_OPEN,
    text: constants.ACTION_TYPES.DEFAULT_OPEN.name,
  });
  options.push({
    value: constants.ACTION_TYPES.DEFAULT_CLOSED,
    text: constants.ACTION_TYPES.DEFAULT_CLOSED.name,
  });
  options.push({
    value: constants.ACTION_TYPES.DEFAULT,
    text: constants.ACTION_TYPES.DEFAULT.name,
  });
  return options;
};

export const ORGANISATION_APPLICATION_ACTION_TYPE_OPTIONS = (__) => {
  let options = [];
  for (let valueObject of Object.values(constants.ACTION_TYPES)) {
    if (valueObject.showInOrg) {
      options.push({
        value: valueObject,
        text: valueObject.name,
      });
    }
  }
  return options;
};

export default Object.freeze(constants);
