<template>
  <div>
    <b-form-group :label="this.title">
      <ul
        v-if="componentVariables.selected.length > 0"
        class="list-inline d-inline-block mb-2"
        data-test="defaultSelectedOptions"
      >
        <li
          v-for="option in componentVariables.selected"
          :key="option.id"
          class="list-inline-item"
          :data-test="option.name"
        >
          <b-form-tag
            @remove="removeOption(option)"
            :title="option.name"
            variant="info"
            >{{ option.displayName }}
          </b-form-tag>
        </li>
      </ul>

      <b-dropdown
        size="sm"
        variant="outline-secondary"
        block
        menu-class="w-100 border-dark"
        :disabled="canSelectMore"
      >
        <template v-slot:button-content> {{ $t('parameterDetail.chooseTitle') }} </template>
        <b-dropdown-form @submit.stop.prevent="() => {}" class="border-dark">
          <b-form-group
            :label-for="$id('tag-search-input')"
            :label="$t('searchTags')"
            label-cols-md="auto"
            class="mb-0"
            label-size="sm"
            :description="searchDesc"
          >
            <b-form-input
              v-model="componentVariables.search"
              :id="$id('tag-search-input')"
              type="search"
              size="sm"
              autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button
          v-for="option in availableOptions"
          :key="option.id"
          :title="option.name"
          @click="addOptionClick(option)"
        >
          {{ option.displayName }}
        </b-dropdown-item-button>
        <b-dropdown-text v-if="availableOptions.length === 0">
          {{ $t("parameterDetail.noTags") }}
        </b-dropdown-text>
      </b-dropdown>
    </b-form-group>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "TagsSelector",
  props: {
    options: { type: Array, require: true },
    selection: { type: Array, require: false },
    title: { type: String, require: true },
    multipleSelection: { type: Boolean, default: () => true },
  },
  watch: {
    selection(newVal, oldVal) {
      Vue.$log.debug("changed");
      this.componentVariables.selected = newVal.slice();
    },
  },
  created() {
    if (this.selection) {
      this.componentVariables.selected = this.selection.slice();
    }
  },
  data: () => {
    return {
      componentVariables: {
        search: "",
        selected: [],
      },
    };
  },
  methods: {
    addOptionClick(option) {
      this.componentVariables.selected.push(option);
      this.componentVariables.search = "";
      this.$emit("addedTag", option);
    },
    removeOption(option) {
      this.componentVariables.selected.filter(function (item) {
        return item !== option;
      });
      this.$emit("removedTag", option);
    },
  },
  computed: {
    criteria() {
      return this.componentVariables.search.trim().toLowerCase();
    },
    canSelectMore() {
      if (this.multipleSelection) {
        return !this.multipleSelection;
      } else {
        return this.componentVariables.selected.length > 0;
      }
    },
    availableOptions() {
      const criteria = this.criteria;
      const options = this.options.filter(
        (opt) => this.componentVariables.selected.indexOf(opt) === -1
      );
      if (criteria) {
        return options.filter(
          (opt) => opt.displayName.toLowerCase().indexOf(criteria) > -1
        );
      }
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
};
</script>
