<template>
  <ScheduleForm
    :id="this.id"
    :schedule="this.componentVariables.schedule"
    :schedule-type="scheduleType"
    :showAddButton="true"
    :show-ignore-year-button="true"
    :actual-schedules="this.actualSchedules"
    @AddSchedule="
      (schedule) => {
        this.requestAddSchedule(schedule);
      }
    "
    @CloseFields="
      requestCloseFields();
      initializeSchedule(false);
    "
    @InitializeSchedule="initializeSchedule(toggleKeepInput)"
    :key="this.componentVariables.mutatedSchedule"
    class="align-items-baseline"
  >
    <b-col slot="additionalContent" cols="0" class="ml-2">
      <action-selector
        :key="this.componentVariables.mutatedSchedule"
        :action="this.componentVariables.action"
        :allApplicationsAsOptions="allApplicationsAsOptions"
        :allMenusAsOptions="allMenusAsOptions"
        :allScriptsAsOptions="allScriptsAsOptions"
        :application="application"
        :modalId="$id('ActionScheduleModal')"
        :title="
          this.componentVariables.schedule.description +
          $t('applicationDetail.action.action')
        "
        @actionCreated="
          (action) => {
            this.componentVariables.action = action;
          }
        "
        @actionRemoved="
          (__) => {
            this.componentVariables.action = null;
          }
        "
      >
      </action-selector>
    </b-col>
    <span>
      <b-form-group :label="$t('applicationDetail.schedules.keepValues')">
        <b-form-checkbox type="checkbox" v-model="toggleKeepInput" />
      </b-form-group>
    </span>
  </ScheduleForm>
</template>
<script>
import constants from "../../EndPointsAndConstants.js";
import { getWeekDays } from "../../Helper.js";
import ScheduleForm from "../common/ScheduleForm.vue";
import Vue from "vue";
export default {
  name: "ActionScheduleForm",
  components: { ScheduleForm },
  props: {
    id: { type: String, required: true },
    allApplicationsAsOptions: { type: Array, required: true },
    allMenusAsOptions: { type: Array, required: true },
    allScriptsAsOptions: { type: Array, required: true },
    application: { type: Object, required: true },
    scheduleType: { type: Number, required: true },
    actualSchedules: { type: Array, required: false },
    schedule: {
      type: Object,
      default: () => ({
        action: {},
        startDate: null,
        endDate: null,
        recurring: 0,
        startTime: null,
        endTime: null,
        fk_DayOfWeek: null,
        description: "",
      }),
    },
  },
  data() {
    return {
      date: null,
      toggleKeepInput: true,
      componentVariables: {
        mutatedSchedule: 0,
        constants: constants,
        schedule: {},
        action: {},
      },
    };
  },
  created() {
    this.componentVariables.schedule = Object.assign({}, this.schedule);
    this.componentVariables.schedule.action = Object.assign(
      {},
      this.schedule.action
    );
  },
  methods: {
    initializeSchedule: function (keepInput) {
      if (keepInput !== true) {
        this.componentVariables.action = {};
        this.componentVariables.schedule = {
          action: {},
          startDate: null,
          endDate: null,
          recurring: false,
          startTime: null,
          endTime: null,
          fk_DayOfWeek: null,
          description: "",
        };
      }
      this.componentVariables.mutatedSchedule++;
    },
    requestAddSchedule: function (schedule) {
      Vue.$log.debug("schedule param >>", schedule);
      schedule.action = this.componentVariables.action;
      this.componentVariables.schedule = schedule;
      Vue.$log.debug("adding schedule >>", this.componentVariables.schedule);
      this.$emit("AddActionSchedule", schedule);
    },
    requestCloseFields: function () {
      this.$emit("CloseFields");
    },
    getWeekDays: function (locale) {
      return getWeekDays(locale);
    },
  },
};
</script>
