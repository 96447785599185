import Vue from "vue";
const state = {
  variant: "success",
  title: "success",
  text: null,
  toast: null,
  keepOnRouting: false,
};

const getters = {
  getVariant() {
    return state.variant;
  },
  getToast() {
    Vue.$log.debug("Getting Toasts");
    return state.toast;
  },
  getKeepOnRouting() {
    return state.keepOnRouting;
  },
};

const actions = {
  addToast({ commit }, toast) {
    Vue.$log.debug("Adding Toast Action: ", toast);
    commit("ADD_TOAST", toast);
  },
  removeToast({ commit }) {
    commit("REMOVE_TOAST");
  },
};

const mutations = {
  SET_VARIANT(state, variant) {
    state.variant = variant;
  },
  ADD_TOAST(state, toast) {
    Vue.$log.debug("Pushing Toast Mutation: ", toast);
    state.toast = {};
    state.toast = toast;
  },
  SET_KEEPONROUTING(state, bool) {
    state.keepOnRouting = bool;
  },
  REMOVE_TOAST(state) {
    Vue.$log.debug("Nullifying toast");
    state.toast = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
