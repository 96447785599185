<template>
  <b-form inline>
    <b-form-group class="mr-2" description="Calendar name">
      <b-form-input v-model="pageVariables.calendar.name"></b-form-input>
    </b-form-group>
    <b-form-group class="mr-2" description="Calendar Display name">
      <b-form-input v-model="pageVariables.calendar.displayName"></b-form-input>
    </b-form-group>
    <b-form-group description="Add Calendar" class="ml-2">
      <b-button variant="outline-secondary" @click="requestAddCalendar"
        ><font-awesome-icon icon="plus"
      /></b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NewCalendar",
  props: {
    calendar: {
      type: Object,
      default: () => ({
        name: null,
        displayName: null,
      }),
    },
  },
  data() {
    return { pageVariables: { calendar: {} } };
  },
  computed: {},
  methods: {
    ...mapActions("calendars", ["addCalendar"]),
    requestAddCalendar() {
      this.addCalendar(this.calendar);
    },
  },
};
</script>
