var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-tab',{staticClass:"text-success font-weight-bold",attrs:{"active":"","lazy":"","active-tab-class":"font-weight-bold text-success"}},[_c('ListWithSearchField',{attrs:{"fields":_vm.fields,"fields-for-csv":this.pageVariables.fieldsForCsv,"filter-function":this.filterScripts,"filter-on":this.pageVariables.filterOn,"item-provider":this.getScripts,"update-key":this.pageVariables.updateTableKey,"search-text":_vm.$t('scriptPage.scriptSearchText'),"title":_vm.$t('scriptPage.scriptCardTitle'),"is-busy":this.pageVariables.isLoading},on:{"rowSelected":function (item) { return _vm.rowSelected(item); }},scopedSlots:_vm._u([(
            this.getPermissions().script.permission ===
            this.getPERMISSION_TYPES().ALL
          )?{key:"additionalContent",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"ml-1 mb-2 p-3 border shadow rounded",attrs:{"fluid":"","inline":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createNewScript)}}},[_c('legend',{staticClass:"font-weight-bold text-success",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t("scriptPage.createScript"))+" ")]),_c('ValidationProvider',{attrs:{"rules":'required|min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('scriptPage.scriptNameField'),"label-class":"ml-1"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"nameInput","data-test":"input-text-scriptName","placeholder":_vm.$t('scriptPage.scriptNameField'),"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.restVariables.script.name),callback:function ($$v) {_vm.$set(_vm.restVariables.script, "name", $$v)},expression:"restVariables.script.name"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"nameInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":'required|min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('scriptPage.scriptDisplayNameField'),"label-class":"ml-1"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"displayInput","data-test":"input-text-scriptDisplayName","placeholder":_vm.$t('scriptPage.scriptDisplayNameField'),"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.restVariables.script.displayName),callback:function ($$v) {_vm.$set(_vm.restVariables.script, "displayName", $$v)},expression:"restVariables.script.displayName"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"displayInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":'min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
          var touched = ref.touched;
return [_c('b-form-group',{attrs:{"label":_vm.$t('scriptPage.scriptDescriptionField'),"label-class":"ml-1"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"descInput","data-test":"input-text-scriptDescription","placeholder":_vm.$t('scriptPage.scriptDescriptionField'),"state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.restVariables.script.description),callback:function ($$v) {_vm.$set(_vm.restVariables.script, "description", $$v)},expression:"restVariables.script.description"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"descInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('b-button',{staticClass:"ml-1 align-self-end",attrs:{"data-test":"btn-createNewScript","type":"submit"}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)]}}],null,false,2779853774)})]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }