import constants from "./EndPointsAndConstants";
import axios from "axios";
import store from "./store";

export const getWeekDays = (locale) => {
  let baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  let weekDays = [];
  for (let i = 1; i < 8; i++) {
    weekDays.push({
      value: i,
      text: baseDate.toLocaleDateString(locale, { weekday: "long" }),
    });
    baseDate.setDate(baseDate.getDate() + 1);
  }
  weekDays.push({ value: null, text: "Please select a day", disabled: true });
  return weekDays;
};
export function getDayName(locale, day) {
  let baseDate = new Date(Date.UTC(2017, 0, 1));
  baseDate.setDate(baseDate.getDate() + day);
  return baseDate.toLocaleDateString(locale, { weekday: "long" });
}

export function populateOptions(dataUrl, arrayToPopulate, id) {
  if (dataUrl === constants.endPoints.APPLICATIONS_BASE) {
    return axios.get(dataUrl).then((response) => {
      response.data
        .filter((item) => {
          return item.organization == false;
        })
        .forEach((option) => {
          if (option.id !== id) {
            arrayToPopulate.push({
              value: option.id,
              text: option.displayName,
            });
          }
        });
    });
  } else {
    return axios.get(dataUrl).then((response) => {
      response.data.forEach((option) => {
        if (option.id !== id) {
          arrayToPopulate.push({ value: option.id, text: option.displayName });
        }
      });
    });
  }
}

export function openUnsavedModal(vm, to, from, next) {
  vm.$children[0].$children[0].$parent.$refs.leaveModal.show(); // TODO Maybe find something better than childrenschildsparent
  vm.$nextTick(() => {
    vm.$children[0].$children[0].$parent.$refs.leaveModal.$once(
      "hide",
      (bvEvt) => {
        if (bvEvt.trigger === "ok") {
          vm.$children[0].$children[0].$parent.$refs.leaveModal.$once(
            "hidden",
            () => {
              next();
            }
          );
          store.dispatch("changes/setUnsavedChanges", false);
        } else {
          next(false);
        }
      }
    );
  });
}

export function containsNoNulls(schedule) {
  if (schedule.action.fk_ActionType != null) {
    if (
      schedule.action.fk_Application != null ||
      schedule.action.fk_Menu != null ||
      schedule.action.fk_Script != null ||
      schedule.action.disabled != null ||
      schedule.action.value != null ||
      schedule.action.fk_ActionType == "DEFAULT" ||
      schedule.action.fk_ActionType == "DEFAULT_OPEN" ||
      schedule.action.fk_ActionType == "DEFAULT_CLOSED" ||
      schedule.action.fk_ActionType == "DISCONNECT"
    ) {
      return true;
    } else {
      return false;
    }
  }
}
