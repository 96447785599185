import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
  unsavedChanges: false,
};

const getters = {
  getUnsavedChanges: (state) => {
    return state.unsavedChanges;
  },
};

const actions = {
  setUnsavedChanges({ commit }, bool) {
    Vue.$log.debug("Setting unsaved changes to ", bool);
    commit("SET_UNSAVED_CHANGES", bool);
  },
};

const mutations = {
  SET_UNSAVED_CHANGES(state, value) {
    state.unsavedChanges = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
