<template>
  <b-container>
    <b-modal ref="mainModal" :title="this.modalTitle" :hide-footer="hideFooter">
      <p v-if="!isBlocked">
        {{ modalFunction }} {{ objectTitle }} {{ IDReference }}?
      </p>
      <p v-if="isBlocked">
        {{ modalFunction }} {{ objectTitle }} {{ IDReference }}
        {{ $t("referenceFound") }}
      </p>
      <b-list-group>
        <b-list-group-item
          v-for="(reference, index) in references"
          v-bind:key="index"
        >
          <b-link v-on:click="reroute(reference.type, reference.id)"
            >{{ reference.name }} ({{ $t("references." + reference.type) }},
            {{ reference.id }})
          </b-link>
          <b-list-group v-bind:disabled="reference.links !== null">
            <b-list-group-item
              v-for="(link, index) in reference.links"
              v-bind:key="index"
            >
              <b-link v-on:click="reroute(reference.type, link.from)">
                From: {{ JSON.stringify(link.from) }}</b-link
              >
              <b-link v-on:click="reroute(reference.type, link.to)"
                >, To: {{ JSON.stringify(link.to) }}</b-link
              >
            </b-list-group-item>
          </b-list-group>
        </b-list-group-item>
      </b-list-group>
      <template #modal-footer>
        <b-button v-if="showRefreshButton" v-on:click="reloadMethod()">{{
          refresh
        }}</b-button>
        <b-button
          :disabled="isBlocked"
          data-test="btn_modal_confirm"
          variant="danger"
          v-on:click="fireMethod()"
          >{{ ok }}</b-button
        >
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from "vue";
// When implementing this modal, use @targetMethod="[YourTargetMethodHere]"
// When implementing this modal, use @reloadReferencesMethod="[YourLoadReferencesMethodHere]" if necessary
export default {
  name: "ReferenceModal",
  props: {
    IDReference: { type: Number },
    objectTitle: { type: String, default: null },
    modalTitle: { type: String },
    okButtonText: { type: String },
    cancelButtonText: { type: String },
    refreshButtonText: { type: String },
    references: { type: Array, default: null },
    showRefreshButton: { type: Boolean, default: false },
    containsReferences: { type: Boolean, default: false },
    modalFunction: { type: String, default: "function" },
    hideFooter: { type: Boolean, default: false },
  },
  computed: {
    isBlocked: function () {
      let blocker;
      if (this.references != null) {
        if (this.references.length < 1) {
          blocker = false;
        } else {
          blocker = true;
        }
      }
      return blocker;
    },
    ok: function () {
      if (this.okButtonText == null) {
        return this.$t("okButtonText");
      } else {
        return this.okButtonText;
      }
    },
    cancel: function () {
      if (this.cancelButtonText == null) {
        return this.$t("cancelButtonText");
      } else {
        return this.cancelButtonText;
      }
    },
    refresh: function () {
      if (this.refreshButtonText == null) {
        return this.$t("applicationDetail.generalSettings.refreshList");
      } else {
        return this.refreshButtonText;
      }
    },
  },
  methods: {
    showModal() {
      this.$refs.mainModal.show();
    },
    hideModal() {
      this.$refs.mainModal.hide();
    },
    fireMethod() {
      Vue.$log.debug("references: " + this.references);
      if (this.references === null || !this.references.entries() > 0) {
        //TODO MARTIN MUST FIX, not allowed to mutate property
        //this.references = null;
      }
      this.$emit("targetMethod");
      this.hideModal();
    },
    reloadMethod() {
      Vue.$log.debug(this.references);
      this.$emit("reloadReferencesMethod");
    },
    reroute(referenceType, reference) {
      if (
        referenceType === "DefaultClose" ||
        referenceType === "DefaultOpen" ||
        referenceType === "DefaultApplication" ||
        referenceType === "Schedule" ||
        referenceType === "ORG"
      ) {
        window.open("#/" + "application/" + reference, "_blank");
      }

      if (referenceType === "Menu") {
        window.open("#/" + "menu/detail/" + reference, "_blank");
      }
    },
  },
};
</script>
