var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-tab',{staticClass:"text-success font-weight-bold",attrs:{"active":"","lazy":"","active-tab-class":"font-weight-bold text-success"}},[_c('ListWithSearchField',{attrs:{"title":_vm.$t('menuPage.menus'),"update-key":this.pageVariables.updateTableKey,"fields":this.fields,"fields-for-csv":this.pageVariables.fieldsForCsv,"filter-on":this.pageVariables.filterOn,"item-provider":this.getMenus,"search-text":_vm.$t('menuPage.menuSearchField'),"is-busy":this.pageVariables.isLoading},on:{"rowSelected":function (item) { return _vm.onRowSelected(item); }},scopedSlots:_vm._u([(
            this.getPermissions().menu.permission ===
            this.getPERMISSION_TYPES().ALL
          )?{key:"additionalContent",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('b-form-group',{staticClass:"",attrs:{"inline":""}},[_c('b-form',{staticClass:"ml-1 mb-2",attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.createMenu(_vm.restVariables.menu))}}},[_c('legend',{staticClass:"font-weight-bold text-success",attrs:{"slot":"label","data-test":"createMenuTitle"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t("menuPage.createMenu"))+" ")]),_c('ValidationProvider',{attrs:{"rules":'required|min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('menuPage.createMenuFieldName'),"label-class":"ml-1"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"nameInput","data-test":"input-text-menuName","placeholder":_vm.$t('menuPage.createMenuFieldName'),"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.restVariables.menu.name),callback:function ($$v) {_vm.$set(_vm.restVariables.menu, "name", $$v)},expression:"restVariables.menu.name"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"nameInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":'required|min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"align-self-center",attrs:{"label":_vm.$t('menuPage.createMenuFieldDisplayName'),"label-class":"ml-1"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"displayInput","data-test":"input-text-menuDisplayName","placeholder":_vm.$t('menuPage.createMenuFieldDisplayName'),"aria-label":_vm.$t('menuPage.createMenuFieldDisplayName'),"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.restVariables.menu.displayName),callback:function ($$v) {_vm.$set(_vm.restVariables.menu, "displayName", $$v)},expression:"restVariables.menu.displayName"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"displayInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('b-button',{staticClass:"ml-1 align-self-end",attrs:{"data-test":"btn-createMenu"},on:{"click":function($event){return _vm.createMenu(_vm.restVariables.menu)}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)],1)]}}],null,false,2779329536)})]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }