<template>
  <b-card class="shadow" no-body v-if="pageVariables.showContent">
    <b-card-header>{{ restVariables.script.displayName }}</b-card-header>
    <b-card-body>
      <b-row class="justify-content-center">
        <b-col class="mb-3" cols="12">
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit="(event) => event.preventDefault()">
              <b-form-group class="ml-1 mb-2" inline>
                <validation-provider
                  :rules="'required|min:1|max:' + pageVariables.fieldLength"
                  v-slot="{ valid, errors, touched }"
                >
                  <b-form-group
                    id="input-group-2"
                    :label="$t('scriptPage.name')"
                    label-for="input-2"
                  >
                    <b-input
                      id="input-2"
                      v-model="restVariables.script.name"
                      class="ml-1"
                      :placeholder="$t('scriptPage.scriptNameField')"
                      description="Name"
                      data-test="scriptName"
                      :state="
                        errors[0] ? false : valid && touched ? true : null
                      "
                    />
                    <b-form-invalid-feedback tag="span">{{
                      errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  :rules="'required|min:1|max:' + pageVariables.fieldLength"
                  v-slot="{ valid, errors, touched }"
                >
                  <b-form-group
                    id="input-group-3"
                    :label="$t('scriptPage.displayName')"
                    label-for="input-3"
                  >
                    <b-input
                      id="input-3"
                      v-model="restVariables.script.displayName"
                      class="ml-1"
                      :placeholder="$t('scriptPage.scriptDisplayNameField')"
                      data-test="scriptDisplayName"
                      :state="
                        errors[0] ? false : valid && touched ? true : null
                      "
                    />
                    <b-form-invalid-feedback tag="span">{{
                      errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  :rules="'max:' + pageVariables.fieldLength"
                  v-slot="{ valid, errors, touched }"
                >
                  <b-form-group
                    id="input-group-4"
                    :label="$t('scriptPage.description')"
                    label-for="input-4"
                  >
                    <b-input
                      id="input-4"
                      v-model="restVariables.script.description"
                      class="ml-1"
                      :placeholder="$t('scriptPage.scriptDescriptionField')"
                      data-test="scriptDescription"
                      :state="
                        errors[0] ? false : valid && touched ? true : null
                      "
                    />
                    <b-form-invalid-feedback tag="span">{{
                      errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-button
                  class="mr-1"
                  variant="primary"
                  @click="
                    handleSubmit((__) => {
                      saveLocal();
                    })
                  "
                  >{{ $t("scriptDetails.update") }}
                </b-button>
                <b-button
                  class="mr-1"
                  variant="warning"
                  @click="gotoScriptPage()"
                  >{{ $t("scriptDetails.cancel") }}
                </b-button>
                <b-button
                  data-test="btn_delete_script"
                  class="mr-1"
                  variant="danger"
                  @click="openDeleteModal()"
                  >{{ $t("scriptDetails.delete") }}
                </b-button>
              </b-form-group>
            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
      <reference-modal
        :i-d-reference="this.id"
        :object-type="'Script'"
        :object-title="restVariables.script.displayName"
        @targetMethod="deleteLocal()"
        :modal-title="$t('scriptDetails.deleteModalTitle')"
        :modal-function="$t('deleteReferenceFunction')"
        ref="deleteModal"
      />
    </b-card-body>
  </b-card>
  <b-col
    v-else
    style="display: flex; align-items: center; justify-content: center"
  >
    <b-spinner
      class="mb-3 text-white"
      :label="$t('loading')"
      style="width: 5rem; height: 5rem"
  /></b-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import Vue from "vue";
import ReferenceModal from "../components/common/ReferenceModal";

export default {
  name: "ScriptDetail",
  props: {
    id: Number,
  },
  components: { ReferenceModal },
  data() {
    return {
      restVariables: {
        script: {
          id: 0,
          name: "",
          displayName: "",
          description: "",
        },
      },
      pageVariables: {
        showContent: false,
        fieldLength: this.$maxLength,
      },
    };
  },

  methods: {
    ...mapGetters("script", ["getScript"]),
    ...mapActions("script", ["fetchScript", "saveScript", "deleteScript"]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    saveLocal() {
      this.saveScript({ vm: this, script: this.restVariables.script }).then(
        (__) => {
          this.setUnsavedChanges(false);
        }
      );
    },
    openDeleteModal() {
      Vue.$log.info("OPENING MODAL");
      this.$refs.deleteModal.showModal();
    },
    deleteLocal() {
      this.deleteScript({ vm: this, script: this.restVariables.script }).then(
        function () {
          this.gotoScriptPage();
        }.bind(this)
      );
    },
    gotoScriptPage() {
      router.push({ name: "script" });
    },
  },
  mounted() {
    this.fetchScript(this.id)
      .then((__) => {
        this.restVariables.script = Object.assign({}, this.getScript());
      })
      .then((__) => {
        this.pageVariables.showContent = true;
      });
    document.title =
      this.$t("pageTitles.scriptDetail") + " " + this.getScript().name;
  },
};
</script>
