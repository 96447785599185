<template>
  <b-card no-body>
    <b-tab
      active
      lazy
      active-tab-class="font-weight-bold text-success"
      class="text-success font-weight-bold"
    >
      <ListWithSearchField
        :fields="fields"
        :fields-for-csv="this.pageVariables.fieldsForCsv"
        :filter-function="this.filterScripts"
        :filter-on="this.pageVariables.filterOn"
        :item-provider="this.getScripts"
        :update-key="this.pageVariables.updateTableKey"
        @rowSelected="(item) => rowSelected(item)"
        :search-text="$t('scriptPage.scriptSearchText')"
        :title="$t('scriptPage.scriptCardTitle')"
        :is-busy="this.pageVariables.isLoading"
      >
        <template
          v-slot:additionalContent
          v-if="
            this.getPermissions().script.permission ===
            this.getPERMISSION_TYPES().ALL
          "
        >
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <b-form
              class="ml-1 mb-2 p-3 border shadow rounded"
              fluid
              inline
              @submit.prevent="handleSubmit(createNewScript)"
            >
              <legend slot="label" class="font-weight-bold text-success">
                {{ $t("scriptPage.createScript") }}
              </legend>
              <ValidationProvider
                :rules="'required|min:1|max:' + pageVariables.fieldLength"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  :label="$t('scriptPage.scriptNameField')"
                  label-class="ml-1"
                >
                  <b-input
                    id="nameInput"
                    data-test="input-text-scriptName"
                    class="ml-1"
                    :placeholder="$t('scriptPage.scriptNameField')"
                    v-model="restVariables.script.name"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-tooltip
                      v-if="errors[0]"
                      :show="errors[0] != null"
                      target="nameInput"
                      variant="danger"

                  >{{ errors[0] }}</b-tooltip>
<!--                  <b-form-invalid-feedback v-if="restVariables.script.name.length > 0" tag="span"-->
<!--                    >{{ errors[0] }}-->
<!--                  </b-form-invalid-feedback>-->
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                :rules="'required|min:1|max:' + pageVariables.fieldLength"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  :label="$t('scriptPage.scriptDisplayNameField')"
                  label-class="ml-1"
                >
                  <b-input
                    id="displayInput"
                    data-test="input-text-scriptDisplayName"
                    class="ml-1"
                    :placeholder="$t('scriptPage.scriptDisplayNameField')"
                    v-model="restVariables.script.displayName"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-tooltip
                      v-if="errors[0]"
                      :show="errors[0] != null"
                      target="displayInput"
                      variant="danger"

                  >{{ errors[0] }}</b-tooltip>
<!--                  <b-form-invalid-feedback v-if="restVariables.script.displayName.length > 0" tag="span"-->
<!--                    >{{ errors[0] }}-->
<!--                  </b-form-invalid-feedback>-->
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                :rules="'min:1|max:' + pageVariables.fieldLength"
                v-slot="{ valid, errors, touched }"
              >
                <b-form-group
                  :label="$t('scriptPage.scriptDescriptionField')"
                  label-class="ml-1"
                >
                  <b-input
                    id="descInput"
                    data-test="input-text-scriptDescription"
                    class="ml-1"
                    :placeholder="$t('scriptPage.scriptDescriptionField')"
                    v-model="restVariables.script.description"
                    :state="errors[0] ? false : valid && touched ? true : null"
                  />
                  <b-tooltip
                      v-if="errors[0]"
                      :show="errors[0] != null"
                      target="descInput"
                      variant="danger"

                  >{{ errors[0] }}</b-tooltip>
<!--                  <b-form-invalid-feedback v-if="restVariables.script.description.length > 0" tag="span"-->
<!--                    >{{ errors[0] }}-->
<!--                  </b-form-invalid-feedback>-->
                </b-form-group>
              </ValidationProvider>

              <b-button
                data-test="btn-createNewScript"
                type="submit"
                class="ml-1 align-self-end"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-form>
          </ValidationObserver>
        </template>
      </ListWithSearchField>
    </b-tab>
  </b-card>
</template>

<script>
import axios from "axios";
import router from "../router";
import constants from "../EndPointsAndConstants";
import { mapActions, mapGetters } from "vuex";
import ListWithSearchField from "../components/common/ListWithSearchField";
import Vue from "vue";
import { makeToast } from "../store/mixin/basic";

export default {
  name: "ScriptManagement",
  components: { ListWithSearchField },
  data() {
    return {
      restVariables: {
        script: {
          name: "",
          displayName: "",
          description: "",
        },
      },
      pageVariables: {
        updateTableKey: 0,
        filterOn: ["name", "displayname"],
        fieldsForCsv: ["id", "name", "displayName", "description"],
        isLoading: true,
        fieldLength: this.$maxLength,
      },
    };
  },

  methods: {
    ...mapActions("scripts", ["fetchScripts"]),
    ...mapGetters("scripts", ["getScripts"]),
    ...mapGetters("authentication", ["getPermissions"]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    getPERMISSION_TYPES() {
      return constants.PERMISSION_TYPES;
    },
    filterScripts(item, filter) {
      if (
        item.displayName.toLowerCase().includes(filter.toLowerCase()) ||
        item.name.toLowerCase().includes(filter.toLowerCase())
      ) {
        return true;
      }
      return false;
    },
    rowSelected(item) {
      router.push({
        name: "scriptDetail",
        params: { id: item.id },
      });
    },
    fetchAndRedraw() {
      this.pageVariables.isLoading = true;
      return this.fetchScripts().then((__) => {
        this.updateTable();
        this.pageVariables.isLoading = false;
      });
    },
    updateTable() {
      Vue.$log.debug("should update");
      this.pageVariables.updateTableKey += 1;
    },
    createNewScript() {
      axios
        .post(constants.endPoints.SCRIPTS_BASE, {
          name: this.restVariables.script.name,
          displayName: this.restVariables.script.displayName,
          description: this.restVariables.script.description,
        })
        .then((data) => {
          this.fetchAndRedraw();
          this.restVariables.script.name = "";
          this.restVariables.script.displayName = "";
          this.restVariables.script.description = "";
          this.pageVariables.updateTableKey += 1;
          makeToast(
            this,
            this.$t("toasts.script.savedSuccessful") + data.data,
            "success"
          );
        })
        .then((__) => {
          this.setUnsavedChanges(false);
        })
        .then(
          requestAnimationFrame(() => {
            this.$refs.observer.reset();
          })
        )
        .catch((__) => {
          makeToast(this, this.$t("toasts.script.savedFailed"), "danger");
        });
    },
  },
  created() {
    this.fetchAndRedraw();
    document.title = this.$t("pageTitles.scripts");
  },
  computed: {
    fields: function () {
      return [
        {
          key: "name",
          label: this.$t("scriptPage.name"),
          thStyle: "width: 30%",
        },
        {
          key: "displayName",
          label: this.$t("scriptPage.displayName"),
          thStyle: "width: 30%",
        },
        {
          key: "description",
          label: this.$t("scriptPage.description"),
          thStyle: "width: 30%",
        },
      ];
    },
  },
};
</script>
