var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":"","data-test":"ParameterPage"}},[_c('b-tabs',{attrs:{"active-nav-item-class":"font-weight-bold text-uppercase text-primary","active-tab-class":"font-weight-bold text-success pt-0","card":""},model:{value:(_vm.choose),callback:function ($$v) {_vm.choose=$$v},expression:"choose"}},[_c('b-tab',{attrs:{"active":"","lazy":"","title":_vm.$t('pgManagement.parameterGroupTab'),"id":"parameter-group-tab","data-test":"parameter-group"}},[_c('b-row',{staticClass:"mb-3"},[_c('ListWithSearchField',{attrs:{"fields":_vm.parameterGroupFields,"fields-for-csv":this.pageVariables.parameterGroupFieldsForCsv,"filter-on":this.pageVariables.filterOn,"item-provider":this.getParameterGroups,"update-key":this.pageVariables.updateParameterGroupTableKey,"search-text":_vm.$t('pgManagement.parameterGroup.PGSearchText'),"title":_vm.$t('pgManagement.parameterGroup.PGCardTitle'),"data-test":"ParameterGroupListPage","is-busy":this.pageVariables.isLoadingParameterGroups},on:{"rowSelected":function (item) { return _vm.rowParameterGroupSelected(item); }},scopedSlots:_vm._u([(
                this.getPermissions().parametergroup.permission ===
                this.getPERMISSION_TYPES().ALL
              )?{key:"additionalContent",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"ml-1 mb-2 p-3 border shadow rounded",attrs:{"fluid":"","inline":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createNewParameterGroup)}}},[_c('legend',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t("pgManagement.parameterGroup.createPG"))+" ")]),_c('ValidationProvider',{attrs:{"rules":'required|min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var valid = ref.valid;
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('pgManagement.parameterGroup.pgNameField')}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"nameInput","data-test":"text-parameter-group-name","placeholder":_vm.$t('pgManagement.parameterGroup.pgNameField'),"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.pageVariables.parameterGroup.name),callback:function ($$v) {_vm.$set(_vm.pageVariables.parameterGroup, "name", $$v)},expression:"pageVariables.parameterGroup.name"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"nameInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":'required|min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var valid = ref.valid;
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('pgManagement.parameterGroup.pgDisplayNameField')}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"displayInput","data-test":"text-parameter-group-display-name","placeholder":_vm.$t('pgManagement.parameterGroup.pgDisplayNameField'),"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.pageVariables.parameterGroup.displayName),callback:function ($$v) {_vm.$set(_vm.pageVariables.parameterGroup, "displayName", $$v)},expression:"pageVariables.parameterGroup.displayName"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"displayInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('b-button',{staticClass:"ml-1 align-self-end",attrs:{"data-test":"btn-create-parameter-group","type":"submit"}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)]}}],null,false,3238063087)})]},proxy:true}:null],null,true)})],1)],1),_c('b-tab',{attrs:{"lazy":"","title":_vm.$t('pgManagement.parameterTab'),"id":"parameter-tab","data-test":"parameter-tab"}},[_c('b-row',{staticClass:"mb-3",attrs:{"data-test":"parameters"}},[_c('ListWithSearchField',{attrs:{"fields":_vm.parameterFields,"fields-for-csv":this.pageVariables.parameterFieldsForCsv,"filter-on":this.pageVariables.filterOn,"item-provider":this.getParameters,"update-key":this.pageVariables.updateParameterTableKey,"search-text":_vm.$t('pgManagement.parameter.PSearchText'),"title":_vm.$t('pgManagement.parameter.PCardTitle'),"data-test":"ParametersListPage","is-busy":this.pageVariables.isLoadingParameters},on:{"rowSelected":function (item) { return _vm.rowParameterSelected(item); }},scopedSlots:_vm._u([(
                this.getPermissions().parameter.permission ===
                this.getPERMISSION_TYPES().ALL
              )?{key:"additionalContent",fn:function(){return [_c('b-form',{staticClass:"border shadow rounded",attrs:{"fluid":"","inline":""}},[_c('b-form-group',{staticClass:"ml-1 mb-2"},[_c('legend',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t("pgManagement.parameter.createParameter"))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"data-test":"btn-add-parameter"},on:{"click":function($event){return _vm.createParameter()}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)],1)]},proxy:true}:null],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }