var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-tab',{staticClass:"text-success font-weight-bold",attrs:{"active":"","lazy":"","active-tab-class":"font-weight-bold text-success"}},[_c('ListWithSearchField',{attrs:{"fields":_vm.fields,"fields-for-csv":this.pageVariables.fieldsForCsv,"filter-function":this.filterApplications,"filter-on":this.pageVariables.filterOn,"item-provider":_vm.getApplicationsAndExpandWithRowVariant,"update-key":this.pageVariables.updateTableKey,"search-text":_vm.$t('applicationPage.applicationSearchField'),"title":_vm.$t('applicationPage.applications'),"is-busy":this.pageVariables.isLoading},on:{"rowSelected":function (item) { return _vm.rowSelected(item); }},scopedSlots:_vm._u([{key:"additionalSearch",fn:function(){return [_c('b-form-radio-group',{staticClass:"ml-1",attrs:{"id":"radio-group-2","label":"Show"},on:{"input":_vm.updateTable},model:{value:(_vm.pageVariables.applicationType),callback:function ($$v) {_vm.$set(_vm.pageVariables, "applicationType", $$v)},expression:"pageVariables.applicationType"}},[_c('b-form-radio',{attrs:{"name":"Applications","value":"application","data-ignore-change":""}},[_vm._v(_vm._s(_vm.$t("applicationPage.applications")))]),_c('b-form-radio',{attrs:{"name":"Organizations","value":"organisation","data-ignore-change":""}},[_vm._v(_vm._s(_vm.$t("applicationPage.organizations")))]),_c('b-form-radio',{attrs:{"name":"All","value":"all","data-ignore-change":""}},[_vm._v(_vm._s(_vm.$t("applicationPage.all")))])],1)]},proxy:true},(
            this.getPermissions().application.permission ===
            this.getPERMISSION_TYPES().ALL
          )?{key:"additionalContent",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"ml-1 mb-2 p-3 border shadow rounded",attrs:{"fluid":"","inline":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createNewApplication)}}},[_c('legend',{staticClass:"font-weight-bold text-success",attrs:{"slot":"label","data-test":"createApplicationTitle"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t("applicationPage.createApplication"))+" ")]),_c('ValidationProvider',{attrs:{"rules":'required|min:1|max:' + _vm.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('applicationPage.createApplicationFieldName'),"label-class":"ml-1 align-self-start"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"nameInput","data-test":"input-text-applicationName","placeholder":_vm.$t('applicationPage.createApplicationFieldName'),"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.restVariables.application.name),callback:function ($$v) {_vm.$set(_vm.restVariables.application, "name", $$v)},expression:"restVariables.application.name"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"nameInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":'required|min:1|max:' + _vm.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"align-self-start",attrs:{"label":_vm.$t('applicationPage.createApplicationFieldDisplayName'),"label-class":"ml-1"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"displayInput","data-test":"input-text-applicationDisplayName","placeholder":_vm.$t('applicationPage.createApplicationFieldDisplayName'),"aria-label":_vm.$t('applicationPage.createApplicationFieldDisplayName'),"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.restVariables.application.displayName),callback:function ($$v) {_vm.$set(_vm.restVariables.application, "displayName", $$v)},expression:"restVariables.application.displayName"}}),(errors[0])?_c('b-tooltip',{attrs:{"show":errors[0] != null,"target":"displayInput","variant":"danger"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),_c('b-form',{staticClass:"align-self-start ml-2"},[_c('label',[_vm._v(_vm._s(_vm.$t("applicationPage.organization")))]),_c('b-checkbox',{attrs:{"data-test":"input-check-organisation"},model:{value:(_vm.restVariables.application.organization),callback:function ($$v) {_vm.$set(_vm.restVariables.application, "organization", $$v)},expression:"restVariables.application.organization"}})],1),_c('b-button',{staticClass:"ml-1 align-self-end",attrs:{"id":"createNewApplication","data-test":"btn-createNewApplication","type":"submit"}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)]}}],null,false,3619101939)})]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }