<template>
  <ValidationObserver ref="menuObserver" v-slot="{ errors }">
    <b-card
      :header="$t('menuDetails.pageTitle') + ' : ' + componentVariables.title"
    >
      <b-spinner
        class="align-self-auto"
        v-if="componentVariables.showContent === false"
      />
      <b-card-body class="pt-0" v-if="componentVariables.showContent === true">
        <b-row>
          <b-col>
            <b-card>
              <validation-provider
                :rules="'required|max:' + componentVariables.fieldLength"
                v-slot="{ valid, errors, touched }"
              >
                <b-form-group :label="$t('menuDetails.name')">
                  <b-form-input
                    v-model="name"
                    type="text"
                    data-test="input_menu_name"
                    :state="errors[0] ? false : valid && touched ? true : null"
                  />
                  <b-form-invalid-feedback tag="span">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                :rules="'required|max:' + componentVariables.fieldLength"
                v-slot="{ valid, errors, touched }"
              >
                <b-form-group :label="$t('menuDetails.displayName')">
                  <b-form-input
                    v-model="displayName"
                    type="text"
                    data-test="input_menu_displayname"
                    :state="errors[0] ? false : valid && touched ? true : null"
                  />
                  <b-form-invalid-feedback tag="span">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                :rules="'max:' + componentVariables.fieldLength"
                v-slot="{ valid, errors, touched }"
              >
                <b-form-group :label="$t('menuDetails.description')">
                  <b-form-input
                    v-model="description"
                    type="text"
                    data-test="input_menu_description"
                    :state="errors[0] ? false : valid && touched ? true : null"
                  />
                  <b-form-invalid-feedback tag="span">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="optionsList"
                rules="required|min_value:1|max_value:120"
                v-slot="{ valid, errors, touched }"
              >
                <b-form-group :label="$t('menuDetails.timeout')">
                  <b-form-input
                    v-model.number="timeout"
                    type="number"
                    :state="errors[0] ? false : valid && touched ? true : null"
                    data-test="input_menu_timeout"
                  />
                </b-form-group>
              </validation-provider>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="h-100">
              <b-form-group :label="$t('menuDetails.initPrompts')">
                <b-form-row data-test="init_prompts">
                  <b-col>
                    <Prompts
                      :title="'init'"
                      :id="'initPrompts'"
                      :prompt-list="getMainPrompts('Init')"
                      @updateList="
                        (prompts) => {
                          saveMainPrompts({
                            eventName: 'Init',
                            prompts: prompts,
                          });
                          keyReRender.initPromptsKey++;
                        }
                      "
                      :horizontal="true"
                      :show-button-title="false"
                    />
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group :label="$t('menuDetails.introPrompts')">
                <b-form-row data-test="intro_prompts">
                  <b-col>
                    <Prompts
                      :title="'intro'"
                      :id="'introPrompt'"
                      :prompt-list="getMainPrompts('Intro')"
                      @updateList="
                        (prompts) => {
                          saveMainPrompts({
                            eventName: 'Intro',
                            prompts: prompts,
                          });
                          keyReRender.introPromptKey++;
                        }
                      "
                      :horizontal="true"
                      :show-button-title="false"
                    />
                  </b-col>
                </b-form-row>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="h-100">
              <b-form-group :label="$t('menuDetails.timeoutPrompts')">
                <b-form-row data-test="timeout_prompts">
                  <b-col>
                    <Prompts
                      :title="'timeout'"
                      :id="'timeoutPrompts'"
                      :prompt-list="getMainPrompts('Timeout')"
                      @updateList="
                        (prompts) => {
                          saveMainPrompts({
                            eventName: 'Timeout',
                            prompts: prompts,
                          });
                          keyReRender.timeoutPromptsKey++;
                        }
                      "
                      :horizontal="true"
                      :show-button-title="false"
                    />
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group :label="$t('menuDetails.wrongInputPrompts')">
                <b-form-row data-test="wrong_input_prompts">
                  <b-col>
                    <Prompts
                      :title="'wrongInput'"
                      :id="'wrongInputPrompts'"
                      :prompt-list="getMainPrompts('WrongInput')"
                      @updateList="
                        (prompts) => {
                          saveMainPrompts({
                            eventName: 'WrongInput',
                            prompts: prompts,
                          });
                          keyReRender.wrongInputPromptsKey++;
                        }
                      "
                      :horizontal="true"
                      :show-button-title="false"
                    />
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group :label="$t('menuDetails.retryPrompts')">
                <b-form-row data-test="retry_prompts">
                  <b-col>
                    <Prompts
                        :title="'retry'"
                        :id="'retryPrompt'"
                        :prompt-list="getMainPrompts('Retry')"
                        @updateList="
                        (prompts) => {
                          saveMainPrompts({
                            eventName: 'Retry',
                            prompts: prompts,
                          });
                          keyReRender.retryPromptKey++;
                        }
                      "
                        :horizontal="true"
                        :show-button-title="false"
                    />
                  </b-col>
                </b-form-row>
              </b-form-group>
              <!-- TODO Currently commented out, as valid prompts are still being evaluated. -->
              <!--                        <b-form-group>-->
              <!--                            <b-form-row data-test="not_valid_prompts">-->
              <!--                                <b-col>-->
              <!--                                    <Prompts :title="'notValid'" :id="'notValidPrompts'"-->
              <!--                                             :prompt-list="getMainPrompts('NotValid')"-->
              <!--                                             :description-title="$t('menuDetails.notValidPrompts')"-->
              <!--                                             @updateList="prompts => {saveMainPrompts({eventName:'NotValid',prompts:prompts}); keyReRender.notValidPromptsKey++}"-->
              <!--                                    />-->
              <!--                                </b-col>-->
              <!--                                <b-col>-->
              <!--                                    <prompt-list :key="keyReRender.notValidPromptsKey" :prompts="getMainPrompts('NotValid')"/>-->
              <!--                                </b-col>-->
              <!--                            </b-form-row>-->
              <!--                        </b-form-group>-->
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-card>
              <b-card-body data-test="max_values">
                <b-card-sub-title>{{
                  $t("menuDetails.maxRetryKeys")
                }}</b-card-sub-title>
                <validation-provider
                  name="generalOptions"
                  rules="menuDefaultMaxOptionSelected|required"
                  v-slot="{ valid, errors, touched }"
                >
                  <b-form-checkbox-group
                    data-test="options_list"
                    v-model="generalOptionsValue"
                    :options="generalOptions"
                    name="generalOptions"
                    :state="errors[0] ? false : valid && touched ? true : null"
                  />
                </validation-provider>
                <menu-event-option
                  v-for="(item, index) in generalOptionsValue"
                  :key="item.selectedValue"
                  :class="{
                    'custom-striped-color': index % 2 === 0,
                    '': index % 2 !== 0,
                  }"
                  :id="item.selectedValue"
                  data-test="max_event_option"
                  :allMenusAsOptions="allMenusAsOptions"
                  :allScriptsAsOptions="allScriptsAsOptions"
                  :allApplicationsAsOptions="allApplicationsAsOptions"
                  :actionTypes="getSelectableActionOptions()"
                  :title="$t(item.translationKey)"
                  :showDefaultCheck="false"
                  :menuEvent="findMenuEventByName(item.selectedValue)"
                  @changeAction="
                    (menuEventMaxTypesReturnObject) => {
                      saveMenuEventByName({
                        eventName: item.selectedValue,
                        action: menuEventMaxTypesReturnObject,
                      });
                    }
                  "
                  @change="
                    (input) => saveEventMaxRetry(item.selectedValue, input)
                  "
                  :inputField="findMaxRetry(item.selectedValue)"
                  :inputFieldText="item.translationKey"
                  :show-value-field="true"
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card>
              <b-card-body data-test="custOptionKeys">
                <b-card-sub-title>{{
                  $t("menuDetails.optionKeys")
                }}</b-card-sub-title>
                <b-form-checkbox-group
                  data-test="option_keys"
                  v-model="custOptionsValue"
                  :options="custOptions"
                />
                <menu-event-option
                  v-for="(item, index) in custOptionsValueSorted"
                  :key="item.selectedValue"
                  :class="{
                    'custom-striped-color': index % 2 === 0,
                    '': index % 2 !== 0,
                  }"
                  :id="item.selectedValue"
                  data-test="key_event_option"
                  :allMenusAsOptions="allMenusAsOptions"
                  :allScriptsAsOptions="allScriptsAsOptions"
                  :allApplicationsAsOptions="allApplicationsAsOptions"
                  :actionTypes="getSelectableActionOptions()"
                  :title="$t(item.translationKey)"
                  :showDefaultCheck="false"
                  :menuEvent="findMenuEventByName(item.selectedValue)"
                  @changeAction="
                    (menuEventMaxTypesReturnObject) => {
                      saveMenuEventByName({
                        eventName: item.selectedValue,
                        action: menuEventMaxTypesReturnObject,
                      });
                    }
                  "
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mr-0">
          <b-button
            data-test="btn_update_menu"
            @click="callMenuUpdate()"
            v-b-tooltip.hover
            id="updateButton"
            variant="primary"
            class="mr-1"
            >{{ $t("menuDetails.updateButton") }}
          </b-button>
          <b-tooltip
            v-if="!errorsAreEmpty"
            target="updateButton"
            ref="updateTooltip"
          >
            <b-badge
              class="bg-danger"
              v-bind:key="error[0]"
              v-for="error in clean(errors)"
              >{{ error[0] }}</b-badge
            >
          </b-tooltip>
          <b-button
            class="mr-1"
            v-on:click="gotoAllMenuPage()"
            variant="warning"
            >{{ $t("menuDetails.cancelButton") }}</b-button
          >
          <b-button
            data-test="btn-delete-menu"
            class="mr-1"
            v-on:click.prevent="openMenuDeleteModal()"
            type="delete"
            variant="danger"
            >{{ $t("menuDetails.deleteButton") }}</b-button
          >
        </b-row>
        <reference-modal
          :i-d-reference="idProp"
          @targetMethod="requestDeleteMenu"
          :modal-title="$t('menuDetails.deleteModalTitle')"
          :modal-function="$t('menuDetails.deleteModalFunction') + displayName"
          :object-title="$t('menuDetails.deleteObjectTitle')"
          ref="deleteMenuModal"
          :okButtonText="$t('okButtonText')"
          :cancel-button-text="$t('cancelButtonText')"
        />
      </b-card-body>
    </b-card>
  </ValidationObserver>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { Vue } from "vue";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import Prompts from "../components/common/Prompts";
import constants from "../EndPointsAndConstants";
import { populateOptions } from "../Helper.js";
import { ACTION_TYPE_OPTIONS_NO_DEFAULTS } from "@/EndPointsAndConstants";
import MenuEventOption from "../components/menu/MenuEventOption";
import ReferenceModal from "../components/common/ReferenceModal";

export default {
  name: "MenuDetail",
  components: {
    MenuEventOption,
    Prompts,
    ReferenceModal,
  },
  data() {
    return {
      parameterGroup: { childrenGroups: [], parameters: [] },
      componentVariables: {
        showContent: false,
        fieldLength: this.$maxLength,
        title: "",
      },
      keyReRender: {
        initPromptsKey: 0,
        introPromptKey: 0,
        retryPromptKey: 0,
        timeoutPromptsKey: 0,
        wrongInputPromptsKey: 0,
        notValidPromptsKey: 0,
      },
      allApplicationsAsOptions: [],
      allScriptsAsOptions: [],
      allMenusAsOptions: [],
      generalOptionsValue: [],
      custOptionsValue: [],
    };
  },
  props: {
    idProp: { type: Number, required: true },
  },
  computed: {
    ...get("menu", [
      "findMenuEventByName",
      "getMenu",
      "getInitPrompts",
      "getMainPrompts",
    ]),
    ...sync("menu/menu@*"),
    generalOptionsValueSorted: function () {
      let optionList = this.generalOptionsValue;
      optionList.sort(this.nameSorterForOption);
      return optionList;
    },
    custOptionsValueSorted: function () {
      let optionList = this.custOptionsValue;
      optionList.sort(this.nameSorterForOption);
      return optionList;
    },

    generalOptions: function () {
      return [
        {
          text: this.$t("menuDetails.optionTimeoutMax"),
          value: {
            selectedValue: "TimeoutMax",
            translationKey: "menuDetails.optionTimeoutMax",
          },
        },
        {
          text: this.$t("menuDetails.optionInputMax"),
          value: {
            selectedValue: "WrongInputMax",
            translationKey: "menuDetails.optionInputMax",
          },
        },
        {
          text: this.$t("menuDetails.optionMaxRetry"),
          value: {
            selectedValue: "RetryMax",
            translationKey: "menuDetails.optionMaxRetry",
          },
        },
      ];
    },

    custOptions: function () {
      return [
        {
          text: this.$t("menuDetails.option0"),
          value: {
            selectedValue: "Option0",
            translationKey: "menuDetails.option0",
          },
        },
        {
          text: this.$t("menuDetails.option1"),
          value: {
            selectedValue: "Option1",
            translationKey: "menuDetails.option1",
          },
        },
        {
          text: this.$t("menuDetails.option2"),
          value: {
            selectedValue: "Option2",
            translationKey: "menuDetails.option2",
          },
        },
        {
          text: this.$t("menuDetails.option3"),
          value: {
            selectedValue: "Option3",
            translationKey: "menuDetails.option3",
          },
        },
        {
          text: this.$t("menuDetails.option4"),
          value: {
            selectedValue: "Option4",
            translationKey: "menuDetails.option4",
          },
        },
        {
          text: this.$t("menuDetails.option5"),
          value: {
            selectedValue: "Option5",
            translationKey: "menuDetails.option5",
          },
        },
        {
          text: this.$t("menuDetails.option6"),
          value: {
            selectedValue: "Option6",
            translationKey: "menuDetails.option6",
          },
        },
        {
          text: this.$t("menuDetails.option7"),
          value: {
            selectedValue: "Option7",
            translationKey: "menuDetails.option7",
          },
        },
        {
          text: this.$t("menuDetails.option8"),
          value: {
            selectedValue: "Option8",
            translationKey: "menuDetails.option8",
          },
        },
        {
          text: this.$t("menuDetails.option9"),
          value: {
            selectedValue: "Option9",
            translationKey: "menuDetails.option9",
          },
        },
        {
          text: this.$t("menuDetails.optionStar"),
          value: {
            selectedValue: "OptionStar",
            translationKey: "menuDetails.optionStar",
          },
        },
        {
          text: this.$t("menuDetails.optionHash"),
          value: {
            selectedValue: "OptionHash",
            translationKey: "menuDetails.optionHash",
          },
        },
      ];
    },
    errorsAreEmpty: function () {
      let isEmpty = true;
      for (const property in this.$refs.menuObserver.errors) {
        if (this.$refs.menuObserver.errors[property].length > 0) {
          isEmpty = false;
        }
      }
      return isEmpty;
    },
  },
  methods: {
    ...mapActions("menu", [
      "fetchMenu",
      "saveMenuEvent",
      "updateStoreForMaxData",
      "saveMenuEventByName",
      "updateMenu",
      "saveMainPrompts",
      "deleteMenu",
      "initializeEmptyPrompts",
    ]),
    ...mapGetters("menu", ["getAllDefaultEventNames"]),
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
    nameSorterForOption(a, b) {
      var nameA = a.selectedValue.toUpperCase();
      var nameB = b.selectedValue.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
    getSelectableActionOptions() {
      let options = ACTION_TYPE_OPTIONS_NO_DEFAULTS();
      return options;
    },

    callMenuUpdate() {
      this.$refs.menuObserver
        .handleSubmit((__) => {
          this.updateMenu({
            vm: this.$parent,
            menu: this.getMenu,
            optionsSelected: this.custOptionsValue,
            menuEventToUpdate: this.getMenuEventsToUpdate(),
            menuEventToDelete: this.getMenuEventsToDelete(),
          });
        })
        .then((__) => {
          this.componentVariables.title = this.displayName;
        });
      // updateMenu({vm: this, menu: menu, optionsSelected: custOptionsValue, menuEventToUpdate:getMenuEventsToUpdate(), menuEventToDelete: menuEventToDelete()})
    },
    isEventEnabled(eventName) {
      if (
        this.generalOptionsValue.includes(eventName) ||
        this.custOptionsValue.includes(eventName)
      ) {
        return true;
      }
      return false;
    },
    findMaxRetry(eventName) {
      switch (eventName) {
        case "RetryMax":
          return this.retryMax;
        case "TimeoutMax":
          return this.timeoutMax;
        case "WrongInputMax":
          return this.wrongInputMax;
        default:
          return null;
      }
    },

    getAllMenuEvents() {
      let allMenuEventsNames = [];
      this.generalOptions.map((menuEvent) =>
        allMenuEventsNames.push(menuEvent.value.selectedValue)
      );
      this.custOptions.map((menuEvent) =>
        allMenuEventsNames.push(menuEvent.value.selectedValue)
      );
      return allMenuEventsNames;
    },
    getMenuEventsToDelete() {
      let allMenuEvents = this.getAllMenuEvents();
      let menuEventsToUpdate = this.getMenuEventsToUpdate();
      let menuEventsToDelete = allMenuEvents.filter(
        (value) => !menuEventsToUpdate.includes(value)
      );
      return menuEventsToDelete;
    },
    getMenuEventsToUpdate() {
      let selectedMenuEvents = [];
      this.generalOptionsValue.map((menuEvent) =>
        selectedMenuEvents.push(menuEvent.selectedValue)
      );
      this.custOptionsValue.map((menuEvent) =>
        selectedMenuEvents.push(menuEvent.selectedValue)
      );
      this.getAllDefaultEventNames().forEach((eventName) =>
        selectedMenuEvents.push(eventName)
      );
      return selectedMenuEvents;
    },
    saveEventMaxRetry(eventName, value) {
      switch (eventName) {
        case "RetryMax":
          return (this.retryMax = value);
        case "TimeoutMax":
          return (this.timeoutMax = value);
        case "WrongInputMax":
          return (this.wrongInputMax = value);
      }
    },
    gotoAllMenuPage() {
      this.$router.push("/menu");
    },
    openMenuDeleteModal() {
      this.$log.info("OPENING MODAL");
      this.$refs.deleteMenuModal.showModal();
    },
    requestDeleteMenu() {
      this.$log.debug("Deleting: ", this.idProp);
      return this.deleteMenu({ vm: this, menu: this.getMenu }).then((__) => {
        this.$router.push("/menu");
      });
    },
  },
  created() {
    this.fetchMenu(this.idProp)
      .then((__) => {
        this.custOptions.forEach((option) => {
          let found = this.menuEvent.find(
            (iteration) =>
              iteration.event.toLowerCase() ===
              option.value.selectedValue.toLowerCase()
          );

          if (found != null) {
            this.custOptionsValue.push(option.value);
            return;
          }
        });
        this.generalOptions.forEach((option) => {
          let found = this.menuEvent.find(
            (iteration) =>
              iteration.event.toLowerCase() ===
              option.value.selectedValue.toLowerCase()
          );

          if (found != null) {
            this.generalOptionsValue.push(option.value);
            return;
          }
        });
      })
      .then((__) => {
        this.initializeEmptyPrompts();

        populateOptions(
          constants.endPoints.APPLICATIONS_BASE,
          this.allApplicationsAsOptions,
          null
        );
        populateOptions(
          constants.endPoints.SCRIPTS_BASE,
          this.allScriptsAsOptions,
          null
        );
        populateOptions(
          constants.endPoints.MENUS_BASE,
          this.allMenusAsOptions,
          this.idProp
        );
      })
      .then((__) => {
        this.componentVariables.title = this.displayName;
        this.componentVariables.showContent = true;
      });

    document.title =
      this.$t("pageTitles.menuDetail") + " " + this.componentVariables.title;
  },
};
</script>
