<template>
  <b-card
    border-variant="secondary"
    class="shadow"
    header-bg-variant="secondary"
    header-text-variant="white"
    no-body
  >
    <b-card-header header-bg-variant="secondary"
                   header-text-variant="white"
                   @click='closedFunction()'>{{ $t('applicationDetail.customParameters.title') }}
    </b-card-header>
    <b-card-body>
      <div data-test="parameters">
        <template>
          <div v-for="(parameterGroup, index) in parameters" v-bind:key="index">
            <parameter
              v-bind:key="index"
              :parameter="parameterGroup.subject"
              :permissions="parameterGroup.permissions"
              :isClosed="componentVariables.changingBool"
            ></parameter>
          </div>
        </template>
        <b-alert
          v-if="!this.componentVariables.loading && parameters.length === 0"
          variant="success"
          show
          >{{ $t("applicationDetail.customParameters.noParameters") }}</b-alert
        >
        <b-row v-if="this.componentVariables.loading"
          ><b-spinner>Loading</b-spinner></b-row
        >
        <b-button
          class="float-left"
          v-if="parameters.length > 0"
          data-test="btn-parameter-save"
          @click="clickedSave"
          >{{
            $t("applicationDetail.customParameters.saveCustomParameters")
          }}</b-button
        >
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("parameter", require("../application/Parameter.vue").default);
export default {
  name: "applicationParameters",
  props: {},
  computed: {
    ...mapGetters("application", {
      parameters: "getParameters",
    }),
  },
  methods: {
    ...mapActions("application", [
      "fetchCustomParametersForApplication",
      "saveParameters",
    ]),
    ...mapActions("parameters", [
      "fetchParameterOptions",
      "fetchParameterOptionsForCustom",
    ]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    clickedSave(event) {
      event.preventDefault();
      this.saveParameters(this).then((__) => {
        this.setUnsavedChanges(false);
      });
    },

    closedFunction(){
      this.componentVariables.changingBool = !this.componentVariables.changingBool;
    }
  },
  data: () => {
    return {
      componentVariables: {
        loading: true,
        closedParameters: false,
        changingBool: true
      },
    };
  },
  created: function () {
    this.componentVariables.loading = true;
    this.fetchCustomParametersForApplication().then((__) => {
      this.componentVariables.loading = false;
      // expected output: "foo"
    });
    this.fetchParameterOptionsForCustom("APPLICATION");
  },
};
</script>
