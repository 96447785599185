<template>
  <b-card no-body v-if="this.pageVariables.showContent">
    <b-tabs
      active-nav-item-class="font-weight-bold text-uppercase text-primary"
      active-tab-class="font-weight-bold text-success"
      card
    >
      <b-tab active lazy :title="$t('parameterDetail.title')">
        <b-row class="mb-1">
          <b-col>
            <b-card class="shadow" no-body>
              <b-card-header>{{
                $t("parameterDetail.choiceTitle")
              }}</b-card-header>
              <b-card-body>
                <b-form-select
                  data-test="select-parameter-type"
                  :disabled="!this.create"
                  @change="resetParameterSelectionArray()"
                  v-model="componentType"
                  class="text-capitalize"
                >
                  <option
                    :key="idx"
                    :title="option.title || null"
                    :value="option.value.name"
                    v-for="(option, idx) in this.CUSTOM_PARAMETERS_KEY_VALUE()"
                    class="text-capitalize"
                  >
                    {{ $t("parameterDetail.choice." + option.text) }}
                  </option>
                </b-form-select>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <validation-observer v-slot="{ handleSubmit }">
          <b-row v-if="configuration">
            <b-col>
              <b-card class="shadow" no-body>
                <b-card-header>{{
                  $t("parameterDetail.fieldsTitle")
                }}</b-card-header>
                <b-card-body>
                  <b-form @submit="(event) => event.preventDefault()">
                    <validation-provider
                      :rules="'required|max:' + pageVariables.fieldLength"
                      v-slot="{ valid, errors, touched }"
                    >
                      <b-form-group
                        :description="
                          $t('parameterDetail.paramNameDescription')
                        "
                        id="input-group-1"
                        :label="$t('parameterDetail.paramName')"
                        label-for="input-1"
                      >
                        <b-form-input
                          data-test="text-parameter-name"
                          debounce="100"
                          id="input-1"
                          :placeholder="$t('parameterDetail.paramNameField')"
                          required
                          type="text"
                          v-model="name"
                          :state="
                            errors[0] ? false : valid && touched ? true : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback tag="span">{{
                          errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      :rules="'required|max:' + pageVariables.fieldLength"
                      v-slot="{ valid, errors, touched }"
                    >
                      <b-form-group
                        :description="
                          $t('parameterDetail.paramDisplayNameDescription')
                        "
                        id="input-group-2"
                        :label="$t('parameterDetail.paramDisplayName')"
                        label-for="input-2"
                      >
                        <b-form-input
                          data-test="text-parameter-displayname"
                          debounce="100"
                          id="input-2"
                          :placeholder="
                            $t('parameterDetail.paramDisplayNameField')
                          "
                          required
                          type="text"
                          v-model="displayName"
                          :state="
                            errors[0] ? false : valid && touched ? true : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback tag="span">{{
                          errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <tags-selector
                      :multiple-selection="configuration.multi"
                      :options="parameterOptionAvailable"
                      :selection="parameterOptionDefault"
                      @addedTag="(option) => addDefaultOption(option)"
                      @removedTag="
                        (option) => this - removeDefaultOption(option)
                      "
                      :title="$t('parameterDetail.paramDefaultOptionTitle')"
                      v-if="
                        configuration &&
                        configuration.type &&
                        configuration.valueType ===
                          pageVariables.PARAMETER_TYPES.BOOLEAN
                      "
                      data-test="DefaultOption"
                    ></tags-selector>

                    <div
                      v-if="
                        (configuration &&
                          configuration.type &&
                          configuration.type ===
                            pageVariables.PARAMETER_RENDER_TYPES.FLOAT) ||
                        configuration.type ===
                          pageVariables.PARAMETER_RENDER_TYPES.INTEGER ||
                        configuration.type ===
                          pageVariables.PARAMETER_RENDER_TYPES.TEXT
                      "
                    >
                      <b-form-group
                        :description="
                          $t('parameterDetail.paramDefaultDescription')
                        "
                        id="input-group-3"
                        :label="$t('parameterDetail.paramDefaultVal')"
                        label-for="input-3"
                      >
                        <b-form-input
                          data-test="defaultValue"
                          debounce="100"
                          id="input-3"
                          :placeholder="$t('parameterDetail.paramDefaultField')"
                          type="text"
                          v-model="defaultValue"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div v-if="configuration && configuration.specify">
                      <tags-selector
                        :options="getAvailableParameterOptions()"
                        :selection="parameterOptionAvailable"
                        @addedTag="(option) => addAvailableOption(option)"
                        @removedTag="(option) => removeAvailableOption(option)"
                        title="Applications"
                        v-if="
                          configuration.valueType ===
                          pageVariables.PARAMETER_TYPES.APPLICATION
                        "
                      ></tags-selector>
                      <tags-selector
                        :options="getAvailableParameterOptions()"
                        :selection="parameterOptionAvailable"
                        @addedTag="(option) => addAvailableOption(option)"
                        @removedTag="(option) => removeAvailableOption(option)"
                        title="Calendar"
                        v-if="
                          configuration.valueType ===
                          pageVariables.PARAMETER_TYPES.CALENDAR
                        "
                      ></tags-selector>
                      <tags-selector
                        :options="getAvailableParameterOptions()"
                        :selection="parameterOptionAvailable"
                        @addedTag="(option) => addAvailableOption(option)"
                        @removedTag="(option) => removeAvailableOption(option)"
                        title="Menu"
                        v-if="
                          configuration.valueType ===
                          pageVariables.PARAMETER_TYPES.MENU
                        "
                      ></tags-selector>
                      <tags-selector
                        :options="getAvailableParameterOptions()"
                        :selection="parameterOptionAvailable"
                        @addedTag="(option) => addAvailableOption(option)"
                        @removedTag="(option) => removeAvailableOption(option)"
                        title="Script"
                        v-if="
                          configuration.valueType ===
                          pageVariables.PARAMETER_TYPES.SCRIPT
                        "
                      ></tags-selector>
                      <tags-selector
                        :multiple-selection="configuration.multi"
                        :options="parameterOptionAvailable"
                        :selection="parameterOptionDefault"
                        @addedTag="(option) => addDefaultOption(option)"
                        @removedTag="
                          (option) => this - removeDefaultOption(option)
                        "
                        :title="$t('parameterDetail.paramDefaultOptionsTitle')"
                        class="border-dark"
                        data-test="DefaultOption"
                      ></tags-selector>
                      <div
                        v-if="
                          configuration.valueType ===
                          pageVariables.PARAMETER_TYPES.CUSTOM
                        "
                      >
                        <b-form-group
                          :label="$t('parameterDetail.custDrop.customOptions')"
                        >
                          <ul
                            class="list-inline d-inline-block mb-2"
                            v-if="
                              parameterOptionAvailable &&
                              parameterOptionAvailable.length > 0
                            "
                          >
                            <li
                              :key="option.id"
                              class="list-inline-item"
                              v-for="option in parameterOptionAvailable"
                            >
                              <b-form-tag
                                :title="option.name"
                                @remove="removeCustomOption(option)"
                                variant="info"
                                >{{ option.name }}<br />
                                {{ option.displayName }}
                              </b-form-tag>
                            </li>
                          </ul>
                          <b-card>
                            <b-form-group
                              id="fieldset-option-id"
                              :label="$t('parameterDetail.custDrop.nameTitle')"
                              label-for="optionName"
                            >
                              <b-form-input
                                data-test="optionName"
                                id="optionName"
                                trim
                                v-model="pageVariables.parameter.optionName"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group
                              data-test="optionDisplayName"
                              id="fieldset-option-text"
                              :label="
                                $t('parameterDetail.custDrop.displayNameTitle')
                              "
                              label-for="optionText"
                            >
                              <b-form-input
                                data-test="optionText"
                                id="optionText"
                                trim
                                v-model="pageVariables.parameter.optionText"
                              ></b-form-input>
                            </b-form-group>
                            <b-button
                              data-test="addCustomOption"
                              @click="addCustomOption()"
                              >{{ $t("parameterDetail.custDrop.addCustBtn") }}
                            </b-button>
                          </b-card>
                        </b-form-group>
                      </div>
                    </div>
                  </b-form>
                </b-card-body>
              </b-card>
              <b-row align-h="end" class="mr-0 mt-1">
                <b-button
                  class="mr-1"
                  @click="
                    handleSubmit((__) => {
                      requestSaveParameter();
                    })
                  "
                  variant="primary"
                  data-test="btn-addOrSave"
                  ><template v-if="create">{{
                    $t("parameterDetail.addParamBtn")
                  }}</template
                  ><template v-else>{{
                    $t("parameterDetail.updateParamBtn")
                  }}</template>
                </b-button>
                <b-button
                  class="mr-1"
                  v-on:click="gotoParametersPage()"
                  variant="warning"
                  >{{ $t("parameterGroupEdit.cancelPG") }}</b-button
                >

                <template v-if="!create">
                  <b-button
                    data-test="btn-delete-parameter"
                    v-on:click="openDeleteModal()"
                    variant="danger"
                    class="mr-1"
                    >{{ $t("parameterDetail.deleteParamBtn") }}
                  </b-button>
                </template>

                <!--              <b-button @click="returnToParameterOverview"-->
                <!--                        title="pressing this button will return you to the overview" type="reset" v-b-tooltip-->
                <!--                        class="mr-1"-->
                <!--                        variant="danger">Cancel-->
                <!--              </b-button>-->
              </b-row>

              <reference-modal
                :i-d-reference="parameterId"
                :object-type="'Parameter'"
                @targetMethod="requestDeleteParameter()"
                :modal-title="$t('parameterDetail.deleteModalTitle')"
                :modal-function="$t('deleteReferenceFunction')"
                :object-title="displayName"
                :okButtonText="$t('okButtonText')"
                :cancel-button-text="$t('cancelButtonText')"
                ref="deleteModal"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </b-tab>
    </b-tabs>
  </b-card>
  <b-col
    v-else
    style="display: flex; align-items: center; justify-content: center"
  >
    <b-spinner
      class="mb-3 text-white"
      :label=" $t('loading')"
      style="width: 5rem; height: 5rem"
  /></b-col>
</template>

<script>
import {
  CUSTOM_PARAMETERS_KEY_VALUE,
  PARAMETER_RENDER_TYPES,
  PARAMETER_TYPES,
} from "../../EndPointsAndConstants";
import TagsSelector from "../common/TagsSelector";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import ReferenceModal from "../common/ReferenceModal";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "parameterdetail",
  props: {
    create: { type: Boolean, required: true },
    parameterId: { type: Number, required: true },
  },
  components: { TagsSelector, ReferenceModal },
  data() {
    return {
      pageVariables: {
        NonParameterOptionsArray: [
          PARAMETER_TYPES.CUSTOM,
          PARAMETER_TYPES.APPLICATION,
          PARAMETER_TYPES.BOOLEAN,
        ],
        PARAMETER_TYPES: PARAMETER_TYPES,
        PARAMETER_RENDER_TYPES: PARAMETER_RENDER_TYPES,
        parameter: {
          configuration: null,
          selection: [],
        },
        showContent: false,
        fieldLength: this.$maxLength,
      },
      restVariables: {
        parameter: {},
      },
    };
  },
  created() {
    this.CUSTOM_PARAMETERS_KEY_VALUE = CUSTOM_PARAMETERS_KEY_VALUE;
    if (this.create) {
      Vue.$log.debug("creating new parameter");
      this.makeNewParameter().then((__) => {
        document.title = this.$t("pageTitles.parameterCreate");
        this.pageVariables.showContent = true;
      });
    } else {
      this.fetchParameter(this.parameterId)
        .then((__) => {
          Vue.$log.debug("fetched parameter >>>", this.getParameter());
        })
        .then((__) => {
          if (
            this.pageVariables.NonParameterOptionsArray.includes(
              this.getParameter().valueType
            )
          ) {
            this.fetchParameterOptions();
          }
        })
        .then((__) => {
          document.title =
            this.$t("pageTitles.parameterDetail") + " " + this.name;
          this.pageVariables.showContent = true;
        });
    }
  },
  computed: {
    configuration: function () {
      return this.getParameterConfiguration();
    },
    componentType: {
      get() {
        return this.getParameter().componentType;
      },
      set(value) {
        this.$store.commit("parameters/UPDATE_PARAMETER_COMPONENT_TYPE", value);
      },
    },
    name: {
      get() {
        return this.getParameter().name;
      },
      set(value) {
        this.$store.commit("parameters/UPDATE_PARAMETER_NAME", value);
      },
    },
    defaultValue: {
      get() {
        return this.getParameter().defaultValue;
      },
      set(value) {
        this.$store.commit("parameters/UPDATE_PARAMETER_DEFAULT_VALUE", value);
      },
    },
    parameterOptionAvailable: {
      get() {
        if(this.create == true && this.configuration.valueType === this.pageVariables.PARAMETER_TYPES.BOOLEAN){
          return [{"id":1,"name":"TRUE","displayName":"TRUE"}, {"id":2,"name":"FALSE","displayName":"FALSE"}];
        } else {
          return this.getParameter().parameterOptionAvailable;
        }
      },
      set(value) {
        this.$store.commit(
          "parameters/UPDATE_PARAMETER_OPTION_AVAILABLE",
          value
        );
      },
    },
    parameterOptionDefault: {
      get() {
        return this.getParameter().parameterOptionDefault;
      },
      set(value) {
        this.$store.commit("parameters/UPDATE_PARAMETER_OPTION_DEFAULT", value);
      },
    },

    displayName: {
      get() {
        return this.getParameter().displayName;
      },
      set(value) {
        this.$store.commit("parameters/UPDATE_PARAMETER_DISPLAY_NAME", value);
      },
    },
  },
  methods: {
    ...mapActions("parameters", [
      "fetchParameter",
      "makeNewParameter",
      "clearParameterOptions",
      "addAvailableOption",
      "removeAvailableOption",
      "addDefaultOption",
      "removeDefaultOption",
      "saveParameter",
      "fetchParameterOptions",
      "deleteParameter",
    ]),
    ...mapGetters("parameters", [
      "getParameter",
      "getParameterConfiguration",
      "getAvailableParameterOptions",
    ]),
    ...mapActions("changes", ["setUnsavedChanges"]),

    addCustomOption() {
      let option = {};
      option.name = this.pageVariables.parameter.optionName;
      option.displayName = this.pageVariables.parameter.optionText;
      this.addAvailableOption(option);
      this.pageVariables.parameter.optionName = null;
      this.pageVariables.parameter.optionText = null;
    },
    removeCustomOption(option) {
      this.removeAvailableOption(option);
    },
    resetParameterSelectionArray() {
      this.clearParameterOptions();
      if (
        this.configuration != null &&
        this.configuration.valueType != null &&
        !this.pageVariables.NonParameterOptionsArray.includes(
          this.configuration.valueType
        )
      ) {
        this.fetchParameterOptions();
      }
      Vue.$log.debug("reset parameter selection array");
    },
    requestSaveParameter() {
      Vue.$log.debug("Saving: ", this.parameterId);
      this.saveParameter(this)
        .then((__) => {
          this.setUnsavedChanges(false);
        })
        .then((__) => {
          console.log("goto overview");
          this.returnToParameterOverview();
        });
    },
    requestDeleteParameter() {
      Vue.$log.debug("Deleting: ", this.parameterId);
      this.deleteParameter(this).then((response) => {
        this.returnToParameterOverview();
      });
      Vue.$log.debug("reset parameter selection array");
    },
    returnToParameterOverview() {
      Vue.$log.debug("returning to overview");
      this.$router.push({
        name: "parameter",
      });
    },
    openDeleteModal() {
      Vue.$log.info("OPENING MODAL");
      this.$refs.deleteModal.showModal();
    },
    gotoParametersPage() {
      this.$router.push({
        name: "parameter",
        params: { choose: 1 },
      });
    },
  },
};
</script>
