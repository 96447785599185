var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"inline":""}},[(
        _vm.scheduleType === _vm.componentVariables.constants.SCHEDULE_TYPES.WEEKLY
      )?[_c('b-col',{staticClass:"ml-2",attrs:{"cols":"0"}},[_c('ValidationProvider',{attrs:{"name":"dayOfWeek","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var valid = ref.valid;
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('applicationDetail.schedules.day'),"disabled":_vm.orgType}},[_c('b-form-select',{attrs:{"data-test":"select_weekday","options":_vm.weekDayOptions(),"placeholder":_vm.$t('applicationDetail.schedules.selectDay'),"state":errors[0] ? false : valid ? true : null},on:{"change":_vm.changed},model:{value:(_vm.fk_DayOfWeek),callback:function ($$v) {_vm.fk_DayOfWeek=$$v},expression:"fk_DayOfWeek"}}),(errors[0] != null)?_c('b-form-invalid-feedback',{staticClass:"d-flex"},[_vm._v(_vm._s(errors[0].substring(0, 25))+" "),_c('br'),_vm._v(" "+_vm._s(errors[0].substring(25, errors[0].length))+" ")]):_vm._e()],1)]}}],null,true)})],1)]:_vm._e(),(
        _vm.scheduleType !== _vm.componentVariables.constants.SCHEDULE_TYPES.WEEKLY
      )?[_c('b-col',{staticClass:"pr-1 ml-2",attrs:{"cols":"0"}},[_c('ValidationProvider',{attrs:{"name":"startDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var valid = ref.valid;
      var errors = ref.errors;
      var dirty = ref.dirty;
return [_c('b-form-group',{attrs:{"label":_vm.$t('applicationDetail.schedules.startDate'),"disabled":_vm.orgType}},[_c('b-input-group',[_c('b-form-input',{staticClass:"pr-0",staticStyle:{"width":"8em"},attrs:{"data-test":"startDate","id":"input-startDate","type":"text","placeholder":"YYYY-MM-DD","autocomplete":"off","state":errors[0] ? false : valid && dirty ? true : null},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","right":"","aria-controls":"input-startDate"},on:{"input":_vm.handleStartDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),(errors[0] != null)?_c('b-form-invalid-feedback',{staticClass:"d-flex"},[_vm._v(_vm._s(errors[0].substring(0, 25))+" "),_c('br'),_vm._v(" "+_vm._s(errors[0].substring(25, errors[0].length))+" ")]):_vm._e()],1)]}}],null,true)})],1)]:_vm._e(),_c('b-col',{staticClass:"pr-1 pl-0 ml-2",attrs:{"cols":"0"}},[_c('ValidationProvider',{attrs:{"name":"startTime","rules":"required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime","skipIfEmpty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var valid = ref.valid;
      var errors = ref.errors;
      var dirty = ref.dirty;
return [_c('b-form-group',{attrs:{"label":_vm.$t('applicationDetail.schedules.startTime'),"disabled":_vm.orgType}},[_c('b-input-group',[_c('b-form-input',{staticClass:"pr-0",staticStyle:{"width":"8em"},attrs:{"data-test":"startTime","id":"startTime","type":"text","placeholder":"HH:mm","state":errors[0] ? false : valid && dirty ? true : null},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"button-only":"","right":"","aria-controls":"startTime","locale":'de'},on:{"input":_vm.handleStartTime},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}})],1)],1)],1),(errors[0] != null)?_c('b-form-invalid-feedback',{staticClass:"d-flex"},[_vm._v(_vm._s(errors[0].substring(0, 25))+" "),_c('br'),_vm._v(" "+_vm._s(errors[0].substring(25, errors[0].length))+" ")]):_vm._e()]}}],null,true)})],1),(
        _vm.scheduleType !== _vm.componentVariables.constants.SCHEDULE_TYPES.WEEKLY
      )?[_c('b-col',{staticClass:"pr-1 pl-0 ml-2",attrs:{"cols":"0"}},[_c('ValidationProvider',{attrs:{"name":"endDate","rules":"required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var valid = ref.valid;
      var errors = ref.errors;
      var dirty = ref.dirty;
return [_c('b-form-group',{attrs:{"label":_vm.$t('applicationDetail.schedules.endDate'),"disabled":_vm.orgType}},[_c('b-input-group',[_c('b-form-input',{staticClass:"pr-0",staticStyle:{"width":"8em"},attrs:{"data-test":"endDate","id":"endDate","type":"text","placeholder":"YYYY-MM-DD","autocomplete":"off","state":errors[0] ? false : valid && dirty ? true : null},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","right":"","aria-controls":"endDate"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),(errors[0] != null)?_c('b-form-invalid-feedback',{staticClass:"d-flex"},[_vm._v(_vm._s(errors[0].substring(0, 25))+" "),_c('br'),_vm._v(" "+_vm._s(errors[0].substring(25, errors[0].length))+" ")]):_vm._e()],1)]}}],null,true)})],1)]:_vm._e(),_c('b-col',{staticClass:"pr-1 pl-0 ml-2",attrs:{"cols":"0"}},[_c('ValidationProvider',{attrs:{"name":"endTime","rules":"required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var valid = ref.valid;
      var errors = ref.errors;
      var dirty = ref.dirty;
return [_c('b-form-group',{attrs:{"label":_vm.$t('applicationDetail.schedules.endTime'),"disabled":_vm.orgType}},[_c('b-input-group',[_c('b-form-input',{staticClass:"pr-0",staticStyle:{"width":"8em"},attrs:{"data-test":"endTime","id":"endTime","type":"text","placeholder":"HH:mm","state":errors[0] ? false : valid && dirty ? true : null},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"button-only":"","right":"","aria-controls":"endTime","locale":'de'},on:{"input":_vm.handleEndTime},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)],1),(errors[0] != null)?_c('b-form-invalid-feedback',{staticClass:"d-flex"},[_vm._v(_vm._s(errors[0].substring(0, 25))+" "),_c('br'),_vm._v(" "+_vm._s(errors[0].substring(25, errors[0].length))+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{staticClass:"pr-1 pl-0 ml-2",attrs:{"cols":"0"}},[(
          _vm.scheduleType !==
            _vm.componentVariables.constants.SCHEDULE_TYPES.WEEKLY &&
          _vm.showIgnoreYearButton
        )?_c('b-form-group',{attrs:{"label":_vm.$t('applicationDetail.schedules.ignoreYear'),"disabled":_vm.orgType}},[_c('b-form-checkbox',{attrs:{"switch":true},model:{value:(_vm.recurring),callback:function ($$v) {_vm.recurring=$$v},expression:"recurring"}})],1):_vm._e()],1),_c('b-col',{staticClass:"pr-1 pl-0 ml-2",attrs:{"cols":"0"}},[_c('span',[_c('b-form-group',{attrs:{"label":_vm.$t('applicationDetail.schedules.description'),"disabled":_vm.orgType}},[_c('b-form-input',{attrs:{"maxlength":"50","id":_vm.$id('ScheduleFormDescription'),"label":_vm.$t('applicationDetail.schedules.enterDescription'),"placeholder":_vm.$t('applicationDetail.schedules.enterDescription')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)]),_vm._t("additionalContent"),_c('b-col',{staticClass:"pr-0 mr-0 pl-0 ml-2",attrs:{"cols":"0"}},[(_vm.showDisableButton)?_c('b-form-group',{staticClass:"float-right",attrs:{"label":_vm.$t('applicationDetail.schedules.disabled')}},[_c('b-form-checkbox',{staticClass:"align-bottom",attrs:{"type":"checkbox"},model:{value:(_vm.disabled),callback:function ($$v) {_vm.disabled=$$v},expression:"disabled"}})],1):_vm._e()],1),_c('b-col',{staticClass:"pr-0 mr-0 pl-0 ml-2",attrs:{"cols":"0"}},[(_vm.showAddButton)?_c('b-form-group',{staticClass:"float-right",attrs:{"label":_vm.$t('applicationDetail.schedules.cancel')}},[_c('b-button',{attrs:{"data-test":"btn_cancelSchedule","variant":"outline-secondary"},on:{"click":function($event){handleSubmit(_vm.requestCloseFields())}}},[_vm._v(" "+_vm._s(_vm.$t("applicationDetail.schedules.cancel"))+" ")])],1):_vm._e()],1),_c('b-col',{staticClass:"pr-1 mr-0 pl-0 ml-2",attrs:{"cols":"0"}},[(_vm.showAddButton)?_c('b-form-group',{staticClass:"float-right",attrs:{"label":_vm.$t('applicationDetail.schedules.addAnother')}},[_c('b-button',{attrs:{"data-test":"btn_addScheduleAndNew","variant":"outline-secondary"},on:{"click":function($event){handleSubmit(function (__) {
              _vm.requestAddSchedule();
              _vm.requestInitializeSchedule();
            })}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1):_vm._e()],1),_c('b-col',{staticClass:"pr-1 mr-0 pl-0 ml-2",attrs:{"cols":"0"}},[_c('ValidationProvider',{attrs:{"name":"submitForm","rules":{
          noOverLappingSchedules: [
            _vm.componentVariables.schedule,
            _vm.actualSchedules ],
        },"skipIfEmpty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
return [(_vm.showAddButton)?_c('b-form-group',{staticClass:"float-right",attrs:{"label":_vm.$t('applicationDetail.schedules.save'),"invalid-feedback":errors[0],"state":errors[0] ? false : valid ? true : null}},[_c('b-button',{attrs:{"data-test":"btn_addScheduleAndClose","variant":"outline-secondary"},on:{"click":function($event){handleSubmit(function (__) {
                _vm.requestAddSchedule();
                _vm.requestCloseFields();
              })}}},[_c('font-awesome-icon',{attrs:{"icon":"save"}})],1)],1):_vm._e()]}}],null,true)})],1)],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }