<template>
  <b-container fluid>This will be a menu</b-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Menu",
};
</script>
