var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageVariables.showContent)?_c('b-card',{staticClass:"shadow",attrs:{"no-body":""}},[_c('b-card-header',[_vm._v(_vm._s(_vm.restVariables.script.displayName))]),_c('b-card-body',[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function (event) { return event.preventDefault(); }}},[_c('b-form-group',{staticClass:"ml-1 mb-2",attrs:{"inline":""}},[_c('validation-provider',{attrs:{"rules":'required|min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"id":"input-group-2","label":_vm.$t('scriptPage.name'),"label-for":"input-2"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"input-2","placeholder":_vm.$t('scriptPage.scriptNameField'),"description":"Name","data-test":"scriptName","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.restVariables.script.name),callback:function ($$v) {_vm.$set(_vm.restVariables.script, "name", $$v)},expression:"restVariables.script.name"}}),_c('b-form-invalid-feedback',{attrs:{"tag":"span"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":'required|min:1|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"id":"input-group-3","label":_vm.$t('scriptPage.displayName'),"label-for":"input-3"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"input-3","placeholder":_vm.$t('scriptPage.scriptDisplayNameField'),"data-test":"scriptDisplayName","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.restVariables.script.displayName),callback:function ($$v) {_vm.$set(_vm.restVariables.script, "displayName", $$v)},expression:"restVariables.script.displayName"}}),_c('b-form-invalid-feedback',{attrs:{"tag":"span"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":'max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"id":"input-group-4","label":_vm.$t('scriptPage.description'),"label-for":"input-4"}},[_c('b-input',{staticClass:"ml-1",attrs:{"id":"input-4","placeholder":_vm.$t('scriptPage.scriptDescriptionField'),"data-test":"scriptDescription","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.restVariables.script.description),callback:function ($$v) {_vm.$set(_vm.restVariables.script, "description", $$v)},expression:"restVariables.script.description"}}),_c('b-form-invalid-feedback',{attrs:{"tag":"span"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){handleSubmit(function (__) {
                    _vm.saveLocal();
                  })}}},[_vm._v(_vm._s(_vm.$t("scriptDetails.update"))+" ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.gotoScriptPage()}}},[_vm._v(_vm._s(_vm.$t("scriptDetails.cancel"))+" ")]),_c('b-button',{staticClass:"mr-1",attrs:{"data-test":"btn_delete_script","variant":"danger"},on:{"click":function($event){return _vm.openDeleteModal()}}},[_vm._v(_vm._s(_vm.$t("scriptDetails.delete"))+" ")])],1)],1)]}}],null,false,1735575463)})],1)],1),_c('reference-modal',{ref:"deleteModal",attrs:{"i-d-reference":this.id,"object-type":'Script',"object-title":_vm.restVariables.script.displayName,"modal-title":_vm.$t('scriptDetails.deleteModalTitle'),"modal-function":_vm.$t('deleteReferenceFunction')},on:{"targetMethod":function($event){return _vm.deleteLocal()}}})],1)],1):_c('b-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('b-spinner',{staticClass:"mb-3 text-white",staticStyle:{"width":"5rem","height":"5rem"},attrs:{"label":_vm.$t('loading')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }