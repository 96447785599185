<template>
  <b-card no-body>
    <b-tabs
      active-nav-item-class="font-weight-bold text-uppercase text-primary"
      active-tab-class="font-weight-bold text-success"
      card
    >
      <b-tab active lazy :title="$t('parameterGroupEdit.title')">
        <validation-observer v-slot="{ handleSubmit }">
          <b-form
            v-if="!pageVariables.pageLoading"
            @reset="onReset"
            @submit="onSubmit"
          >
            <validation-provider
              :rules="'required|max:' + pageVariables.fieldLength"
              v-slot="{ valid, errors, touched }"
            >
              <b-form-group
                :description="$t('parameterGroupEdit.pgNameFieldDesc')"
                id="input-group-1"
                :label="$t('parameterGroupEdit.pgNameTitle')"
                label-for="input-1"
              >
                <b-form-input
                  data-test="text-name"
                  id="input-1"
                  :placeholder="$t('pgManagement.parameterGroup.pgNameField')"
                  required
                  type="text"
                  v-model="pageVariables.parameterGroup.name"
                  :state="errors[0] ? false : valid && touched ? true : null"
                />
                <b-form-invalid-feedback tag="span">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              :rules="'required|max:' + pageVariables.fieldLength"
              v-slot="{ valid, errors, touched }"
            >
              <b-form-group
                :description="$t('parameterGroupEdit.pgDisplayNameFieldDesc')"
                id="input-group-2"
                :label="$t('parameterGroupEdit.pgDisplayNameTitle')"
                label-for="input-2"
              >
                <b-form-input
                  data-test="text-display-name"
                  id="input-2"
                  :placeholder="
                    $t('pgManagement.parameterGroup.pgDisplayNameField')
                  "
                  required
                  v-model="pageVariables.parameterGroup.displayName"
                  :state="errors[0] ? false : valid && touched ? true : null"
                />
                <b-form-invalid-feedback tag="span">{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-row class="mb-1" data-test="parameter-groups">
              <b-col>
                <ListMover
                  :available-options-list="pageVariables.parameterGroups"
                  :selected-options-list="
                    pageVariables.parameterGroup.childrenGroups
                  "
                  :availableOptionsTitle="
                    $t('parameterGroupEdit.availableParameterGroups')
                  "
                  :cardTitle="$t('parameterGroupEdit.parametergroupsTitle')"
                  :non-valid-available-option="id"
                  groupId="parametergroups"
                  :selected-options-title="
                    $t('parameterGroupEdit.selectedParameterGroups')
                  "
                  @selectionChanged="updateParameterGroupsForParameterGroup"
                />
              </b-col>
            </b-row>
            <b-row class="mb-1" data-test="parameters">
              <b-col>
                <ListMover
                  :available-options-list="pageVariables.parameters"
                  :selected-options-list="
                    pageVariables.parameterGroup.parameters
                  "
                  :availableOptionsTitle="
                    $t('parameterGroupEdit.availableParameters')
                  "
                  :cardTitle="$t('parameterGroupEdit.parametersTitle')"
                  groupId="parameters"
                  :selected-options-title="
                    $t('parameterGroupEdit.selectedParameters')
                  "
                  @selectionChanged="updateParametersForParameterGroup"
                />
              </b-col>
            </b-row>
            <b-row class="mb-1" data-test="applications">
              <b-col>
                <ListMover
                  :available-options-list="pageVariables.applications"
                  :selected-options-list="
                    pageVariables.parameterGroup.applications
                  "
                  :availableOptionsTitle="
                    $t('parameterGroupEdit.availableApplications')
                  "
                  :cardTitle="$t('parameterGroupEdit.applicationsTitle')"
                  groupId="applications"
                  :selected-options-title="
                    $t('parameterGroupEdit.selectedApplications')
                  "
                  @selectionChanged="updateApplicationsForParameterGroup"
                />
              </b-col>
            </b-row>
            <b-row align-h="end" class="mr-0">
              <b-button
                class="mr-1"
                data-test="btn-update-parameter-group"
                v-on:click="
                  handleSubmit((__) => {
                    saveOrOpenReferenceModal();
                  })
                "
                variant="primary"
                >{{ $t("parameterGroupEdit.updatePG") }}</b-button
              >
              <b-button
                class="mr-1"
                v-on:click="gotoParameterGroupspage()"
                variant="warning"
                >{{ $t("parameterGroupEdit.cancelPG") }}</b-button
              >
              <b-button
                data-test="btn-delete-parameter-group"
                class="mr-1"
                v-on:click.prevent="openParameterGroupDeleteModal()"
                type="delete"
                variant="danger"
                >{{ $t("parameterGroupEdit.deletePG") }}</b-button
              >
            </b-row>
            <reference-modal
              :i-d-reference="id"
              :object-type="'ParameterGroup'"
              :object-title="pageVariables.parameterGroup.displayName"
              @targetMethod="
                handleSubmit((__) => {
                  onSubmit;
                })
              "
              @reloadReferencesMethod="getReferences()"
              :modal-title="$t('parameterGroupEdit.saveModalTitle')"
              :modal-function="$t('saveReferenceFunction')"
              :show-refresh-button="true"
              :references="pageVariables.references"
              :reference-type="'ParameterGroup'"
              :reference-route="'parametergroupedit/'"
              :okButtonText="$t('okButtonText')"
              :cancel-button-text="$t('cancelButtonText')"
              ref="referenceModal"
              :hide-footer="true"
            />
            <reference-modal
              :i-d-reference="id"
              :object-type="'ParameterGroup'"
              @targetMethod="requestDeleteParameterGroup"
              :modal-title="$t('parameterGroupEdit.deleteModalTitle')"
              :modal-function="$t('deleteReferenceFunction')"
              :object-title="pageVariables.parameterGroup.displayName"
              :okButtonText="$t('okButtonText')"
              :cancel-button-text="$t('cancelButtonText')"
              ref="deleteParameterGroupModal"
            />
          </b-form>
          <b-spinner class="align-self-auto" v-else>Loading</b-spinner>
        </validation-observer>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import ListMover from "@/components/common/ListMover";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import ReferenceModal from "../common/ReferenceModal";
import axios from "axios";
import constants from "../../EndPointsAndConstants";
import { openUnsavedModal } from "../../Helper";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "parametergroupedit",
  components: { ListMover, ReferenceModal },
  props: {
    id: { type: Number, required: true },
  },
  data() {
    return {
      pageVariables: {
        pageLoading: true,
        parameterGroup: { childrenGroups: [], parameters: [] },
        parameterGroups: [],
        parameters: [],
        applications: [],
        references: [],
        fieldLength: this.$maxLength,
      },
    };
  },
  created() {
    this.reset();
    document.title =
      this.$t("pageTitles.parameterGroupDetail") +
      " " +
      this.getParameterGroup().name;
  },
  computed: {},
  methods: {
    ...mapGetters("parameterGroups", [
      "getParameterGroup",
      "getParameterGroups",
    ]),
    ...mapGetters("parameters", ["getParameters"]),
    ...mapGetters("applications", ["getApplications"]),
    ...mapActions("parameters", ["fetchParameters"]),
    ...mapActions("applications", ["fetchApplications"]),
    ...mapActions("parameterGroups", [
      "fetchParameterGroups",
      "fetchParameterGroup",
      "saveParameterGroup",
      "deleteParameterGroup",
    ]),
    ...mapMutations("parameterGroups", ["SET_PARAMETER_GROUP"]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    updateParametersForParameterGroup(list) {
      this.pageVariables.parameterGroup.parameters = [...list];
    },
    updateApplicationsForParameterGroup(list) {
      this.pageVariables.parameterGroup.applications = [...list];
    },
    updateParameterGroupsForParameterGroup(list) {
      this.pageVariables.parameterGroup.childrenGroups = [...list];
    },
    onReset() {
      this.reset();
    },
    onSubmit(e) {
      // e.preventDefault();
      Vue.$log.debug("saving");
      this.saveParameterGroup({
        vm: this,
        parameterGroup: this.pageVariables.parameterGroup,
      }).then((__) => {
        this.setUnsavedChanges(false);
      });
      //this.reset();
    },
    reset() {
      {
        Vue.$log.debug("parameter group id:>", this.id);
        this.pageVariables.pageLoading = true;
        this.fetchParameterGroups().then((__) =>
          this.fetchParameters().then((__) => {
            this.fetchParameterGroup(this.id)
              .then((__) => {
                this.pageVariables.parameterGroup = Object.assign(
                  {},
                  this.getParameterGroup()
                );
              })
              .then((__) => {
                this.fetchApplications().then((__) => {
                  Vue.$log.debug(
                    "done loading-->",
                    this.pageVariables.parameterGroup
                  );
                  this.pageVariables.parameters = this.getParameters();
                  this.pageVariables.parameterGroups =
                    this.getParameterGroups().filter((item) => {
                      return item.id !== this.id;
                    });
                  this.pageVariables.applications = this.getApplications();
                  this.pageVariables.pageLoading = false;
                });
              });
          })
        );
      }
    },
    requestDeleteParameterGroup() {
      Vue.$log.debug("Deleting: ", this.id);
      this.deleteParameterGroup({ vm: this, id: this.id }).then((response) => {
        this.$router.push("/parameter");
      });
    },
    saveOrOpenReferenceModal() {
      Vue.$log.info("OPENING MODAL");
      this.getReferences();
    },
    getReferences() {
      this.pageVariables.references = [];

      axios
        .post(
          constants.endPoints.PARAMETER_GROUPS_BASE +
            constants.endPoints.PARAMETER_GROUP_REFERENCE,
          this.pageVariables.parameterGroup
        )
        .then((response) => {
          this.onSubmit();
        })
        .catch((error) => {
          if (Object.entries(error.response.data).length !== 0) {
            error.response.data.forEach((element) => {
              Vue.$log.debug("Element : ", element);
              let reference = {};
              reference.links = [];
              reference.id = element.parameterGroupID;
              element.errorList.map((error) => {
                reference.links.push({
                  from: error.fromParameterGroupID,
                  to: error.toParameterGroupID,
                });
              });
              this.pageVariables.references.push(reference);
            });
          }
          if (this.pageVariables.references.length > 0) {
            this.$refs.referenceModal.showModal();
          }
        });
    },
    openParameterGroupDeleteModal() {
      Vue.$log.info("OPENING MODAL");
      this.$refs.deleteParameterGroupModal.showModal();
    },
    gotoParameterGroupspage() {
      this.$router.push("/parameter");
    },
  },
};
</script>
