/**
 *
 * This mixin provides basic usage such as creating toasts or sanitising links
 */
import store from "../index";

export function makeToast(vm, message, variant, keepOnRouting) {
  let title;
  if (variant === "danger") {
    title = "Failed";
  } else {
    title = "Success";
  }
  if (keepOnRouting !== null) {
    store.commit("toaster/SET_KEEPONROUTING", keepOnRouting);
  } else {
    store.commit("toaster/SET_KEEPONROUTING", false);
  }
  store.dispatch("toaster/addToast", { variant, message });
  // toaster.actions.addToast({variant, message});
}

export function makeAlertMessage(vm, message, variant, error) {
  store.dispatch("toaster/addToast", { variant, message });
  //error.response.statusText
}

export function sanitiseLink(link) {
  if (link && link.href) {
    return link.href.replace("http://localhost:9999", "");
  }
  return link;
}
