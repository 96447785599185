<template>
  <b-row class="justify-content-center">
    <b-col cols="12" class="mb-3">
      <b-card title="Weekly" class="shadow">
        <schedule-table
          @DeleteSchedule="deleteSchedule"
          @UpdateSchedule="updateSchedule"
          :scheduleList="getWeeklySchedules"
          :fields="pageVariables.weeklyScheduleTableFields"
        ></schedule-table>
        <ScheduleForm
          @AddSchedule="addSchedule"
          :schedule-type="pageVariables.scheduleTypeWeek"
        ></ScheduleForm>
      </b-card>
    </b-col>
    <b-col cols="12" class="mb-3">
      <b-card title="Special" class="shadow">
        <schedule-table
          :scheduleList="getSpecialSchedules"
          :fields="pageVariables.specialScheduleTableFields"
        ></schedule-table>
        <ScheduleForm
          @AddSchedule="addSchedule"
          :schedule-type="pageVariables.scheduleTypeSpecial"
        ></ScheduleForm>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ScheduleTable from "./ScheduleTable";
import ScheduleForm from "../common/ScheduleForm";
import constants from "../../EndPointsAndConstants.js";
import { getDayName } from "../../Helper";
import Vue from "vue";

export default {
  name: "CalendarSchedule",
  components: { ScheduleForm, ScheduleTable },
  data() {
    return {
      pageVariables: {
        weeklyScheduleTableFields: [
          { key: "dayOfWeek", label: "Day" },
          { key: "startTime", label: "Start" },
          { key: "endTime", label: "End" },
          { key: "description", label: "Description" },
          { key: "disabled", label: "Disabled" },
        ],
        specialScheduleTableFields: [
          { key: "startDate", label: "Start date" },
          { key: "endDate", label: "End date" },
          { key: "startTime", label: "Start Time" },
          { key: "endTime", label: "End time" },
          { key: "description", label: "Description" },
        ],
        constants: constants,
        scheduleTypeWeek: constants.SCHEDULE_TYPES.WEEKLY,
        scheduleTypeSpecial: constants.SCHEDULE_TYPES.SPECIAL,
        scheduleHolder: {},
        loadedSchedules: false,
      },
    };
  },
  computed: {
    ...mapGetters("calendar", [
      "getWeeklySchedules",
      "getSpecialSchedules",
      "getRecurringSpecialSchedule",
    ]),
  },
  methods: {
    ...mapActions("calendar", [
      "fetchCalendar",
      "addCalendarSchedule",
      "deleteCalendarSchedule",
      "checkScheduleDisabled",
      "saveSchedule",
    ]),

    addSchedule(schedule) {
      Vue.$log.debug(schedule);
      this.addCalendarSchedule(schedule);
    },
    dayFormatter(value, key, item) {
      return getDayName(this.$i18n.locale, value);
    },

    deleteSchedule(schedule) {
      this.deleteCalendarSchedule({ vm: this, schedule: schedule });
    },
    updateSchedule(schedule) {
      this.saveSchedule({ vm: this, schedule: schedule });
    },
    printSchedules() {
      Vue.$log.debug("Weekly schedules: ", this.getWeeklySchedules);
      Vue.$log.debug("Special schedules: ", this.getSpecialSchedules);
      Vue.$log.debug(
        "Recurring special schedules: ",
        this.getRecurringSpecialSchedule
      );
    },
  },
};
</script>
