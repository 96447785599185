import axios from "axios";
import { constants } from "../../EndPointsAndConstants";
import { makeToast } from "../mixin/basic";

const state = {
  scripts: [],
};

const getters = {
  getScripts: (state) => {
    return state.scripts;
  },
};

const actions = {
  fetchScripts({ commit }) {
    return axios
      .get(constants.endPoints.SCRIPTS_BASE)
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          commit("SET_SCRIPTS", response.data);
        }
      })
      .catch((response) => {
        console.error("Fetch scripts not working");
      });
  }
};

const mutations = {
  SET_SCRIPTS(state, scripts) {
    state.scripts = scripts;
  },
  ADD_SCRIPT(state, script) {
    state.scripts.push(script);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
