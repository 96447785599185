<template>
  <b-row class="mb-3">
    <b-spinner v-if="this.pageVariables.showContent === false" />
    <b-col v-else>
      <b-card class="shadow" no-body>
        <b-card-header
          header-bg-variant="secondary"
          header-text-variant="white"
          class="d-flex"
          style="flex-direction: row"
        > {{this.pageVariables.currentDisplayName}}
        </b-card-header>
        <b-card-body>
          <b-card :header="this.$t('applicationPage.applicationName')">
            <b-row>
              <b-col cols="2">
                <ValidationProvider
                    :rules="{
                      min: 1,
                      max: this.pageVariables.fieldLength,
                      required: true
                    }"
                    v-slot="{ valid, errors, touched }"
                >
                  <b-form-group
                      class="mb-0 pb-0"
                      :label="'Display Name'">
                    <b-form-input data-test="applicationDisplayName" v-model="displayName" type="text" placeholder="display name" :state="
                              errors[0] ? false : valid && touched ? true : null"/>
                    <b-form-invalid-feedback tag="span">{{
                        errors[0]
                      }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="2">
                <ValidationProvider
                    :rules="{
                      min: 1,
                      max: this.pageVariables.fieldLength,
                      required: true
                    }"
                    v-slot="{ valid, errors, touched }"
                >
                  <b-form-group
                      class="mb-0 pb-0"
                      :label="'Application Name'">
                    <b-form-input data-test="applicationName" v-model="name" type="text" placeholder="name" :state="
                              errors[0] ? false : valid && touched ? true : null"/>
                    <b-form-invalid-feedback tag="span">{{
                        errors[0]
                      }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-card>


          <b-row v-if="!this.selectedApplication.organization">
            <b-col>
              <ValidationObserver ref="triggerObserver">
                <b-card
                  :header="
                    $t('applicationDetail.generalSettings.triggerCardTitle')
                  "
                  class="mt-3"
                >
                  <b-row>
                    <b-col cols="3">
                      <b-form inline ref="triggerForm">
                        <ValidationProvider
                            name="trigger"
                            :rules="{
                        usedTrigger: getTriggers(),
                        min: 1,
                        max: this.pageVariables.fieldLength,
                        checkForExistingTriggers: {trigger: pageVariables.trigger, existingTriggers: getAllTriggers()}
                      }"
                            v-slot="{ valid, errors, touched }"
                        >
                          <b-form-group
                              class="mr-2"
                              :label="
                          $t(
                            'applicationDetail.generalSettings.triggerFieldDescription'
                          )
                        "

                          >
                            <b-form-input
                                id="form_input_text_trigger"
                                data-test="form_input_text_trigger"
                                type="text"
                                v-model="pageVariables.trigger"
                                :state="
                          errors[0] ? false : valid && touched ? true : null
                        "
                            />
                            <b-button v-if="getAllTriggers().find(item => item.trigger == pageVariables.trigger) && !getTriggers().find(item => item == pageVariables.trigger)"
                                      @click="openRoute('applicationDetails', getAllTriggers().find(item => item.trigger == pageVariables.trigger).applicationID)"
                                      class="ml-1"
                                      data-test="gotoTriggerOrigin">
                              <font-awesome-icon icon="arrow-right" />
                            </b-button>
                            <b-button
                                id="btn_add_trigger"
                                data-test="btn_add_trigger"
                                @click="addTriggerTemp(pageVariables.trigger)"
                                class="ml-1"
                                :disabled="pageVariables.trigger.length < 1 || getAllTriggers().find(item => item.trigger == pageVariables.trigger) || getTriggers().find(item => item == pageVariables.trigger)"
                            >
                              {{
                                $t(
                                    "applicationDetail.generalSettings.triggerButtonText"
                                )
                              }}
                            </b-button>
                            <b-tooltip
                                v-if="errors[0]"
                                :show="errors[0] != null"
                                target="btn_add_trigger"
                                variant="danger"
                                data-test="trigger_error"
                            >{{ errors[0] }}</b-tooltip>
<!--                            <b-form-invalid-feedback  v-if="pageVariables.trigger.length > 0" data-test="trigger_error" tag="span">{{-->
<!--                                errors[0]-->
<!--                              }}</b-form-invalid-feedback>-->
                          </b-form-group>
                        </ValidationProvider>
                        <!--                    <b-form-group class="align-self-end">-->
                        <!--                      <b-button-->
                        <!--                        id="btn_add_trigger"-->
                        <!--                        data-test="btn_add_trigger"-->
                        <!--                        @click="addTriggerTemp(pageVariables.trigger)"-->
                        <!--                      >-->
                        <!--                        {{-->
                        <!--                          $t(-->
                        <!--                            "applicationDetail.generalSettings.triggerButtonText"-->
                        <!--                          )-->
                        <!--                        }}-->
                        <!--                      </b-button>-->
                        <!--                    </b-form-group>-->
                      </b-form>
                    </b-col>
                    <b-col cols="9" align-self="end">
                    <span
                        id="application_triggers"
                        data-test="application_triggers"
                        v-bind:key="trigger"
                        v-for="trigger in selectedApplication.triggers"
                        class="align-items-start align-content-start"
                    >
                    <b-badge
                        class="ml-1 mr-1 text-white pb-0 pt-0 pr-0"
                        variant="primary"
                        data-test="trigger"
                    >{{ trigger
                      }}<b-button
                          data-test="delete_trigger"
                          class="bg-danger ml-1"
                          @click="removeTriggerTemp(trigger)"
                          :title="
                          $t(
                            'applicationDetail.generalSettings.removeTrigger'
                          )
                        "
                          v-b-tooltip.hover
                      ><font-awesome-icon icon="trash" /></b-button
                      ></b-badge>
                  </span>
                    </b-col>
                  </b-row>

                </b-card>
              </ValidationObserver>
            </b-col>
          </b-row>
          <!--                <b-row class="mt-3">-->
          <!--                  <b-col>-->
          <!--                    <b-card header="Responsible for this application">-->
          <!--                      <b-form inline>-->
          <!--                        <b-form-group>-->
          <!--                          <b-form-input-->
          <!--                            placeholder="Enter name for primary responsible for this, could be department or person"-->
          <!--                            type="text"-->
          <!--                          ></b-form-input>-->
          <!--                        </b-form-group>-->
          <!--                      </b-form>-->
          <!--                    </b-card>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->

          <b-row class="" v-if="!selectedApplication.organization">
            <b-col cols="7">
              <ValidationObserver ref="OrgValidator">
                <b-card
                  :header="$t('applicationDetail.generalSettings.orgCardTitle')"
                  class="mt-3"
                >
                  <b-form inline>
                    <b-form-group>
                      <ValidationProvider
                        :rules="{
                          checkForSelfReference: {
                            containsReferences:
                              this.fetchOrgReferences(fk_ApplicationOrg),
                          },
                        }"
                        v-slot="{ valid, errors, touched }"
                      >
                        <b-form-select
                          :options="pageVariables.allOrganizationsAsOptions"
                          id="organisation"
                          :title="
                            $t(
                              'applicationDetail.generalSettings.orgFieldTitle'
                            )
                          "
                          v-b-tooltip
                          v-model="fk_ApplicationOrg"
                          data-test="organisation_selector"
                          :state="
                            errors[0] ? false : valid && touched ? true : null
                          "
                        />
                        <b-form-invalid-feedback>{{
                          errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <b-button
                      v-if="fk_ApplicationOrg != undefined"
                      data-test="gotoOrganisationBtn"
                      @click="
                        openRoute('applicationDetails', fk_ApplicationOrg)
                      "
                    >
                      <font-awesome-icon icon="arrow-right" />
                    </b-button>
                  </b-form>
                </b-card>
              </ValidationObserver>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col cols="7">
              <b-row>
                <b-col>
                  <b-card
                    :header="
                      $t(
                        'applicationDetail.generalSettings.defaultActionCardTitle'
                      )
                    "
                    class="mt-3"
                  >
                    <b-form inline>
                      <b-form-group>
                        <b-form-select
                          :options="pageVariables.defaultNoHitOptions"
                          v-model="actionOnNoHit"
                          data-test="actionOnNoHit"
                        />
                      </b-form-group>
                    </b-form>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <b-card
                    :header="
                      $t('applicationDetail.generalSettings.defaultActionOpen')
                    "
                  >
                    <b-row>
                      <b-col>
                        <action-selector
                          data-test="actionOnTrue_selector"
                          :showDefaultCheck="pageVariables.showDefaultCheckOpen"
                          :action="actionOnTrue"
                          :actionTypes="getSelectableActionOptions()"
                          :allApplicationsAsOptions="
                            pageVariables.allApplicationsAsOptions
                          "
                          :allMenusAsOptions="pageVariables.allMenusAsOptions"
                          :allScriptsAsOptions="
                            pageVariables.allScriptsAsOptions
                          "
                          :application="selectedApplication"
                          modalId="actionOnTrue"
                          :title="
                            $t(
                              'applicationDetail.generalSettings.openActionCardTitle'
                            )
                          "
                          @actionCreated="
                            (action) => {
                              actionOnTrue = action;
                            }
                          "
                          @actionChanged="
                            (action) => {
                              checkActionUseDefaultOpen(action);
                            }
                          "
                          class="align-items-baseline"
                          :colWidth="'col-5'"
                          :valueColWidth="'col-5'"
                        >
                        </action-selector>
                      </b-col>
                      <b-col cols="4" class="d-flex align-items-center">
                        <prompt-list
                          :prompts="actionOnTrue.prompts"
                          data-test="actionOnTrue_prompt_list"
                        />
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col>
                  <b-card
                    :header="
                      $t(
                        'applicationDetail.generalSettings.defaultActionClosed'
                      )
                    "
                  >
                    <b-row>
                      <b-col>
                        <action-selector
                          data-test="actionOnFalse_selector"
                          :showDefaultCheck="
                            pageVariables.showDefaultCheckClosed
                          "
                          :actionTypes="getSelectableActionOptions()"
                          :action="actionOnFalse"
                          :allApplicationsAsOptions="
                            pageVariables.allApplicationsAsOptions
                          "
                          :allMenusAsOptions="pageVariables.allMenusAsOptions"
                          :allScriptsAsOptions="
                            pageVariables.allScriptsAsOptions
                          "
                          :application="this.selectedApplication"
                          modalId="actionOnFalse"
                          :title="
                            $t(
                              'applicationDetail.generalSettings.closedActionCardTitle'
                            )
                          "
                          @actionCreated="
                            (action) => {
                              this.actionOnFalse = action;
                            }
                          "
                          @actionChanged="
                            (action) => {
                              this.actionOnFalse = action;
                              checkActionUseDefaultClosed(action);
                            }
                          "
                          class="align-items-baseline"
                          :colWidth="'col-5'"
                          :valueColWidth="'col-5'"
                        >
                        </action-selector>
                      </b-col>
                      <b-col cols="4" class="d-flex align-items-center">
                        <prompt-list
                          :prompts="actionOnFalse.prompts"
                          data-test="actionOnFalse_prompt_list"
                        />
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
            <b-col></b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card
                class="mt-3"
                :header="
                  $t(
                    'applicationDetail.generalSettings.defaultSettingsCardTitle'
                  )
                "
              >
                <ValidationObserver ref="generalSettingsObserver">
                  <b-row>
                    <b-col>
                      <b-card
                        :header="
                          $t('applicationDetail.generalSettings.scriptSetting')
                        "
                        data-test="defaultScript"
                      >
                        <b-form-group
                          :label="
                            $t('applicationDetail.generalSettings.setting')
                          "
                          label-for="script"
                        >
                          <b-form-select
                            id="script"
                            :options="pageVariables.allScriptsAsOptions"
                            v-model="defaultScriptActionFk_Script"
                          />
                          <b-button
                            v-if="defaultScriptActionFk_Script != undefined"
                            data-test="gotoApplicationBtn"
                            @click="
                              openRoute(
                                'scriptDetail',
                                defaultScriptActionFk_Script
                              )
                            "
                          >
                            <font-awesome-icon icon="arrow-right" />
                          </b-button>
                        </b-form-group>

                        <prompts
                          :prompt-list="defaultScriptActionPrompts"
                          @updateList="
                            (promptList) => {
                              defaultScriptActionPrompts = promptList;
                            }
                          "
                          :title="
                            $t(
                              'applicationDetail.generalSettings.defaultScript'
                            )
                          "
                          :horizontal="true"
                        >
                        </prompts>
                      </b-card>
                    </b-col>

                    <b-col>
                      <b-card
                        :header="
                          $t(
                            'applicationDetail.generalSettings.transferSetting'
                          )
                        "
                        data-test="defaultTransfer"
                      >
                        <ValidationProvider
                          :rules="'min:1|max:' + pageVariables.fieldLength"
                          v-slot="{ valid, errors, touched }"
                        >
                          <b-form-group
                            :label="
                              $t('applicationDetail.generalSettings.setting')
                            "
                            label-for="transfer-prompt"
                          >
                            <b-input
                              id="transfer-prompt"
                              data-test="transferValue"
                              type="text"
                              v-model="defaultTransferActionValue"
                              :state="
                                errors[0]
                                  ? false
                                  : valid && touched
                                  ? true
                                  : null
                              "
                            />
                            <b-form-invalid-feedback tag="span">{{
                              errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </ValidationProvider>

                        <prompts
                          :prompt-list="defaultTransferActionPrompts"
                          @updateList="
                            (promptList) => {
                              defaultTransferActionPrompts = promptList;
                            }
                          "
                          :title="
                            $t(
                              'applicationDetail.generalSettings.defaultTransfer'
                            )
                          "
                          :horizontal="true"
                        >
                        </prompts>
                      </b-card>
                    </b-col>
                    <b-col>
                      <b-card
                        :header="
                          $t('applicationDetail.generalSettings.menuSetting')
                        "
                      >
                        <b-form-group :label="$t('applicationDetail.generalSettings.setting')" label-for="menu">
                          <b-form-select
                            id="script"
                            :options="pageVariables.allMenusAsOptions"
                            v-model="defaultMenuActionFk_Menu"
                          />
                          <b-button
                            v-if="defaultMenuActionFk_Menu != undefined"
                            data-test="gotoMenuBtn"
                            @click="
                              openRoute('menuDetail', defaultMenuActionFk_Menu)
                            "
                          >
                            <font-awesome-icon icon="arrow-right" />
                          </b-button>
                        </b-form-group>
                        <prompts
                          :prompt-list="defaultMenuActionPrompts"
                          @updateList="
                            (promptList) => {
                              defaultMenuActionPrompts = promptList;
                            }
                          "
                          :title="
                            $t('applicationDetail.generalSettings.defaultMenu')
                          "
                          :showPrompts="true"
                          :horizontal="true"
                        >
                        </prompts>
                        <!--                                              <prompts-->
                        <!--                                                :prompt-list="application.defaultMenuAction.prompts"-->
                        <!--                                                @updateList="-->
                        <!--                                              promptList =>-->
                        <!--                                              savePrompts({-->
                        <!--                                                vm: this,-->
                        <!--                                                promptList: promptList,-->
                        <!--                                                linkName: this.pageVariables.constants-->
                        <!--                                                .APPLICATION.LINKS-->
                        <!--                                                .DEFAULT_MENU_ACTION_PROMPT_HANDLING-->
                        <!--                                              }).then(-->
                        <!--                                                e =>-->
                        <!--                                                (application.defaultMenuAction.prompts =-->
                        <!--                                                e.data.content)-->
                        <!--                                              )-->
                        <!--                                                "-->
                        <!--                                                title="Default menu"-->
                        <!--                                              >-->
                        <!--                                                Prompts-->
                        <!--                                              </prompts>-->
                      </b-card>
                    </b-col>
                    <b-col>
                      <b-card
                        :header="
                          $t(
                            'applicationDetail.generalSettings.applicationSetting'
                          )
                        "
                        data-test="defaultApplication"
                      >
                        <b-form-group
                          :label="
                            $t('applicationDetail.generalSettings.setting')
                          "
                          label-for="application"
                        >
                          <b-form-select
                            :options="pageVariables.allApplicationsAsOptions"
                            id="application"
                            v-model="defaultApplicationActionFk_Application"
                          />
                          <b-button
                            v-if="
                              defaultApplicationActionFk_Application !=
                              undefined
                            "
                            data-test="gotoApplicationBtn"
                            @click="
                              openRoute(
                                'applicationDetails',
                                defaultApplicationActionFk_Application
                              )
                            "
                          >
                            <font-awesome-icon icon="arrow-right" />
                          </b-button>
                        </b-form-group>

                        <prompts
                          :prompt-list="defaultApplicationActionPrompts"
                          @updateList="
                            (promptList) => {
                              defaultApplicationActionPrompts = promptList;
                            }
                          "
                          :title="
                            $t(
                              'applicationDetail.generalSettings.defaultApplication'
                            )
                          "
                          :horizontal="true"
                        >
                        </prompts>
                      </b-card>
                    </b-col>
                    <b-col>
                      <b-card
                        class="h-100"
                        :header="
                          $t(
                            'applicationDetail.generalSettings.disconnectSetting'
                          )
                        "
                        no-body
                        data-test="defaultDisconnect"
                      >
                        <b-card-body class="d-flex">
                          <prompts
                            class="d-flex flex-column justify-content-end"
                            :prompt-list="defaultDisconnectActionPrompts"
                            @updateList="
                              (promptList) => {
                                defaultDisconnectActionPrompts = promptList;
                              }
                            "
                            :title="
                              $t(
                                'applicationDetail.generalSettings.defaultDisconnect'
                              )
                            "
                            :horizontal="true"
                          >
                          </prompts>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                </ValidationObserver>
              </b-card>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-row align-h="end" class="mt-1 mb-0 mr-0">
        <b-button
          data-test="btn_update_application"
          @click="saveApplicationTemp()"
          :title="
            $t('applicationDetail.generalSettings.updateApplicationTitle')
          "
          v-b-tooltip.hover
          variant="primary"
          class="mr-1"
          >{{ $t("applicationDetail.generalSettings.updateApplication") }}
        </b-button>
        <b-button
          data-test="btn_delete_application"
          v-if="
            this.getPermissions().application.permission ===
            this.getPERMISSION_TYPES().ALL
          "
          v-on:click="openDeleteModal()"
          class="mr-1"
          variant="danger"
          >{{ $t("applicationDetail.generalSettings.deleteApplication") }}
        </b-button>
      </b-row>
      <reference-modal
        ref="deleteModal"
        :modal-title="$t('applicationDetail.generalSettings.deleteModalTitle')"
        :i-d-reference="id"
        :object-title="displayName"
        :show-refresh-button="true"
        @targetMethod="requestDeleteApplication(id)"
        @reloadReferencesMethod="requestReloadReferences()"
        :references="referenceList"
        :ok-button-text="
          $t('applicationDetail.generalSettings.deleteConfirmationButton')
        "
        :modal-function="$t('deleteReferenceFunction')"
      />
    </b-col>
  </b-row>
</template>

<script>
import constants from "../../EndPointsAndConstants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ACTION_TYPE_NO_DEFAULTS_ORGANISATION,
  ACTION_TYPE_OPTIONS_NO_DEFAULTS,
} from "@/EndPointsAndConstants";
import { mapFields } from "vuex-map-fields";
import ReferenceModal from "../common/ReferenceModal";
import PromptList from "../common/PromptList";
import { populateOptions } from "../../Helper.js";
import axios from "axios";
import { makeToast } from "../../store/mixin/basic";

export default {
  name: "ApplicationGeneralSettings",
  components: { PromptList, ReferenceModal },
  props: {},
  data() {
    return {
      pageVariables: {
        showDefaultCheckOpen: true,
        showDefaultCheckClosed: true,
        trigger: "",
        constants: constants,
        defaultNoHitOptions: [
          {
            value: false,
            text: this.$t(
              "applicationDetail.generalSettings.defaultActionClosed"
            ),
          },
          {
            value: true,
            text: this.$t(
              "applicationDetail.generalSettings.defaultActionOpen"
            ),
          },
        ],
        allApplicationsAsOptions: [],
        allOrganizationsAsOptions: [],
        allScriptsAsOptions: [],
        allMenusAsOptions: [],
        loadedApplication: false,
        loadedApplicationFailed: false,
        showContent: false,
        fieldLength: this.$maxLength,
        currentDisplayName: ""
      },
    };
  },
  computed: {
    ...mapFields("application", [
      "selectedApplication",
      "selectedApplication.actionOnNoHit",
      "selectedApplication.id",
      "selectedApplication.fk_ApplicationOrg",
      "selectedApplication.displayName",
      "selectedApplication.name",
      "selectedApplication.actionOnTrue",
      "selectedApplication.actionOnFalse",
      "selectedApplication.defaultTransferAction",
      "selectedApplication.allTriggers"
    ]),
    ...mapFields("application", {
      defaultDisconnectActionPrompts:
        "selectedApplication.defaultDisconnectAction.prompts",
      defaultApplicationActionPrompts:
        "selectedApplication.defaultApplicationAction.prompts",
      defaultApplicationActionFk_Application:
        "selectedApplication.defaultApplicationAction.fk_Application",
      defaultTransferActionValue:
        "selectedApplication.defaultTransferAction.value",
      defaultTransferActionPrompts:
        "selectedApplication.defaultTransferAction.prompts",
      defaultScriptAction: "selectedApplication.defaultScriptAction",
      defaultScriptActionFk_Script:
        "selectedApplication.defaultScriptAction.fk_Script",
      defaultScriptActionPrompts:
        "selectedApplication.defaultScriptAction.prompts",
      defaultMenuAction: "selectedApplication.defaultMenuAction",
      defaultMenuActionFk_Menu: "selectedApplication.defaultMenuAction.fk_Menu",
      defaultMenuActionPrompts: "selectedApplication.defaultMenuAction.prompts",
    }),
    referenceList: {
      get() {
        return this.getInheritingApplications().map((item) => ({
          id: item.inheritID,
          type: item.where,
          name: item.name,
        }));
      },
    },
  },
  methods: {
    ...mapGetters("authentication", ["getPermissions"]),
    ...mapMutations("application", [
      "SET_SELECTED_APPLICATION",
      "SET_CLOSED_ACTION",
      "SET_OPEN_ACTION",
      "SET_ALL_TRIGGERS"
    ]),
    ...mapGetters("application", ["getSelectedApplication", "getAllTriggers"]),
    ...mapActions("application", [
      "fetchSelectedApplication",
      "saveApplication",
      "savePrompts",
      "removeTrigger",
      "addTrigger",
      "fetchOrgReferences",
      "fetchAllTriggers"
    ]),
    ...mapActions("applications", ["deleteApplication"]),
    ...mapActions("application", ["fetchInheritingActions"]),
    ...mapGetters("application", ["getInheritingApplications"]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    getTriggers() {
      console.log("reading triggers");

      return this.selectedApplication.triggers;
    },
    getSelectableActionOptions() {
      if (this.selectedApplication.organization) {
        let options = ACTION_TYPE_NO_DEFAULTS_ORGANISATION();
        options.forEach((option) => {
          option.text = this.$t("actionDropTypes." + option.value.name);
        });
        return options;
      } else {
        let options = ACTION_TYPE_OPTIONS_NO_DEFAULTS();
        options.forEach((option) => {
          option.text = this.$t("actionDropTypes." + option.value.name);
        });
        return options;
      }
    },
    removeTriggerTemp(trigger) {
      this.removeTrigger({
        vm: this,
        trigger: trigger,
      });
    },
    getPERMISSION_TYPES() {
      return constants.PERMISSION_TYPES;
    },
    addTriggerTemp(trigger) {
      if(trigger != null){
        this.$refs.triggerObserver.handleSubmit((__) => {
          this.addTrigger({ vm: this, trigger: this.pageVariables.trigger }).then(
              (__) => {
                // console.log(this.getAllTriggers())
                // let array = this.getAllTriggers();
                // array.add(this.pageVariables.trigger);
                this.pageVariables.trigger = "";
                this.$nextTick(() => {
                  // this.setAllTriggers(array);
                  this.$refs.triggerForm.reset();
                });
              }
          );
        });
      }
    },
    saveApplicationTemp() {
      this.$refs.generalSettingsObserver.handleSubmit((__) => {
        this.saveApplication({
          vm: this,
        }).then((__) => {
          this.pageVariables.currentDisplayName = this.displayName;
          this.$emit("updateApplicationTitle");
          this.setUnsavedChanges(false);
          this.fetchAllTriggers();
        });
      });
    },
    requestReloadReferences() {
      this.fetchInheritingActions(this.id);
    },
    requestDeleteApplication(id) {
      this.$log.debug("Deleting: ", id);
      return this.deleteApplication({ vm: this, applicationId: id }).then(
        (__) => {
          this.$router.push("/application");
        }
      );
    },
    openDeleteModal() {
      this.fetchInheritingActions(this.id);
      this.$refs.deleteModal.showModal();
    },
    showORHideDefaultBasedOnAction(action) {
      this.$log.debug("checking action");
      switch (action.fk_ActionType) {
        case constants.ACTION_TYPES.DEFAULT.name:
          return false;
        case constants.ACTION_TYPES.DEFAULT_CLOSED.name:
          return false;
        case constants.ACTION_TYPES.DEFAULT_OPEN.name:
          return false;
        default:
          return true;
      }
    },
    checkActionUseDefaultOpen(action) {
      this.pageVariables.showDefaultCheckOpen =
        this.showORHideDefaultBasedOnAction(action);
    },
    checkActionUseDefaultClosed(action) {
      this.pageVariables.showDefaultCheckClosed =
        this.showORHideDefaultBasedOnAction(action);
    },
    openRoute(targetLink, targetID) {
      this.$log.debug(targetID);
      //TODO fix this form menu
      if (targetLink.includes("menuDetail")) {
        this.$router.push({
          name: targetLink,
          params: { idProp: targetID },
        });
      } else {
        this.$router.push({
          name: targetLink,
          params: { id: targetID },
        });
      }
    },
  },
  created: function () {
    this.checkActionUseDefaultOpen(this.actionOnTrue);
    this.checkActionUseDefaultClosed(this.actionOnFalse);
    this.pageVariables.allApplicationsAsOptions.push({
      value: null,
      text: this.$t("applicationDetail.action.dropdownApplicationDescriptor"),
      disabled: true,
    });
    populateOptions(
      constants.endPoints.APPLICATIONS_BASE,
      this.pageVariables.allApplicationsAsOptions,
      this.selectedApplication.id
    );
    this.pageVariables.allMenusAsOptions.push({
      value: null,
      text: this.$t("applicationDetail.action.dropdownMenuDescriptor"),
      disabled: true,
    });
    populateOptions(
      constants.endPoints.MENUS_BASE,
      this.pageVariables.allMenusAsOptions,
      null
    );
    this.pageVariables.allScriptsAsOptions.push({
      value: null,
      text: this.$t("applicationDetail.action.dropdownScriptDescriptor"),
      disabled: true,
    });
    populateOptions(
      constants.endPoints.SCRIPTS_BASE,
      this.pageVariables.allScriptsAsOptions,
      null
    );
    populateOptions(
      constants.endPoints.APPLICATIONS_BASE +
        constants.endPoints.APPLICATIONS_ORG,
      this.pageVariables.allOrganizationsAsOptions,
      null
    ).then((__) => {
      this.pageVariables.allOrganizationsAsOptions.push({
        value: null,
        text: this.$t("applicationDetail.generalSettings.orgFieldDefault"),
      });
      this.pageVariables.currentDisplayName = this.displayName;
      this.fetchAllTriggers();
      this.pageVariables.showContent = true;
    });
  },
  watch: {
    // displayName(){
    //   this.pageVariables.currentDisplayName = this.displayName;
    // }
  }
};
</script>
