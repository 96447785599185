<template>
  <ValidationObserver>
    <b-row>
      <b-col :class="[selectorColWidth]">
        <b-form-group
          :disabled="isScheduleOrg"
          :id="componentVariables.id"
          :label="$t('applicationDetail.action.action')"
        >
          <template v-if="this.componentVariables.localAction != null">
            <ValidationProvider
              name="selectedAction"
              rules="required"
              mode="lazy"
              v-slot="{ valid, errors, touched }"
            >
              <b-form-select
                :id="componentVariables.id"
                data-test="action_type_select"
                :state="errors[0] ? false : valid && touched ? true : null"
                :options="getActionTypes()"
                @change="
                  (input) => {
                    actionTypeChanged(input);
                  }
                "
                v-if="!isOpenCloseSelector"
                v-model="fkAction"
                style="width: 10em"
              ></b-form-select>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              <b-form-select
                :id="componentVariables.id"
                :options="
                  componentVariables.constants.OPEN_CLOSE_ACTION_TYPE_OPTIONS
                "
                @change="
                  (input) => {
                    actionTypeChanged(input);
                  }
                "
                v-if="isOpenCloseSelector"
                v-model="fkAction"
              ></b-form-select>
            </ValidationProvider>
          </template>
          <template v-else>
            <ValidationProvider
              name="selectedAction"
              rules="required"
              mode="lazy"
              v-slot="{ valid, errors }"
            >
              <b-form-select
                :state="errors[0] ? false : valid ? true : null"
                data-test="action_type_select"
                :options="getActionTypes()"
                @change="
                  (input) => {
                    actionTypeChanged(input);
                  }
                "
                v-model="componentVariables.actionNullTypePlaceHolder"
              ></b-form-select>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </template>
        </b-form-group>
      </b-col>
      <template v-if="componentVariables.localAction != null">
        <ValidationProvider
          :rules="{
            actionTypeDefault: [componentVariables.localAction, application],
          }"
          mode="lazy"
          v-slot="{ valid, errors, touched }"
        >
          <b-form-group
            class="col"
            id="default_check_group"
            :disabled="isScheduleOrg"
            :label="$t('applicationDetail.action.useDefaults')"
            v-if="calculateDefaultCheckVisible"
          >
            <b-form-checkbox
              data-test="useDefault_switch"
              @change="emitActionChanged()"
              v-model="useDefault"
              :state="errors[0] ? false : valid && touched ? true : null"
            />
          </b-form-group>
          <b-tooltip
            triggers="hover"
            v-if="errors.length > 0"
            target="default_check_group"
            variant="danger"
            >{{ errors[0] }}</b-tooltip
          >
        </ValidationProvider>
        <component
          :class="[valueColWidth]"
          :action="componentVariables.localAction"
          :allApplicationsAsOptions="allApplicationsAsOptions"
          :allMenusAsOptions="allMenusAsOptions"
          :allScriptsAsOptions="allScriptsAsOptions"
          :is="componentToPick"
          :isScheduleOrg="isScheduleOrg"
          v-if="fkAction != null"
          @componentChanged="emitActionChanged()"
        />
        <prompts
          class="col-1"
          :disabled="isScheduleOrg"
          :id="modalId"
          :prompt-list="componentVariables.localAction.prompts"
          :title="title"
          :showPrompts="showPrompts"
          @updateList="
            (promptList) => {
              this.promptListUpdated(promptList);
            }
          "
        />
      </template>
    </b-row>
  </ValidationObserver>
</template>

<script>
/**
 *
 */
import Vue from "vue";
import {
  APPLICATION_ACTION_TYPE_OPTIONS,
  constants,
  ORGANISATION_APPLICATION_ACTION_TYPE_OPTIONS,
} from "../../EndPointsAndConstants.js";

Vue.component(constants.ACTION_TYPES.TRANSFER.name, {
  template:
    '<ValidationProvider :rules="action.useDefault || isScheduleOrg ? \'max:\' + fieldLength : \'required|max:\' + fieldLength" mode="lazy" v-slot="{ valid, errors, touched }" > <label >{{$t(\'applicationDetail.action.transferActionDescription\')}}</label> <b-form :disabled="action.useDefault || isScheduleOrg" :label="$t(\'applicationDetail.action.transferActionDescription\')"><b-form-input data-test="transfer_input" type="text" :state="errors[0] ? false : (valid && touched ? true : null)" v-model="action.value" @change="emitComponentChanged()" style="width: 80%;"/></b-form> </ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  data() {
    return {
      fieldLength: this.$maxLength,
    };
  },
  methods: {
    emitComponentChanged() {
      this.$emit("componentChanged");
    },
  },
});
Vue.component(constants.ACTION_TYPES.SCRIPT.name, {
  template:
    '<ValidationProvider :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'" mode="lazy" v-slot="{ valid, errors, touched }" ><label >{{$t(\'applicationDetail.action.scriptActionDescription\')}}</label><b-form inline class="mt-0 pt-0" :disabled="action.useDefault || isScheduleOrg"   ><b-form-select data-test="script_input" :options="allScriptsAsOptions"  v-model="action.fk_Script" :state="errors[0] ? false : (valid && touched ? true : null)" @change="emitComponentChanged()" style="width: 80%;"></b-form-select><b-button :disabled="disableButton" data-test="gotoBtn" @click="openRoute(\'scriptDetail\', action.fk_Script)"> <font-awesome-icon icon="arrow-right" /></b-button></b-form></ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  methods: {
    emitComponentChanged() {
      this.$emit("componentChanged");
    },
    openRoute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      this.$router.push({
        name: targetLink,
        params: { id: targetID },
      });
    },
  },
  computed: {
    disableButton() {
      if (this.action.fk_Script) {
        return false;
      } else {
        return true;
      }
    },
  },
});
Vue.component(constants.ACTION_TYPES.APPLICATION.name, {
  template:
    '<ValidationProvider :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'" mode="lazy" v-slot="{ valid, errors, dirty }" ><label >{{$t(\'applicationDetail.action.applicationActionDescription\')}}</label><b-form inline :disabled="action.useDefault || isScheduleOrg" :label="$t(\'applicationDetail.action.applicationActionDescription\')"><b-form-select data-test="application_input" v-model="action.fk_Application"  :options="allApplicationsAsOptions" :state="errors[0] ? false : (valid && dirty ? true : null)" @change="emitComponentChanged()" style="width: 80%;"></b-form-select><b-button :disabled="disableButton" data-test="gotoBtn" @click="openRoute(\'applicationDetails\', action.fk_Application)"> <font-awesome-icon icon="arrow-right" /></b-button></b-form> </ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
    fieldWidth:{type: String, default: '12em'}
  },
  methods: {
    emitComponentChanged() {
      this.$emit("componentChanged");
    },
    openRoute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      this.$router.push({
        name: targetLink,
        params: { id: targetID },
      });
    },
  },
  computed: {
    disableButton() {
      if (this.action.fk_Application) {
        return false;
      } else {
        return true;
      }
    },
  },
});
Vue.component(constants.ACTION_TYPES.MENU.name, {
  template:
    '<ValidationProvider :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'" mode="lazy" v-slot="{ valid, errors, dirty }" ><label>{{$t(\'applicationDetail.action.menuActionDescription\')}}</label><b-form inline :disabled="action.useDefault || isScheduleOrg" :label="$t(\'applicationDetail.action.menuActionDescription\')"><b-form-select data-test="menu_input"  :options="allMenusAsOptions"  v-model="action.fk_Menu" :state="errors[0] ? false : (valid && dirty ? true : null)" @change="emitComponentChanged()" style="width: 80%;"></b-form-select><b-button :disabled="disableButton" data-test="gotoBtn" @click="openRoute(\'menuDetail\', action.fk_Menu)"> <font-awesome-icon icon="arrow-right" /></b-button></b-form></ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  methods: {
    emitComponentChanged() {
      this.$emit("componentChanged");
    },
    openRoute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      if (targetLink.includes("menuDetail")) {
        this.$router.push({
          name: targetLink,
          params: { idProp: targetID },
        });
      } else {
        this.$router.push({
          name: targetLink,
          params: { id: targetID },
        });
      }
    },
  },
  computed: {
    disableButton() {
      if (this.action.fk_Menu) {
        return false;
      } else {
        return true;
      }
    },
  },
});
Vue.component(constants.ACTION_TYPES.DISCONNECT.name, {
  template: "<b-col cols='4'></b-col>",
});

export default {
  name: "actionSelector",
  props: {
    isScheduleOrg: { type: Boolean, default: () => false },
    actionTypes: { type: Array, default: () => null },
    showDefaultCheck: { type: Boolean, default: () => true },
    application: { type: Object, require: true },
    action: { type: Object, require: false, default: () => null },
    modalId: { type: String, default: "0" },
    title: { type: String, require: false },
    allMenusAsOptions: { type: Array, default: () => [] },
    allScriptsAsOptions: { type: Array, default: () => [] },
    allApplicationsAsOptions: { type: Array, default: () => [] },
    isOpenCloseSelector: { type: Boolean, default: false },
    showPrompts: { type: Boolean, default: false },
    valueColWidth: { type: String, default: "col-6" },
    selectorColWidth: { type: String, default: "col-3" },
  },
  data: () => {
    return {
      componentVariables: {
        localAction: null,
        constants: constants,
        applicationActionTypeOptions: APPLICATION_ACTION_TYPE_OPTIONS,
        organizationActionTypeOptions:
          ORGANISATION_APPLICATION_ACTION_TYPE_OPTIONS,
        actionNullTypePlaceHolder: null,
        selected_action: null,
      },
    };
  },
  created() {
    this.componentVariables.localAction = Object.assign({}, this.action);
  },

  watch: {
  },
  methods: {
    getNonRenderComponents() {
      let nonRenderComponents = [];
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_CLOSED.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_OPEN.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_ORG.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_ORG_CLOSED.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_ORG_OPEN.name);
      return nonRenderComponents;
    },
    getActionTypes() {
      Vue.$log.debug("options", this.actionTypes);
      if (this.actionTypes == null) {
        if (this.application.organization === true) {
          return this.componentVariables.organizationActionTypeOptions();
        } else {
          return this.componentVariables.applicationActionTypeOptions();
        }
      } else {
        return this.actionTypes;
      }
    },
    emitActionChanged() {
      this.$emit(
        "actionCreated",
        Object.assign({}, this.componentVariables.localAction)
      );
    },
    actionTypeChanged(input) {
      Vue.$log.debug(input);
      // this.componentVariables.actionNullTypePlaceHolder = null;
      if (input) {
        //emit new action
        let actionId = null;
        if (this.action) {
          actionId = this.action.id;
        }
        if (!this.componentVariables.localAction) {
          this.componentVariables.localAction = { prompts: [] };
        }
        this.componentVariables.localAction = {
          id: actionId,
          fk_ActionType: input.name,
          fk_Script: null,
          fk_Menu: null,
          fk_Application: null,
          value: null,
          useDefault: false,
          prompts: this.componentVariables.localAction.prompts,
        };
        this.$emit(
          "actionCreated",
          Object.assign({}, this.componentVariables.localAction)
        );
      }
      if (!input) {
        //emit destroyed action
        this.componentVariables.localAction = null;
        this.$emit("actionRemoved");
      }
      //This is a better naming!
      this.$emit(
        "actionChanged",
        Object.assign({}, this.componentVariables.localAction)
      );
      Vue.$log.debug(input);
    },
    promptListUpdated(promptList) {
      Vue.$log.debug("inner function:", promptList);
      this.componentVariables.localAction.prompts = promptList;
      this.emitActionChanged();
    },
  },
  computed: {
    calculateDefaultCheckVisible: {
      get() {
        if (
          this.getNonRenderComponents().includes(
            this.componentVariables.localAction.fk_ActionType
          )
        ) {
          return false;
        } else {
          return this.showDefaultCheck;
        }
      },
    },
    fkAction: {
      get() {
        let selectedItem = null;
        for (let valueObject of Object.values(constants.ACTION_TYPES)) {
          if (
            valueObject.name ===
            this.componentVariables.localAction.fk_ActionType
          ) {
            selectedItem = valueObject;
            break;
          }
        }
        return selectedItem;
      },
      set(value) {
        this.componentVariables.localAction.fk_ActionType = value.name;
        this.componentVariables.selected_action = value;
      },
    },
    useDefault: {
      get: function () {
        return this.componentVariables.localAction.useDefault;
      },
      set: function (newValue) {
        this.componentVariables.localAction.useDefault = newValue;
        this.emitActionChanged();
      },
    },
    componentToPick() {
      let nonRenderComponents = this.getNonRenderComponents();
      if (
        this.componentVariables.localAction != null &&
        this.fkAction != null &&
        !nonRenderComponents.includes(
          this.componentVariables.localAction.fk_ActionType
        )
      ) {
        Vue.$log.debug(
          "returning " + this.componentVariables.localAction.fk_ActionType
        );
        return this.componentVariables.localAction.fk_ActionType;
      } else {
        return constants.ACTION_TYPES.DISCONNECT.name;
      }
    },
  },
};
</script>
