var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScheduleForm',{key:this.componentVariables.mutatedSchedule,staticClass:"align-items-baseline",attrs:{"id":this.id,"schedule":this.componentVariables.schedule,"schedule-type":_vm.scheduleType,"showAddButton":true,"show-ignore-year-button":true,"actual-schedules":this.actualSchedules},on:{"AddSchedule":function (schedule) {
      this$1.requestAddSchedule(schedule);
    },"CloseFields":function($event){_vm.requestCloseFields();
    _vm.initializeSchedule(false);},"InitializeSchedule":function($event){return _vm.initializeSchedule(_vm.toggleKeepInput)}}},[_c('b-col',{staticClass:"ml-2",attrs:{"slot":"additionalContent","cols":"0"},slot:"additionalContent"},[_c('action-selector',{key:this.componentVariables.mutatedSchedule,attrs:{"action":this.componentVariables.action,"allApplicationsAsOptions":_vm.allApplicationsAsOptions,"allMenusAsOptions":_vm.allMenusAsOptions,"allScriptsAsOptions":_vm.allScriptsAsOptions,"application":_vm.application,"modalId":_vm.$id('ActionScheduleModal'),"title":this.componentVariables.schedule.description +
        _vm.$t('applicationDetail.action.action')},on:{"actionCreated":function (action) {
          this$1.componentVariables.action = action;
        },"actionRemoved":function (__) {
          this$1.componentVariables.action = null;
        }}})],1),_c('span',[_c('b-form-group',{attrs:{"label":_vm.$t('applicationDetail.schedules.keepValues')}},[_c('b-form-checkbox',{attrs:{"type":"checkbox"},model:{value:(_vm.toggleKeepInput),callback:function ($$v) {_vm.toggleKeepInput=$$v},expression:"toggleKeepInput"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }