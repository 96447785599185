import axios from "axios";
import constants from "@/EndPointsAndConstants";

const state = {
  authenticated: false,
  name: "",
  permissions: {
    script: {
      permission: "ALL",
    },
    menu: {
      permission: "ALL",
    },
    parameter: {
      permission: "ALL",
    },
    calendar: {
      permission: "ALL",
    },
    parametergroup: {
      permission: "ALL",
    },
    application: {
      permission: "ALL",
      subpages: {},
    },
  },
};

const getters = {
  isAuthenticated: (state) => {
    return state.authenticated;
  },
  getName: (state) => {
    return state.name;
  },
  getPermissions: (state) => {
    return state.permissions;
  },
};

const actions = {
  initialize({ commit }) {
    commit("SET_NAME", null);
    commit("SET_AUTHENTICATED", false);
  },
  fetchPermissions({ commit }) {
    return axios.get(constants.endPoints.PERMISSIONS).then((response) => {
      if (response.data) {
        commit("SET_PERMISSIONS", response.data);
      }
    });
  },
};

const mutations = {
  SET_AUTHENTICATED(state, authenticated) {
    state.authenticated = authenticated;
  },
  SET_NAME(state, name) {
    state.name = name;
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
