var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"shadow",attrs:{"border-variant":"secondary","header":_vm.$t('applicationDetail.adhocPrompts.adhocCardTitle'),"header-bg-variant":"secondary","header-text-variant":"white"}},[(_vm.componentVariables.showContent === false)?_c('b-spinner'):[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"d-flex align-items-baseline"},[_c('prompts',{staticClass:"align-items-center justify-content-center",attrs:{"title":'adhoc',"promptList":_vm.componentVariables.adhocPromptShadow.prompts,"show-prompts":true,"horizontal":true},on:{"updateList":function (promptList) { return _vm.promptListUpdated(promptList); }}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-baseline"},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.$t("applicationDetail.adhocPrompts.adhocType")))]),_c('b-form',{staticClass:"mr-2"},[_c('b-form-select',{attrs:{"data-test":"adhocType","options":_vm.typeChoices,"required":""},model:{value:(_vm.componentVariables.adhocPromptShadow.type),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "type", $$v)},expression:"componentVariables.adhocPromptShadow.type"}})],1),(_vm.componentVariables.adhocPromptShadow.type != 'Disable')?[_c('ValidationObserver',{ref:"dateObserver"},[_c('div',{staticClass:"d-flex flex-row"},[_c('ValidationProvider',{attrs:{"name":"startDate","rules":"required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("applicationDetail.adhocPrompts.startDate")))]),_c('b-form',{staticClass:"mr-2",attrs:{"invalid-feedback":errors[0],"state":errors[0] ? false : valid ? true : null}},[_c('b-input-group',[_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","right":"","disabled":_vm.componentVariables.adhocPromptShadow.type ==
                              'ActiveRestOfDay' ||
                            _vm.componentVariables.adhocPromptShadow.type ==
                              'ActivForEver'},on:{"input":_vm.handleStartDate},model:{value:(
                            _vm.componentVariables.adhocPromptShadow.fromDate
                          ),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "fromDate", $$v)},expression:"\n                            componentVariables.adhocPromptShadow.fromDate\n                          "}})],1),_c('b-form-input',{attrs:{"data-test":"fromDate","input-id":_vm.$id('FromDate'),"type":"text","placeholder":"YYYY-MM-DD","autocomplete":"off","disabled":_vm.componentVariables.adhocPromptShadow.type ==
                            'ActiveRestOfDay' ||
                          _vm.componentVariables.adhocPromptShadow.type ==
                            'ActivForEver'},model:{value:(
                          _vm.componentVariables.adhocPromptShadow.fromDate
                        ),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "fromDate", $$v)},expression:"\n                          componentVariables.adhocPromptShadow.fromDate\n                        "}})],1)],1)]}}],null,false,1220887843)}),_c('ValidationProvider',{attrs:{"name":"startTime","rules":"required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var valid = ref.valid;
                        var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("applicationDetail.adhocPrompts.startTime")))]),_c('b-form',{staticClass:"mr-2",attrs:{"invalid-feedback":errors[0],"state":errors[0] ? false : valid ? true : null}},[_c('b-input-group',[_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"button-only":"","right":"","locale":'de',"disabled":_vm.componentVariables.adhocPromptShadow.type ==
                              'ActiveRestOfDay' ||
                            _vm.componentVariables.adhocPromptShadow.type ==
                              'ActivForEver'},on:{"input":_vm.handleStartTime},model:{value:(
                            _vm.componentVariables.adhocPromptShadow.fromTime
                          ),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "fromTime", $$v)},expression:"\n                            componentVariables.adhocPromptShadow.fromTime\n                          "}})],1),_c('b-form-input',{staticClass:"pr-0",attrs:{"data-test":"fromTime","input-id":_vm.$id('FromTime'),"type":"text","placeholder":"HH:mm","formatter":_vm.timeFormatter,"disabled":_vm.componentVariables.adhocPromptShadow.type ==
                            'ActiveRestOfDay' ||
                          _vm.componentVariables.adhocPromptShadow.type ==
                            'ActivForEver'},model:{value:(
                          _vm.componentVariables.adhocPromptShadow.fromTime
                        ),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "fromTime", $$v)},expression:"\n                          componentVariables.adhocPromptShadow.fromTime\n                        "}})],1)],1)]}}],null,false,1500524388)})],1),_c('div',{staticClass:"d-flex flex-row"},[_c('ValidationProvider',{attrs:{"name":"endDate","rules":"required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var valid = ref.valid;
                        var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("applicationDetail.adhocPrompts.endDate")))]),(
                      _vm.componentVariables.adhocPromptShadow.type !=
                      'ActivForEver'
                    )?_c('b-form',{staticClass:"mr-2",attrs:{"invalid-feedback":errors[0],"state":errors[0] ? false : valid ? true : null}},[_c('b-input-group',[_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","right":"","disabled":_vm.componentVariables.adhocPromptShadow.type ==
                              'ActiveRestOfDay' ||
                            _vm.componentVariables.adhocPromptShadow.type ==
                              'ActivForEver'},model:{value:(
                            _vm.componentVariables.adhocPromptShadow.endDate
                          ),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "endDate", $$v)},expression:"\n                            componentVariables.adhocPromptShadow.endDate\n                          "}})],1),_c('b-form-input',{attrs:{"data-test":"endDate","input-id":_vm.$id('EndDate'),"type":"text","placeholder":"YYYY-MM-DD","autocomplete":"off","disabled":_vm.componentVariables.adhocPromptShadow.type ==
                            'ActiveRestOfDay' ||
                          _vm.componentVariables.adhocPromptShadow.type ==
                            'ActivForEver'},model:{value:(_vm.componentVariables.adhocPromptShadow.endDate),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "endDate", $$v)},expression:"componentVariables.adhocPromptShadow.endDate"}})],1)],1):_vm._e()]}}],null,false,3547279889)}),_c('ValidationProvider',{attrs:{"name":"endTime","rules":"required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var valid = ref.valid;
                            var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("applicationDetail.adhocPrompts.endTime")))]),(
                      _vm.componentVariables.adhocPromptShadow.type !=
                      'ActivForEver'
                    )?_c('b-form',{staticClass:"mr-2",attrs:{"invalid-feedback":errors[0],"state":errors[0] ? false : valid ? true : null}},[_c('b-input-group',[_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"button-only":"","right":"","locale":'de',"disabled":_vm.componentVariables.adhocPromptShadow.type ==
                              'ActiveRestOfDay' ||
                            _vm.componentVariables.adhocPromptShadow.type ==
                              'ActivForEver'},on:{"input":_vm.handleEndTime},model:{value:(
                            _vm.componentVariables.adhocPromptShadow.endTime
                          ),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "endTime", $$v)},expression:"\n                            componentVariables.adhocPromptShadow.endTime\n                          "}})],1),_c('b-form-input',{staticClass:"pr-0",attrs:{"data-test":"endTime","input-id":_vm.$id('EndTime'),"type":"text","placeholder":"HH:mm","formatter":_vm.timeFormatter,"disabled":_vm.componentVariables.adhocPromptShadow.type ==
                            'ActiveRestOfDay' ||
                          _vm.componentVariables.adhocPromptShadow.type ==
                            'ActivForEver'},model:{value:(_vm.componentVariables.adhocPromptShadow.endTime),callback:function ($$v) {_vm.$set(_vm.componentVariables.adhocPromptShadow, "endTime", $$v)},expression:"componentVariables.adhocPromptShadow.endTime"}})],1)],1):_vm._e()]}}],null,false,2913879898)})],1)])]:_vm._e(),_c('b-button',{staticClass:"mt-2",attrs:{"data-test":"saveAdhoc"},on:{"click":function($event){return _vm.saveAdhoc(_vm.componentVariables.adhocPromptShadow)}}},[_vm._v(_vm._s(_vm.$t("applicationDetail.adhocPrompts.saveAdhocPrompts"))+" ")])],2)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }