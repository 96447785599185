var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"shadow mb-1",attrs:{"no-body":"","border-variant":"secondary"}},[_c('b-card-header',{attrs:{"header-text-variant":"white","header-bg-variant":"secondary"},on:{"click":function($event){_vm.collapse = !_vm.collapse}}},[_vm._v(" "+_vm._s(this.parameter.displayName)+" ")]),_c('b-collapse',{model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-card-body',{staticClass:"pb-lg-0",attrs:{"data-test":'parametergroup-' + this.parameter.id}},[_vm._l((_vm.parameter.parameters),function(parameterComponent,index){return _c('b-form',{key:_vm.parameter.parameterGroupRefId + '_parameters_' + index,attrs:{"data-test":'parameter-' + parameterComponent.id}},[(
            _vm.componentVariables.availableComponents.includes(
              _vm.componentVariables.constants.CUSTOM_PARAMETER_TYPE_PREFIX +
                parameterComponent.componentType
            )
          )?_c(_vm.componentVariables.constants.CUSTOM_PARAMETER_TYPE_PREFIX +
            parameterComponent.componentType,{tag:"component",class:{
            'custom-striped-color': index % 2 === 0,
            '': index % 2 !== 0,
          },attrs:{"parameter":parameterComponent,"parameterGroupRefId":_vm.parameter.parameterGroupRefId,"isDisabled":!_vm.permissions[0].scopes.includes('scope_write')}}):_vm._e()],1)}),_vm._l((_vm.parameter.childrenGroups),function(child,index){return _c('b-row',{key:_vm.parameter.parameterGroupRefId + '_children_' + index},[_c('b-col',[_c('parameter',{attrs:{"parameter":child,"permissions":_vm.permissions}})],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }