import Vue from "vue";
import Axios from "axios";
import EndPointsAndConstants from "../../EndPointsAndConstants";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
  language: {},
};

const getters = {
  getLanguage: (state) => {
    return state.language;
  },
};

const actions = {
  fetchLanguage({ commit }, userLang) {
    return Axios.get(
      EndPointsAndConstants.endPoints.LANGUAGE_BASE + "/" + userLang
    ).then((response) => {
      Vue.$log.debug("Current language: ", userLang);
      Vue.$log.debug(response.data);
      commit("SET_LANGUAGE", response.data);
      localStorage.setItem("currentLang", userLang);
    });
  },
};

const mutations = {
  SET_LANGUAGE(state, values) {
    state.language = values;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
