<template>
  <b-form inline>
    <b-form-group class="mr-2" description="Menu name">
      <b-form-input></b-form-input>
    </b-form-group>
    <b-form-group class="mr-2" description="Menu Display name">
      <b-form-input v></b-form-input>
    </b-form-group>
    <b-form-group description="Add Menu" class="ml-2">
      <b-button variant="outline-secondary" @click="requestAddMenu(menu)"
        ><font-awesome-icon icon="plus"
      /></b-button>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  name: "NewMenu",
  props: {
    menu: {
      type: Object,
      default: () => ({
        name: null,
        displayName: null,
        description: null,
        retryMax: null,
        timeout: null,
        timeoutMax: null,
        wrongInputMax: null,
      }),
    },
  },
  computed: {},
  methods: {
    requestAddMenu(menu) {
      menu.description = "description";
      menu.retryMax = null;
      menu.timeout = 30;
      menu.timeoutMax = null;
      menu.wrongInputMax = null;
      this.$emit("AddMenu", menu);
    },
  },
};
</script>
