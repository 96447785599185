import Vue from "vue";
import VueRouter from "vue-router";
import Application from "./views/Application.vue";
import ApplicationDetail from "./views/ApplicationDetail.vue";
import Calendar from "./views/Calendar.vue";
import Menu from "./views/Menu.vue";
import CalendarDetail from "./views/CalendarDetail.vue";
import ParameterManagement from "./views/ParameterManagement.vue";
import ParameterEdit from "./views/ParameterEdit";
import ParameterCreate from "./views/ParameterCreate";
import AdminCalendar from "./views/administration/AdminCalendar";
import AdminMenu from "./views/administration/AdminMenu";
import MenuDetail from "./views/MenuDetail";
import ParameterGroupEdit from "@/components/parameterGroup/ParameterGroupEdit";
import Script from "@/views/Script";
import ScriptDetail from "@/views/ScriptDetail";
//import App from "./App";
//import Home from "./views/Home.vue";
import store from "./store";
import { openUnsavedModal } from "./Helper";

Vue.use(VueRouter);

function getId(route) {
  return {
    id: Number.parseInt(route.params.id),
  };
}

let vueRouter = new VueRouter();

vueRouter.beforeEach((to, from, next) => {
  if (!store.getters["toaster/getKeepOnRouting"]) {
    store.dispatch("toaster/removeToast");
  }
  if (store.getters["changes/getUnsavedChanges"] === false) {
    next();
  } else {
    openUnsavedModal(vueRouter.app, to, from, next);
  }
  // store.commit("toaster/SET_KEEPONROUTING", false)
});

vueRouter.addRoute({
  path: "/application",
  name: "application",
  component: Application,
});
vueRouter.addRoute({
  path: "/application/:id",
  name: "applicationDetails",
  component: ApplicationDetail,
  props: ({ params }) => ({
    applicationId: Number.parseInt(params.id, 10) || 0,
  }),
});

vueRouter.addRoute({
  path: "/calendar",
  name: "calendar",
  component: Calendar,
});
vueRouter.addRoute({
  path: "/calendar/detail/:id",
  name: "calendarDetail",
  component: CalendarDetail,
  props: true,
});
vueRouter.addRoute({
  path: "/menu",
  name: "menu",
  component: Menu,
});
vueRouter.addRoute({
  path: "/menu/detail/:idProp",
  name: "menuDetail",
  component: MenuDetail,
  props: ({ params }) => ({
    idProp: Number.parseInt(params.idProp, 10) || 0,
  }),
});
vueRouter.addRoute({
  path: "/parameter/",
  name: "parameter",
  component: ParameterManagement,
  props: true,
});
vueRouter.addRoute({
  path: "/script/detail/:id",
  name: "scriptDetail",
  component: ScriptDetail,
  props: getId,
});
vueRouter.addRoute({
  path: "/script/",
  name: "script",
  component: Script,
});
vueRouter.addRoute({
  path: "/parameteredit/:id",
  name: "parameteredit",
  component: ParameterEdit,
  props: getId,
});
vueRouter.addRoute({
  path: "/parametercreate/",
  name: "parametercreate",
  component: ParameterCreate,
});
vueRouter.addRoute({
  path: "/parametergroupedit/:id",
  name: "parametergroupedit",
  component: ParameterGroupEdit,
  props: ({ params }) => ({
    id: Number.parseInt(params.id, 10) || 0,
  }),
});
vueRouter.addRoute({
  path: "/admincalendar/",
  name: "admincalendar",
  component: AdminCalendar,
});
vueRouter.addRoute({
  path: "/adminmenu/",
  name: "adminmenu",
  component: AdminMenu,
});

export default vueRouter;
