import axios from "axios";
import constants from "../../EndPointsAndConstants";
import { makeToast } from "../mixin/basic";
import Vue from "vue";

const state = {
  parameterGroups: [],
  currentParameterGroup: {},
  availableParameterGroupOptions: [],
};

const getters = {
  getParameterGroups: (state) => {
    return state.parameterGroups;
  },
  getParameterGroup: (state) => {
    return state.currentParameterGroup;
  },
};

const actions = {
  addParameterGroup({ commit }, { vm, parameterGroup }) {
    return axios
      .post(constants.endPoints.PARAMETER_GROUPS_BASE, parameterGroup)
      .then((__) => {
        makeToast(
          vm,
          vm.$t("toasts.parameterGroup.parameterGroup") +
            parameterGroup.displayName +
            vm.$t("toasts.parameterGroup.added"),
          "success"
        );
      })
      .catch((response) => {
        Vue.$log.error(response);
      });
  },
  saveParameterGroup({ commit }, { vm, parameterGroup }) {
    return axios
      .put(constants.endPoints.PARAMETER_GROUPS_BASE, parameterGroup)
      .then((response) => {
        makeToast(
          vm,
          vm.$t("toasts.parameterGroup.parameterGroup") +
            parameterGroup.displayName +
            vm.$t("toasts.parameterGroup.saved"),
          "success"
        );
      })
      .catch((response) => {
        Vue.$log.error(response);
      });
  },
  fetchParameterGroups({ commit }) {
    return axios
      .get(constants.endPoints.PARAMETER_GROUPS_BASE)
      .then((response) => {
        if (response.data) {
          commit("SET_PARAMETER_GROUPS", response.data);
        }
      });
  },
  fetchParameterGroup({ commit }, id) {
    return axios
      .get(
        constants.endPoints.PARAMETER_GROUPS_BASE +
          constants.endPoints.PARAMETER_GROUP_DETAIL +
          id
      )
      .then((response) => {
        if (response.data) {
          commit("SET_PARAMETER_GROUP", response.data);
        }
      });
  },
  deleteParameterGroup({ state }, { id, vm }) {
    Vue.$log.debug("ABOUT TO BE DELETED:   ", id);
    return axios
      .delete(constants.endPoints.PARAMETER_GROUPS_BASE + id)
      .then((response) => {
        makeToast(
          vm,
          vm.$t("toasts.parameterGroup.parameterGroup") +
            state.currentParameterGroup.displayName +
            vm.$t("toasts.parameterGroup.deleted"),
          "success",
          true
        );
      })
      .catch((response) => {
        Vue.$log.error(response.data);
      });
  },
};

const mutations = {
  SET_PARAMETER_GROUPS(state, parameterGroups) {
    state.parameterGroups = parameterGroups;
  },
  SET_PARAMETER_GROUP(state, parameterGroup) {
    state.currentParameterGroup = parameterGroup;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
