var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"menuObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-card',{attrs:{"header":_vm.$t('menuDetails.pageTitle') + ' : ' + _vm.componentVariables.title}},[(_vm.componentVariables.showContent === false)?_c('b-spinner',{staticClass:"align-self-auto"}):_vm._e(),(_vm.componentVariables.showContent === true)?_c('b-card-body',{staticClass:"pt-0"},[_c('b-row',[_c('b-col',[_c('b-card',[_c('validation-provider',{attrs:{"rules":'required|max:' + _vm.componentVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.name')}},[_c('b-form-input',{attrs:{"type":"text","data-test":"input_menu_name","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('b-form-invalid-feedback',{attrs:{"tag":"span"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":'required|max:' + _vm.componentVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.displayName')}},[_c('b-form-input',{attrs:{"type":"text","data-test":"input_menu_displayname","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),_c('b-form-invalid-feedback',{attrs:{"tag":"span"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":'max:' + _vm.componentVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.description')}},[_c('b-form-input',{attrs:{"type":"text","data-test":"input_menu_description","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('b-form-invalid-feedback',{attrs:{"tag":"span"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"optionsList","rules":"required|min_value:1|max_value:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.timeout')}},[_c('b-form-input',{attrs:{"type":"number","state":errors[0] ? false : valid && touched ? true : null,"data-test":"input_menu_timeout"},model:{value:(_vm.timeout),callback:function ($$v) {_vm.timeout=_vm._n($$v)},expression:"timeout"}})],1)]}}],null,true)})],1)],1),_c('b-col',[_c('b-card',{staticClass:"h-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.initPrompts')}},[_c('b-form-row',{attrs:{"data-test":"init_prompts"}},[_c('b-col',[_c('Prompts',{attrs:{"title":'init',"id":'initPrompts',"prompt-list":_vm.getMainPrompts('Init'),"horizontal":true,"show-button-title":false},on:{"updateList":function (prompts) {
                        _vm.saveMainPrompts({
                          eventName: 'Init',
                          prompts: prompts,
                        });
                        _vm.keyReRender.initPromptsKey++;
                      }}})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.introPrompts')}},[_c('b-form-row',{attrs:{"data-test":"intro_prompts"}},[_c('b-col',[_c('Prompts',{attrs:{"title":'intro',"id":'introPrompt',"prompt-list":_vm.getMainPrompts('Intro'),"horizontal":true,"show-button-title":false},on:{"updateList":function (prompts) {
                        _vm.saveMainPrompts({
                          eventName: 'Intro',
                          prompts: prompts,
                        });
                        _vm.keyReRender.introPromptKey++;
                      }}})],1)],1)],1)],1)],1),_c('b-col',[_c('b-card',{staticClass:"h-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.timeoutPrompts')}},[_c('b-form-row',{attrs:{"data-test":"timeout_prompts"}},[_c('b-col',[_c('Prompts',{attrs:{"title":'timeout',"id":'timeoutPrompts',"prompt-list":_vm.getMainPrompts('Timeout'),"horizontal":true,"show-button-title":false},on:{"updateList":function (prompts) {
                        _vm.saveMainPrompts({
                          eventName: 'Timeout',
                          prompts: prompts,
                        });
                        _vm.keyReRender.timeoutPromptsKey++;
                      }}})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.wrongInputPrompts')}},[_c('b-form-row',{attrs:{"data-test":"wrong_input_prompts"}},[_c('b-col',[_c('Prompts',{attrs:{"title":'wrongInput',"id":'wrongInputPrompts',"prompt-list":_vm.getMainPrompts('WrongInput'),"horizontal":true,"show-button-title":false},on:{"updateList":function (prompts) {
                        _vm.saveMainPrompts({
                          eventName: 'WrongInput',
                          prompts: prompts,
                        });
                        _vm.keyReRender.wrongInputPromptsKey++;
                      }}})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('menuDetails.retryPrompts')}},[_c('b-form-row',{attrs:{"data-test":"retry_prompts"}},[_c('b-col',[_c('Prompts',{attrs:{"title":'retry',"id":'retryPrompt',"prompt-list":_vm.getMainPrompts('Retry'),"horizontal":true,"show-button-title":false},on:{"updateList":function (prompts) {
                        _vm.saveMainPrompts({
                          eventName: 'Retry',
                          prompts: prompts,
                        });
                        _vm.keyReRender.retryPromptKey++;
                      }}})],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('b-card',[_c('b-card-body',{attrs:{"data-test":"max_values"}},[_c('b-card-sub-title',[_vm._v(_vm._s(_vm.$t("menuDetails.maxRetryKeys")))]),_c('validation-provider',{attrs:{"name":"generalOptions","rules":"menuDefaultMaxOptionSelected|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var valid = ref.valid;
                      var errors = ref.errors;
                      var touched = ref.touched;
return [_c('b-form-checkbox-group',{attrs:{"data-test":"options_list","options":_vm.generalOptions,"name":"generalOptions","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.generalOptionsValue),callback:function ($$v) {_vm.generalOptionsValue=$$v},expression:"generalOptionsValue"}})]}}],null,true)}),_vm._l((_vm.generalOptionsValue),function(item,index){return _c('menu-event-option',{key:item.selectedValue,class:{
                  'custom-striped-color': index % 2 === 0,
                  '': index % 2 !== 0,
                },attrs:{"id":item.selectedValue,"data-test":"max_event_option","allMenusAsOptions":_vm.allMenusAsOptions,"allScriptsAsOptions":_vm.allScriptsAsOptions,"allApplicationsAsOptions":_vm.allApplicationsAsOptions,"actionTypes":_vm.getSelectableActionOptions(),"title":_vm.$t(item.translationKey),"showDefaultCheck":false,"menuEvent":_vm.findMenuEventByName(item.selectedValue),"inputField":_vm.findMaxRetry(item.selectedValue),"inputFieldText":item.translationKey,"show-value-field":true},on:{"changeAction":function (menuEventMaxTypesReturnObject) {
                    _vm.saveMenuEventByName({
                      eventName: item.selectedValue,
                      action: menuEventMaxTypesReturnObject,
                    });
                  },"change":function (input) { return _vm.saveEventMaxRetry(item.selectedValue, input); }}})})],2)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-card',[_c('b-card-body',{attrs:{"data-test":"custOptionKeys"}},[_c('b-card-sub-title',[_vm._v(_vm._s(_vm.$t("menuDetails.optionKeys")))]),_c('b-form-checkbox-group',{attrs:{"data-test":"option_keys","options":_vm.custOptions},model:{value:(_vm.custOptionsValue),callback:function ($$v) {_vm.custOptionsValue=$$v},expression:"custOptionsValue"}}),_vm._l((_vm.custOptionsValueSorted),function(item,index){return _c('menu-event-option',{key:item.selectedValue,class:{
                  'custom-striped-color': index % 2 === 0,
                  '': index % 2 !== 0,
                },attrs:{"id":item.selectedValue,"data-test":"key_event_option","allMenusAsOptions":_vm.allMenusAsOptions,"allScriptsAsOptions":_vm.allScriptsAsOptions,"allApplicationsAsOptions":_vm.allApplicationsAsOptions,"actionTypes":_vm.getSelectableActionOptions(),"title":_vm.$t(item.translationKey),"showDefaultCheck":false,"menuEvent":_vm.findMenuEventByName(item.selectedValue)},on:{"changeAction":function (menuEventMaxTypesReturnObject) {
                    _vm.saveMenuEventByName({
                      eventName: item.selectedValue,
                      action: menuEventMaxTypesReturnObject,
                    });
                  }}})})],2)],1)],1)],1),_c('b-row',{staticClass:"mr-0",attrs:{"align-h":"end"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1",attrs:{"data-test":"btn_update_menu","id":"updateButton","variant":"primary"},on:{"click":function($event){return _vm.callMenuUpdate()}}},[_vm._v(_vm._s(_vm.$t("menuDetails.updateButton"))+" ")]),(!_vm.errorsAreEmpty)?_c('b-tooltip',{ref:"updateTooltip",attrs:{"target":"updateButton"}},_vm._l((_vm.clean(errors)),function(error){return _c('b-badge',{key:error[0],staticClass:"bg-danger"},[_vm._v(_vm._s(error[0]))])}),1):_vm._e(),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.gotoAllMenuPage()}}},[_vm._v(_vm._s(_vm.$t("menuDetails.cancelButton")))]),_c('b-button',{staticClass:"mr-1",attrs:{"data-test":"btn-delete-menu","type":"delete","variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.openMenuDeleteModal()}}},[_vm._v(_vm._s(_vm.$t("menuDetails.deleteButton")))])],1),_c('reference-modal',{ref:"deleteMenuModal",attrs:{"i-d-reference":_vm.idProp,"modal-title":_vm.$t('menuDetails.deleteModalTitle'),"modal-function":_vm.$t('menuDetails.deleteModalFunction') + _vm.displayName,"object-title":_vm.$t('menuDetails.deleteObjectTitle'),"okButtonText":_vm.$t('okButtonText'),"cancel-button-text":_vm.$t('cancelButtonText')},on:{"targetMethod":_vm.requestDeleteMenu}})],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }