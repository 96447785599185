import axios from "axios";
import constants from "../../EndPointsAndConstants";
import EndPointsAndConstants from "../../EndPointsAndConstants";
import { makeToast } from "../mixin/basic";
const state = {
  applications: [],
};

const getters = {
  getApplications: (state) => {
    return state.applications;
  },
};

const actions = {
  fetchApplications({ commit }) {
    return axios
      .get(constants.endPoints.APPLICATIONS_BASE)
      .then((response) => {
        if (response.data) {
          commit("SET_APPLICATIONS", response.data);
        }
      })
      .then((__) => {});
  },
  deleteApplication({ commit }, { vm, applicationId }) {
    return axios
      .delete(EndPointsAndConstants.endPoints.APPLICATIONS_BASE + applicationId)
      .then((__) => {
        makeToast(
          vm,
          vm.$t("toasts.application.applicationDeleteSuccessful"),
          "success",
          true
        );
      })
      .catch((__) => {
        makeToast(
          vm,
          vm.$t("toasts.application.applicationDeleteFailed"),
          "danger",
            true
        );
      });
  },
};

const mutations = {
  SET_APPLICATIONS(state, applications) {
    state.applications = applications;
  },
  DELETE_APPLICATION(state, applicationId) {
    state.applications.splice(1, applicationId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
