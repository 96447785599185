import axios from "axios";
import constants from "../../EndPointsAndConstants";
import { makeToast } from "../mixin/basic";
import Vue from "vue";

function makeShallowOption(arrayToIterate, arrayToFill) {
  arrayToIterate.forEach(function (item) {
    let thinObject = {};
    thinObject.id = item.id;
    thinObject.name = item.name;
    thinObject.displayName = item.displayName;
    arrayToFill.push(thinObject);
  });
}

const state = {
  parameters: [],
  currentParameter: {},
  availableParameterOptions: [],
  availableParameterOptionsApplicationAll: [],
};

const getters = {
  getParameters: (state) => {
    return state.parameters;
  },
  getAvailableParameterOptions: (state) => {
    Vue.$log.debug(
      "getAvailableParameterOptions",
      state.availableParameterOptions
    );
    return state.availableParameterOptions;
  },
  getAvailableParameterOptionsApplicationAll: (state) => {
    return state.availableParameterOptionsApplicationAll;
  },
  getParameter: (state) => {
    Vue.$log.debug(state.currentParameter);
    return state.currentParameter;
  },
  getParameterConfiguration: (state) => {
    if (!state.currentParameter.componentType) {
      return null;
    }
    for (let configuration of Object.values(
      constants.CUSTOM_PARAMETER_TYPES_CREATE
    )) {
      if (configuration.name === state.currentParameter.componentType) {
        Vue.$log.debug("got configuration");
        return configuration;
      }
    }
    return null;
  },
};

const actions = {
  fetchParameters({ commit }) {
    return axios.get(constants.endPoints.PARAMETERS_BASE).then((response) => {
      if (response.data) {
        commit("SET_PARAMETERS", response.data);
      }
    });
  },
  fetchParameterOptions({ commit, getters }) {
    Vue.$log.debug(
      "getParameterConfiguration",
      getters.getParameterConfiguration
    );
    return axios
      .get(
        constants.endPoints.PARAMETERS_BASE +
          constants.endPoints.PARAMETER_OPTIONS +
          getters.getParameterConfiguration.dataType
      )
      .then((response) => {
        if (response.data) {
          Vue.$log.debug("fetch parameter options: ", response.data);
          commit("SET_AVAILABLE_PARAMETER_OPTIONS", response.data);
        } else {
          Vue.$log.debug("did not fetch parameter options: ", response.data);
          commit("SET_AVAILABLE_PARAMETER_OPTIONS", []);
        }
      });
  },
  fetchParameterOptionsForCustom({ commit, state }, dataType) {
    Vue.$log.debug("dataType", dataType);
    return axios
      .get(
        constants.endPoints.PARAMETERS_BASE +
          constants.endPoints.PARAMETER_OPTIONS +
          dataType
      )
      .then((response) => {
        if (response.data) {
          Vue.$log.debug("fetch parameter options: ", response.data);
          if (dataType === "APPLICATION") {
            commit(
              "SET_AVAILABLE_PARAMETER_OPTIONS_APPLICATION_ALL",
              response.data
            );
          }
          return response.data;
        } else {
          Vue.$log.debug("did not fetch parameter options: ", response.data);
          return [];
          commit("SET_AVAILABLE_PARAMETER_OPTIONS", []);
        }
      });
  },
  fetchParameter({ commit }, id) {
    return axios
      .get(constants.endPoints.PARAMETERS_BASE + id)
      .then((response) => {
        if (response.data) {
          commit("SET_PARAMETER", response.data);
        }
      });
  },
  makeNewParameter({ commit }) {
    let parameter = {};
    parameter.name = null;
    parameter.displayName = null;
    parameter.componentType = null;
    parameter.parameterOptionAvailable = [];
    parameter.parameterOptionDefault = [];
    commit("SET_PARAMETER", parameter);
  },
  clearParameterOptions({ commit }) {
    commit("UPDATE_PARAMETER_OPTION_AVAILABLE", []);
    commit("UPDATE_PARAMETER_OPTION_DEFAULT", []);
  },
  addAvailableOption({ commit, getters }, option) {
    commit("ADD_PARAMETER_OPTION_AVAILABLE", option);
  },
  addDefaultOption({ commit, getters }, option) {
    commit("ADD_PARAMETER_OPTION_DEFAULT", option);
  },
  removeAvailableOption({ commit, getters }, option) {
    let updatedArray = getters.getParameter.parameterOptionAvailable.filter(
      function (item) {
        return item !== option;
      }
    );
    commit("UPDATE_PARAMETER_OPTION_AVAILABLE", updatedArray);
  },
  removeDefaultOption({ commit, getters }, option) {
    let updatedArray = getters.getParameter.parameterOptionDefault.filter(
      function (item) {
        return item !== option;
      }
    );
    commit("UPDATE_PARAMETER_OPTION_DEFAULT", updatedArray);
  },
  saveParameter({ state }, vm) {
    let pushableParameter = {};
    pushableParameter.name = state.currentParameter.name;
    pushableParameter.displayName = state.currentParameter.displayName;
    pushableParameter.componentType = state.currentParameter.componentType;
    pushableParameter.dataType = state.currentParameter.dataType;
    pushableParameter.defaultValue = state.currentParameter.defaultValue;
    pushableParameter.parameterOptionAvailable = [];
    makeShallowOption(
      state.currentParameter.parameterOptionAvailable,
      pushableParameter.parameterOptionAvailable
    );

    pushableParameter.parameterOptionDefault = [];

    makeShallowOption(
      state.currentParameter.parameterOptionDefault,
      pushableParameter.parameterOptionDefault
    );

    // TODO POST OR PUT
    let promise = null;
    if (state.currentParameter.id) {
      pushableParameter.id = state.currentParameter.id;
      promise = axios.put(
        constants.endPoints.PARAMETERS_BASE,
        pushableParameter
      );
    } else {
      promise = axios.post(
        constants.endPoints.PARAMETERS_BASE,
        pushableParameter
      );
    }
    promise
      .then((__) => {
        makeToast(
          vm,
          vm.$t("toasts.parameter.parameterSavedSuccessful"),
          "success",
          true
        );
      })
      .catch((__) => {
        makeToast(vm, vm.$t("toasts.parameter.parameterSavedFailed"), "danger");
      });
    return promise;
  },
  deleteParameter({ state }, vm) {
    Vue.$log.debug("ABOUT TO BE DELETED:   ", state.currentParameter.id);
    let promise = axios.delete(
      constants.endPoints.PARAMETERS_BASE + state.currentParameter.id
    );

    promise.then((response) => {
      Vue.$log.debug("received: ", response.data);
      makeToast(
        vm,
        vm.$t("toasts.parameter.parameter") +
          state.currentParameter.displayName +
          vm.$t("toasts.parameter.deleted"),
        "success",
        true
      );
    });

    return promise;
  },
};

const mutations = {
  SET_PARAMETERS(state, parameters) {
    state.parameters = parameters;
  },
  SET_PARAMETER(state, parameter) {
    state.currentParameter = parameter;
  },
  UPDATE_PARAMETER_NAME(state, name) {
    state.currentParameter.name = name;
  },
  UPDATE_PARAMETER_DEFAULT_VALUE(state, defaultValue) {
    if (defaultValue == "") {
      defaultValue = null;
    }
    state.currentParameter.defaultValue = defaultValue;
  },
  UPDATE_PARAMETER_DISPLAY_NAME(state, displayName) {
    state.currentParameter.displayName = displayName;
  },
  UPDATE_PARAMETER_COMPONENT_TYPE(state, type) {
    state.currentParameter.componentType = type;
  },
  ADD_PARAMETER_OPTION_AVAILABLE(state, option) {
    state.currentParameter.parameterOptionAvailable.push(option);
  },
  ADD_PARAMETER_OPTION_DEFAULT(state, option) {
    state.currentParameter.parameterOptionDefault.push(option);
  },
  UPDATE_PARAMETER_OPTION_AVAILABLE(state, options) {
    state.currentParameter.parameterOptionAvailable = options;
  },
  UPDATE_PARAMETER_OPTION_DEFAULT(state, options) {
    state.currentParameter.parameterOptionDefault = options;
  },
  SET_AVAILABLE_PARAMETER_OPTIONS(state, options) {
    state.availableParameterOptions = options;
  },
  SET_AVAILABLE_PARAMETER_OPTIONS_APPLICATION_ALL(state, options) {
    state.availableParameterOptionsApplicationAll = options;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
