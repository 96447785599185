<template>
  <div @change="changesMade">
    <Navigation class="sticky-top"></Navigation>
    <router-view :key="$route.fullPath" class="mt-0 pt-0"></router-view>
    <b-modal
      ref="leaveModal"
      id="leaveModal"
      size="sm"
      centered
      :title="$t('unsavedChanges.title')"
      ok-variant="primary"
      data-test="unsaved_changes"
    >
      <p class="my-4 text-center">{{ $t("unsavedChanges.body") }}</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          data-test="btn_modal_confirm"
          variant="success"
          @click="ok()"
          >{{ $t("unsavedChanges.ok_BTN") }}</b-button
        >
        <b-button data-test="btn_modal_cancel" @click="cancel()">{{
          $t("unsavedChanges.cancel_BTN")
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Navigation from "./base/Navigation.vue";
import { mapActions } from "vuex";
import Vue from "vue";
import { constants } from "@/EndPointsAndConstants";

export default {
  components: { Navigation },
  methods: {
    ...mapActions("changes", ["setUnsavedChanges"]),
    changesMade(event) {
      if (!event.target.hasAttribute("data-ignore-change")) {
        this.setUnsavedChanges(true);
      }
      this.$log.debug("Change event: ", event.type);
    },
  },
  created() {
    document.title = this.$t("pageTitles.main");
  },
};
</script>
