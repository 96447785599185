<template>
  <b-card
    border-variant="secondary"
    class="shadow"
    :header="$t('applicationDetail.adhocPrompts.adhocCardTitle')"
    header-bg-variant="secondary"
    header-text-variant="white"
  >
    <b-spinner v-if="componentVariables.showContent === false" />
    <template v-else>
      <b-row class="mb-3">
        <b-col class="d-flex align-items-baseline">
          <prompts
            class="align-items-center justify-content-center"
            :title="'adhoc'"
            :promptList="componentVariables.adhocPromptShadow.prompts"
            @updateList="(promptList) => promptListUpdated(promptList)"
            :show-prompts="true"
            :horizontal="true"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex align-items-baseline">
          <b-form-group>
            <label>{{ $t("applicationDetail.adhocPrompts.adhocType") }}</label>
            <b-form class="mr-2">
              <b-form-select
                data-test="adhocType"
                :options="typeChoices"
                required
                v-model="componentVariables.adhocPromptShadow.type"
              />
            </b-form>

            <template
              v-if="componentVariables.adhocPromptShadow.type != 'Disable'"
            >
              <ValidationObserver ref="dateObserver">
                <div class="d-flex flex-row">
                  <ValidationProvider
                    name="startDate"
                    rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"
                    v-slot="{ valid, errors }"
                  >
                    <label>{{
                      $t("applicationDetail.adhocPrompts.startDate")
                    }}</label>
                    <b-form
                      class="mr-2"
                      :invalid-feedback="errors[0]"
                      :state="errors[0] ? false : valid ? true : null"
                    >
                      <b-input-group>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="
                              componentVariables.adhocPromptShadow.fromDate
                            "
                            button-only
                            right
                            @input="handleStartDate"
                            :disabled="
                              componentVariables.adhocPromptShadow.type ==
                                'ActiveRestOfDay' ||
                              componentVariables.adhocPromptShadow.type ==
                                'ActivForEver'
                            "
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <b-form-input
                          data-test="fromDate"
                          :input-id="$id('FromDate')"
                          v-model="
                            componentVariables.adhocPromptShadow.fromDate
                          "
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                          :disabled="
                            componentVariables.adhocPromptShadow.type ==
                              'ActiveRestOfDay' ||
                            componentVariables.adhocPromptShadow.type ==
                              'ActivForEver'
                          "
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                  </ValidationProvider>
                  <ValidationProvider
                    name="startTime"
                    rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"
                    v-slot="{ valid, errors }"
                  >
                    <label>{{
                      $t("applicationDetail.adhocPrompts.startTime")
                    }}</label>
                    <b-form
                      class="mr-2"
                      :invalid-feedback="errors[0]"
                      :state="errors[0] ? false : valid ? true : null"
                    >
                      <b-input-group>
                        <b-input-group-append>
                          <b-form-timepicker
                            v-model="
                              componentVariables.adhocPromptShadow.fromTime
                            "
                            button-only
                            right
                            :locale="'de'"
                            @input="handleStartTime"
                            :disabled="
                              componentVariables.adhocPromptShadow.type ==
                                'ActiveRestOfDay' ||
                              componentVariables.adhocPromptShadow.type ==
                                'ActivForEver'
                            "
                          ></b-form-timepicker>
                        </b-input-group-append>
                        <b-form-input
                          data-test="fromTime"
                          :input-id="$id('FromTime')"
                          v-model="
                            componentVariables.adhocPromptShadow.fromTime
                          "
                          type="text"
                          placeholder="HH:mm"
                          class="pr-0"
                          :formatter="timeFormatter"
                          :disabled="
                            componentVariables.adhocPromptShadow.type ==
                              'ActiveRestOfDay' ||
                            componentVariables.adhocPromptShadow.type ==
                              'ActivForEver'
                          "
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                  </ValidationProvider>
                </div>
                <div class="d-flex flex-row">
                  <ValidationProvider
                    name="endDate"
                    rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"
                    v-slot="{ valid, errors }"
                  >
                    <label>{{
                      $t("applicationDetail.adhocPrompts.endDate")
                    }}</label>
                    <b-form
                      class="mr-2"
                      v-if="
                        componentVariables.adhocPromptShadow.type !=
                        'ActivForEver'
                      "
                      :invalid-feedback="errors[0]"
                      :state="errors[0] ? false : valid ? true : null"
                    >
                      <b-input-group>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="
                              componentVariables.adhocPromptShadow.endDate
                            "
                            button-only
                            right
                            :disabled="
                              componentVariables.adhocPromptShadow.type ==
                                'ActiveRestOfDay' ||
                              componentVariables.adhocPromptShadow.type ==
                                'ActivForEver'
                            "
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <b-form-input
                          data-test="endDate"
                          :input-id="$id('EndDate')"
                          v-model="componentVariables.adhocPromptShadow.endDate"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                          :disabled="
                            componentVariables.adhocPromptShadow.type ==
                              'ActiveRestOfDay' ||
                            componentVariables.adhocPromptShadow.type ==
                              'ActivForEver'
                          "
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                  </ValidationProvider>
                  <ValidationProvider
                    name="endTime"
                    rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"
                    v-slot="{ valid, errors }"
                  >
                    <label>{{
                      $t("applicationDetail.adhocPrompts.endTime")
                    }}</label>
                    <b-form
                      class="mr-2"
                      v-if="
                        componentVariables.adhocPromptShadow.type !=
                        'ActivForEver'
                      "
                      :invalid-feedback="errors[0]"
                      :state="errors[0] ? false : valid ? true : null"
                    >
                      <b-input-group>
                        <b-input-group-append>
                          <b-form-timepicker
                            v-model="
                              componentVariables.adhocPromptShadow.endTime
                            "
                            button-only
                            right
                            :locale="'de'"
                            @input="handleEndTime"
                            :disabled="
                              componentVariables.adhocPromptShadow.type ==
                                'ActiveRestOfDay' ||
                              componentVariables.adhocPromptShadow.type ==
                                'ActivForEver'
                            "
                          ></b-form-timepicker>
                        </b-input-group-append>
                        <b-form-input
                          data-test="endTime"
                          :input-id="$id('EndTime')"
                          v-model="componentVariables.adhocPromptShadow.endTime"
                          type="text"
                          placeholder="HH:mm"
                          class="pr-0"
                          :formatter="timeFormatter"
                          :disabled="
                            componentVariables.adhocPromptShadow.type ==
                              'ActiveRestOfDay' ||
                            componentVariables.adhocPromptShadow.type ==
                              'ActivForEver'
                          "
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                  </ValidationProvider>
                </div>
              </ValidationObserver>
            </template>
            <b-button
              class="mt-2"
              data-test="saveAdhoc"
              @click="saveAdhoc(componentVariables.adhocPromptShadow)"
              >{{ $t("applicationDetail.adhocPrompts.saveAdhocPrompts") }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";
import prompts from "../common/Prompts";
import constants from "../../EndPointsAndConstants";
import Vue from "vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Adhoc",
  components: { prompts },
  data() {
    return {
      componentVariables: {
        adhocPromptShadow: { prompts: [] },
        showContent: false,
      },
      firstTimeSet: null,
      firstDateSet: null,
    };
  },
  props: {
    applicationId: { type: Number },
    promptListUpdated: {
      type: Function,
      default: function innerfunction(promptList) {
        this.componentVariables.adhocPromptShadow.prompts = promptList;
      },
    },
  },
  created() {
    this.fetchAdhocForApplication(this.applicationId)
      .then((__) => {
        this.componentVariables.adhocPromptShadow = Object.assign(
          {},
          this.getSelectedAdhoc()
        );
      })
      .finally((__) => {
        this.componentVariables.adhocPromptShadow = Object.assign(
          {},
          this.getSelectedAdhoc()
        );
        Vue.$log.debug(
          "Set AdhocPrompt : ",
          this.componentVariables.adhocPromptShadow
        );
        this.componentVariables.showContent = true;

        if (this.componentVariables.adhocPromptShadow.fromTime !== null) {
          this.componentVariables.adhocPromptShadow.fromTime =
            DateTime.fromFormat(
              this.componentVariables.adhocPromptShadow.fromTime,
              "HH:mm:ss"
            ).toFormat(constants.TIME_FORMAT);
        }
        if (this.componentVariables.adhocPromptShadow.endTime !== null) {
          this.componentVariables.adhocPromptShadow.endTime =
            DateTime.fromFormat(
              this.componentVariables.adhocPromptShadow.endTime,
              "HH:mm:ss"
            ).toFormat(constants.TIME_FORMAT);
        }
      });
  },
  mounted() {
    this.firstTimeSet = true;
    this.firstDateSet = true;
  },
  methods: {
    ...mapActions("application", [
      "updateAdhocPrompt",
      "fetchAdhocForApplication",
    ]),
    ...mapGetters("application", [
      "getSelectedApplication",
      "getSelectedAdhoc",
    ]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    getHourMinute(dateTimeString) {
      dateTimeString = dateTimeString.replace("T", " ");
      let time = DateTime.fromFormat(dateTimeString, constants.DATETIME_FORMAT);
      let timeString = time.toFormat(constants.TIME_FORMAT);
      return timeString;
    },
    getDate(dateTimeString) {
      dateTimeString = dateTimeString.replace("T", " ");
      let date = DateTime.fromFormat(dateTimeString, constants.DATETIME_FORMAT);
      let dateString = date.toFormat(constants.DATE_FORMAT);
      return dateString;
    },
    saveAdhoc(object) {
      this.$log.debug("Saved object: ", object);
      if (object.type == "ActiveSpecial") {
        this.$refs.dateObserver.handleSubmit((__) => {
          this.requestAdhocSave(object);
        });
      } else {
        this.requestAdhocSave(object);
      }
    },
    requestAdhocSave(adhocObject) {
      this.updateAdhocPrompt({
        vm: this,
        id: this.applicationId,
        adhocPrompt: adhocObject,
      }).then((response) => {
        this.componentVariables.adhocPromptShadow = Object.assign(
          {},
          this.getSelectedAdhoc()
        );
        this.componentVariables.adhocPromptShadow.fromTime =
          DateTime.fromFormat(
            this.componentVariables.adhocPromptShadow.fromTime,
            "HH:mm:ss"
          ).toFormat(constants.TIME_FORMAT);
        this.componentVariables.adhocPromptShadow.endTime = DateTime.fromFormat(
          this.componentVariables.adhocPromptShadow.endTime,
          "HH:mm:ss"
        ).toFormat(constants.TIME_FORMAT);
        this.setUnsavedChanges(false);
      });
    },
    handleStartDate(eventData) {
      Vue.$log.debug("eventData for handlestartdate: ", eventData);

      this.componentVariables.adhocPromptShadow.fromDate = eventData;
      if (this.firstDateSet) {
        this.componentVariables.adhocPromptShadow.endDate = eventData;
        this.firstDateSet = false;
      }
    },
    handleStartTime(eventData) {
      Vue.$log.debug("setting: ", eventData);
      if (eventData.length > 5) {
        let tempStartTime = DateTime.fromFormat(
          eventData.toString(),
          "HH:mm:ss"
        );
        if (
          (this.firstTimeSet &&
            this.componentVariables.adhocPromptShadow.endTime === null) ||
          this.componentVariables.adhocPromptShadow.endTime === undefined
        ) {
          let tempEndTime = DateTime.fromFormat(
            eventData.toString(),
            "HH:mm:ss"
          );
          tempEndTime = tempEndTime.plus({ minutes: 30 });
          this.componentVariables.adhocPromptShadow.endTime =
            tempEndTime.toFormat("HH:mm");
          this.firstTimeSet = false;
        }
        this.componentVariables.adhocPromptShadow.fromTime =
          tempStartTime.toFormat("HH:mm");
      }
    },
    handleEndTime(eventData) {
      if (eventData.length > 5) {
        let tempEndTime = DateTime.fromFormat(eventData.toString(), "HH:mm:ss");
        this.componentVariables.adhocPromptShadow.endTime =
          tempEndTime.toFormat("HH:mm");
      }
    },
    timeFormatter(value) {
      this.$log.debug("FORMATTING: ", value);
      if (value.length > 5) {
        let tempTime = DateTime.fromFormat(value, "HH:mm:ss");
        return tempTime.toFormat("HH:mm");
      } else {
        return value;
      }
    },
  },
  computed: {
    typeChoices: function () {
      let options = constants.ADHOC_TYPE_OPTIONS;
      options.forEach((option) => {
        option.text = this.$t("applicationDetail.adhocPrompts." + option.value);
      });
      return options;
    },
  },
};
</script>
