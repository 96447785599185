<template>
  <b-card no-body class="shadow mb-1" border-variant="secondary">
    <b-card-header
      header-text-variant="white"
      header-bg-variant="secondary"
      @click="collapse = !collapse"
    >
      {{ this.parameter.displayName }}
    </b-card-header>
    <b-collapse v-model="collapse" >
      <b-card-body
        :data-test="'parametergroup-' + this.parameter.id"
        class="pb-lg-0"
      >
        <b-form
          :data-test="'parameter-' + parameterComponent.id"
          v-for="(parameterComponent, index) in parameter.parameters"
          v-bind:key="parameter.parameterGroupRefId + '_parameters_' + index"
        >
          <component
            v-if="
              componentVariables.availableComponents.includes(
                componentVariables.constants.CUSTOM_PARAMETER_TYPE_PREFIX +
                  parameterComponent.componentType
              )
            "
            :class="{
              'custom-striped-color': index % 2 === 0,
              '': index % 2 !== 0,
            }"
            :is="
              componentVariables.constants.CUSTOM_PARAMETER_TYPE_PREFIX +
              parameterComponent.componentType
            "
            :parameter="parameterComponent"
            :parameterGroupRefId="parameter.parameterGroupRefId"
            :isDisabled="!permissions[0].scopes.includes('scope_write')"
          />
        </b-form>
        <b-row
          v-for="(child, index) in parameter.childrenGroups"
          v-bind:key="parameter.parameterGroupRefId + '_children_' + index"
        >
          <b-col>
            <parameter
              :parameter="child"
              :permissions="permissions"
            ></parameter>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import Vue from "vue";
import constants from "../../EndPointsAndConstants";
import { mapActions, mapGetters } from "vuex";

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("parameter", require("../application/Parameter.vue").default);
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "parameter",
  props: {
    parameter: { required: true },
    permissions: { type: Array },
    isClosed: {type: Boolean}
  },
  methods: {
    ...mapActions("applications", ["fetchApplications"]),
    ...mapGetters("applications", ["getApplications"])
  },
  created() {
    this.componentVariables.availableComponents.push(
      constants.CUSTOM_PARAMETER_TYPE_PREFIX +
        constants.CUSTOM_PARAMETER_TYPES.TEXT
    );
    this.componentVariables.availableComponents.push(
      constants.CUSTOM_PARAMETER_TYPE_PREFIX +
        constants.CUSTOM_PARAMETER_TYPES.FLOAT
    );
    this.componentVariables.availableComponents.push(
      constants.CUSTOM_PARAMETER_TYPE_PREFIX +
        constants.CUSTOM_PARAMETER_TYPES.INTEGER
    );

    this.componentVariables.availableComponents.push(
      constants.CUSTOM_PARAMETER_TYPE_PREFIX +
        constants.CUSTOM_PARAMETER_TYPES.BOOLEAN_RADIO
    );
    this.componentVariables.availableComponents.push(
      constants.CUSTOM_PARAMETER_TYPE_PREFIX +
        constants.CUSTOM_PARAMETER_TYPES.CUST_DROP
    );
    this.componentVariables.availableComponents.push(
      constants.CUSTOM_PARAMETER_TYPE_PREFIX +
        constants.CUSTOM_PARAMETER_TYPES.APPL_DROP_ALL
    );
    if (this.getApplications.length < 1) {
      this.fetchApplications();
    }
  },
  data: () => {
    return {
      collapse: true,
      componentVariables: {
        availableComponents: [],
        constants: constants,
      },
    };
  },
  watch: {
    isClosed(newVal, oldVal){
      this.collapse = newVal;
    }
  }
};
Vue.component(
  constants.CUSTOM_PARAMETER_TYPE_PREFIX +
    constants.CUSTOM_PARAMETER_TYPES.APPL_DROP_ALL,
  {
    template:
      '<b-form-group class="d-flex align-items-center mb-0" label-cols="2" :label="parameter.displayName" :label-for="unique_id"><b-form-select :disabled="isDisabled" class="mt-2 mb-2" style="width: 50rem;" :id="unique_id" v-model="value" :options="options"/></b-form-group>',
    props: {
      parameter: { type: Object },
      isDisabled: { type: Boolean },
      parameterGroupRefId: { type: Number },
    },
    methods: {
      ...mapActions("application", ["parameterChanged"]),
      ...mapGetters("parameters", [
        "getAvailableParameterOptionsApplicationAll",
      ])
    },
    computed: {
      unique_id: function () {
        return this.parameterGroupRefId + "-" + this.parameter.id;
      },

      value: {
        get() {
          Vue.$log.debug(
            "returning selected appl drop all value >>",
            this.parameter.parameterOptionSelected
          );
          if (
            Array.isArray(this.parameter.parameterOptionSelected) &&
            this.parameter.parameterOptionSelected.length > 0
          ) {
            Vue.$log.debug(this.parameter.parameterOptionSelected[0]);
            return this.parameter.parameterOptionSelected[0].id;
          }
          return null;
        },
        set(value) {
          this.parameterChanged({
            parameterGroupRefId: this.parameterGroupRefId,
            parameter: this.parameter,
            value: value,
          });
        },
      },
      options: {
        get() {
          let options = [];
          options = this.getAvailableParameterOptionsApplicationAll().map(
            (item) => ({ value: item.id, text: item.displayName })
          );

          return options;
        },
      },
    },
  }
);

Vue.component(
  constants.CUSTOM_PARAMETER_TYPE_PREFIX +
    constants.CUSTOM_PARAMETER_TYPES.CUST_DROP,
  {
    template:
      '<b-form-group class="d-flex align-items-center mb-0" label-cols="2" :label="parameter.displayName" :label-for="unique_id"><b-form-select :disabled="isDisabled" class="mt-2 mb-2" style="width: 50rem;" :id="unique_id" v-model="value" :options="options"/></b-form-group>',
    props: {
      parameter: { type: Object },
      isDisabled: { type: Boolean },
      parameterGroupRefId: { type: Number },
    },
    methods: {
      ...mapActions("application", ["parameterChanged"])
    },
    computed: {
      unique_id: function () {
        return this.parameterGroupRefId + "-" + this.parameter.id;
      },

      value: {
        get() {
          Vue.$log.debug(
            "returning selected cust single list value >>",
            this.parameter.parameterOptionSelected
          );
          if (
            Array.isArray(this.parameter.parameterOptionSelected) &&
            this.parameter.parameterOptionSelected.length > 0
          ) {
            return this.parameter.parameterOptionSelected[0];
          }
          return null;
        },
        set(value) {
          this.parameterChanged({
            parameterGroupRefId: this.parameterGroupRefId,
            parameter: this.parameter,
            value: value,
          });
        },
      },
      options: function () {
        let options = [];
        let defaultOption = null;
        this.parameter.parameterOptionAvailable.forEach((option) => {
          const matchOnValue = (element) => element.id === option.id;
          if (this.parameter.parameterOptionDefault.some(matchOnValue)) {
            defaultOption = {
              text: option.displayName + " (default)",
              value: null,
            };
          }
          options.push({ text: option.displayName, value: option });
        });
        if (defaultOption) {
          options.push(defaultOption);
        }
        return options;
      },
    },
  }
);

Vue.component(
  constants.CUSTOM_PARAMETER_TYPE_PREFIX +
    constants.CUSTOM_PARAMETER_TYPES.TEXT,
  {
    template:
      '<b-form-group class="d-flex align-items-center mb-0" label-cols="2" :label="parameter.displayName" :label-for="unique_id"><b-form-input :disabled="isDisabled" class="mt-2 mb-2" style="width: 50rem;" type="text" :id="unique_id" v-model="value" :placeholder="defaultValue"/></b-form-group>',
    props: {
      parameter: { type: Object },
      isDisabled: { type: Boolean },
      parameterGroupRefId: { type: Number },
    },
    methods: {
      ...mapActions("application", ["parameterChanged"])
    },
    computed: {
      unique_id: function () {
        return (
          "text-slots" + this.parameterGroupRefId + "-" + this.parameter.id
        );
      },
      value: {
        get() {
          return this.parameter.value;
        },
        set(value) {
          this.parameterChanged({
            parameterGroupRefId: this.parameterGroupRefId,
            parameter: this.parameter,
            value: value,
          });
        },
      },
      defaultValue: {
        get() {
          if (this.value != null) {
            return "";
          } else {
            return this.parameter.defaultValue;
          }
        },
      },
    },
  }
);
Vue.component(
  constants.CUSTOM_PARAMETER_TYPE_PREFIX +
    constants.CUSTOM_PARAMETER_TYPES.INTEGER,
  {
    template:
      '<b-form-group class="d-flex align-items-center mb-0" label-cols="2" :label="parameter.displayName" :label-for="unique_id"><b-form-input :disabled="isDisabled" class="mt-2 mb-2" style="width: 50rem;" type="number" :id="unique_id" v-model="value" :placeholder="defaultValue"/></b-form-group>',
    props: {
      parameter: { type: Object },
      isDisabled: { type: Boolean },
      parameterGroupRefId: { type: Number },
    },
    methods: {
      ...mapActions("application", ["parameterChanged"])
    },
    computed: {
      unique_id: function () {
        return (
          "integer-slots" + this.parameterGroupRefId + "-" + this.parameter.id
        );
      },
      value: {
        get() {
          return this.parameter.value;
        },
        set(value) {
          this.parameterChanged({
            parameterGroupRefId: this.parameterGroupRefId,
            parameter: this.parameter,
            value: value,
          });
        },
      },
      defaultValue: {
        get() {
          return this.parameter.defaultValue;
        },
      },
    },
  }
);
Vue.component(
  constants.CUSTOM_PARAMETER_TYPE_PREFIX +
    constants.CUSTOM_PARAMETER_TYPES.FLOAT,
  {
    template:
      '<b-form-group class="d-flex align-items-center mb-0" label-cols="2" :label="parameter.displayName" :label-for="unique_id"><b-form-input :disabled="isDisabled" class="mt-2 mb-2" style="width: 50rem;" type="number" :id="unique_id" v-model="value"/></b-form-group>',
    props: {
      parameter: { type: Object },
      isDisabled: { type: Boolean },
      parameterGroupRefId: { type: Number },
    },
    methods: {
      ...mapActions("application", ["parameterChanged"])
    },
    computed: {
      unique_id: function () {
        return (
          "float-slots" + this.parameterGroupRefId + "-" + this.parameter.id
        );
      },
      value: {
        get() {
          return this.parameter.value;
        },
        set(value) {
          this.parameterChanged({
            parameterGroupRefId: this.parameterGroupRefId,
            parameter: this.parameter,
            value: value,
          });
        },
      },
    },
  }
);
Vue.component(
  constants.CUSTOM_PARAMETER_TYPE_PREFIX +
    constants.CUSTOM_PARAMETER_TYPES.BOOLEAN_RADIO,
  {
    template:
      '<b-form-group class="d-flex align-items-center mb-0" label-cols="2" :label="parameter.displayName" :label-for="unique_id"><b-form-radio-group :disabled="isDisabled" :id="unique_id" type="text" v-model="value" :options="options"/></b-form-group>',
    props: {
      parameter: { type: Object },
      isDisabled: { type: Boolean },
      parameterGroupRefId: { type: Number },
    },
    methods: {
      ...mapActions("application", ["parameterChanged"])
    },
    computed: {
      unique_id: function () {
        return this.parameterGroupRefId + "-" + this.parameter.id;
      },

      value: {
        get() {
          if (
            Array.isArray(this.parameter.parameterOptionSelected) &&
            this.parameter.parameterOptionSelected.length > 0
          ) {
            Vue.$log.debug(
              "returning selected boolean value >>",
              this.parameter.parameterGroupRefId,
              this.parameter.parameterOptionSelected[0]
            );
            return this.parameter.parameterOptionSelected[0];
          }
          if (this.parameter.parameterOptionSelected.length === 0) {
            Vue.$log.debug(
              "returning default boolean value >>",
              this.parameter.parameterGroupRefId,
              this.parameter.parameterOptionDefault[0]
            );
            return this.parameter.parameterOptionDefault[0];
          }
          Vue.$log.debug("returning NULL boolean value");
          return null;
        },
        set(value) {
          this.parameterChanged({
            parameterGroupRefId: this.parameterGroupRefId,
            parameter: this.parameter,
            value: value,
          });
        },
      },
      options: function () {
        let options = [];
        this.parameter.parameterOptionAvailable.forEach((option) => {
          let translatedValue = this.$t('applicationDetail.customParameters.' + option.displayName.toLowerCase())
          options.push({ text: translatedValue, value: option });
        });
        return options;
      },
    },
  }
);
</script>
