<template>
  <div class="ml-1 pl-0 mt-0">
    <label v-if="showButtonTitle">{{
      $t("applicationDetail.action.prompts")
    }}</label>
    <div :class="{ 'd-flex flex-row': horizontal, '': !horizontal }">
      <div :class="'ml-0 pl-0 mt-0 mr-5'">
        <b-button
          :disabled="disabled"
          data-test="managePrompts"
          id="managePrompts"
          v-b-tooltip.hover
          :title="this.$t('applicationDetail.action.editPrompts') + '\ ' + title + '\ ' + this.$t('applicationDetail.action.prompts')"
          type="button"
          @click="onPop()"
          :key="componentVariables.hasMutatedKey"
        >
          <font-awesome-icon icon="file-audio" class="fa-lg" />
        </b-button>
      </div>
      <div v-if="showPrompts">
        <prompt-list :prompts="componentVariables.mutatingPromptList" />
      </div>
    </div>

    <b-modal
      :id="modalId"
      :title="title + '\ ' + this.$t('applicationDetail.action.prompts')"
      scrollable
      hide-header-close
      ok-title="save"
      @ok="saveChangedPromptList"
      @cancel="cancel()"
    >
      <table data-test="promptTable" class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th scope="col"></th>
            <th scope="col">{{this.$t('applicationDetail.action.promptID')}}</th>
            <th scope="col">{{this.$t('applicationDetail.action.promptValue')}}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <draggable
          v-model="componentVariables.mutatingPromptList"
          tag="tbody"
          :key="componentVariables.hasMutatedKey"
        >
          <tr
            v-for="item in componentVariables.mutatingPromptList"
            :key="getItemKey(item)"
          >
            <td>
              <font-awesome-icon icon="grip-lines-vertical" />
            </td>
            <td scope="row">{{ item.position }}</td>
            <td>{{ item.value }}</td>
            <td>
              <b-button id="deletePrompt" @click="removePrompt(item)">
                <font-awesome-icon icon="trash" />
              </b-button>
            </td>
          </tr>
        </draggable>
      </table>

      <template #modal-footer="{ ok, cancel }" >
        <b-row class="flex-fill" >
          <b-col cols="9">
            <validation-provider
                :rules="{
          emptyPromptField: [componentVariables.newPromptName],
          max: componentVariables.fieldLength,
        }"
                v-slot="{ valid, errors, touched }"
                class="pl-0 ml-0"
            >
              <b-form inline v-on:submit.prevent>
                <b-form-input
                    v-model="componentVariables.newPromptName"
                    type="text"
                    placeholder="prompt"
                    data-test="input_addPrompt"
                    :state="errors[0] ? false : valid && touched ? true : null"
                />
                <b-button
                    id="addBtn"
                    data-test="btn_addPrompt"
                    :disabled="isAddAllowed()"
                    @click="putNewPromptInList"
                >
                  <font-awesome-icon icon="plus" />
                </b-button>
                <b-form-invalid-feedback tag="span">
                  <b-tooltip
                      v-if="errors[0] && componentVariables.firstPrompt"
                      :show="componentVariables.firstPrompt"
                      target="addBtn"
                      variant="danger"
                  >{{ $t("applicationDetail.action.rememberPrompt") }}</b-tooltip>
                </b-form-invalid-feedback>
                <b-tooltip
                    v-if="isAddAllowed()"
                    :show="true"
                    target="addBtn"
                    variant="danger"
                >{{ $t("applicationDetail.action.promptTooLong") }}</b-tooltip>
              </b-form>
            </validation-provider>
          </b-col>
          <b-col cols="3" class="">
            <b-button @click="cancel()" class="mr-1" title="cancel">
              <font-awesome-icon data-test="prompt_modal_cancel" icon="window-close" />
            </b-button>
            <b-button @click="ok()" title="save">
              <font-awesome-icon data-test="prompt_modal_ok" icon="save" />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import PromptList from "./PromptList";
/**
 * @vue-prop {Array} promptList - List of prompts
 * @vue-prop {String} title - modal title
 * @vue-data {Number} componentVariables.hasMutatedKey - Used as key for Vue
 * @vue-data {String} componentVariables.newPromptName - Value holder for new prompt name
 * @vue-data {Array} componentVariables.mutatingPromptList - temporary array for prompts
 * @vue-computed {String} promptsAsString - prompts displayed on button
 * @vue-computed {String} modalId - id for modal popup
 * @vue-event {Array} updateList - Updated promptlist
 */
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "prompts",
  components: { PromptList },
  props: {
    disabled: { type: Boolean, default: () => false },
    promptList: { type: Array, default: () => [] },
    title: { type: String, required: true },
    id: { type: String, required: false, default: "0" },
    descriptionTitle: { type: String, default: null },
    showPrompts: { type: Boolean, default: true },
    horizontal: { type: Boolean, default: false },
    showButtonTitle: { type: Boolean, default: true },
  },
  watch: {
    promptList(newVal, oldVal) {
      // watch it
      this.componentVariables.mutatingPromptList = newVal.slice();
      this.componentVariables.hasMutatedKey += 1;
    },
  },
  data() {
    return {
      componentVariables: {
        hasMutatedKey: 0,
        newPromptName: "",
        mutatingPromptList: this.promptList.slice(),
        fieldLength: this.$maxLength,
        firstPrompt: true
      },
    };
  },
  methods: {
    isAddAllowed() {
      return (
        this.componentVariables.newPromptName &&
        this.componentVariables.newPromptName.length > 0 &&
        this.componentVariables.newPromptName.length > 51
      );
    },
    getItemKey(item) {
      return item.position + item.value;
    },
    onPop() {
      this.$bvModal.show(this.modalId);
      this.$forceUpdate();
    },
    removePrompt(prompt) {
      this.componentVariables.mutatingPromptList =
        this.componentVariables.mutatingPromptList.filter(
          (item) => !(item === prompt)
        );
    },
    putNewPromptInList() {
      this.componentVariables.mutatingPromptList.push({
        value: this.componentVariables.newPromptName,
        position: null,
      });
      this.componentVariables.newPromptName = null;
      this.componentVariables.firstPrompt = false;
    },
    saveChangedPromptList(bvModalEvent) {
      if (!this.componentVariables.newPromptName) {
        this.$bvModal.hide(this.modalId);
        this.$emit("updateList", this.componentVariables.mutatingPromptList);
        this.componentVariables.hasMutatedKey += 1;
      } else {
        this.$log.debug("Prompt field not empty - modal still showing");
      }
    },
    cancel() {
      this.componentVariables.mutatingPromptList = this.promptList.slice();
      this.componentVariables.hasMutatedKey += 1;
    },
  },
  computed: {
    descriptionTitleComputed() {
      if (this.descriptionTitle === null) {
        return null;
      } else {
        return this.descriptionTitle;
      }
    },
    promptsAsString() {
      let promptString = "";
      if (
        this.componentVariables.mutatingPromptList !== null ||
        this.componentVariables.mutatingPromptList !== undefined
      ) {
        if (this.componentVariables.mutatingPromptList.length === 0) {
          promptString = this.$t("applicationDetail.action.editPrompts");
        }
        if (this.componentVariables.mutatingPromptList.length > 0) {
          promptString =
            this.$t("applicationDetail.action.editPrompts") +
            " [" +
            this.componentVariables.mutatingPromptList.length +
            "]";
        }

        //   if (this.componentVariables.mutatingPromptList.length > 0) {
        //     if(this.componentVariables.mutatingPromptList.length === 1){
        //       promptString = this.componentVariables.mutatingPromptList[0].value;
        //     } else {
        //       promptString = this.componentVariables.mutatingPromptList[0].value;
        //       this.componentVariables.mutatingPromptList.slice(1,3).forEach(prompt => {
        //         promptString = promptString + ", " + prompt.value;
        //       });
        //     }
        //     promptString = promptString.slice(0,20) + "...";
        //   }
        // } else {
        //   promptString = "Edit Prompts";
      }
      return promptString;
    },
    modalId() {
      return this.title.replace(/ /g, "") + this.id + "bvPromptModal";
    },
  },
};
</script>
