<template>
  <b-container fluid class="mt-3" v-if="getCalendar">
    <b-row class="justify-content-center">
      <b-col cols="12" class="mb-3">
        <h1 class="text-center text-white">
          {{ getCalendar.displayName }}
        </h1>
      </b-col>
    </b-row>
    <calendar-schedule></calendar-schedule>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CalendarSchedule from "../components/calendar/CalendarSchedule";

export default {
  name: "CalendarDetail",
  components: { CalendarSchedule },
  props: {
    id: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("calendar", ["getCalendar"]),
  },
  methods: {
    ...mapActions("calendar", ["fetchCalendar"]),
  },
  mounted() {
    this.fetchCalendar(this.id);
  },
};
</script>
