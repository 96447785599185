<template>
  <div>
    <!--Navbar starts-->
    <b-navbar toggleable="lg" type="dark" variant="primary" class="shadow">
      <div>
        <!-- Image and text -->
        <b-navbar variant="faded" type="light">
          <b-navbar-brand to="/application">
            <b-img :src="logo" alt="logo" width="150"></b-img>
          </b-navbar-brand>
          <b-nav-text>CCAdmin {{ version }}</b-nav-text>
        </b-navbar>
      </div>
      <!--Hamburger starts-->
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#/application" data-test="goToApplications">{{
            $t("navBar.applicationTab")
          }}</b-nav-item>
          <!--          <b-nav-item href="#/calendar">Calendar</b-nav-item>-->
          <b-nav-item href="#/menu">{{ $t("navBar.menuTab") }}</b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--                    <b-nav-item-dropdown text="Administration" right>-->
          <!--                      <b-dropdown-item href="#/adminmenu">-->
          <!--                        Menu-->
          <!--                      </b-dropdown-item>-->
          <!--                      <b-dropdown-item href="#/admincalendar">-->
          <!--              Calendar-->
          <!--            </b-dropdown-item>-->
          <!--                    </b-nav-item-dropdown>-->
          <b-nav-item
            href="#/script"
            data-test="goToScripts"
            v-if="
              this.getPermissions().script.permission ===
              this.getPERMISSION_TYPES().ALL
            "
            >{{ $t("navBar.scriptTab") }}</b-nav-item
          >
          <b-nav-item
            href="#/parameter"
            data-test="goToParameters"
            v-if="
              this.getPermissions().parameter.permission ===
              this.getPERMISSION_TYPES().ALL
            "
            >{{ $t("navBar.parametersTab") }}</b-nav-item
          >
          <b-nav-item-dropdown
            data-test="languageBtn"
            :text="$t('navBar.languageTab')"
            right
          >
            <b-dropdown-item
              data-test="languageDK"
              href="#"
              @click="changeLang('da-DK')"
              >{{ $t("navBar.languages.dk") }}
              <b-img :src="dkFlag" alt="dk flag" />
            </b-dropdown-item>
            <b-dropdown-item
              data-test="languageENG"
              href="#"
              @click="changeLang('en-US')"
              >{{ $t("navBar.languages.en") }}
              <b-img :src="ukFlag" alt="uk flag" />
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            right
            :text="this.getName"
            data-test="toogle-account"
          >
            <!-- Using 'button-content' slot -->
            <b-dropdown-item
              href="#"
              data-test="account-manageAccount"
              @click="manageAccount()"
              >{{ $t("navBar.userManageBtn") }}</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              data-test="account-logout"
              @click="logout()"
              >{{ $t("navBar.userSignoutBtn") }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
      <!--Hamburger ends-->
    </b-navbar>
    <b-row v-if="this.hasApplicationInfo()" no-gutters>
      <b-col>
        <b-alert :variant="this.getApplicationInfoVariant()" show
          >{{ $t("navBar.freeApplications") }} :
          {{ this.getLicenseState().applicationsLeft }} {{ $t("navBar.of") }}
          {{ this.getLicenseState().totalAllowedApplications }}</b-alert
        >
      </b-col>
    </b-row>
    <b-row v-if="this.hasTriggerInfo()" no-gutters>
      <b-col>
        <b-alert :variant="this.getTriggerInfoVariant()" show
          >{{ $t("navBar.freeTriggers") }} :
          {{ this.getLicenseState().triggersLeft }} {{ $t("navBar.of") }}
          {{ this.getLicenseState().totalAllowedTriggers }}</b-alert
        >
      </b-col>
    </b-row>
    <b-row v-if="this.getToast" style="height: 51px" no-gutters>
      <b-col>
        <b-alert
          show
          :id="'toast-emitter'"
          :variant="this.getToast.variant"
          data-test="informationToast"
          >{{ this.getToast.message }}</b-alert
        >
      </b-col>
    </b-row>

    <!--Navbar ends-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CCADMIN_VERSION, constants } from "../EndPointsAndConstants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Navigation",
  methods: {
    ...mapGetters("authentication", ["getPermissions"]),
    ...mapGetters("license", [
      "hasTriggerInfo",
      "hasApplicationInfo",
      "getLicenseState",
      "getApplicationInfoVariant",
      "getTriggerInfoVariant",
    ]),
    ...mapActions("license", ["fetchLicenseState"]),
    ...mapActions("language", ["fetchLanguage"]),
    ...mapGetters("language", ["getLanguage"]),
    logout() {
      this.$keycloak.logout();
    },
    getPERMISSION_TYPES() {
      return constants.PERMISSION_TYPES;
    },
    manageAccount() {
      this.$keycloak.accountManagement();
    },
    changeLang(chosenLang) {
      this.$i18n.locale = chosenLang;
      localStorage.setItem("currentLang", chosenLang);
    },
  },
  computed: {
    ...mapGetters("authentication", ["getName"]),
    ...mapGetters("toaster", ["getToast"]),
    language: function () {
      return this.getLanguage();
    },
  },
  data() {
    return {
      /*Import images here:*/
      version: CCADMIN_VERSION,
      logo: require("@/assets/icons/Logo5.png"),
      dkFlag: require("@/assets/images/dk.png"),
      ukFlag: require("@/assets/images/uk.png"),
    };
  },
  created() {
    this.fetchLicenseState();
    setInterval(
      function () {
        this.fetchLicenseState();
      }.bind(this),
      5000
    );
  },
};
</script>
