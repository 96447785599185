var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"border"},[_c('b-row',{staticClass:"mt-0"},[(_vm.showValueField)?_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"name":"optionsList","rules":'required|min_value:1|max_value:120'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"label":_vm.title}},[_c('b-form-input',{attrs:{"data-test":"event_input","type":"number","state":errors[0] ? false : valid && touched ? true : null},on:{"change":function($event){_vm.$emit('change', parseInt(_vm.componentVariables.inputField))}},model:{value:(_vm.componentVariables.inputField),callback:function ($$v) {_vm.$set(_vm.componentVariables, "inputField", _vm._n($$v))},expression:"componentVariables.inputField"}})],1)]}}],null,false,1330964413)})],1):_c('b-col',{attrs:{"cols":"2"}},[_c('label',[_vm._v(_vm._s(_vm.title))])]),_c('b-col',{attrs:{"cols":"8"}},[_c('action-selector',{attrs:{"allApplicationsAsOptions":_vm.allApplicationsAsOptions,"allMenusAsOptions":_vm.allMenusAsOptions,"allScriptsAsOptions":_vm.allScriptsAsOptions,"application":_vm.application,"actionTypes":_vm.actionTypes,"title":_vm.title,"selectorColWidth":'col-2',"valueColWidth":'col-8',"showDefaultCheck":false,"action":this.componentVariables.menuEvent.action},on:{"actionCreated":function (action) {
            this$1.componentVariables.menuEvent.action = action;
          }}})],1),_c('b-col',{staticClass:"align-self-center",attrs:{"cols":"2"}},[_c('prompt-list',{attrs:{"prompts":this.componentVariables.menuEvent.action.prompts}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }