<template>
  <b-col class="border">
    <b-row class="mt-0">
      <b-col cols="2" v-if="showValueField">
        <ValidationProvider
          name="optionsList"
          :rules="'required|min_value:1|max_value:120'"
          v-slot="{ valid, errors, touched }"
        >
          <b-form-group :label="title">
            <b-form-input
              data-test="event_input"
              type="number"
              v-model.number="componentVariables.inputField"
              :state="errors[0] ? false : valid && touched ? true : null"
              @change="$emit('change', parseInt(componentVariables.inputField))"
            />
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="2" v-else>
        <label>{{ title }}</label>
      </b-col>
      <b-col cols="8">
        <action-selector
          :allApplicationsAsOptions="allApplicationsAsOptions"
          :allMenusAsOptions="allMenusAsOptions"
          :allScriptsAsOptions="allScriptsAsOptions"
          :application="application"
          :actionTypes="actionTypes"
          :title="title"
          :selectorColWidth="'col-2'"
          :valueColWidth="'col-8'"
          :showDefaultCheck="false"
          :action="this.componentVariables.menuEvent.action"
          @actionCreated="
            (action) => {
              this.componentVariables.menuEvent.action = action;
            }
          "
        />
      </b-col>
      <b-col cols="2" class="align-self-center">
        <prompt-list
          :prompts="this.componentVariables.menuEvent.action.prompts"
        />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import PromptList from "../common/PromptList";

export default {
  name: "MenuEventOption",
  props: {
    actionTypes: { type: Array, default: () => null },
    showDefaultCheck: { type: Boolean, default: () => false },
    application: {
      type: Object,
      default: () => {},
    },
    menuEvent: { type: Object, require: false, default: () => null },
    title: { type: String, require: false },
    allMenusAsOptions: { type: Array, default: () => [] },
    allScriptsAsOptions: { type: Array, default: () => [] },
    allApplicationsAsOptions: { type: Array, default: () => [] },
    inputField: { type: Number, require: false },
    showValueField: { type: Boolean, require: false, default: () => false },
  },
  components: {
    PromptList,
  },
  data() {
    return {
      componentVariables: {
        inputField: null,
        menuEvent: {
          action: null,
        },
      },
    };
  },
  created: function () {
    this.componentVariables.inputField = this.inputField;
    this.componentVariables.menuEvent = Object.assign({}, this.menuEvent);
  },
  watch: {
    "componentVariables.menuEvent.action": {
      handler(newVal, oldVal) {
        this.$emit("changeAction", newVal);
      },
    },
    immediate: false,
  },
};
</script>

<style scoped></style>
