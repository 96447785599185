import Vue from "vue";
import axios from "axios";
import constants from "@/EndPointsAndConstants";

const state = {
  noData: true,
  licenseInfo: {
    hostName: "w7vnw",
    triggersInUse: 0,
    applicationsInUse: 0,
    totalAllowedApplications: 0,
    totalAllowedTriggers: 0,
    applicationsLeft: 0,
    triggersLeft: 0,
  },
};

const getters = {
  hasTriggerInfo() {
    if (state.noData) {
      return false;
    }
    return "success" !== getters.getTriggerInfoVariant();
  },
  getTriggerInfoVariant() {
    if (state.licenseInfo.triggersLeft < 5) {
      return "danger";
    }
    if (state.licenseInfo.triggersLeft < 10) {
      return "warning";
    }
    return "success";
  },
  hasApplicationInfo() {
    if (state.noData) {
      return false;
    }
    return "success" !== getters.getApplicationInfoVariant();
  },
  getApplicationInfoVariant() {
    if (state.licenseInfo.applicationsLeft < 5) {
      return "danger";
    }
    if (state.licenseInfo.applicationsLeft < 10) {
      return "warning";
    }
    return "success";
  },
  getLicenseState() {
    return state.licenseInfo;
  },
};
const actions = {
  fetchLicenseState({ commit }) {
    return axios.get(constants.endPoints.LICENSE_BASE).then((response) => {
      if (response.data) {
        commit("SET_LICENSE_STATE", response.data);
      }
    });
  },
};
const mutations = {
  SET_LICENSE_STATE(state, licenseInfo) {
    state.licenseInfo = licenseInfo;
    state.noData = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
