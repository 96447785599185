import axios from "axios";
import constants from "../../EndPointsAndConstants";
import { makeToast } from "../mixin/basic";

const state = {
  script: {
    id: null,
    name: null,
    displayName: null,
    description: null,
  },
};

const getters = {
  getScript: (state) => {
    return state.script;
  },
};

const actions = {
  fetchScript({ commit }, id) {
    return axios.get(constants.endPoints.SCRIPTS_BASE + id).then((response) => {
      console.log(response.data);
      if (response.data) {
        commit("SET_SCRIPT", response.data.subject);
      }
    });
  },
  deleteScript({ commit }, { vm, script }) {
    console.log("Delete Schedule Vuex Hit");
    return axios
      .delete(constants.endPoints.SCRIPTS_BASE + script.id)
      .then((__) => {
        commit("DELETE_SCRIPT");
        makeToast(
          vm,
          vm.$t("toasts.script.script") +
            script.id +
            vm.$t("toasts.script.deleted"),
          "success",
          true
        );
      })
      .catch((data) => {
        makeToast(
          vm,
          vm.$t("toasts.script.script") + script.id + " " + data,
          "danger",
          true
        );
      });
  },
  saveScript({ dispatch }, { vm, script }) {
    console.log("script are" + script);
    let promise = axios.put(constants.endPoints.SCRIPTS_BASE, script);
    promise.then((__) => {
      dispatch("fetchScript", script.id);
      makeToast(
        vm,
        vm.$t("toasts.script.script") +
          script.id +
          vm.$t("toasts.script.saved"),
        "success"
      );
    });
    return promise;
  },
};

const mutations = {
  SET_SCRIPT(state, script) {
    state.script = script;
  },

  ADD_SCHEDULE(state, scheduleList) {
    state.calendar.scheduleList = scheduleList;
  },

  DELETE_SCRIPT(state) {
    state.script = {
      id: null,
      name: null,
      displayName: null,
      description: null,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
