<template>
  <ValidationObserver ref="scheduleTableObserver">
    <b-table
      :fields="internalFields"
      :items="internalItems"
      :ref="this.id"
      :small="true"
      select-mode="single"
      show-empty
      striped
      fixed
      bordered
      class="mb-0"
      head-variant="light"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-if="templateItem !== null" #head(show_details)="data">
        <div>
          {{ data.label }}
          <b-button
            v-if="!editMode"
            @click="toggleAddItem()"
            :disabled="componentVariables.addItemShow"
            size="sm"
            data-test="toggleCreateSchedule"
            class="mr-1 float-right"
            :title="$t('applicationDetail.schedules.add')"
            v-b-tooltip.hover.noninteractive
          >
            <font-awesome-icon icon="plus" />
          </b-button>
        </div>
      </template>

      <template v-if="componentVariables.addItemShow" slot="top-row">
        <template v-for="(field, index) in internalFields">
          <b-td :key="index">
            <template v-if="field.key === 'show_details'">
              <ValidationProvider
                name="saveTableRow"
                :rules="{
                  noOverLappingSchedules: [
                    componentVariables.currentTargetEditSchedule.item.editItem,
                    schedules,
                  ],
                }"
                v-slot="{ errors, valid }"
                v-bind:skipIfEmpty="false"
              >
                <b-form-group
                  class="float-right"
                  id="save-group"
                  :state="errors[0] ? false : valid ? true : null"
                >
                  <b-button
                    v-if="scheduleType === 0"
                    size="sm"
                    class="mr-1 float-right"
                    @click="addScheduleAndPrepareAnotherIntermediate()"
                    data-test="btn_addScheduleAndNew"
                    :title="$t('applicationDetail.schedules.save')"
                    v-b-tooltip.hover.noninteractive
                  >
                    <font-awesome-icon icon="save" />
                  </b-button>
                  <b-button
                    v-else
                    data-test="btn_addSchedules"
                    @click="addScheduleBulk()"
                    class="mr-1 float-right"
                    size="sm"
                    :title="$t('applicationDetail.schedules.add')"
                    v-b-tooltip.hover.noninteractive
                  >
                    <font-awesome-icon icon="save" />
                  </b-button>

                  <b-tooltip
                    v-if="errors.length > 0"
                    :show="true"
                    target="save-group"
                    variant="danger"
                    >{{ errors[0] }}
                  </b-tooltip>
                </b-form-group>
              </ValidationProvider>
              <b-button
                data-test="btn_cancelSchedule"
                @click="cancelAdd()"
                class="mr-1 float-right"
                size="sm"
                :title="
                  $t('components.application.ScheduleTable.cancelModifyRow')
                "
                v-b-tooltip.hover.noninteractive
              >
                <font-awesome-icon icon="window-close" />
              </b-button>
            </template>

            <schedule-default
              :calculate-default-check-visible="calculateDefaultCheckVisible"
              :data="componentVariables.currentTargetEditSchedule"
              v-if="field.key === 'Default'"
            ></schedule-default>
            <schedule-startTime
              :data="componentVariables.currentTargetEditSchedule"
              :handleStartTime="handleStartTime"
              v-if="field.key === 'rawItem.startTime'"
            ></schedule-startTime>
            <schedule-endTime
              :data="componentVariables.currentTargetEditSchedule"
              v-if="field.key === 'rawItem.endTime'"
            ></schedule-endTime>
            <schedule-weekday
              :data="componentVariables.currentTargetEditSchedule"
              v-if="field.key === 'rawItem.fk_DayOfWeek'"
              :week-day-options="weekDayOptions"
              :chosen-days="componentVariables.chosenDays"
              :day-list="formattedDayList"
            ></schedule-weekday>
            <schedule-description
              v-if="field.key === 'rawItem.description'"
              :data="componentVariables.currentTargetEditSchedule"
            />
            <schedule-disabled
              v-if="field.key === 'Disabled'"
              :data="componentVariables.currentTargetEditSchedule"
            />
            <schedule-action
              v-if="field.key === 'Action'"
              :data="componentVariables.currentTargetEditSchedule"
              :get-action-types="getActionTypes"
              :get-action-for-schedule="getActionForSchedule"
              :get-non-render-components="getNonRenderComponents"
            />
            <schedule-action-value
              v-if="field.key === 'ActionValue'"
              :data="componentVariables.currentTargetEditSchedule"
              :get-action-types="getActionTypes"
              :all-applications-as-options="allApplicationsAsOptions"
              :all-menus-as-options="allMenusAsOptions"
              :all-scripts-as-options="allScriptsAsOptions"
              :get-non-render-components="getNonRenderComponents"
              :component-to-pick="componentToPick"
            />
            <schedule-action-prompts
              v-if="field.key === 'ActionPrompts'"
              :data="componentVariables.currentTargetEditSchedule"
              :component-variables="componentVariables"
              :get-non-render-prompts="getNonRenderPrompts"
              :get-non-render-components="getNonRenderComponents"
            />

            <schedule-start-date
              v-if="field.key === 'rawItem.startDate'"
              :data="componentVariables.currentTargetEditSchedule"
            />
            <schedule-end-date
              v-if="field.key === 'rawItem.endDate'"
              :data="componentVariables.currentTargetEditSchedule"
            />

            <schedule-recurring
              v-if="field.key === 'rawItem.recurring'"
              :data="componentVariables.currentTargetEditSchedule"
            />
          </b-td>
        </template>
      </template>

      <template slot="empty">
        <h4 align="center">{{ $t("noData") }}</h4>
      </template>
      <template v-slot:cell(show_details)="row">
        <template v-if="row.detailsShowing">
          <ValidationProvider
            name="saveTableRow"
            :rules="{ noOverLappingSchedules: [row.item.editItem, schedules] }"
            v-slot="{ errors, valid }"
            v-bind:skipIfEmpty="false"
          >
            <b-form-group
              class="float-right"
              id="save-group"
              :state="errors[0] ? false : valid ? true : null"
            >
              <b-button
                data-test="btn_saveScheduleIntermediate"
                @click="saveScheduleIntermediate(row)"
                class="mr-1 float-right"
                size="sm"
                :title="$t('applicationDetail.schedules.saveTitle')"
                v-b-tooltip.hover.noninteractive
              >
                <font-awesome-icon icon="save" />
              </b-button>
              <b-tooltip
                v-if="errors.length > 0"
                show="true"
                target="save-group"
                variant="danger"
                >{{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
          <b-button
            v-if="editMode"
            data-test="toggleDetailsCancel"
            @click="toggleDetailsAndCreateUpdated(row, true)"
            class="mr-1 float-right"
            size="sm"
            :title="$t('components.application.ScheduleTable.cancelModifyRow')"
            v-b-tooltip.hover.noninteractive
          >
            <font-awesome-icon icon="window-close" />
          </b-button>
        </template>
        <template v-if="!row.detailsShowing">
          <b-button
            v-if="!editMode"
            data-test="toggleDetails"
            id="toggleDetails"
            @click="toggleDetailsAndCreateUpdated(row, false)"
            class="mr-1 float-right"
            size="sm"
            v-b-tooltip.hover.noninteractive
            :title="$t('components.application.ScheduleTable.modifyRow')"
          >
            <font-awesome-icon icon="pen" />
          </b-button>

          <b-button
            v-if="!row.item.rawItem.orgType && !editMode"
            class="mr-1 float-left"
            data-test="remove"
            size="sm"
            :title="$t('applicationDetail.schedules.deleteTitle')"
            v-b-tooltip.hover.noninteractive
            variant="danger"
            @click="openDeleteModal(row.item.rawItem.id)"
          >
            <font-awesome-icon icon="trash" />
          </b-button>
          <reference-modal
            :ref="'deleteModal' + row.item.rawItem.id"
            :object-type="'Schedule'"
            :i-d-reference="row.item.rawItem.id"
            :modal-title="$t('applicationDetail.schedules.deleteSchedule')"
            :ok-button-text="$t('yes')"
            :cancel-button-text="$t('no')"
            :modal-function="$t('deleteReferenceFunction')"
            @targetMethod="deleteSchedule(row)"
          />
        </template>
      </template>
      <template v-slot:row-details></template>
      <template #cell(rawItem.fk_DayOfWeek)="data">
        <schedule-weekday
          :data="data"
          :week-day-options="weekDayOptions"
          :chosen-days="componentVariables.chosenDays"
          :day-list="formattedDayList"
        ></schedule-weekday>
      </template>
      <template #cell(rawItem.startDate)="data">
        <schedule-start-date :data="data" />
      </template>
      <template #cell(rawItem.startTime)="data">
        <schedule-startTime :data="data" :handle-start-time="handleStartTime" />
      </template>
      <template #cell(rawItem.endDate)="data">
        <schedule-end-date :data="data" />
      </template>
      <template #cell(rawItem.endTime)="data">
        <schedule-endTime :data="data"></schedule-endTime>
      </template>
      <template #cell(rawItem.recurring)="data">
        <schedule-recurring :data="data" />
      </template>
      <template #cell(rawItem.description)="data">
        <schedule-description :data="data" />
      </template>

      <template #cell(Action)="data">
        <schedule-action
          :data="data"
          :get-action-types="getActionTypes"
          :get-action-for-schedule="getActionForSchedule"
          :get-non-render-components="getNonRenderComponents"
        />
      </template>
      <template #cell(Default)="data">
        <schedule-default
          :data="data"
          :calculate-default-check-visible="calculateDefaultCheckVisible"
          :application="application"
        ></schedule-default>
      </template>

      <template #cell(ActionValue)="data">
        <schedule-action-value
          :data="data"
          :get-action-types="getActionTypes"
          :all-applications-as-options="allApplicationsAsOptions"
          :all-menus-as-options="allMenusAsOptions"
          :all-scripts-as-options="allScriptsAsOptions"
          :get-non-render-components="getNonRenderComponents"
          :component-to-pick="componentToPick"
        />
      </template>
      <template #cell(Disabled)="data">
        <schedule-disabled :data="data" />
      </template>
      <template #cell(ActionPrompts)="data">
        <schedule-action-prompts
          :data="data"
          :component-variables="componentVariables"
          :get-non-render-prompts="getNonRenderPrompts"
          :get-non-render-components="getNonRenderComponents"
        />
      </template>
    </b-table>
  </ValidationObserver>
</template>
<script>
/**
 * @vue-prop {Object} application - application for showing defaults
 * @vue-prop {Array} schedules - schedules for the table
 * @vue-prop {Array} fields - fields to display
 * @vue-prop {Object} scheduleType - what kind of schedule should it display
 * @vue-prop {String} id - modal popup id
 * @vue-prop {Array} allMenusAsOptions - options for menus
 * @vue-prop {Array} allScriptsAsOptions - options for scripts
 * @vue-prop {Array} allApplicationsAsOptions - options for applications
 */
import Vue from "vue";
import ScheduleForm from "../common/ScheduleForm.vue";
import { mapActions, mapGetters } from "vuex";
import constants, {
  APPLICATION_ACTION_TYPE_OPTIONS,
  ORGANISATION_APPLICATION_ACTION_TYPE_OPTIONS,
} from "../../EndPointsAndConstants.js";
import { DateTime } from "luxon";
import ReferenceModal from "../common/ReferenceModal";
import application from "@/store/modules/application";

Vue.component("schedule-recurring", {
  template:
    '<span><template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    '  <b-form-checkbox v-model="data.item.editItem.recurring"/>' +
    "</template>\n" +
    "<template v-else>{{ data.value }}</template></span> ",
  props: { data: Object },
});

Vue.component("schedule-end-date", {
  template:
    '<span><template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    "  <ValidationProvider\n" +
    '      name="endDate"\n' +
    '      rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"\n' +
    '      v-slot="{ valid, errors }"\n' +
    "  >\n" +
    '    <b-input-group id="endDate-group">\n' +
    '      <b-tooltip v-if="errors.length>0" target="endDate-group" variant="danger">{{ errors[0] }}</b-tooltip>\n' +
    "      <b-form-input\n" +
    '          data-test="endDate"\n' +
    '          id="enddate-input"\n' +
    '          v-model="data.item.editItem.endDate"\n' +
    '          type="text"\n' +
    '          placeholder="YYYY-MM-DD"\n' +
    '          class="pr-0"\n' +
    '          autocomplete="off"\n' +
    '      :state="errors[0] ? false : valid ? true : null"\n' +
    "      ></b-form-input>\n" +
    "    <b-input-group-append>\n" +
    "      <b-form-datepicker\n" +
    '          v-model="data.item.editItem.endDate"\n' +
    "          button-only\n" +
    "          right\n" +
    '          size="sm"\n' +
    '          aria-controls="endDate-input"\n' +
    "      ></b-form-datepicker>\n" +
    "    </b-input-group-append>\n" +
    "  </b-input-group>\n" +
    "</ValidationProvider>\n" +
    "</template>\n" +
    "<template v-else>{{ data.value }}</template></span>",
  props: { data: Object },
});

Vue.component("schedule-start-date", {
  template:
    '<span><template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    "  <ValidationProvider\n" +
    '      name="startDate"\n' +
    '      rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"\n' +
    '      v-slot="{ valid, errors }"\n' +
    "  >\n" +
    '    <b-input-group id="startDate-group">\n' +
    '      <b-tooltip v-if="errors.length>0" target="startDate-group" variant="danger">{{ errors[0] }}</b-tooltip>\n' +
    "      <b-form-input\n" +
    '          data-test="startDate"\n' +
    '          id="startDate-input"\n' +
    '          v-model="data.item.editItem.startDate"\n' +
    '          type="text"\n' +
    '          placeholder="YYYY-MM-DD"\n' +
    "\n" +
    '          class="pr-0"\n' +
    '          autocomplete="off"\n' +
    '      :state="errors[0] ? false : valid ? true : null"\n' +
    "      ></b-form-input>\n" +
    "    <b-input-group-append>\n" +
    "      <b-form-datepicker\n" +
    '          v-model="data.item.editItem.startDate"\n' +
    "          button-only\n" +
    "          right\n" +
    '          size="sm"\n' +
    '          aria-controls="startDate-input"\n' +
    "      ></b-form-datepicker>\n" +
    "    </b-input-group-append>\n" +
    "  </b-input-group>\n" +
    "</ValidationProvider>\n" +
    "</template>\n" +
    "<template v-else>{{ data.value }}</template></span>",
  props: { data: Object },
});

Vue.component("schedule-action-prompts", {
  template:
    "<span>" +
    '<template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    "  <prompts\n" +
    "  :id=\"$id('ActionScheduleModal')\"\n" +
    '  :prompt-list="data.item.editItem.action.prompts"\n' +
    '  :horizontal="true"\n' +
    '  :showButtonTitle="false"\n' +
    '  :title="$t(\'schedule\')" \n' +
    '  @updateList="\n' +
    "  (promptList) => {\n" +
    "  data.item.editItem.action.prompts = promptList;\n" +
    "}\n" +
    '  "\n' +
    '  class="ml-2"\n' +
    "  >\n" +
    "  Prompts\n" +
    "</prompts>\n" +
    "</template>\n" +
    "<template v-else>\n" +
    '  <p data-test="promptOutput"\n' +
    '     class="float-left"\n' +
    '     v-for="(prompt, index) in data.item.rawItem.action.prompts"\n' +
    "  :key=\"'application_prompt' + prompt.position\"\n" +
    "  >\n" +
    "  {{ prompt.value }}\n" +
    "  <template\n" +
    '      v-if="\n' +
    "                index + 1 < data.item.rawItem.action.prompts.length\n" +
    '              "\n' +
    "  >\n" +
    "    {{ componentVariables.promptSeperator }}\n" +
    "  </template>\n" +
    "</p>\n" +
    "</template>\n" +
    "<template\n" +
    '    v-if="\n' +
    "            getNonRenderComponents().includes(\n" +
    "              data.item.rawItem.action.fk_ActionType\n" +
    "            ) && getNonRenderPrompts(data.item.editItem.action)\n" +
    '          "\n' +
    ">\n" +
    "  <small\n" +
    '      class="text-muted float-right"\n' +
    '      v-for="(prompt, index) in getNonRenderPrompts(\n' +
    "              data.item.editItem.action\n" +
    "            )\n" +
    "              .slice()\n" +
    '              .reverse()"\n' +
    "  :key=\"'inherited_prompt' + prompt.position\"\n" +
    "  >{{ prompt.value }}\n" +
    "  <template\n" +
    '      v-if="\n' +
    "                index !== 0 &&\n" +
    "                index + 1 <=\n" +
    "                  getNonRenderPrompts(data.item.editItem.action).length\n" +
    '              "\n' +
    "  >\n" +
    "    {{ componentVariables.promptSeperator }}\n" +
    "  </template>\n" +
    "</small>\n" +
    "</template>" +
    "</span>",
  props: {
    data: Object,
    componentVariables: Object,
    getNonRenderPrompts: Function,
    getNonRenderComponents: Function,
  },
});

Vue.component("schedule-action-value", {
  template:
    "<span>" +
    "<template\n" +
    '    v-if="\n' +
    "            data.detailsShowing &&\n" +
    "            !getNonRenderComponents().includes(\n" +
    "              data.item.editItem.action.fk_ActionType\n" +
    "            )\n" +
    '          "\n' +
    ">\n" +
    "  <component\n" +
    '  :action="data.item.editItem.action"\n' +
    '  :allApplicationsAsOptions="allApplicationsAsOptions"\n' +
    '  :allMenusAsOptions="allMenusAsOptions"\n' +
    '  :allScriptsAsOptions="allScriptsAsOptions"\n' +
    '  :is="componentToPick(data.item.editItem.action)"\n' +
    '  :isScheduleOrg="data.item.editItem.isScheduleOrg"\n' +
    "  />\n" +
    "</template>\n" +
    "<template v-else>\n" +
    "  <template\n" +
    '      v-if="\n' +
    "              getNonRenderComponents().includes(\n" +
    "                data.item.editItem.action.fk_ActionType\n" +
    "              )\n" +
    '            "\n' +
    "  >\n" +
    '    <small className="text-muted">{{data.value}}</small>\n' +
    "  </template>\n" +
    "  <template v-else>\n" +
    '    <b-link data-test=\'gotoBTN\' v-if=\'data.item.rawItem.action.fk_ActionType !== "TRANSFER"\' className="text-muted" v-on:click="reroute(data.item.rawItem.action.fk_ActionType)">{{data.value}}</b-link>\n' +
    "    <small v-else>{{data.value}}</small>\n" +
    "  </template>\n" +
    "</template>" +
    // "  <template v-if='data.value=\"ERROR\"'>\n" +
    // "    <small>{{data.value}}</small>\n" +
    // "  </template>\n" +
    "</template>" +
    "</span>",
  props: {
    data: Object,
    getNonRenderComponents: Function,
    getActionTypes: Function,
    allApplicationsAsOptions: Array,
    allMenusAsOptions: Array,
    allScriptsAsOptions: Array,
    componentToPick: Function,
  },
  methods: {
    reroute(referenceType) {
      // TODO Commented routes for final product, opening windows does not work with cypress
      if (referenceType === "APPLICATION") {
        console.log(
          "REROUTE " +
            referenceType +
            " Value " +
            this.data.item.rawItem.action.fk_Application
        );
        // window.open("#/" + "application/" + this.data.item.rawItem.action.fk_Application, "_blank");
        this.$router.push({
          name: "applicationDetails",
          params: { id: this.data.item.rawItem.action.fk_Application },
        });
      }

      if (referenceType === "MENU") {
        console.log(
          "REROUTE " +
            referenceType +
            " Value " +
            this.data.item.rawItem.action.fk_Menu
        );
        // window.open("#/" + "menu/detail/" + this.data.item.rawItem.action.fk_Menu, "_blank");
        this.$router.push({
          name: "menuDetail",
          params: { idProp: this.data.item.rawItem.action.fk_Menu },
        });
      }

      if (referenceType === "SCRIPT") {
        console.log(
          "REROUTE " +
            referenceType +
            " Value " +
            this.data.item.rawItem.action.fk_Script
        );
        // window.open("#/" + "script/detail/" + this.data.item.rawItem.action.fk_Script, "_blank");
        this.$router.push({
          name: "scriptDetail",
          params: { id: this.data.item.rawItem.action.fk_Script },
        });
      }
    },
  },
});

Vue.component("schedule-action", {
  template:
    '<span><template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    '  <ValidationProvider :rules="{required:true}" v-slot="{ valid, errors }">\n' +
    "  <b-form-select\n" +
    '      class="float-left"\n' +
    '      data-test="action_type_select"\n' +
    '  :options="getActionTypes()"\n' +
    '  value-field="value.name"\n' +
    '  v-model="data.item.editItem.action.fk_ActionType"\n' +
    '  :state="errors[0] ? false : valid ? true : null"\n' +
    "  ></b-form-select>\n" +
    "</ValidationProvider>\n" +
    "</template>\n" +
    "<template v-else>{{ data.item.rawItem.action.fk_ActionType }}</template>\n" +
    "<template\n" +
    '    v-if="\n' +
    "            getNonRenderComponents().includes(\n" +
    "              data.item.rawItem.action.fk_ActionType\n" +
    "            ) && getActionForSchedule(data.item.rawItem.action)\n" +
    '          "\n' +
    ">\n" +
    "  <br/>\n" +
    '  <small class="text-muted">{{\n' +
    "    getActionForSchedule(data.item.rawItem.action).fk_ActionType\n" +
    "  }}</small>\n" +
    "</template></span>",
  props: {
    data: Object,
    getNonRenderComponents: Function,
    getActionTypes: Function,
    getActionForSchedule: Function,
  },
});

Vue.component("schedule-weekday", {
  template:
    '<span>        <template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    //   "          <ValidationProvider\n" +
    // '              v-if="data.item.editItem.id"' +
    // '              name="select_weekday"\n' +
    // '              rules="required"\n' +
    // '              v-slot="{ valid, errors }"\n' +
    // "          >\n" +
    // "            <b-form-select\n" +
    // '                id="select_weekday"\n' +
    // '                data-test="select_weekday"\n' +
    // '                :options="weekDayOptions()"\n' +
    // "                :placeholder=\"$t('applicationDetail.schedules.selectDay')\"\n" +
    // '                v-model="data.item.editItem.fk_DayOfWeek"\n' +
    // '                :state="errors[0] ? false : valid ? true : null"\n' +
    // "            ></b-form-select>\n" +
      "<b-form-radio-group\n" +
    '             v-if="data.item.editItem.id"' +
    "             button-variant='scheduleSize'" +
    "             data-test=\"schedule_days\"\n" +
    "             :options=\"weekDayOptions()\"\n" +
    "             v-model=\"data.item.editItem.fk_DayOfWeek\"\n" +
    "             buttons" +
    "          />\n" +
    // '            <b-tooltip v-if="errors.length>0" target="select_weekday" variant="danger">{{ errors[0] }}</b-tooltip>\n' +
    // "          </ValidationProvider>\n" +
    "          <b-form-checkbox-group\n" +
    "             v-else" +
    "             button-variant='scheduleSize'" +
    "             data-test=\"schedule_days\"\n" +
    "             :options=\"dayList\"\n" +
    "             v-model=\"data.chosenDays\"\n" +
    "             buttons" +
    "          />\n" +
    "     </template> " +
    '     <template v-else>{{ this.$t("days."+data.value) }}</template>'+
    "</span>\n",
  props: { data: Object, weekDayOptions: Function, dayList: Array },
  computed: {
    daysAsText() {
      let dayArray = [];
      if(this.data.chosenDays != null){
        this.data.chosenDays.forEach(item => {
          dayArray.push(this.$t("days." + item.translationKey));
        })
        return dayArray;
      } else {
        return null;
      }
    }
  }
});

Vue.component("schedule-description", {
  template:
    '<span><template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    "  <b-form-input\n" +
    '      maxlength="50"\n' +
    "  :id=\"$id('ScheduleFormDescription')\"\n" +
    "  :description=\"$t('applicationDetail.schedules.enterDescription')\"\n" +
    '  v-model="data.item.editItem.description"\n' +
    "  :placeholder=\"$t('applicationDetail.schedules.enterDescription')\"\n" +
    "  ></b-form-input>\n" +
    "</template>\n" +
    "<template v-else>{{ data.value }}</template></span>",
  props: { data: Object },
});

Vue.component("schedule-default", {
  template:
    '        <ValidationProvider :rules="{ actionTypeDefault: [data.item.editItem.action, application] }"\n' +
    '                            v-slot="{ valid, errors }"\n' +
    '                            v-if="calculateDefaultCheckVisible(data.item.editItem.action.fk_ActionType) && !data.item.editItem.orgType">\n' +
    "\n" +
    '          <b-form-group id="default_check_group" v-b-tooltip="{title:errors[0],variant:\'danger\'}"\n' +
    '                        :disabled="!data.detailsShowing" v-if="valid===false">\n' +
    '            <b-form-checkbox data-test="useDefault_switch"\n' +
    '                             v-model="data.item.editItem.action.useDefault"\n' +
    '                             :state="errors[0] ? false : valid ? true : null"\n' +
    "            />\n" +
    "          </b-form-group>\n" +
    '          <b-form-group id="default_check_group" :disabled="!data.detailsShowing" v-else-if="data.detailsShowing">\n' +
    "\n" +
    '            <b-form-checkbox data-test="useDefault_switch"\n' +
    '                             v-model="data.item.editItem.action.useDefault"\n' +
    '                             :state="errors[0] ? false : valid ? true : null"\n' +
    "            />\n" +
    "          </b-form-group>\n" +
    "        </ValidationProvider>",
  props: {
    data: Object,
    calculateDefaultCheckVisible: Function,
    application: Object,
  },
});

Vue.component("schedule-startTime", {
  template:
    '<span> <template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    "          <ValidationProvider\n" +
    '              name="startTime"\n' +
    '              rules="required|hour24|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"\n' +
    '              v-slot="{ valid, errors }"\n' +
    "          >\n" +
    '            <b-input-group id="startTime-group">\n' +
    '              <b-tooltip v-if="errors.length>0" target="startTime-group" variant="danger">{{ errors[0] }}</b-tooltip>\n' +
    "              <b-form-input\n" +
    '                  data-test="startTime"\n' +
    '                  id="startTime"\n' +
    '                  v-model="data.item.editItem.startTime"\n' +
    '                  type="text"\n' +
      '                @input="value=>handleStartTime(value,data.item)"' +
    "\n" +
    '                  maxlength="5"\n' +
    '                  placeholder="HH:mm"\n' +
    '                  class="pr-0"\n' +
    '                  :state="errors[0] ? false : valid ? true : null"\n' +
    "              ></b-form-input>\n" +
    "              <b-input-group-append>\n" +
    "                <b-form-timepicker\n" +
    '                    v-model="data.item.editItem.startTime"\n' +
    "\n" +
    '                    @input="value=>handleStartTime(value,data.item)"\n' +
    "                    button-only\n" +
    "                    right\n" +
    '                    size="sm"\n' +
    '                    aria-controls="startTime"\n' +
    "                    :locale=\"'de'\"\n" +
    "                ></b-form-timepicker>\n" +
    "              </b-input-group-append>\n" +
    "            </b-input-group>\n" +
    "          </ValidationProvider>\n" +
    "        </template>\n" +
    "        <template v-else>{{ data.value }}</template></span>",
  props: { data: { type: Object }, handleStartTime: Function },
});
Vue.component("schedule-endTime", {
  template:
    '<span><template v-if="data.detailsShowing && !data.item.editItem.orgType">\n' +
    "          <ValidationProvider\n" +
    '              name="endTime"\n' +
    '              rules="required|hour24|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"\n' +
    '              v-slot="{ valid, errors }"\n' +
    "          >\n" +
    '            <b-input-group id="endTime_group">\n' +
    '              <b-tooltip v-if="errors.length>0" target="endTime_group" variant="danger">{{ errors[0] }}</b-tooltip>\n' +
    "              <b-form-input\n" +
    '                  data-test="endTime"\n' +
    '                  id="endTime"\n' +
    '                  v-model="data.item.editItem.endTime"\n' +
    '                  type="text"\n' +
    '                  maxlength="5"\n' +
    '                  placeholder="HH:mm"\n' +
    '                  class="pr-0"\n' +
    '                  :state="errors[0] ? false : valid ? true : null"\n' +
    "              ></b-form-input>\n" +
    "              <b-input-group-append>\n" +
    "                <b-form-timepicker\n" +
    '                    v-model="data.item.editItem.endTime"\n' +
    "                    button-only\n" +
    "                    right\n" +
    '                    size="sm"\n' +
    '                    aria-controls="endTime"\n' +
    "                    :locale=\"'de'\"\n" +
    "                ></b-form-timepicker>\n" +
    "              </b-input-group-append>\n" +
    "            </b-input-group>\n" +
    "          </ValidationProvider>\n" +
    "        </template>\n" +
    "        <template v-else>{{ data.value }}</template>\n </span>",
  props: { data: Object },
});

Vue.component("schedule-disabled", {
  template:
    '<span> <template v-if="data.detailsShowing">\n' +
    "  <b-form-checkbox\n" +
    '      data-test="disabled"\n' +
    '      type="checkbox"\n' +
    '      v-model="data.item.editItem.disabled"\n' +
    "  />\n" +
    "</template>\n" +
    '<template v-else-if="!data.item.templateItem==true">\n' +
    "  <b-form-checkbox\n" +
    '      data-test="disabled"\n' +
    '      className="align-bottom"\n' +
    '      type="checkbox"\n' +
    '      v-model="data.item.rawItem.disabled"\n' +
    "      disabled\n" +
    "  /></template> </span>",
  props: { data: Object },
});

Vue.component("scheduleTable" + constants.ACTION_TYPES.TRANSFER.name, {
  template:
    '<ValidationProvider v-slot="{ valid, errors }" :rules="action.useDefault || isScheduleOrg ? \'max:\'+fieldLength : \'required|max:\' + fieldLength"><b-form-group :disabled="action.useDefault || isScheduleOrg"><b-row no-gutters><b-form-input data-test="transfer_input" type="text" v-model="action.value" :state="errors[0] ? false : (valid ? true : null)"/></b-row></b-form-group></ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  data() {
    return {
      fieldLength: this.$maxLength,
    };
  },
});
Vue.component("scheduleTable" + constants.ACTION_TYPES.SCRIPT.name, {
  template:
    '<ValidationProvider v-slot="{ valid, errors }" :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'"><b-form-group :disabled="action.useDefault || isScheduleOrg"><b-row no-gutters><b-col cols="8" class="ml-0 pl-0 mr-0 pr-0"><b-form-select  data-test="script_input" :options="allScriptsAsOptions" v-model="action.fk_Script" :state="errors[0] ? false : (valid ? true : null)"/></b-col><b-col class="ml-0 pl-0"><b-button :disabled="disableButton" data-test="gotoBtn" @click="reroute(\'scriptDetail\', action.fk_Script)"> <font-awesome-icon icon="arrow-right" /></b-button></b-col></b-row></b-form-group></ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
    rerouteMethod: { type: Function },
  },
  computed: {
    disableButton() {
      if (this.action.fk_Script) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    reroute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      this.$router.push({
        name: targetLink,
        params: { id: targetID },
      });
    },
  },
});
Vue.component("scheduleTable" + constants.ACTION_TYPES.APPLICATION.name, {
  template:
    '<ValidationProvider v-slot="{ valid, errors }" :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'"><b-form-group :disabled="action.useDefault || isScheduleOrg"><b-row no-gutters><b-col cols="8" class="ml-0 pl-0 mr-0 pr-0"><b-form-select data-test="application_input" v-model="action.fk_Application" :options="allApplicationsAsOptions" :state="errors[0] ? false : (valid ? true : null)"/></b-col><b-col class="ml-0 pl-0"><b-button :disabled="disableButton" data-test="gotoBtn" @click="reroute(\'applicationDetails\', action.fk_Application)"> <font-awesome-icon icon="arrow-right" /></b-button></b-col></b-row></b-form-group></ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  computed: {
    disableButton() {
      if (this.action.fk_Application) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    reroute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      this.$router.push({
        name: targetLink,
        params: { id: targetID },
      });
    },
  },
});
Vue.component("scheduleTable" + constants.ACTION_TYPES.MENU.name, {
  template:
    '<ValidationProvider v-slot="{ valid, errors }" :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'"><b-form-group :disabled="action.useDefault || isScheduleOrg" ><b-row class="d-flex" no-gutters><b-col cols="8" class="ml-0 pl-0 mr-0 pr-0"><b-form-select data-test="menu_input" :options="allMenusAsOptions" v-model="action.fk_Menu" :state="errors[0] ? false : (valid ? true : null)"/></b-col><b-col class=" ml-0 pl-0"><b-button :disabled="disableButton" data-test="gotoBtn" @click="reroute(\'menuDetail\', action.fk_Menu)"> <font-awesome-icon icon="arrow-right" /></b-button></b-col></b-row></b-form-group></ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  computed: {
    disableButton() {
      if (this.action.fk_Menu) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    reroute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      this.$router.push({
        name: targetLink,
        params: { idProp: targetID },
      });
    },
  },
});
Vue.component("scheduleTable" + constants.ACTION_TYPES.DISCONNECT.name, {
  template: "<div/>",
});

export default {
  name: "scheduleTable",
  components: { ReferenceModal },
  props: {
    application: { type: Object, required: true },
    schedules: { type: Array, default: () => [] },
    fields: { type: Array, default: () => [] },
    scheduleType: { type: Number, required: true },
    id: { type: String, required: true },
    allMenusAsOptions: { type: Array, default: () => [] },
    allScriptsAsOptions: { type: Array, default: () => [] },
    allApplicationsAsOptions: { type: Array, default: () => [] },
    hideDetailsColumn: { type: Boolean, default: () => false },
    templateItem: { type: Object, default: () => null },
    editMode: { type: Boolean, default: () => false },
    activeScheduleID: { type: Number, required: false },
    allowBulk:{type: Boolean, default: () => false}
  },
  watch: {},
  created() {},
  data() {
    return {
      componentVariables: {
        applicationActionTypeOptions: APPLICATION_ACTION_TYPE_OPTIONS,
        organizationActionTypeOptions:
          ORGANISATION_APPLICATION_ACTION_TYPE_OPTIONS,
        currentExpandedRow: null,
        constants: constants,
        addItemShow: false,
        currentTargetEditSchedule: {},
        promptSeperator: ", \xa0", // \xa0 is a non-break space
        chosenDays: []
      },
    };
  },
  methods: {
    ...mapActions("application", [
      "deleteDisableSchedule",
      "saveSchedule",
      "fetchApplicationOrganization",
    ]),
    ...mapGetters("applications", ["getApplications"]),
    ...mapGetters("application", [
      "getOrganizationApplication",
      "getSelectedApplication",
      "getActiveSchedule",
    ]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    resetAddItemToTemplate(schedule) {
      if (schedule == null) {
        let wrappedItem = Object.assign({}, this.templateItem);
        wrappedItem.action = Object.assign({}, this.templateItem.action);
        this.componentVariables.currentTargetEditSchedule = {
          item: { editItem: wrappedItem, rawItem: wrappedItem },
          detailsShowing: true,
        };
      } else {
        let wrappedItem = Object.assign({}, schedule);
        wrappedItem.action = Object.assign({}, schedule.action);
        this.componentVariables.currentTargetEditSchedule = {
          item: { editItem: wrappedItem, rawItem: wrappedItem },
          detailsShowing: true,
        };
      }
    },
    toggleAddItem() {
      if (!this.componentVariables.addItemShow) {
        this.emitMode(true);
        this.resetAddItemToTemplate(null);
      } else {
        this.emitMode(false);
        this.componentVariables.currentTargetEditSchedule = {};
      }
      this.componentVariables.addItemShow =
        !this.componentVariables.addItemShow;
    },
    handleStartTime(eventData, row) {
      Vue.$log.debug("setting: ", eventData);
      if (eventData.length == 2) {
        eventData += ":00:00";
        Vue.$log.debug("setting with :00 : ", eventData);
        let tempStartTime = DateTime.fromFormat(
            eventData.toString(),
            "HH:mm:ss"
        );
        if (
            row.editItem.endTime === null ||
            row.editItem.endTime === undefined
        ) {
          let tempEndTime = DateTime.fromFormat(
              eventData.toString(),
              "HH:mm:ss"
          );
          tempEndTime = tempEndTime.plus({ minutes: 30 });
          if (tempEndTime !== null || tempEndTime !== undefined) {
            row.editItem.endTime = tempEndTime.toFormat("HH:mm");
            this.firstTimeSet = false;
          } else {
          }
        }
        row.editItem.startTime = tempStartTime.toFormat("HH:mm");

      }
      if (eventData.length > 5) {
        let tempStartTime = DateTime.fromFormat(
          eventData.toString(),
          "HH:mm:ss"
        );
        if (
          row.editItem.endTime === null ||
          row.editItem.endTime === undefined
        ) {
          let tempEndTime = DateTime.fromFormat(
            eventData.toString(),
            "HH:mm:ss"
          );
          tempEndTime = tempEndTime.plus({ minutes: 30 });
          if (tempEndTime !== null || tempEndTime !== undefined) {
            row.editItem.endTime = tempEndTime.toFormat("HH:mm");
            this.firstTimeSet = false;
          } else {
          }
        }
        row.editItem.startTime = tempStartTime.toFormat("HH:mm");
      }
    },
    handleEndTime(eventData, row) {
      if (eventData.length > 5) {
        let tempEndTime = DateTime.fromFormat(eventData.toString(), "HH:mm:ss");
        row.editItem.endTime = tempEndTime.toFormat("HH:mm");
      }
    },
    templateComponentToPick(rawKey) {
      console.log("key is>>>" + rawKey);
      switch (rawKey) {
        case "Default":
          return "schedule-default";
          break;

        default:
          return constants.ACTION_TYPES.DISCONNECT.name;
      }
    },
    componentToPick(action) {
      let nonRenderComponents = this.getNonRenderComponents();
      if (
        action != null &&
        !nonRenderComponents.includes(action.fk_ActionType)
      ) {
        Vue.$log.debug("returning " + "scheduleTable" + action.fk_ActionType);
        return "scheduleTable" + action.fk_ActionType;
      } else {
        return constants.ACTION_TYPES.DISCONNECT.name;
      }
    },
    calculateDefaultCheckVisible(actionType) {
      console.log("calulating:" + actionType);
      if (this.getNonRenderComponents().includes(actionType)) {
        return false;
      } else {
        return true;
      }
    },
    getNonRenderPrompts(action) {
      console.log("nonRenderPrompts");
      let actionToReturn = this.getActionForSchedule(action);
      if (actionToReturn) {
        return actionToReturn.prompts;
      }
      return null;
    },
    getNonRenderComponents() {
      let nonRenderComponents = [];
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_CLOSED.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_OPEN.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_ORG.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_ORG_CLOSED.name);
      nonRenderComponents.push(constants.ACTION_TYPES.DEFAULT_ORG_OPEN.name);
      return nonRenderComponents;
    },
    getActionTypes() {
      Vue.$log.debug("options", this.actionTypes);
      if (this.actionTypes == null) {
        if (this.application.organization === true) {
          return this.componentVariables.organizationActionTypeOptions();
        } else {
          return this.componentVariables.applicationActionTypeOptions();
        }
      } else {
        return this.actionTypes;
      }
    },

    markRow(rowId) {
      Vue.$log.debug("prepare dynamic, row ID are >>", rowId);
      let markRowDyn = new Function(
        "rowId",
        "refs",
        "this.console.log('did something');refs." +
          this.id +
          ".clearSelected();refs." +
          this.id +
          ".selectRow(rowId); return refs." +
          this.id +
          ".isRowSelected(rowId)"
      );
      Vue.$log.debug("run dynamic");
      Vue.$log.debug("did select >>", markRowDyn(rowId, this.$refs));
    },
    addScheduleBulk(){
      Vue.$log.debug("addScheduleBulk called");
      Vue.$log.debug(this.componentVariables.currentTargetEditSchedule);
      Vue.$log.debug(this.componentVariables.currentTargetEditSchedule.item.editItem);
      this.$refs.scheduleTableObserver.handleSubmit((__) => {
        let updatedRawItem = Object.assign(
            {},
            this.componentVariables.currentTargetEditSchedule.item.editItem
        );

        this.$emit("AddActionScheduleBulk", {days: this.getChosenDays()}, updatedRawItem);
        this.resetAddItemToTemplate(updatedRawItem);
        this.$refs.scheduleTableObserver.reset();
        this.toggleAddItem();
      });
    },
    getChosenDays(){
      let selectedDays = [];
      this.componentVariables.currentTargetEditSchedule.chosenDays.map((day) => {
        selectedDays.push(day.selectedValue);
      })
      return selectedDays;
    },
    addScheduleAndPrepareAnotherIntermediate() {
      console.log("addScheduleAndPrepareAnotherIntermediate called");
      console.log(this.componentVariables.currentTargetEditSchedule);
      console.log(
        this.componentVariables.currentTargetEditSchedule.item.editItem
      );
      this.$refs.scheduleTableObserver.handleSubmit((__) => {
        let updatedRawItem = Object.assign(
          {},
          this.componentVariables.currentTargetEditSchedule.item.editItem
        );

        this.$emit("AddActionSchedule", updatedRawItem);
        this.resetAddItemToTemplate(updatedRawItem);
        this.$refs.scheduleTableObserver.reset();
        this.toggleAddItem();
      });
    },
    addScheduleIntermediate() {
      console.log("addScheduleIntermediate called");
      this.$refs.scheduleTableObserver.handleSubmit((__) => {
        let updatedRawItem = Object.assign(
          {},
          this.componentVariables.currentTargetEditSchedule.item.editItem
        );
        this.$emit("AddActionSchedule", updatedRawItem);
        this.toggleAddItem();
        this.$emit("updateActive");
      });
    },
    saveScheduleIntermediate(row) {
      console.log("saveScheduleIntermediate called");
      this.$refs.scheduleTableObserver.handleSubmit((__) => {
        let updatedRawItem = Object.assign({}, row.item.editItem);
        if (row.item.schedule) {
          updatedRawItem = Object.assign({}, row.item.schedule);
        }
        if (row.item.action) {
          updatedRawItem.action = row.item.action;
        }
        this.saveSchedule({ vm: this, schedule: updatedRawItem }).then((__) => {
          this.setUnsavedChanges(false);
          this.$emit("updateActive");
        });
        this.componentVariables.currentTargetEditSchedule = null;
        row.toggleDetails();
        this.emitMode(false);
      });
    },
    deleteSchedule(row) {
      Vue.$log.debug("deleting", row.item);
      this.deleteDisableSchedule({
        vm: this,
        schedule: row.item.rawItem,
        application: this.application,
      }).then((__) => {
        this.$emit("deletedSchedule");
        this.$emit("updateActive");
      });
    },
    recurringDateFormatter(value, key, item) {
      if (value === null) {
        return value;
      }
      Vue.$log.debug(value, key, item);
      let timeformat = "yyyy-MM-dd";
      let date = DateTime.fromFormat(value, timeformat);
      if (item.rawItem.recurring === true) {
        return (value = date.toFormat("--------MM-dd"));
      } else {
        return (value = date.toFormat("yyyy-MM-dd"));
      }
    },
    openDeleteModal(id) {
      let modalName = "deleteModal" + id;
      this.$refs[modalName].showModal();
    },
    getApplication(id) {
      let foundElement = this.getApplications().find((iteration) => {
        return iteration.id === id;
      });

      if (foundElement) {
        return foundElement;
      }
    },
    getApplicationName(id) {
      return this.getApplication(id).displayName;
    },
    getActionForSchedule(actionToInspect) {
      if (
        !this.getNonRenderComponents().includes(actionToInspect.fk_ActionType)
      ) {
        return actionToInspect;
      } else {
        let actionToReturn = null;

        if (
          !this.application.organization &&
          (actionToInspect.fk_ActionType ===
            this.componentVariables.constants.ACTION_TYPES.DEFAULT_CLOSED
              .name ||
            (actionToInspect.fk_ActionType ===
              this.componentVariables.constants.ACTION_TYPES.DEFAULT.name &&
              !this.application.actionOnNoHit))
        ) {
          actionToReturn = this.application.actionOnFalse;
        }
        if (
          !this.application.organization &&
          (actionToInspect.fk_ActionType ===
            this.componentVariables.constants.ACTION_TYPES.DEFAULT_OPEN.name ||
            (actionToInspect.fk_ActionType ===
              this.componentVariables.constants.ACTION_TYPES.DEFAULT.name &&
              application.actionOnNoHit))
        ) {
          actionToReturn = this.application.actionOnTrue;
        }
        let orgApp = this.getOrganizationApplication();
        if (
          actionToInspect.fk_ActionType ===
            this.componentVariables.constants.ACTION_TYPES.DEFAULT_ORG.name ||
          actionToInspect.fk_ActionType ===
            this.componentVariables.constants.ACTION_TYPES.DEFAULT_ORG_OPEN
              .name ||
          actionToInspect.fk_ActionType ===
            this.componentVariables.constants.ACTION_TYPES.DEFAULT_ORG_CLOSED
              .name
        ) {
          //Has no org parent so must be itself
          console.log("figuring action value for org out");
          if (orgApp) {
            console.log("app has org");
            if (
              orgApp.actionOnNoHit ||
              actionToInspect.fk_ActionType ===
                this.componentVariables.constants.ACTION_TYPES.DEFAULT_ORG_OPEN
                  .name
            ) {
              console.log("app has org with no hit true");
              actionToReturn = orgApp.actionOnTrue;
            }
            if (
              !orgApp.actionOnNoHit ||
              actionToInspect.fk_ActionType ===
                this.componentVariables.constants.ACTION_TYPES
                  .DEFAULT_ORG_CLOSED.name
            ) {
              console.log("app has org with no hit false");
              actionToReturn = orgApp.actionOnFalse;
            }
          } else {
            if (
              this.application.actionOnNoHit ||
              actionToInspect.fk_ActionType ===
                this.componentVariables.constants.ACTION_TYPES.DEFAULT_ORG_OPEN
                  .name
            ) {
              actionToReturn = this.application.actionOnTrue;
            } else {
              actionToReturn = this.application.actionOnFalse;
            }
          }
        }
        return actionToReturn;
      }
    },

    getActionValue(action) {
      console.log("looking at action" + JSON.stringify(action));
      if (
        action !== null &&
        action.fk_ActionType === constants.ACTION_TYPES.APPLICATION.name
      ) {
        console.log("action is application");
        return this.getApplicationName(action.fk_Application);
      }
      if (
        action !== null &&
        action.fk_ActionType === constants.ACTION_TYPES.SCRIPT.name
      ) {
        console.log("action is is script");
        return this.getScriptName(action.fk_Script);
      }
      if (
        action !== null &&
        action.fk_ActionType === constants.ACTION_TYPES.TRANSFER.name
      ) {
        console.log("action is is transfer");
        return action.value;
      }
      if (
        action !== null &&
        action.fk_ActionType === constants.ACTION_TYPES.MENU.name
      ) {
        console.log("action is application");
        return this.getMenuName(action.fk_Menu);
      }
    },
    getScriptName(id) {
      let foundElement = this.allScriptsAsOptions.find((iteration) => {
        return iteration.value === id;
      });

      if (foundElement) {
        return foundElement.text;
      }
    },
    getMenuName(id) {
      let foundElement = this.allMenusAsOptions.find((iteration) => {
        return iteration.value === id;
      });

      if (foundElement) {
        return foundElement.text;
      }
    },

    cancelAdd(row) {
      this.toggleAddItem();
    },
    toggleDetailsAndCreateUpdated(row, isClose) {
      this.componentVariables.currentTargetEditSchedule = Object.assign(
        {},
        row.item.rawItem
      );
      row.toggleDetails();
      if (isClose) {
        this.emitMode(false);
      } else {
        this.emitMode(true);
      }
    },
    translatedDay(day) {
      let dayText = this.$t("days." + day);
      return dayText;
    },
    weekDayOptions: function () {
      let days = [];
      constants.WEEK_DAYS.forEach((day) => {
        let newDay = day;
        let text = this.translatedDay(day.value).substring(0,2);
        newDay.text = text;
        days.push(newDay);
      });
      console.log(JSON.stringify(days));
      return days;
    },
    emitMode(bool) {
      this.$emit("editMode", bool);
    },
    openRoute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      this.$router.push({
        name: targetLink,
        params: { id: targetID },
      });
    },
  },
  computed: {
    internalItems() {
      let wrappedSchedules = [];
      this.schedules.forEach((item) => {
        let _rowVariant = null;
        console.log("SCHEDULE ITEM: " + item);

        let editItem = Object.assign({}, item);
        if (
          (!this.getActiveSchedule() &&
            item.id === this.getSelectedApplication().actionOnFalse.id) ||
          (!this.getActiveSchedule() &&
            item.id === this.getSelectedApplication().actionOnTrue.id)
        ) {
          _rowVariant = "success";
        }
        if (item.id === this.getActiveSchedule().id) {
          _rowVariant = "success";
        }

        if (item.action) {
          editItem.action = Object.assign({}, item.action);
        }
        if (item.disabled === true) {
          _rowVariant = "danger";
          if(item.orgType === true){
            _rowVariant = "disabledOrgSchedule";
          }
        } else {
          if (item.orgType === true) {
            _rowVariant = "warning";
            if (item.id === this.getActiveSchedule().id){
              _rowVariant = "activeOrgSchedule";
            }
          }
        }
        wrappedSchedules.push({
          editItem: editItem,
          rawItem: item,
          _rowVariant: _rowVariant,
        });
      });
      return wrappedSchedules;
    },
    internalFields() {
      let wrappedFields = [];
      this.fields.forEach((field) => {
        if (field instanceof String && field != "show_details") {
          wrappedFields.push("rawItem." + field);
        } else {
          if (field.key != "show_details") {
            let wrappedItem = Object.assign({}, field);
            wrappedItem.key = "rawItem." + field.key;

            if (field.key == "startDate" && this.scheduleType === 0) {
              wrappedItem.formatter = this.recurringDateFormatter;
            } else if (field.key == "endDate" && this.scheduleType === 0) {
              wrappedItem.formatter = this.recurringDateFormatter;
            }
            wrappedFields.push(wrappedItem);
          }
        }
      });
      wrappedFields.push({
        key: "Action",
        label: this.$t("applicationDetail.action.action"),
        thStyle: "width:8%",
      });
      if (!this.hideDetailsColumn) {
        wrappedFields.push({
          key: "Default",
          label: this.$t("components.application.ScheduleTable.default"),
          thStyle: "width:8%",
        });
      }
      wrappedFields.push({
        key: "ActionValue",
        label: this.$t("applicationDetail.action.actionValue"),
        thStyle: "width:10%",
        formatter: (value, key, item) => {
          Vue.$log.debug(item);
          if(item.rawItem.action != null){
            if (
                !this.getNonRenderComponents().includes(
                    item.rawItem.action.fk_ActionType
                )
            ) {
              return this.getActionValue(item.rawItem.action);
            } else {
              let action = null;

              if (
                  !this.application.organization &&
                  (item.rawItem.action.fk_ActionType ===
                      this.componentVariables.constants.ACTION_TYPES.DEFAULT_CLOSED
                          .name ||
                      (item.rawItem.action.fk_ActionType ===
                          this.componentVariables.constants.ACTION_TYPES.DEFAULT.name &&
                          !this.application.actionOnNoHit))
              ) {
                action = this.application.actionOnFalse;
              }
              if (
                  !this.application.organization &&
                  (item.rawItem.action.fk_ActionType ===
                      this.componentVariables.constants.ACTION_TYPES.DEFAULT_OPEN
                          .name ||
                      (item.rawItem.action.fk_ActionType ===
                          this.componentVariables.constants.ACTION_TYPES.DEFAULT.name &&
                          application.actionOnNoHit))
              ) {
                action = this.application.actionOnTrue;
              }
              let orgApp = this.getOrganizationApplication();
              if (
                  item.rawItem.action.fk_ActionType ===
                  this.componentVariables.constants.ACTION_TYPES.DEFAULT_ORG
                      .name ||
                  item.rawItem.action.fk_ActionType ===
                  this.componentVariables.constants.ACTION_TYPES.DEFAULT_ORG_OPEN
                      .name ||
                  item.rawItem.action.fk_ActionType ===
                  this.componentVariables.constants.ACTION_TYPES
                      .DEFAULT_ORG_CLOSED.name
              ) {
                //Has no org parent so must be itself
                console.log("figuring action value for org out");
                if (orgApp) {
                  console.log("app has org");
                  if (
                      orgApp.actionOnNoHit ||
                      item.rawItem.action.fk_ActionType ===
                      this.componentVariables.constants.ACTION_TYPES
                          .DEFAULT_ORG_OPEN.name
                  ) {
                    console.log("app has org with no hit true");
                    action = orgApp.actionOnTrue;
                  }
                  if (
                      !orgApp.actionOnNoHit ||
                      item.rawItem.action.fk_ActionType ===
                      this.componentVariables.constants.ACTION_TYPES
                          .DEFAULT_ORG_CLOSED.name
                  ) {
                    console.log("app has org with no hit false");
                    action = orgApp.actionOnFalse;
                  }
                } else {
                  if (
                      this.application.actionOnNoHit ||
                      item.rawItem.action.fk_ActionType ===
                      this.componentVariables.constants.ACTION_TYPES
                          .DEFAULT_ORG_OPEN.name
                  ) {
                    action = this.application.actionOnTrue;
                  } else {
                    action = this.application.actionOnFalse;
                  }
                }
              }
              return this.getActionValue(action);
            }
          } else {
            item.rawItem.action = {
              fk_ActionType: "ERROR",
              value: "ERROR"
            }
          }

        },
      });
      wrappedFields.push({
        key: "ActionPrompts",
        label: this.$t("applicationDetail.action.actionPrompts"),
        thStyle: "width:15%",
      });
      if (!this.hideDetailsColumn) {
        wrappedFields.push({
          key: "Disabled",
          label: this.$t("components.application.ScheduleTable.disabled"),
        });
        wrappedFields.push({
          key: "show_details",
          label: this.$t("applicationDetail.schedules.showDetails"),
        });
      }
      return wrappedFields;
    },
    formattedDayList: function() {
      let days = [];
      this.dayList.forEach(item => {
        days.push(item);
      })
      days.forEach(item => {
        item.text = item.text.substring(0,2);
      })
      return days;
    },
    dayList: function(){
      return [
        {
          text: this.$t("days.MONDAY"),
          value: {
            selectedValue: "MONDAY",
            translationKey: "MONDAY"
          }
        },
        {
          text: this.$t("days.TUESDAY"),
          value: {
            selectedValue: "TUESDAY",
            translationKey: "TUESDAY"
          }
        },
        {
          text: this.$t("days.WEDNESDAY"),
          value: {
            selectedValue: "WEDNESDAY",
            translationKey: "WEDNESDAY"
          }
        },
        {
          text: this.$t("days.THURSDAY"),
          value: {
            selectedValue: "THURSDAY",
            translationKey: "THURSDAY"
          }
        },
        {
          text: this.$t("days.FRIDAY"),
          value: {
            selectedValue: "FRIDAY",
            translationKey: "FRIDAY"
          }
        },
        {
          text: this.$t("days.SATURDAY"),
          value: {
            selectedValue: "SATURDAY",
            translationKey: "SATURDAY"
          }
        },
        {
          text: this.$t("days.SUNDAY"),
          value: {
            selectedValue: "SUNDAY",
            translationKey: "SUNDAY"
          }
        }
      ]

    }
  },
};
</script>
