<template>
  <parameter-detail :parameter-id="this.id" :create="false"></parameter-detail>
</template>

<script>
import Vue from "vue";
import { openUnsavedModal } from "../Helper";
Vue.component(
  "parameter-detail",
  require("../components/parameter/ParameterDetail").default
);

export default {
  name: "ParameterEdit",

  props: {
    id: { type: Number, required: true },
  },
};
</script>
