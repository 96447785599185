<template>
  <b-container fluid class="mt-4" data-test="ListWithSearchField">
    <b-row class="mb-4">
      <b-col>
        <b-card class="shadow overflow-auto" :header="title" fluid>
          <b-row class="mb-4">
            <b-col class="pr-0" style="max-width: 7em;">
              <b-form-group>
                <b-form-select
                    v-model="componentVariables.perPage"
                    :options="componentVariables.perPageOptions"
                    v-b-tooltip
                    :title="$t('perPage')"
                    @change="setPerPage(componentVariables.perPage)"/>
              </b-form-group>
            </b-col>
            <b-col cols="11">
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text class="bg-white">
                    <font-awesome-icon icon="search" />
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-input
                  id="filterText"
                  data-test="filterText"
                  data-ignore-change
                  v-model="componentVariables.search.text"
                  @keydown="componentVariables.isFilterProcessing = true"
                  @keyup="componentVariables.isFilterProcessing = false"
                  type="search"
                  :placeholder="searchText"
                  ref="searchText"
                ></b-input>
                <slot name="additionalSearch"></slot>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <template v-if="!filterFunction">
                <b-table
                  :key="updateKey"
                  @filtered="onFiltered"
                  @refreshed="initiateTotalRowsOnRefresh"
                  :per-page="componentVariables.perPage"
                  :current-page="componentVariables.currentPage"
                  :no-provider-filtering="true"
                  :no-provider-paging="true"
                  :busy="isBusy"
                  id="list_table"
                  data-test="list_table"
                  selectable
                  :select-mode="componentVariables.mode"
                  :filter="componentVariables.search.text"
                  :filterIncludedFields="filterOn"
                  @row-selected="rowSelected"
                  striped
                  hover
                  bordered
                  :items="itemProvider"
                  :fields="fields"
                  show-empty
                >
                  <div slot="table-busy" class="text-center text-danger mt-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{ $t("loading") }}</strong>
                  </div>
                  <template slot="empty">
                    <h4>{{ $t("noData") }}</h4>
                  </template>
                  <template slot="emptyfiltered">
                    <h4>{{ $t("searchNotFound") }}</h4>
                  </template>
                </b-table>
              </template>
              <template v-else>
                <b-table
                  :key="updateKey"
                  @filtered="onFiltered"
                  @refreshed="initiateTotalRowsOnRefresh"
                  :per-page="componentVariables.perPage"
                  :current-page="componentVariables.currentPage"
                  :no-provider-filtering="true"
                  :no-provider-paging="true"
                  :busy="isBusy"
                  id="list_table"
                  data-test="list_table"
                  selectable
                  :select-mode="componentVariables.mode"
                  :filter="componentVariables.search.text"
                  :filterIncludedFields="filterOn"
                  :filter-function="filterFunction"
                  @row-selected="rowSelected"
                  striped
                  hover
                  bordered
                  :items="itemProvider"
                  :fields="fields"
                  show-empty
                >
                  <div slot="table-busy" class="text-center text-danger mt-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{ $t("loading") }}</strong>
                  </div>
                  <template slot="empty">
                    <h4 data-test="list_empty">{{ $t("noData") }}</h4>
                  </template>
                  <template slot="emptyfiltered">
                    <h4>{{ $t("searchNotFound") }}</h4>
                  </template>
                </b-table>
              </template>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="1">
              <b-pagination
                v-model="componentVariables.currentPage"
                :total-rows="componentVariables.totalRows"
                :per-page="componentVariables.perPage"
                aria-controls="application_table"
              >
              </b-pagination>
            </b-col>

<!--            <b-col cols="6">-->
<!--              <download-csv-->
<!--                :fields="fieldsForCsv"-->
<!--                :data="componentVariables.items"-->
<!--                :name="title + '.csv'"-->
<!--              >-->
<!--                <b-button>{{ $t("csvBtn") }}</b-button>-->
<!--              </download-csv>-->
<!--            </b-col>-->
          </b-row>
          <b-row>
            <b-col>
              <slot name="additionalContent"> </slot>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from "vue";

export default {
  name: "ListWithSearchField",
  props: {
    title: { type: String, require: true },
    searchText: { type: String, require: true },
    fields: { type: Array, require: true },
    filterOn: { type: Array, require: true },
    filterFunction: { type: Function, require: false },
    fieldsForCsv: { type: Array, require: true },
    itemProvider: { type: Function, require: true },
    updateKey: { type: Number, require: true },
    isBusy: { type: Boolean, require: true },
  },
  data() {
    return {
      componentVariables: {
        totalRows: 0,
        perPage: 10,
        perPageOptions: [10,25,50,100],
        currentPage: 1,
        isFilterProcessing: false,
        mode: "single",
        items: [],
        search: {
          text: null,
        },
      },
    };
  },
  methods: {
    initiateTotalRowsOnRefresh() {
      Vue.$log.debug("UpdateTotalRows");
      this.componentVariables.totalRows = this.itemProvider().length;
    },
    rowSelected(item) {
      this.$emit("rowSelected", item[0]);
    },
    onFiltered(filteredItems) {
      Vue.$log.debug("OnFiltered");
      this.componentVariables.totalRows = filteredItems.length;
      this.componentVariables.items = filteredItems;
      this.componentVariables.currentPage = 1;
    },
    setPerPage(value){
      localStorage.setItem('perPage', value);
    }
  },
  mounted() {
    this.$refs.searchText.focus();
    this.componentVariables.perPage = localStorage.getItem('perPage');
  }
};
</script>
