<template>
  <b-card no-body data-test="ParameterPage">
    <b-tabs
      active-nav-item-class="font-weight-bold text-uppercase text-primary"
      active-tab-class="font-weight-bold text-success pt-0"
      card
      v-model="choose"
    >
      <b-tab
        active
        lazy
        :title="$t('pgManagement.parameterGroupTab')"
        id="parameter-group-tab"
        data-test="parameter-group"
      >
        <b-row class="mb-3">
          <ListWithSearchField
            :fields="parameterGroupFields"
            :fields-for-csv="this.pageVariables.parameterGroupFieldsForCsv"
            :filter-on="this.pageVariables.filterOn"
            :item-provider="this.getParameterGroups"
            :update-key="this.pageVariables.updateParameterGroupTableKey"
            @rowSelected="(item) => rowParameterGroupSelected(item)"
            :search-text="$t('pgManagement.parameterGroup.PGSearchText')"
            :title="$t('pgManagement.parameterGroup.PGCardTitle')"
            data-test="ParameterGroupListPage"
            :is-busy="this.pageVariables.isLoadingParameterGroups"
          >
            <template
              v-slot:additionalContent
              v-if="
                this.getPermissions().parametergroup.permission ===
                this.getPERMISSION_TYPES().ALL
              "
            >
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <b-form
                  class="ml-1 mb-2 p-3 border shadow rounded"
                  fluid
                  inline
                  @submit.prevent="handleSubmit(createNewParameterGroup)"
                >
                  <legend slot="label">
                    {{ $t("pgManagement.parameterGroup.createPG") }}
                  </legend>
                  <ValidationProvider
                    :rules="'required|min:1|max:' + pageVariables.fieldLength"
                    v-slot="{ valid, errors }"
                  >
                    <b-form-group
                      :label="$t('pgManagement.parameterGroup.pgNameField')"
                    >
                      <b-input
                        id="nameInput"
                        data-test="text-parameter-group-name"
                        class="ml-1"
                        :placeholder="
                          $t('pgManagement.parameterGroup.pgNameField')
                        "
                        v-model="pageVariables.parameterGroup.name"
                        :state="errors[0] ? false : valid ? true : null"
                      />
                      <b-tooltip
                          v-if="errors[0]"
                          :show="errors[0] != null"
                          target="nameInput"
                          variant="danger"

                      >{{ errors[0] }}</b-tooltip>
<!--                      <b-form-invalid-feedback v-if="pageVariables.parameterGroup.name.length > 0" tag="span">{{-->
<!--                        errors[0]-->
<!--                      }}</b-form-invalid-feedback>-->
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider
                    :rules="'required|min:1|max:' + pageVariables.fieldLength"
                    v-slot="{ valid, errors }"
                  >
                    <b-form-group
                      :label="
                        $t('pgManagement.parameterGroup.pgDisplayNameField')
                      "
                    >
                      <b-input
                        id="displayInput"
                        data-test="text-parameter-group-display-name"
                        class="ml-1"
                        :placeholder="
                          $t('pgManagement.parameterGroup.pgDisplayNameField')
                        "
                        v-model="pageVariables.parameterGroup.displayName"
                        :state="errors[0] ? false : valid ? true : null"
                      />
                      <b-tooltip
                          v-if="errors[0]"
                          :show="errors[0] != null"
                          target="displayInput"
                          variant="danger"

                      >{{ errors[0] }}</b-tooltip>
<!--                      <b-form-invalid-feedback v-if="pageVariables.parameterGroup.displayName.length > 0" tag="span">{{-->
<!--                        errors[0]-->
<!--                      }}</b-form-invalid-feedback>-->
                    </b-form-group>
                  </ValidationProvider>
                  <b-button
                    data-test="btn-create-parameter-group"
                    type="submit"
                    class="ml-1 align-self-end"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </b-form>
              </ValidationObserver>
            </template>
          </ListWithSearchField>
        </b-row>
      </b-tab>
      <b-tab
        lazy
        :title="$t('pgManagement.parameterTab')"
        id="parameter-tab"
        data-test="parameter-tab"
      >
        <b-row class="mb-3" data-test="parameters">
          <ListWithSearchField
            :fields="parameterFields"
            :fields-for-csv="this.pageVariables.parameterFieldsForCsv"
            :filter-on="this.pageVariables.filterOn"
            :item-provider="this.getParameters"
            :update-key="this.pageVariables.updateParameterTableKey"
            @rowSelected="(item) => rowParameterSelected(item)"
            :search-text="$t('pgManagement.parameter.PSearchText')"
            :title="$t('pgManagement.parameter.PCardTitle')"
            data-test="ParametersListPage"
            :is-busy="this.pageVariables.isLoadingParameters"
          >
            <template
              v-slot:additionalContent
              v-if="
                this.getPermissions().parameter.permission ===
                this.getPERMISSION_TYPES().ALL
              "
            >
              <b-form class="border shadow rounded" fluid inline>
                <b-form-group class="ml-1 mb-2">
                  <legend slot="label">
                    {{ $t("pgManagement.parameter.createParameter") }}
                  </legend>
                  <b-button
                    @click="createParameter()"
                    class="ml-1"
                    data-test="btn-add-parameter"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </b-form-group>
              </b-form>
            </template>
          </ListWithSearchField>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ListWithSearchField from "../components/common/ListWithSearchField";
import constants from "../EndPointsAndConstants";
import router from "../router";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Parameters",
  components: { ListWithSearchField },
  data() {
    return {
      restVariables: {
        application: {
          name: "",
          displayName: "",
        },
      },
      pageVariables: {
        parameterGroup: {
          name: "",
          displayName: "",
        },
        updateParameterTableKey: 0,
        updateParameterGroupTableKey: 0,
        filterOn: ["id", "name", "displayName"],
        parameterFieldsForCsv: [
          "id",
          "name",
          "displayName",
          "componentType",
          "dataType",
        ],

        parameterGroupFieldsForCsv: ["id", "name", "displayName"],
        isLoadingParameters: true,
        isLoadingParameterGroups: true,
        fieldLength: this.$maxLength,
      },
      chosenTab: 0,
    };
  },
  methods: {
    ...mapGetters("parameters", ["getParameters"]),
    ...mapGetters("parameterGroups", ["getParameterGroups"]),
    ...mapGetters("authentication", ["getPermissions"]),
    ...mapActions("parameterGroups", [
      "fetchParameterGroups",
      "addParameterGroup",
    ]),
    ...mapActions("parameters", ["fetchParameters"]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    getPERMISSION_TYPES() {
      return constants.PERMISSION_TYPES;
    },
    rowParameterSelected(item) {
      router.push({
        name: "parameteredit",
        params: { id: item.id },
      });
    },
    rowParameterGroupSelected(item) {
      router.push({
        name: "parametergroupedit",
        params: { id: item.id },
      });
    },
    createParameter() {
      router.push({
        name: "parametercreate",
      });
    },
    createNewParameterGroup() {
      this.addParameterGroup({
        vm: this,
        parameterGroup: this.pageVariables.parameterGroup,
      })
        .then((__) => {
          this.pageVariables.parameterGroup = { name: "", displayName: "" };
          this.fetchParameterGroups().then((__) => {
            this.pageVariables.updateParameterGroupTableKey += 1;
          });
        })
        .then((__) => {
          this.setUnsavedChanges(false);
        })
        .then(
          requestAnimationFrame(() => {
            this.$refs.observer.reset();
          })
        );
    },
  },
  created() {
    this.pageVariables.isLoadingParameters = true;
    this.pageVariables.isLoadingParameterGroups = true;
    this.fetchParameters().then((__) => {
      this.pageVariables.updateParameterTableKey += 1;
      this.pageVariables.isLoadingParameters = false;
    });
    this.fetchParameterGroups().then((__) => {
      this.pageVariables.updateParameterGroupTableKey += 1;
      this.pageVariables.isLoadingParameterGroups = false;
    });
    document.title = this.$t("pageTitles.parameters");
  },
  computed: {
    parameterFields: function () {
      return [
        // {
        //   key: "id",
        //   label: this.$t("pgManagement.parameter.id"),
        //   thStyle: "width: 5%;",
        // },
        {
          key: "name",
          label: this.$t("pgManagement.parameter.name"),
          thStyle: "width: 30%",
        },
        {
          key: "displayName",
          label: this.$t("pgManagement.parameter.displayName"),
          thStyle: "width: 30%",
        },
        {
          key: "componentType",
          label: this.$t("pgManagement.parameter.componentType"),
          thStyle: "width: 15%",
        },
        // {
        //   key: "dataType",
        //   label: this.$t("pgManagement.parameter.dataType"),
        //   thStyle: "width: 15%",
        // },
      ];
    },

    parameterGroupFields: function () {
      return [
        // {
        //   key: "id",
        //   label: this.$t("pgManagement.parameterGroup.id"),
        //   thStyle: "width: 5%;",
        // },
        {
          key: "name",
          label: this.$t("pgManagement.parameterGroup.name"),
          thStyle: "width: 40%",
        },
        {
          key: "displayName",
          label: this.$t("pgManagement.parameterGroup.displayName"),
          thStyle: "width: 55%",
        },
      ];
    },
    choose: {
      set(value) {
        localStorage.setItem("paramTab", value);
      },
      get() {
        if (localStorage.getItem("paramTab") === 1) {
          return 1;
        } else {
          return 0;
        }
      },
    },
  },
};
</script>
