<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form inline>
      <template
        v-if="
          scheduleType === componentVariables.constants.SCHEDULE_TYPES.WEEKLY
        "
      >
        <b-col cols="0" class="ml-2">
          <ValidationProvider
            name="dayOfWeek"
            rules="required"
            v-slot="{ valid, errors }"
          >
            <b-form-group
              :label="$t('applicationDetail.schedules.day')"
              :disabled="orgType"
            >
              <b-form-select
                data-test="select_weekday"
                :options="weekDayOptions()"
                :placeholder="$t('applicationDetail.schedules.selectDay')"
                v-model="fk_DayOfWeek"
                @change="changed"
                :state="errors[0] ? false : valid ? true : null"
              ></b-form-select>
              <b-form-invalid-feedback class="d-flex" v-if="errors[0] != null"
                >{{ errors[0].substring(0, 25) }} <br />
                {{ errors[0].substring(25, errors[0].length) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </template>

      <template
        v-if="
          scheduleType !== componentVariables.constants.SCHEDULE_TYPES.WEEKLY
        "
      >
        <b-col cols="0" class="pr-1 ml-2">
          <ValidationProvider
            name="startDate"
            rules="required"
            v-slot="{ valid, errors, dirty }"
          >
            <b-form-group
              :label="$t('applicationDetail.schedules.startDate')"
              :disabled="orgType"
            >
              <b-input-group>
                <b-form-input
                  data-test="startDate"
                  id="input-startDate"
                  v-model="startDate"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  class="pr-0"
                  style="width: 8em"
                  :state="errors[0] ? false : valid && dirty ? true : null"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="startDate"
                    button-only
                    right
                    aria-controls="input-startDate"
                    @input="handleStartDate"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="d-flex" v-if="errors[0] != null"
                >{{ errors[0].substring(0, 25) }} <br />
                {{ errors[0].substring(25, errors[0].length) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </template>
      <b-col cols="0" class="pr-1 pl-0 ml-2">
        <ValidationProvider
          name="startTime"
          rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"
          v-slot="{ valid, errors, dirty }"
          v-bind:skipIfEmpty="false"
        >
          <b-form-group
            :label="$t('applicationDetail.schedules.startTime')"
            :disabled="orgType"
          >
            <b-input-group>
              <b-form-input
                data-test="startTime"
                id="startTime"
                v-model="startTime"
                type="text"
                placeholder="HH:mm"
                class="pr-0"
                style="width: 8em"
                :state="errors[0] ? false : valid && dirty ? true : null"
              ></b-form-input>
              <b-input-group-append>
                <b-form-timepicker
                  v-model="startTime"
                  button-only
                  right
                  aria-controls="startTime"
                  @input="handleStartTime"
                  :locale="'de'"
                ></b-form-timepicker>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-invalid-feedback class="d-flex" v-if="errors[0] != null"
            >{{ errors[0].substring(0, 25) }} <br />
            {{ errors[0].substring(25, errors[0].length) }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </b-col>
      <template
        v-if="
          scheduleType !== componentVariables.constants.SCHEDULE_TYPES.WEEKLY
        "
      >
        <b-col cols="0" class="pr-1 pl-0 ml-2">
          <ValidationProvider
            name="endDate"
            rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"
            v-slot="{ valid, errors, dirty }"
          >
            <b-form-group
              :label="$t('applicationDetail.schedules.endDate')"
              :disabled="orgType"
            >
              <b-input-group>
                <b-form-input
                  data-test="endDate"
                  id="endDate"
                  v-model="endDate"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  class="pr-0"
                  style="width: 8em"
                  :state="errors[0] ? false : valid && dirty ? true : null"
                ></b-form-input>

                <b-input-group-append>
                  <b-form-datepicker
                    v-model="endDate"
                    button-only
                    right
                    aria-controls="endDate"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="d-flex" v-if="errors[0] != null"
                >{{ errors[0].substring(0, 25) }} <br />
                {{ errors[0].substring(25, errors[0].length) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </template>
      <b-col cols="0" class="pr-1 pl-0 ml-2">
        <ValidationProvider
          name="endTime"
          rules="required|olderDateBeforeNewAndTime:@startDate,@startTime,@endDate,@endTime"
          v-slot="{ valid, errors, dirty }"
        >
          <b-form-group
            :label="$t('applicationDetail.schedules.endTime')"
            :disabled="orgType"
          >
            <b-input-group>
              <b-form-input
                data-test="endTime"
                id="endTime"
                v-model="endTime"
                type="text"
                placeholder="HH:mm"
                class="pr-0"
                style="width: 8em"
                :state="errors[0] ? false : valid && dirty ? true : null"
              ></b-form-input>

              <b-input-group-append>
                <b-form-timepicker
                  v-model="endTime"
                  button-only
                  right
                  aria-controls="endTime"
                  :locale="'de'"
                  @input="handleEndTime"
                ></b-form-timepicker>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback class="d-flex" v-if="errors[0] != null"
              >{{ errors[0].substring(0, 25) }} <br />
              {{ errors[0].substring(25, errors[0].length) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="0" class="pr-1 pl-0 ml-2">
        <b-form-group
          v-if="
            scheduleType !==
              componentVariables.constants.SCHEDULE_TYPES.WEEKLY &&
            showIgnoreYearButton
          "
          :label="$t('applicationDetail.schedules.ignoreYear')"
          :disabled="orgType"
        >
          <b-form-checkbox :switch="true" v-model="recurring" />
        </b-form-group>
      </b-col>
      <b-col cols="0" class="pr-1 pl-0 ml-2">
        <span>
          <b-form-group
            :label="$t('applicationDetail.schedules.description')"
            :disabled="orgType"
          >
            <b-form-input
              maxlength="50"
              :id="$id('ScheduleFormDescription')"
              :label="$t('applicationDetail.schedules.enterDescription')"
              v-model="description"
              :placeholder="$t('applicationDetail.schedules.enterDescription')"
            ></b-form-input>
          </b-form-group>
        </span>
      </b-col>

      <slot name="additionalContent"> </slot>

      <b-col cols="0" class="pr-0 mr-0 pl-0 ml-2">
        <b-form-group
          :label="$t('applicationDetail.schedules.disabled')"
          v-if="showDisableButton"
          class="float-right"
        >
          <b-form-checkbox
            class="align-bottom"
            type="checkbox"
            v-model="disabled"
          />
        </b-form-group>
      </b-col>

      <b-col cols="0" class="pr-0 mr-0 pl-0 ml-2">
        <b-form-group
          v-if="showAddButton"
          :label="$t('applicationDetail.schedules.cancel')"
          class="float-right"
        >
          <b-button
            data-test="btn_cancelSchedule"
            @click="handleSubmit(requestCloseFields())"
            variant="outline-secondary"
          >
            {{ $t("applicationDetail.schedules.cancel") }}
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="0" class="pr-1 mr-0 pl-0 ml-2">
        <b-form-group
          :label="$t('applicationDetail.schedules.addAnother')"
          v-if="showAddButton"
          class="float-right"
        >
          <b-button
            @click="
              handleSubmit((__) => {
                requestAddSchedule();
                requestInitializeSchedule();
              })
            "
            data-test="btn_addScheduleAndNew"
            variant="outline-secondary"
          >
            <font-awesome-icon icon="plus" />
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="0" class="pr-1 mr-0 pl-0 ml-2">
        <ValidationProvider
          name="submitForm"
          :rules="{
            noOverLappingSchedules: [
              componentVariables.schedule,
              actualSchedules,
            ],
          }"
          v-slot="{ errors, valid }"
          v-bind:skipIfEmpty="false"
        >
          <b-form-group
            :label="$t('applicationDetail.schedules.save')"
            class="float-right"
            v-if="showAddButton"
            :invalid-feedback="errors[0]"
            :state="errors[0] ? false : valid ? true : null"
          >
            <b-button
              @click="
                handleSubmit((__) => {
                  requestAddSchedule();
                  requestCloseFields();
                })
              "
              data-test="btn_addScheduleAndClose"
              variant="outline-secondary"
            >
              <font-awesome-icon icon="save" />
            </b-button>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-form>
  </ValidationObserver>
</template>
<script>
import constants from "../../EndPointsAndConstants.js";
import { DateTime } from "luxon";
import Vue from "vue";

export default {
  name: "ScheduleForm",
  props: {
    showDisableButton: { type: Boolean, default: () => true },
    showIgnoreYearButton: { type: Boolean, default: () => false },
    showAddButton: { type: Boolean, default: () => true },
    scheduleType: { type: Number, required: true },
    actualSchedules: { type: Array, required: false },
    schedule: {
      type: Object,
      default: () => ({
        startDate: null,
        endDate: null,
        recurring: null,
        startTime: null,
        endTime: null,
        fk_DayOfWeek: null,
        fk_calendar: null,
        description: "",
        disabled: null,
      }),
    },
  },
  data() {
    return {
      DateTime: DateTime,
      date: null,
      firstTimeSet: null,
      firstDateSet: null,
      componentVariables: {
        schedule: null,
        timeformat: "yyyy-MM-dd HH:mm:ss.SSSZZ",
        constants: constants,
      },
    };
  },
  created() {
    this.componentVariables.schedule = Object.assign({}, this.schedule);
  },
  mounted() {
    this.firstTimeSet = true;
    this.firstDateSet = true;
  },
  computed: {
    fk_DayOfWeek: {
      get() {
        return this.componentVariables.schedule.fk_DayOfWeek;
      },
      set(value) {
        this.componentVariables.schedule.fk_DayOfWeek = value;
        this.changed();
      },
    },
    orgType: {
      get() {
        let internalOrgType = this.componentVariables.schedule.orgType;
        if (!internalOrgType) {
          internalOrgType = false;
        }
        return internalOrgType;
      },
    },
    startDate: {
      get() {
        return this.componentVariables.schedule.startDate;
      },
      set(value) {
        this.componentVariables.schedule.startDate = value;
        this.changed();
      },
    },
    endDate: {
      get() {
        return this.componentVariables.schedule.endDate;
      },
      set(value) {
        this.componentVariables.schedule.endDate = value;
        this.changed();
      },
    },
    startTime: {
      get() {
        return this.componentVariables.schedule.startTime;
      },
      set(value) {
        this.componentVariables.schedule.startTime = value;
        this.changed();
      },
    },
    endTime: {
      get() {
        return this.componentVariables.schedule.endTime;
      },
      set(value) {
        this.componentVariables.schedule.endTime = value;
        this.changed();
      },
    },

    description: {
      get() {
        return this.componentVariables.schedule.description;
      },
      set(value) {
        this.componentVariables.schedule.description = value;
        this.changed();
      },
    },

    disabled: {
      get() {
        return this.componentVariables.schedule.disabled;
      },
      set(value) {
        this.componentVariables.schedule.disabled = value;
        this.changed();
      },
    },

    recurring: {
      get: function () {
        Vue.$log.debug("recurring was retrieved");
        return this.componentVariables.schedule.recurring;
      },
      set: function (value) {
        Vue.$log.debug("recurring was set to ", value);
        this.componentVariables.schedule.recurring = value;
        this.changed();
      },
    },
  },
  methods: {
    handleStartTime(eventData) {
      Vue.$log.debug("setting: ", eventData);
      if (eventData.length > 5) {
        let tempStartTime = DateTime.fromFormat(
          eventData.toString(),
          "HH:mm:ss"
        );
        if (
          (this.firstTimeSet &&
            this.componentVariables.schedule.endTime === null) ||
          this.componentVariables.schedule.endTime === undefined
        ) {
          let tempEndTime = DateTime.fromFormat(
            eventData.toString(),
            "HH:mm:ss"
          );
          tempEndTime = tempEndTime.plus({ minutes: 30 });
          if (tempEndTime !== null || tempEndTime !== undefined) {
            this.componentVariables.schedule.endTime =
              tempEndTime.toFormat("HH:mm");
            this.firstTimeSet = false;
          } else {
          }
        }
        this.componentVariables.schedule.startTime =
          tempStartTime.toFormat("HH:mm");
      }
    },
    handleEndTime(eventData) {
      if (eventData.length > 5) {
        let tempEndTime = DateTime.fromFormat(eventData.toString(), "HH:mm:ss");
        this.componentVariables.schedule.endTime =
          tempEndTime.toFormat("HH:mm");
      }
    },
    getHourMinute(dateTimeString) {
      if (!dateTimeString || (dateTimeString && dateTimeString.length === 0)) {
        return dateTimeString;
      }

      Vue.$log.debug(dateTimeString);

      // dateTimeString=dateTimeString.replace("Z","");
      dateTimeString = dateTimeString.replace("T", " ");
      let time = DateTime.fromFormat(
        dateTimeString,
        this.componentVariables.timeformat
      );
      let timeString = time.toFormat("HH:mm");
      Vue.$log.debug(timeString);
      return timeString;
    },
    handleStartDate(eventData) {
      Vue.$log.debug("eventData for handlestartdate: ", eventData);

      this.startDate = eventData;
      if (this.firstDateSet) {
        this.endDate = eventData;
        this.firstDateSet = false;
      }
    },
    handleEndDate(eventData) {
      this.componentVariables.schedule.endDate = this.getDate(eventData);
    },
    getDate(dateTimeString) {
      Vue.$log.debug("dateTimeString:", dateTimeString);
      if (!dateTimeString || (dateTimeString && dateTimeString.length === 0)) {
        return dateTimeString;
      }
      // dateTimeString=dateTimeString.replace("Z","");
      dateTimeString = dateTimeString.replace("T", " ");
      let date = DateTime.fromFormat(
        dateTimeString,
        this.componentVariables.timeformat
      );
      let dateString = date.toFormat("yyyy-MM-dd");
      Vue.$log.debug(dateString);
      return dateString;
    },
    requestAddSchedule() {
      this.$emit("AddSchedule", this.componentVariables.schedule);
    },
    requestCloseFields() {
      this.$emit("CloseFields");
    },
    requestInitializeSchedule() {
      this.$emit("InitializeSchedule");
    },
    changed() {
      Vue.$log.debug("Changed hit: ", this.componentVariables.schedule);
      this.$emit("updated", this.componentVariables.schedule);
    },
    weekDayOptions() {
      return constants.WEEK_DAYS;
    },
    dateFormat(value) {
      Vue.$log.debug(
        "get dateformat",
        this.componentVariables.schedule.recurring
      );
      if (this.componentVariables.schedule.recurring) {
        return DateTime.fromFormat(value.toString(), "yyyy-MM-dd")
          .toFormat("MM-dd")
          .toString();
      } else {
        return DateTime.fromFormat(value.toString(), "MM-dd")
          .toFormat("yyyy-MM-dd")
          .toString();
      }
    },
  },
};
</script>
