<template>
  <b-card class="no-side-margin no-side-padd" no-body>
    <b-tabs
      data-test="tabs"
      ref="applicationTabs"
      active-nav-item-class="font-weight-bold text-uppercase text-primary"
      active-tab-class="font-weight-bold text-success"
      card
      v-if="this.pageVariables.loadedApplication === true"
      v-on:activate-tab="changeTabCheck"
      v-model="pageVariables.currentTab"
    >
      <b-tab
        :title="
          $t('applicationDetail.application') +
          ': ' +
          this.pageVariables.applicationDisplayName
        "
        disabled
      ></b-tab>
      <b-tab
        id="tab_schedule"
        lazy
        :title="$t('applicationDetail.schedulesTab')"
      >
        <b-row class="mb-3" data-test="default_schedule">
          <b-col>
            <b-card-header
              header-bg-variant="secondary"
              header-text-variant="white"
              class="d-flex justify-content-between"
              data-test="weekly-header"
            >
              <b-row class="ml-1 d-flex align-items-center">
                {{ $t("applicationDetail.schedules.defaultScheduleTitle") }}
              </b-row>
              <b-row class="mt-0 mb-0 pt-0 pb-0 mr-2">
                <b-form-input
                  data-test="activeDate"
                  id="activeDate"
                  v-model="pageVariables.activeSchedule.date"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  class="pr-1 mt-0 mb-0 pt-0 pb-0"
                  style="width: 8em"
                  data-ignore-change
                />
                <b-form-input
                  data-test="activeTime"
                  id="activeTime"
                  v-model="pageVariables.activeSchedule.time"
                  type="text"
                  placeholder="HH:mm"
                  class="pr-1 mt-0 mb-0 pt-0 pb-0"
                  style="width: 8em"
                  data-ignore-change
                />
                <b-button
                  data-test="checkSchedule"
                  class="bg-primary"
                  @click="markActive()"
                  data-ignore-change
                >
                  <font-awesome-icon icon="search" />
                </b-button>
                <b-button
                  data-test="scheduleLegend"
                  variant="primary"
                  class="pb-1 pt-1 ml-1"
                  @click="$bvModal.show('legendModal'); $forceUpdate();"
                ><font-awesome-icon icon="book" /></b-button>
                <b-modal  id="legendModal"
                          :title='$t("applicationDetail.schedules.legendTitle")'
                          hide-header-close
                          hide-footer>
                  <p class="activeLegend mb-0">{{ $t("applicationDetail.schedules.activeLegend") }}</p>
                  <p class="nonHitLegend mb-0">{{ $t("applicationDetail.schedules.nonHitLegend") }}</p>
                  <p class="disabledLegend mb-0">{{ $t("applicationDetail.schedules.disabledLegend") }}</p>
                  <p class="orgLegend mb-0">{{ $t("applicationDetail.schedules.orgLegend") }}</p>
                  <p class="activeOrgLegend mb-0">{{ $t("applicationDetail.schedules.activeOrgLegend") }}</p>
                  <p class="disabledOrgLegend mb-0">{{ $t("applicationDetail.schedules.disabledOrgLegend") }}</p>
                </b-modal>
              </b-row>
            </b-card-header>
            <b-card border-variant="secondary" class="shadow">
              <b-row>
                <b-col>
                  <scheduleTable
                    :all-applications-as-options="
                      this.pageVariables.allApplicationsAsOptions
                    "
                    :all-menus-as-options="this.pageVariables.allMenusAsOptions"
                    :all-scripts-as-options="
                      this.pageVariables.allScriptsAsOptions
                    "
                    :application="application"
                    :fields="this.pageVariables.defaultTableFields"
                    :schedule-type="this.pageVariables.scheduleTypeWeek"
                    :schedules="
                      this.defaultAction(
                        this.getSelectedApplication().actionOnNoHit
                      )
                    "
                    :hideDetailsColumn="true"
                    id="defaultScheduleTable"
                    ref="defaultScheduleTable"
                  >
                  </scheduleTable>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="mb-3" data-test="weekly_schedule">
          <b-col>
            <b-card-header
              header-bg-variant="secondary"
              header-text-variant="white"
              class="d-flex justify-content-between"
              data-test="weekly-header"
            >
              {{ $t("applicationDetail.schedules.weeklyScheduleTitle") }}
            </b-card-header>
            <b-card
              border-variant="secondary"
              class="shadow"
              :body-class="'pt-0'"
            >
              <b-row class="pt-1">
                <b-col>
                  <scheduleTable
                    @AddActionSchedule="
                      (schedule) => {
                        addSchedule({ vm: this, schedule: schedule }).then(
                          (__) => {
                            this.getActiveScheduleAtTimestamp(
                              this.pageVariables.markActiveTime
                            );
                            this.setUnsavedChanges(false);
                          }
                        );
                      }
                    "
                    @AddActionScheduleBulk="
                      (days, schedule) => {
                        addWeeklyScheduleBulk({vm: this, days: days, schedule: schedule}).then(
                            (__) => {
                              this.getActiveScheduleAtTimestamp(
                                this.pageVariables.markActiveTime
                              );
                              this.setUnsavedChanges(false);
                            }
                        )
                      }
                    "
                    :templateItem="this.pageVariables.weeklyScheduleTemplate"
                    :all-applications-as-options="
                      this.pageVariables.allApplicationsAsOptions
                    "
                    :all-menus-as-options="this.pageVariables.allMenusAsOptions"
                    :all-scripts-as-options="
                      this.pageVariables.allScriptsAsOptions
                    "
                    :application="application"
                    :fields="this.pageVariables.weeklyScheduleTableFields"
                    :schedule-type="this.pageVariables.scheduleTypeWeek"
                    :schedules="this.getWeeklySchedules"
                    id="weeklyScheduleTable"
                    ref="weeklyScheduleTable"
                    :edit-mode="this.editMode"
                    @editMode="
                      (state) => {
                        changeMode(state);
                      }
                    "
                    :allowBulk="true"
                  >
                  </scheduleTable>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mb-3" data-test="special_schedule">
          <b-col>
            <b-card-header
              header-bg-variant="secondary"
              header-text-variant="white"
              class="d-flex justify-content-between"
              data-test="special-header"
            >
              {{ $t("applicationDetail.schedules.specialScheduleTitle") }}
            </b-card-header>
            <b-card
              border-variant="secondary"
              class="shadow"
              :body-class="'pt-0'"
            >
              <b-row class="pt-1">
                <b-col>
                  <scheduleTable
                    @AddActionSchedule="
                      (schedule) => {
                        addSchedule({ vm: this, schedule: schedule }).then(
                          (__) => {
                            this.getActiveScheduleAtTimestamp(
                              this.pageVariables.markActiveTime
                            );
                            this.setUnsavedChanges(false);
                          }
                        );
                      }
                    "
                    :templateItem="this.pageVariables.specialScheduleTemplate"
                    :all-applications-as-options="
                      this.pageVariables.allApplicationsAsOptions
                    "
                    :all-menus-as-options="this.pageVariables.allMenusAsOptions"
                    :all-scripts-as-options="
                      this.pageVariables.allScriptsAsOptions
                    "
                    :application="application"
                    :fields="this.pageVariables.specialScheduleTableFields"
                    :schedule-type="this.pageVariables.scheduleTypeSpecial"
                    :schedules="this.getSpecialSchedules"
                    :v-if="this.pageVariables.loadedSchedules"
                    id="specialScheduleTable"
                    ref="specialScheduleTable"
                    :edit-mode="this.editMode"
                    @editMode="
                      (state) => {
                        changeMode(state);
                      }
                    "
                  >
                  </scheduleTable>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab
        id="tab_general_settings"
        data-test="tab_general_settings"
        lazy
        :title="$t('applicationDetail.generalSettingsTab')"
      >
        <ApplicationGeneralSettings @updateApplicationTitle="pageVariables.applicationDisplayName = application.displayName"></ApplicationGeneralSettings>
      </b-tab>

      <b-tab
        id="tab_adhoc_prompt"
        data-test="tab_adhoc_prompt"
        lazy
        :title="$t('applicationDetail.adhocTab')"
      >
        <Adhoc
          :application-id="this.getSelectedApplicationID()"
          v-if="this.pageVariables.loadedApplication"
        />
      </b-tab>
      <b-tab
        id="tab_custom_parameters"
        lazy
        :title="$t('applicationDetail.customParametersTab')"
      >
        <applicationParameters />
      </b-tab>
    </b-tabs>
    <b-container
      class="text-center bg-primary w-100"
      fluid
      style="height: 90vh"
      v-else
    >
      <b-row
        align-v="center"
        class="h-100"
        v-if="
          pageVariables.loadedApplication === false &&
          pageVariables.loadedApplicationFailed !== true
        "
      >
        <b-col
          style="display: flex; align-items: center; justify-content: center"
        >
          <b-spinner
            class="mb-3 text-white"
            label=" Loading..."
            style="width: 5rem; height: 5rem"
          ></b-spinner>
        </b-col>
      </b-row>
      <b-row
        align-v="center"
        class="h-100 w-100 text-white font-weight-bold"
        style="display: flex; align-items: center; justify-content: center"
        v-if="
          pageVariables.loadedApplicationFailed === true &&
          pageVariables.loadedApplication === false
        "
        >FAILED TO LOAD APPLICATION
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import constants from "../EndPointsAndConstants.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ActionScheduleForm from "../components/application/ActionScheduleForm";
import ApplicationGeneralSettings from "../components/application/ApplicationGeneralSettings";
import { getDayName, populateOptions, openUnsavedModal } from "../Helper.js";
import Adhoc from "../components/application/Adhoc";
import { DateTime } from "luxon";
import EndPointsAndConstants from "../EndPointsAndConstants.js";

Vue.component(
  "scheduleTable",
  require("../components/application/ScheduleTable.vue").default
);
Vue.component(
  "applicationParameters",
  require("../components/application/ApplicationParameters.vue").default
);
export default {
  components: { Adhoc, ApplicationGeneralSettings },
  props: { applicationId: { type: Number } },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Application",
  mixins: [],
  data() {
    return {
      variablesForRest: {
        weeklySchedule: {
          action: {
            prompts: [],
          },
          description: "",
        },
        specialSchedule: {
          action: {
            prompts: [],
          },
          description: "",
        },
      },
      pageVariables: {
        specialScheduleTemplate: {
          startDate: null,
          endDate: null,
          recurring: null,
          startTime: null,
          endTime: null,
          description: null,
          disabled: false,
          action: {
            useDefault: false,
            value: null,
            prompts: [],
            fk_ActionType: null,
            fk_Application: null,
            fk_Script: null,
            fk_Menu: null,
          },
          fk_Calendar: null,
          fk_DayOfWeek: null,
        },
        weeklyScheduleTemplate: {
          startDate: null,
          endDate: null,
          recurring: null,
          startTime: null,
          endTime: null,
          description: null,
          disabled: false,
          action: {
            useDefault: false,
            value: null,
            prompts: [],
            fk_ActionType: null,
            fk_Application: null,
            fk_Script: null,
            fk_Menu: null,
          },
          fk_Calendar: null,
          fk_DayOfWeek: null,
        },
        applicationAdhocPrompt: {
          prompts: null,
          type: null,
          fromDate: null,
          fromTime: null,
          endDate: null,
          endTime: null,
        },
        application: null,
        currentTab: 1,
        trigger: "",
        defaultTableFields: [
          {
            key: "openOrClose",
            label: this.$t("applicationDetail.schedules.type"),
            thStyle: "width: 10%",
          },
        ],
        weeklyScheduleTableFields: [
          {
            key: "fk_DayOfWeek",
            label: this.$t("applicationDetail.schedules.day"),
            thStyle: "width: 10%",
          },
          {
            key: "startTime",
            label: this.$t("applicationDetail.schedules.startTime"),
            thStyle: "width: 8%",
            formatter: this.timeFormatter,
          },
          {
            key: "endTime",
            label: this.$t("applicationDetail.schedules.endTime"),
            thStyle: "width: 8%",
            formatter: this.timeFormatter,
          },
          {
            key: "description",
            label: this.$t("applicationDetail.schedules.description"),
            thStyle: "width: 20%",
          },
        ],
        specialScheduleTableFields: [
          {
            key: "startDate",
            label: this.$t("applicationDetail.schedules.startDate"),
            thStyle: "width: 8%",
          },
          {
            key: "startTime",
            label: this.$t("applicationDetail.schedules.startTime"),
            thStyle: "width: 8%",
            formatter: this.timeFormatter,
          },
          {
            key: "endDate",
            label: this.$t("applicationDetail.schedules.endDate"),
            thStyle: "width: 8%",
          },
          {
            key: "endTime",
            label: this.$t("applicationDetail.schedules.endTime"),
            thStyle: "width: 8%",
            formatter: this.timeFormatter,
          },
          {
            key: "recurring",
            label: this.$t("applicationDetail.schedules.ignoreYear"),
            thStyle: "width: 7%",
          },
          {
            key: "description",
            label: this.$t("applicationDetail.schedules.description"),
            thStyle: "width: 10%",
          },
        ],
        constants: constants,
        scheduleTypeWeek: constants.SCHEDULE_TYPES.WEEKLY,
        scheduleTypeSpecial: constants.SCHEDULE_TYPES.SPECIAL,
        defaultNoHitOptions: [
          { value: false, text: "Closed" },
          { value: true, text: "Open" },
        ],
        allApplicationsAsOptions: [],
        allOrganizationsAsOptions: [],
        allScriptsAsOptions: [],
        allMenusAsOptions: [],
        scheduleHolder: {},
        loadedApplication: false,
        loadedApplicationFailed: false,
        loadedSchedules: false,
        markActiveTime: null,
        editSchedule: false,
        activeSchedule: {
          time: DateTime.now().toFormat("HH:mm"),
          date: DateTime.now().toFormat("yyyy-MM-dd"),
        },
        defaultAction: [],
        applicationDisplayName: ""
      },
    };
  },
  computed: {
    editMode: {
      get: function () {
        console.log("EDIT MODE");
        console.log(this.pageVariables.editSchedule);
        return this.pageVariables.editSchedule;
      },
      set: function (value) {
        this.pageVariables.editSchedule = value;
      },
    },
    application: {
      get() {
        return this.getSelectedApplication();
      },
      set(value) {
        this.SET_SELECTED_APPLICATION(value);
      },
    },
    activeDateTime() {
      console.log(
        "Getting Marking: " +
          DateTime.fromFormat(
            this.pageVariables.activeSchedule.date +
              this.pageVariables.activeSchedule.time,
            "yyyy-MM-ddHH:mm"
          )
            .toFormat("yyyy-MM-dd-HH:mm")
            .toString()
      );
      return DateTime.fromFormat(
        this.pageVariables.activeSchedule.date +
          " " +
          this.pageVariables.activeSchedule.time,
        "yyyy-MM-dd HH:mm"
      ).toSeconds();
    },
    ...mapGetters("application", ["getSpecialSchedules", "getWeeklySchedules"]),
  },
  methods: {
    ...mapMutations("application", [
      "SET_SELECTED_APPLICATION",
      "SET_CLOSED_ACTION",
      "SET_OPEN_ACTION",
    ]),
    ...mapGetters("application", [
      "getSelectedApplication",
      "getSelectedApplicationID",
      "getOrganizationApplication",
    ]),
    ...mapActions("applications", ["fetchApplications"]),
    ...mapActions("application", [
      "getActiveScheduleNow",
      "getActiveScheduleAtTimestamp",
      "fetchSelectedApplication",
      "fetchApplicationOrganization",
      "saveApplication",
      "savePrompts",
      "removeTrigger",
      "addTrigger",
      "addSchedule",
      "addWeeklyScheduleBulk",
      "fetchSchedulesForApplication",
      "fetchAdhocForApplication",
    ]),
    ...mapGetters("changes", ["getUnsavedChanges"]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    ...mapActions("toaster", ["removeToast"]),
    dayFormatter(value, key, item) {
      return getDayName(this.$i18n.locale, value);
    },
    markActive() {
      Vue.$log.debug("marking - ", this.activeDateTime);
      Vue.$log.debug("marking - ", DateTime.fromSeconds(this.activeDateTime));
      Vue.$log.debug("marking - ", this.pageVariables.activeSchedule.date);
      Vue.$log.debug("marking - ", this.pageVariables.activeSchedule.time);
      this.pageVariables.markActiveTime = this.activeDateTime;
      Vue.$log.debug("marking - ", this.pageVariables.markActiveTime);
      this.getActiveScheduleAtTimestamp(this.pageVariables.markActiveTime);
    },
    updateSchedules() {
      Vue.$log.debug("update schedules");
      this.pageVariables.loadedApplication = false;
      this.fetchSchedulesForApplication().then((__) => {
        this.pageVariables.loadedApplication = true;
      });
    },
    updateApplication() {
      if (this.getUnsavedChanges() === false) {
        this.pageVariables.loadedApplication = false;
        this.fetchSelectedApplication(this.applicationId);
        this.pageVariables.loadedApplication = true;
      }
    },
    defaultAction(actionOnNoHit) {
      let array = [];
      let object = {};
      if (actionOnNoHit === true) {
        object = {
          id: this.getSelectedApplication().actionOnTrue.id,
          useDefault: this.getSelectedApplication().actionOnTrue.useDefault,
          openOrClose: this.$t("applicationDetail.schedules.open"),
          action: {
            value: this.getSelectedApplication().actionOnTrue.value,
            fk_Application:
              this.getSelectedApplication().actionOnTrue.fk_Application,
            fk_Script: this.getSelectedApplication().actionOnTrue.fk_Script,
            fk_Menu: this.getSelectedApplication().actionOnTrue.fk_Menu,
            fk_ActionType:
              this.getSelectedApplication().actionOnTrue.fk_ActionType,
            prompts: this.getSelectedApplication().actionOnTrue.prompts,
          },
        };
        array.push(object);
        this.$log.debug(this.getSelectedApplication().actionOnTrue);
      } else {
        object = {
          id: this.getSelectedApplication().actionOnFalse.id,
          useDefault: this.getSelectedApplication().actionOnFalse.useDefault,
          openOrClose: this.$t("applicationDetail.schedules.close"),
          action: {
            value: this.getSelectedApplication().actionOnFalse.value,
            fk_Application:
              this.getSelectedApplication().actionOnFalse.fk_Application,
            fk_Script: this.getSelectedApplication().actionOnFalse.fk_Script,
            fk_Menu: this.getSelectedApplication().actionOnFalse.fk_Menu,
            fk_ActionType:
              this.getSelectedApplication().actionOnFalse.fk_ActionType,
            prompts: this.getSelectedApplication().actionOnFalse.prompts,
          },
        };
        array.push(object);
        console.log(
          JSON.stringify(this.getSelectedApplication().actionOnFalse)
        );
      }
      return array;
    },
    changeTabCheck(nextTab, previousTab, event) {
      this.$log.debug("This is unsaved: ", this.getUnsavedChanges());
      if (this.getUnsavedChanges()) {
        event.preventDefault();
        this.$parent.$refs.leaveModal.show();
        this.$nextTick(() => {
          this.$parent.$refs.leaveModal.$once("hide", (bvEvt) => {
            if (bvEvt.trigger === "ok") {
              this.$parent.$refs.leaveModal.$once("hidden", () => {

                if(nextTab == 1){
                  this.updateSchedules();
                }

                this.changeMode(false);
                this.pageVariables.currentTab = nextTab;
                this.fetchSelectedApplication(this.getSelectedApplicationID());
                this.setUnsavedChanges(false);
              });
            } else {
              this.pageVariables.currentTab = previousTab;
            }
          });
        });
      } else {
        this.pageVariables.currentTab = nextTab;
        this.changeMode(false);
        if(nextTab == 1){
          this.updateSchedules();
        }
      }
    },
    savedChanges() {
      // TODO If this list of schedules is equal to the one saved in vuex, set unchanged to false
      // TODO Else set to true
      // this.pageVariables.unsavedChanges = bool
      this.removeToast();
    },
    timeFormatter(value, key, item) {
      if (value !== null) {
        let tempTime = DateTime.fromFormat(value, "HH:mm:ss");
        return tempTime.toFormat("HH:mm");
      }
      return "";
    },
    changeMode(state) {
      console.log("Change Mode");
      console.log(state);
      this.editMode = state;
    },
  },
  created: function () {
    this.pageVariables.application = null;
    this.setUnsavedChanges(false);
    this.fetchSelectedApplication(this.applicationId).then((__) => {
      this.pageVariables.application = this.getSelectedApplication();

      Promise.all([
        this.fetchApplications(),
        this.fetchSchedulesForApplication(),
        this.fetchApplicationOrganization(),
      ])
        .then((__) => {
          this.markActive();
          this.pageVariables.loadedApplication = true;
        })
        .catch((__) => {
          this.pageVariables.loadedApplicationFailed = true;
        });

      this.pageVariables.allApplicationsAsOptions.push({
        value: null,
        text: this.$t("applicationDetail.action.dropdownApplicationDescriptor"),
        disabled: true,
      });
      populateOptions(
        constants.endPoints.APPLICATIONS_BASE,
        this.pageVariables.allApplicationsAsOptions,
        this.applicationId
      );
      this.pageVariables.allMenusAsOptions.push({
        value: null,
        text: this.$t("applicationDetail.action.dropdownMenuDescriptor"),
        disabled: true,
      });
      populateOptions(
        constants.endPoints.MENUS_BASE,
        this.pageVariables.allMenusAsOptions,
        null
      );
      this.pageVariables.allScriptsAsOptions.push({
        value: null,
        text: this.$t("applicationDetail.action.dropdownScriptDescriptor"),
        disabled: true,
      });
      populateOptions(
        constants.endPoints.SCRIPTS_BASE,
        this.pageVariables.allScriptsAsOptions,
        null
      );
      this.pageVariables.applicationDisplayName = this.application.displayName;
    });
  },
};
</script>
