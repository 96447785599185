var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.pageVariables.showContent)?_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"active-nav-item-class":"font-weight-bold text-uppercase text-primary","active-tab-class":"font-weight-bold text-success","card":""}},[_c('b-tab',{attrs:{"active":"","lazy":"","title":_vm.$t('parameterDetail.title')}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('b-card',{staticClass:"shadow",attrs:{"no-body":""}},[_c('b-card-header',[_vm._v(_vm._s(_vm.$t("parameterDetail.choiceTitle")))]),_c('b-card-body',[_c('b-form-select',{staticClass:"text-capitalize",attrs:{"data-test":"select-parameter-type","disabled":!this.create},on:{"change":function($event){return _vm.resetParameterSelectionArray()}},model:{value:(_vm.componentType),callback:function ($$v) {_vm.componentType=$$v},expression:"componentType"}},_vm._l((this.CUSTOM_PARAMETERS_KEY_VALUE()),function(option,idx){return _c('option',{key:idx,staticClass:"text-capitalize",attrs:{"title":option.title || null},domProps:{"value":option.value.name}},[_vm._v(" "+_vm._s(_vm.$t("parameterDetail.choice." + option.text))+" ")])}),0)],1)],1)],1)],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var this$1 = this;
var handleSubmit = ref.handleSubmit;
return [(_vm.configuration)?_c('b-row',[_c('b-col',[_c('b-card',{staticClass:"shadow",attrs:{"no-body":""}},[_c('b-card-header',[_vm._v(_vm._s(_vm.$t("parameterDetail.fieldsTitle")))]),_c('b-card-body',[_c('b-form',{on:{"submit":function (event) { return event.preventDefault(); }}},[_c('validation-provider',{attrs:{"rules":'required|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"description":_vm.$t('parameterDetail.paramNameDescription'),"id":"input-group-1","label":_vm.$t('parameterDetail.paramName'),"label-for":"input-1"}},[_c('b-form-input',{attrs:{"data-test":"text-parameter-name","debounce":"100","id":"input-1","placeholder":_vm.$t('parameterDetail.paramNameField'),"required":"","type":"text","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('b-form-invalid-feedback',{attrs:{"tag":"span"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":'required|max:' + _vm.pageVariables.fieldLength},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var touched = ref.touched;
return [_c('b-form-group',{attrs:{"description":_vm.$t('parameterDetail.paramDisplayNameDescription'),"id":"input-group-2","label":_vm.$t('parameterDetail.paramDisplayName'),"label-for":"input-2"}},[_c('b-form-input',{attrs:{"data-test":"text-parameter-displayname","debounce":"100","id":"input-2","placeholder":_vm.$t('parameterDetail.paramDisplayNameField'),"required":"","type":"text","state":errors[0] ? false : valid && touched ? true : null},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),_c('b-form-invalid-feedback',{attrs:{"tag":"span"}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(
                      _vm.configuration &&
                      _vm.configuration.type &&
                      _vm.configuration.valueType ===
                        _vm.pageVariables.PARAMETER_TYPES.BOOLEAN
                    )?_c('tags-selector',{attrs:{"multiple-selection":_vm.configuration.multi,"options":_vm.parameterOptionAvailable,"selection":_vm.parameterOptionDefault,"title":_vm.$t('parameterDetail.paramDefaultOptionTitle'),"data-test":"DefaultOption"},on:{"addedTag":function (option) { return _vm.addDefaultOption(option); },"removedTag":function (option) { return this$1 - _vm.removeDefaultOption(option); }}}):_vm._e(),(
                      (_vm.configuration &&
                        _vm.configuration.type &&
                        _vm.configuration.type ===
                          _vm.pageVariables.PARAMETER_RENDER_TYPES.FLOAT) ||
                      _vm.configuration.type ===
                        _vm.pageVariables.PARAMETER_RENDER_TYPES.INTEGER ||
                      _vm.configuration.type ===
                        _vm.pageVariables.PARAMETER_RENDER_TYPES.TEXT
                    )?_c('div',[_c('b-form-group',{attrs:{"description":_vm.$t('parameterDetail.paramDefaultDescription'),"id":"input-group-3","label":_vm.$t('parameterDetail.paramDefaultVal'),"label-for":"input-3"}},[_c('b-form-input',{attrs:{"data-test":"defaultValue","debounce":"100","id":"input-3","placeholder":_vm.$t('parameterDetail.paramDefaultField'),"type":"text"},model:{value:(_vm.defaultValue),callback:function ($$v) {_vm.defaultValue=$$v},expression:"defaultValue"}})],1)],1):_vm._e(),(_vm.configuration && _vm.configuration.specify)?_c('div',[(
                        _vm.configuration.valueType ===
                        _vm.pageVariables.PARAMETER_TYPES.APPLICATION
                      )?_c('tags-selector',{attrs:{"options":_vm.getAvailableParameterOptions(),"selection":_vm.parameterOptionAvailable,"title":"Applications"},on:{"addedTag":function (option) { return _vm.addAvailableOption(option); },"removedTag":function (option) { return _vm.removeAvailableOption(option); }}}):_vm._e(),(
                        _vm.configuration.valueType ===
                        _vm.pageVariables.PARAMETER_TYPES.CALENDAR
                      )?_c('tags-selector',{attrs:{"options":_vm.getAvailableParameterOptions(),"selection":_vm.parameterOptionAvailable,"title":"Calendar"},on:{"addedTag":function (option) { return _vm.addAvailableOption(option); },"removedTag":function (option) { return _vm.removeAvailableOption(option); }}}):_vm._e(),(
                        _vm.configuration.valueType ===
                        _vm.pageVariables.PARAMETER_TYPES.MENU
                      )?_c('tags-selector',{attrs:{"options":_vm.getAvailableParameterOptions(),"selection":_vm.parameterOptionAvailable,"title":"Menu"},on:{"addedTag":function (option) { return _vm.addAvailableOption(option); },"removedTag":function (option) { return _vm.removeAvailableOption(option); }}}):_vm._e(),(
                        _vm.configuration.valueType ===
                        _vm.pageVariables.PARAMETER_TYPES.SCRIPT
                      )?_c('tags-selector',{attrs:{"options":_vm.getAvailableParameterOptions(),"selection":_vm.parameterOptionAvailable,"title":"Script"},on:{"addedTag":function (option) { return _vm.addAvailableOption(option); },"removedTag":function (option) { return _vm.removeAvailableOption(option); }}}):_vm._e(),_c('tags-selector',{staticClass:"border-dark",attrs:{"multiple-selection":_vm.configuration.multi,"options":_vm.parameterOptionAvailable,"selection":_vm.parameterOptionDefault,"title":_vm.$t('parameterDetail.paramDefaultOptionsTitle'),"data-test":"DefaultOption"},on:{"addedTag":function (option) { return _vm.addDefaultOption(option); },"removedTag":function (option) { return this$1 - _vm.removeDefaultOption(option); }}}),(
                        _vm.configuration.valueType ===
                        _vm.pageVariables.PARAMETER_TYPES.CUSTOM
                      )?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('parameterDetail.custDrop.customOptions')}},[(
                            _vm.parameterOptionAvailable &&
                            _vm.parameterOptionAvailable.length > 0
                          )?_c('ul',{staticClass:"list-inline d-inline-block mb-2"},_vm._l((_vm.parameterOptionAvailable),function(option){return _c('li',{key:option.id,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":option.name,"variant":"info"},on:{"remove":function($event){return _vm.removeCustomOption(option)}}},[_vm._v(_vm._s(option.name)),_c('br'),_vm._v(" "+_vm._s(option.displayName)+" ")])],1)}),0):_vm._e(),_c('b-card',[_c('b-form-group',{attrs:{"id":"fieldset-option-id","label":_vm.$t('parameterDetail.custDrop.nameTitle'),"label-for":"optionName"}},[_c('b-form-input',{attrs:{"data-test":"optionName","id":"optionName","trim":""},model:{value:(_vm.pageVariables.parameter.optionName),callback:function ($$v) {_vm.$set(_vm.pageVariables.parameter, "optionName", $$v)},expression:"pageVariables.parameter.optionName"}})],1),_c('b-form-group',{attrs:{"data-test":"optionDisplayName","id":"fieldset-option-text","label":_vm.$t('parameterDetail.custDrop.displayNameTitle'),"label-for":"optionText"}},[_c('b-form-input',{attrs:{"data-test":"optionText","id":"optionText","trim":""},model:{value:(_vm.pageVariables.parameter.optionText),callback:function ($$v) {_vm.$set(_vm.pageVariables.parameter, "optionText", $$v)},expression:"pageVariables.parameter.optionText"}})],1),_c('b-button',{attrs:{"data-test":"addCustomOption"},on:{"click":function($event){return _vm.addCustomOption()}}},[_vm._v(_vm._s(_vm.$t("parameterDetail.custDrop.addCustBtn"))+" ")])],1)],1)],1):_vm._e()],1):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mr-0 mt-1",attrs:{"align-h":"end"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","data-test":"btn-addOrSave"},on:{"click":function($event){handleSubmit(function (__) {
                    _vm.requestSaveParameter();
                  })}}},[(_vm.create)?[_vm._v(_vm._s(_vm.$t("parameterDetail.addParamBtn")))]:[_vm._v(_vm._s(_vm.$t("parameterDetail.updateParamBtn")))]],2),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.gotoParametersPage()}}},[_vm._v(_vm._s(_vm.$t("parameterGroupEdit.cancelPG")))]),(!_vm.create)?[_c('b-button',{staticClass:"mr-1",attrs:{"data-test":"btn-delete-parameter","variant":"danger"},on:{"click":function($event){return _vm.openDeleteModal()}}},[_vm._v(_vm._s(_vm.$t("parameterDetail.deleteParamBtn"))+" ")])]:_vm._e()],2),_c('reference-modal',{ref:"deleteModal",attrs:{"i-d-reference":_vm.parameterId,"object-type":'Parameter',"modal-title":_vm.$t('parameterDetail.deleteModalTitle'),"modal-function":_vm.$t('deleteReferenceFunction'),"object-title":_vm.displayName,"okButtonText":_vm.$t('okButtonText'),"cancel-button-text":_vm.$t('cancelButtonText')},on:{"targetMethod":function($event){return _vm.requestDeleteParameter()}}})],1)],1):_vm._e()]}}],null,false,385204576)})],1)],1)],1):_c('b-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('b-spinner',{staticClass:"mb-3 text-white",staticStyle:{"width":"5rem","height":"5rem"},attrs:{"label":_vm.$t('loading')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }