import Vue from "vue";
import Vuex from "vuex";

import calendar from "./modules/calendar";
import calendars from "./modules/calendars";
import application from "./modules/application";
import applications from "./modules/applications";
import parameters from "./modules/parameters";
import authentication from "./modules/authentication";
import menu from "./modules/menu";
import menus from "./modules/menus";
import scripts from "./modules/scripts";
import parameterGroups from "./modules/parameterGroups";
import script from "@/store/modules/script";
import toaster from "./modules/toaster";
import license from "@/store/modules/license";
import language from "./modules/language";
import changes from "./modules/changes";
import pathify from "vuex-pathify";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  plugins: [pathify.plugin],

  modules: {
    calendar,
    calendars,
    application,
    applications,
    parameters,
    parameterGroups,
    authentication,
    menu,
    menus,
    scripts,
    script,
    toaster,
    license,
    language,
    changes,
  },
  strict: debug,
});
