<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <ListWithSearchField
          title="Calendars"
          :update-key="this.pageVariables.updateTableKey"
          :fields="this.pageVariables.fields"
          :fields-for-csv="this.pageVariables.fieldsForCsv"
          :filter-on="this.pageVariables.filterOn"
          :item-provider="this.getCalendars"
          search-text="Search for Calendar"
          @rowSelected="(item) => onRowSelected(item)"
        >
          <template
            v-slot:additionalContent
            v-if="
              this.getPermissions().calendar.permission ===
              this.constants.PERMISSION_TYPES.ALL
            "
          >
            <b-card title="Create calendar">
              <b-card-body>
                <NewCalendar @AddCalendar="addCalendarMethod"></NewCalendar>
              </b-card-body>
            </b-card>
          </template>
        </ListWithSearchField>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NewCalendar from "../components/calendar/NewCalendar";
import ListWithSearchField from "../components/common/ListWithSearchField";
import constants from "../EndPointsAndConstants";
import Vue from "vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Calendars",
  components: { ListWithSearchField, NewCalendar },
  data() {
    return {
      pageVariables: {
        updateTableKey: 0,
        filterOn: ["displayName"],
        fieldsForCsv: ["displayName", "name"],
        fields: [
          {
            key: "displayName",
            label: "Calendar Name",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions("calendars", ["fetchCalendars", "addCalendar"]),
    ...mapGetters("calendars", ["getCalendars"]),

    onRowSelected(selectedItem) {
      this.$router.push({
        name: "calendarDetail",
        params: { id: String(selectedItem.id) },
      });
    },

    addCalendarMethod(calendar) {
      this.addCalendar({ vm: this, calendar: calendar });
      this.updateTableKey += 1;
      Vue.$log.debug(calendar);
    },
  },
  created() {
    this.fetchCalendars().then((__) => {
      this.pageVariables.updateTableKey += 1;
    });
  },
};
</script>
