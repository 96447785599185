// import "./assets/styles/style.scss";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import BootstrapVue, { TablePlugin } from "bootstrap-vue";
import BVToastPlugin from "bootstrap-vue";
import "./vee-validate";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import JsonCSV from "vue-json-csv";
import { library } from "@fortawesome/fontawesome-svg-core";
import Keycloak from "keycloak-js";
import VueLogger from "vuejs-logger";

// example of icon
import {
  faEdit,
  faGripLinesVertical,
  faMinus,
  faPlus,
  faSave,
  faSearch,
  faTrash,
  faArrowRight,
  faPen,
  faWindowClose,
  faFileAudio,
  faList,
  faBook
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import draggable from "vuedraggable";
import VueI18n from "vue-i18n";
import UniqueId from "vue-unique-id";
import Axios from "axios";
import EndPointsAndConstants, { constants } from "./EndPointsAndConstants";
import { makeAlertMessage } from "./store/mixin/basic";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { setupValidation } from "./vee-validate";

Vue.use(BVToastPlugin);

require("./global.scss");

Vue.use(UniqueId);

library.add(
  faPlus,
  faEdit,
  faSearch,
  faGripLinesVertical,
  faTrash,
  faSave,
  faMinus,
  faArrowRight,
  faPen,
  faMinus,
  faWindowClose,
  faFileAudio,
  faList,
  faBook
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("draggable", draggable);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.use(BootstrapVue);
Vue.use(TablePlugin);
Vue.use(VueI18n);

Vue.component("downloadCsv", JsonCSV);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("prompts", require("./components/common/Prompts.vue").default);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Keycloak", require("keycloak-js"));

Vue.component(constants.ACTION_TYPES.TRANSFER.name, {
  template:
    '<ValidationProvider :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'" v-slot="{ valid, errors }" > <b-form-group :disabled="action.useDefault || isScheduleOrg" v-b-tooltip :title="$t(\'applicationDetail.action.transferActionDescription\')"><b-form-input data-test="transfer_input" type="text" :state="errors[0] ? false : (valid ? true : null)" v-model="action.value" @change="emitComponentChanged()" /></b-form-group> </ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  methods: {
    emitComponentChanged() {
      this.$emit("componentChanged");
    },
  },
});
Vue.component(constants.ACTION_TYPES.SCRIPT.name, {
  template:
    '<ValidationProvider :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'" v-slot="{ valid, errors }" ><b-form-group :disabled="action.useDefault || isScheduleOrg" v-b-tooltip  :title="$t(\'applicationDetail.action.scriptActionDescription\')"><b-form-select data-test="script_input" :options="allScriptsAsOptions"  v-model="action.fk_Script" :state="errors[0] ? false : (valid ? true : null)" @change="emitComponentChanged()"></b-form-select><b-button v-if="action.fk_Script !== null" data-test="gotoBtn" @click="openRoute(\'scriptDetail\', action.fk_Script)"> <font-awesome-icon icon="arrow-right" /></b-button></b-form-group></ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  methods: {
    emitComponentChanged() {
      this.$emit("componentChanged");
    },
    openRoute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      this.$router.push({
        name: targetLink,
        params: { id: targetID },
      });
    },
  },
});
Vue.component(constants.ACTION_TYPES.APPLICATION.name, {
  template:
    '<ValidationProvider :rules="action.useDefault || isScheduleOrg ? \'\' : \'required\'" v-slot="{ valid, errors }" ><b-form-group :disabled="action.useDefault || isScheduleOrg" v-b-tooltip :title="$t(\'applicationDetail.action.applicationActionDescription\')"><b-form-select data-test="application_input" v-model="action.fk_Application"  :options="allApplicationsAsOptions" :state="errors[0] ? false : (valid ? true : null)" @change="emitComponentChanged()" ></b-form-select><b-button v-if="action.fk_Application !== null" data-test="gotoBtn" @click="openRoute(\'applicationDetails\', action.fk_Application)"> <font-awesome-icon icon="arrow-right" /></b-button></b-form-group></ValidationProvider>',
  props: {
    isScheduleOrg: { type: Boolean },
    action: { type: Object },
    allMenusAsOptions: { type: Array },
    allScriptsAsOptions: { type: Array },
    allApplicationsAsOptions: { type: Array },
  },
  methods: {
    emitComponentChanged() {
      this.$emit("componentChanged");
    },
    openRoute(targetLink, targetID) {
      this.$log.debug(targetLink, targetID);
      this.$router.push({
        name: targetLink,
        params: { id: targetID },
      });
    },
  },
});
// Vue.component(constants.ACTION_TYPES.MENU, {
//   template:
//     '<b-form-group label="Please select an menu"><b-form-select  :options="allMenusAsOptions"  v-model="action.fk_Menu"></b-form-select></b-form-group>',
//   props: {
//     action: { type: Object },
//     allMenusAsOptions: { type: Array },
//     allScriptsAsOptions: { type: Array },
//     allApplicationsAsOptions: { type: Array }
//   }
// });
Vue.component(constants.ACTION_TYPES.DISCONNECT.name, {
  template: "<div/>",
});

Vue.component(
  "actionSelector",
  require("./components/common/ActionSelector.vue").default
);
let errorMessage =
  "Failed to load application, backend connection or network access might have been lost.";
let keycloak = new Keycloak(EndPointsAndConstants.endPoints.KEYCLOAK_CONFIG);
let vue = null;
let i18n = null;
let i18nPromise = new Promise(function (resolve) {});
keycloak
  .init({ onLoad: "login-required" })
  .then((auth) => {
    if (auth === true) {
      localStorage.setItem("vue-token", keycloak.token);
      localStorage.setItem("vue-refresh-token", keycloak.refreshToken);
      Vue.prototype.$keycloak = keycloak;
      Axios.get(EndPointsAndConstants.endPoints.FRONTEND_CONFIG)
        .then((response) => {
          Axios.defaults.headers.authorization =
            response.data.headerType + keycloak.token;
          Vue.use(VueLogger, response.data.loggerOptions);
          Vue.config.productionTip = response.data.showProductionTip;
          Vue.config.devtools = response.data.useDevTools;
          Vue.config.headerType = response.data.headerType;

          // const i18n = new VueI18n({
          //     // locale: 'en', // set locale
          //     // fallbackLocale: 'en',
          //     // messages: words
          // });

          Axios.get(EndPointsAndConstants.endPoints.LANGUAGE_BASE)
            .then((response) => {
              var userLang = null;
              if (localStorage.getItem("currentLang") == undefined) {
                userLang = navigator.language;
                if (userLang === "da") {
                  userLang += "-DK";
                }
              } else {
                userLang = localStorage.getItem("currentLang");
              }

              i18n = new VueI18n({
                locale: userLang,
                fallbackLocale: "en-US",
                messages: response.data,
              });
              setupValidation(i18n);

              Vue.$log.debug("locale i18n: " + i18n.locale);
            })
            .then((__) => {
              Vue.prototype.$maxLength = EndPointsAndConstants.maxInputLength;
              vue = new Vue({
                store,
                i18n,
                router,
                el: "#app",
                render: (h) => h(App),
              });
            })
            .catch((response) => {
              Vue.$log.error(
                "App could not be loaded, due to missing configurations from backend." +
                  response
              );
            })
            .then((resolved) => {
              Vue.$log.info(
                "Log level: ",
                response.data.loggerOptions.logLevel
              );
              store.dispatch("authentication/fetchPermissions");
            });
        })
        .catch((error) => {
          console.log("Failed loading config");
          document.write(
            "<html><body><h2>" + errorMessage + "</h2></body></html>"
          );
        });

      //router.push("/application")
    }
  })
  .then((auth) => {
    // store.dispatch("authentication/fetchPermissions");
    keycloak
      .loadUserProfile()
      .then(function (profile) {
        store.commit("authentication/SET_NAME", profile.username);
      })
      .catch(function () {
        alert("Failed to load user profile");
      });
  })
  .catch((error) => {
    console.log("CONFIG DELIVERANCE FAILED");
    document.write("<html><body><h2>" + errorMessage + "</h2></body></html>");
  });

keycloak.onTokenExpired = function () {
  keycloak
    .updateToken(0)
    .then((refreshed) => {
      if (refreshed) {
        Vue.$log.debug("Token refreshed", refreshed);
        localStorage.setItem("vue-token", keycloak.token);
        localStorage.setItem("vue-refresh-token", keycloak.refreshToken);
        Axios.defaults.headers.authorization =
          Vue.config.headerType + keycloak.token;
      } else {
        Vue.$log.warn(
          "Token not refreshed, valid for ",
          Math.round(
            keycloak.tokenParsed.exp +
              keycloak.timeSkew -
              new Date().getTime() / 1000
          ) + " seconds"
        );
      }
    })
    .catch((err) => {
      Vue.$log.error("Failed to refresh token", err);
    });
};

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let message;
    Vue.$log.error("Failed Request", error);
    if (error.response.data.entity != null) {
      message = JSON.stringify(error.response.data.entity);
    } else {
      message =
        "Method: " +
        JSON.stringify(error.response.config.method) +
        " - Url: " +
        JSON.stringify(error.response.config.url) +
        " - Status: " +
        JSON.stringify(error.response.status) +
        " - Response Body: " +
        JSON.stringify(error.response.data);
    }
    if (error.response.config.url != "/api/parametergroups/verifyreference/") {
      makeAlertMessage(vue, message, "danger", error);
    }
    if (error.response.status === 401) {
      makeAlertMessage(
        vue,
        "Token has expired, please refresh page or re-login.",
        "danger",
        error
      );
    }
    return Promise.reject(error);
  }
);

console.log("mutations:", store._mutations);

export { i18nPromise };
