<template>
  <parameter-detail :parameter-id="0" :create="true" />
</template>

<script>
import Vue from "vue";
import { openUnsavedModal } from "../Helper";
Vue.component(
  "parameter-detail",
  require("../components/parameter/ParameterDetail").default
);

export default {
  name: "ParameterCreate",
};
</script>
