import {
  excluded,
  required,
  min,
  max,
  min_value,
  max_value,
  confirmed,
  length,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";
let setupValidation = function (i18n) {
  extend("required", {
    ...required,
    message: i18n.t("validation.required"),
  });

  extend("min", {
    ...min,
    message: i18n.t("validation.min"),
  });
  extend("max", {
    ...max,
    message: i18n.t("validation.max"),
  });
  extend("min_value", {
    ...min_value,
    message: i18n.t("validation.min_val"),
  });

  extend("excluded", {
    ...excluded,
    message: i18n.t("validation.excluded"),
  });
  extend("max_value", {
    ...max_value,
    message: i18n.t("validation.max_val"),
  });
  extend("olderDateBeforeNewAndTime", {
    params: ["startDate", "startTime", "endDate", "endTime"],
    validate(value, { startDate, startTime, endDate, endTime }) {
      console.log("olderDateBeforeNewAndTime");
      if ((startTime != null) & (endTime != null)) {
        if (startDate == endDate && startTime < endTime) {
          console.log("validated");
          return true;
        }
        if (endDate > startDate) {
          console.log("validated");
          return true;
        }
        if (startDate == null && endDate == null && startTime < endTime) {
          console.log("validated");
          return true;
        }

        console.log("cannot validate");
        console.log("start" + startDate + startTime);
        console.log("end" + endDate + endTime);
        return false;
      }
      return false;
    },
    message:
      i18n.t("validation.olderDateBeforeNewAndTime") +
      "{startTime}" +
      i18n.t("validation.and") +
      "{endTime}",
  });
  extend("hour24", {
    getMessage: (field) => `The format must be HH:MM`,
    validate: (value) =>
      new Promise((resolve) => {
        let regex = new RegExp("([01]?[0-9]|2[0-3]):[0-5][0-9]");
        resolve({
          valid: value && regex.test(value),
        });
      }),
  });

  extend("timeBeforeOther", {
    params: ["startTime", "endTime"],
    validate(value, { startTime, endTime }) {
      console.log("start" + startTime);
      console.log("end" + endTime);

      if ((endTime != null) & (startTime != null)) {
        return startTime < endTime;
      }
      console.log("not validate");

      return false;
    },
    message:
      i18n.t("validation.timeBeforeOther") +
      " {startTime}" +
      i18n.t("validation.and") +
      "{endTime}",
  });
  //validation for application default open closed or default
  extend("applicationActionDefaultType", {
    params: ["application"],
    validate(value, { application }) {
      {
        switch (value) {
          case "DEFAULT":
            return true; //application.defaultDisconnectAction
          case "DEFAULT_CLOSED":
            return null !== application.defaultApplicationAction.fk_Application;
          case "DEFAULT_OPEN":
            return null !== application.defaultScriptAction.fk_Script;

          case "DEFAULT_ORG":
            return null !== application.defaultMenuAction.fk_Menu;
          case "DEFAULT_ORG_CLOSED":
            if (application.defaultTransferAction.value.length === 0) {
              return false;
            }
          case "DEFAULT_ORG_OPEN":
            if (application.defaultTransferAction.value.length === 0) {
              return false;
            }
        }
      }
      console.log("default validation passed");
      return true;
    },
    message: i18n.t("validation.applicationActionDefaultType"),
  });

  //validation for application default for specified action type
  extend("actionTypeDefault", {
    params: ["action", "application"],
    validate(value, { action, application }) {
      if (action.fk_ActionType !== null && value == true) {
        switch (action.fk_ActionType) {
          case "DISCONNECT":
            return true; //application.defaultDisconnectAction
          case "APPLICATION":
            return null !== application.defaultApplicationAction.fk_Application;
          case "SCRIPT":
            return null !== application.defaultScriptAction.fk_Script;
          case "MENU":
            return null !== application.defaultMenuAction.fk_Menu;
          case "TRANSFER":
            if (
              application.defaultTransferAction.value === null ||
              application.defaultTransferAction.value.length === 0
            ) {
              return false;
            }
        }
      }
      console.log("default validation passed");
      return true;
    },
    message: i18n.t("validation.actionTypeDefault"),
  });

  extend("noOverLappingSchedules", {
    params: ["actualSchedule", "existingSchedules"],
    validate(value, { actualSchedule, existingSchedules }) {
      console.log("actualSchedule" + actualSchedule);
      console.log("existingSchedules" + existingSchedules);

      function overLappingWeekly(element) {
        if(actualSchedule.orgType == true){
          return false;
        }
        if (
          element.id != actualSchedule.id &&
          element.fk_DayOfWeek == actualSchedule.fk_DayOfWeek
        ) {
          return (
            element.startTime <= actualSchedule.endTime &&
            element.endTime >= actualSchedule.startTime
          );
        }
        return false;
      }
      function overLappingSpecial(element) {
        if (element.id == actualSchedule.id) {
          // skip validation of self
          return false;
        }
        let actualStartDateWithTime = Date.parse(
          actualSchedule.startDate + " " + actualSchedule.startTime
        );
        let actualEndDateWithTime = Date.parse(
          actualSchedule.endDate + " " + actualSchedule.endTime
        );

        let possibleOverLapStart = Date.parse(
          element.startDate + " " + element.startTime
        );
        let possibleOverLapEnd = Date.parse(
          element.endDate + " " + element.endTime
        );

        return (
          possibleOverLapStart <= actualEndDateWithTime &&
          possibleOverLapEnd >= actualStartDateWithTime
        );
      }
      if (
        (actualSchedule.fk_DayOfWeek ||
          (actualSchedule.startDate && actualSchedule.endDate)) &&
        actualSchedule.startTime &&
        actualSchedule.endTime
      ) {
        console.log("begin validation");
        //Weekly schedule
        if (
          actualSchedule.fk_DayOfWeek &&
          existingSchedules.some(overLappingWeekly)
        ) {
          return false;
        }
        //Special schedule
        if (
          actualSchedule.startDate &&
          actualSchedule.endDate &&
          existingSchedules.some(overLappingSpecial)
        ) {
          return false;
        }
      }

      return true;
    },
    message: i18n.t("validation.noOverLappingSchedules"),
  });
  extend("menuDefaultMaxOptionSelected", {
    validate(value) {
      if (value != null) {
        if (value.length >= 2) {
          return true;
        } else {
          console.log(value);
          let found = value.find(
            (option) => option.selectedValue == "RetryMax"
          );
          if (found) {
            return true;
          }
        }
        return false;
      }
      return false;
    },
    message: i18n.t("toasts.menu.saveMenuDanger"),
  });

  extend("emptyPromptField", {
    params: ["prompt"],
    validate(value, { prompt }) {
      console.log(prompt);
      return false;
    },
    message: i18n.t("validation.emptyPromptField"),
  });

  extend("checkForSelfReference", {
    params: ["containsReferences"],
    validate(value, { containsReferences }) {
      return containsReferences;
    },
    message: i18n.t("validation.checkForSelfReference"),
  });

  extend("checkForExistingApplications", {
    params: ["applicationName", "existingApplications"],
    validate(value, { applicationName, existingApplications }) {
      return existingApplications.some(applicationName);
    },
    message: "An application already exists",
  });

  extend("checkForExistingTriggers", {
    params: ["trigger", "existingTriggers"],
    validate(value, { trigger, existingTriggers }) {
      if(existingTriggers.find(item => item.trigger == trigger) != null){
        return false;
      } else {
        return true;
      }
    },
    message: i18n.t("validation.duplicateTriggerError"),
  });
  extend("usedTrigger", {
    ...excluded,
    message: i18n.t("validation.duplicateUsedTriggerError"),
  });
};
export { setupValidation };
