import axios from "axios";
import constants from "../../EndPointsAndConstants";
import { makeToast } from "../mixin/basic";

const state = {
  calendar: {
    id: null,
    name: null,
    displayName: null,
    scheduleList: [
      //Data reference
      // {
      //     id: null,
      //     dayOfWeek: null,
      //     startDate: null,
      //     endDate: null,
      //     recurring: null,
      //     startTime: null,
      //     endTime: null,
      //     description: null,
      //     disabled: null
      // }
    ],
  },
};

const getters = {
  getCalendar: (state) => {
    return state.calendar;
  },

  getWeeklySchedules: (state) => {
    return state.calendar.scheduleList.filter((schedule) => {
      return schedule.dayOfWeek != null;
    });
  },

  getSpecialSchedules: (state) => {
    return state.calendar.scheduleList.filter((schedule) => {
      return (
        schedule.recurring == null &&
        schedule.startDate != null &&
        schedule.endDate != null
      );
    });
  },

  //TODO Change getter name to the correct term for this schedule type
  getRecurringSpecialSchedule: (state) => {
    return state.calendar.scheduleList.filter((schedule) => {
      return (
        schedule.recurring != null &&
        schedule.startDate != null &&
        schedule.endDate != null
      );
    });
  },
};

const actions = {
  fetchCalendar({ commit }, id) {
    axios.get("/api/calendars/public/" + id).then((response) => {
      Vue.$log.debug(response.data);
      if (response.data) {
        commit("SET_CALENDAR", response.data);
      }
    });
  },

  addCalendarSchedule({ commit }, schedule) {
    Vue.$log.debug("Add Schedule Vuex Hit");
    axios
      .post(
        "/api/calendars/public/" + state.calendar.id + "/schedules",
        schedule
      )
      .then((response) => {
        if (response.data) {
          Vue.$log.debug(response.data);
          commit("ADD_SCHEDULE", response.data);
        }
      });
  },

  deleteCalendarSchedule({ commit }, { vm, schedule }) {
    Vue.$log.debug("Delete Schedule Vuex Hit");
    axios
      .delete(
        constants.endPoints.CALENDAR_BASE +
          state.calendar.id +
          constants.endPoints.CALENDAR_SCHEDULES +
          schedule.id
      )
      .then((__) => {
        let index = state.calendar.scheduleList.findIndex(
          (scheduleList) => scheduleList.id == schedule.id
        );
        commit("DELETE_SCHEDULE", index);
        makeToast(
          vm,
          vm.$t("toasts.calendar.schedule") +
            schedule.id +
            vm.$t("toasts.calendar.deleted"),
          "success"
        );
      })
      .catch((__) => {});
  },
  saveSchedule({ dispatch }, { vm, schedule }) {
    Vue.$log.debug("schedule are" + schedule);
    let promise = axios.put(
      constants.endPoints.CALENDAR_BASE +
        state.calendar.id +
        constants.endPoints.CALENDAR_SCHEDULES +
        schedule.id,
      schedule
    );
    promise.then((__) => {
      dispatch("fetchCalendar", state.calendar.id);
      makeToast(
        vm,
        vm.$t("toasts.calendar.schedule") +
          schedule.id +
          vm.$t("toasts.calendar.saved"),
        "success"
      );
    });
    return promise;
  },
};

const mutations = {
  SET_CALENDAR(state, calendar) {
    state.calendar = calendar;
  },

  ADD_SCHEDULE(state, scheduleList) {
    state.calendar.scheduleList = scheduleList;
  },

  DELETE_SCHEDULE(state, index) {
    state.calendar.scheduleList.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
