<template>
  <div class="pl-0">
    <div v-if="showTitle">
      {{ $t("applicationDetail.action.prompts") }}
    </div>
    <div v-if="prompts.length > 0" data-test="listedPrompts">
      <div
        class="d-inline-flex"
        v-for="prompt in prompts"
        :key="prompt.position"
      >
        {{ prompt.value }}
        <div
          v-if="
            prompts.length >
              prompts.findIndex((x) => x.value === prompt.value) &&
            prompts.length - 1 !==
              prompts.findIndex((x) => x.value === prompt.value)
          "
        >
          {{ promptSeperator }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromptList",
  props: {
    prompts: { type: Array, required: true },
    showTitle: { type: Boolean, default: false },
  },
  data() {
    return {
      promptSeperator: ", \xa0", // \xa0 is a non-break space
    };
  },
};
</script>
