<template>
  <b-card
    border-variant="secondary"
    class="shadow"
    :header="this.cardTitle"
    header-bg-variant="secondary"
    header-text-variant="white"
  >
    <b-row>
      <b-col>
        <b-table-simple
          caption-top
          hover
          responsive
          small
          class="pre-scrollable"
          data-test="listmover-drag-from"
          sticky-header
          no-border-collapse
        >
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="pb-0"
                >{{ availableOptionsTitle }}
                <b-input-group>
                  <b-input
                    data-test="listmover-filterText"
                    id="filterText"
                    type="search"
                    :placeholder="$t('parameterGroupEdit.filter')"
                    v-model="searchText"
                  />
                  <b-input-group-prepend class="mr-0">
                    <b-input-group-text>
                      <font-awesome-icon icon="search" />
                    </b-input-group-text>
                  </b-input-group-prepend>
                </b-input-group>
              </b-th>
            </b-tr>
          </b-thead>

          <draggable
            :clone="clone"
            :group="{ name: this.groupId, pull: 'clone', put: false }"
            :list="availableOptions"
            tag="b-tbody"
            @choose="dragStart"
            @end="dragEnd"
            @unchoose="dragEnd"
          >
            <b-tr
              class="move"
              :key="element.id"
              v-for="element in availableOptions"
            >
              <b-td>{{ element.displayName }}</b-td>
            </b-tr>
          </draggable>
        </b-table-simple>
      </b-col>
      <b-col>
        <b-table-simple
          class="pre-scrollable h-100"
          caption-top
          hover
          responsive
          small
          data-test="listmover-drop-on"
          sticky-header
          no-border-collapse
          :table-class="'h-100'"
        >
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>{{ selectedOptionsTitle }}</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <draggable
            :list="this.internalSelectedList"
            @change="log"
            :group="this.groupId"
            tag="b-tbody"
            ref="droppableArea"
            class=""
          >
            <b-tr
              :key="element.id"
              v-for="(element, index) in internalSelectedList"
              style="height: 1rem;"
            >
              <b-td> {{ element.displayName }}</b-td>
              <b-td @click="removeAt(index)">
                <font-awesome-icon icon="trash" />
              </b-td>
            </b-tr>
            <div class="flex-fill"/>
<!--            <b-tr><b-td> </b-td><b-td> </b-td></b-tr>-->
          </draggable>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import Vue from "vue";

export default {
  name: "list-Mover",
  display: "Drag between two lists",
  props: {
    availableOptionsTitle: { type: String, default: () => "A list" },
    selectedOptionsTitle: { type: String, default: () => "B list" },
    groupId: { type: String, required: true },
    nonValidAvailableOption: { type: Number, default: () => null },
    availableOptionsList: { type: Array, required: true },
    selectedOptionsList: { type: Array, required: true },
    cardTitle: { type: String, default: () => "Selectables" },
  },
  data() {
    return {
      internalSelectedList: [],
      originalDroppableAreaStyle: null,
      searchTextValue: ""
    };
  },
  mounted() {
    this.internalSelectedList = [...this.selectedOptionsList];
  },
  methods: {
    removeAt(idx) {
      this.internalSelectedList.splice(idx, 1);
      this.selectionChanged();
    },
    clone: function (el) {
      return Object.assign({}, el);
    },
    selectionChanged() {
      this.$emit("selectionChanged", this.internalSelectedList);
      this.$emit("change");
    },
    log: function (evt) {
      this.selectionChanged();
    },
    dragStart() {
      Vue.$log.debug("start trying todo something");
      if (!this.originalDroppableAreaStyle) {
        this.originalDroppableAreaStyle =
          this.$refs.droppableArea.$el.className;
      }
      //ref="droppableArea"
      this.$refs.droppableArea.$el.className =
        this.originalDroppableAreaStyle + " border border-success";
    },
    dragEnd() {
      Vue.$log.debug("end trying todo something");
      this.$refs.droppableArea.$el.className = this.originalDroppableAreaStyle;
    },
  },
  computed: {
    searchText: {
      get() {
        return this.searchTextValue;
      },
      set(value) {
        Vue.$log.debug(value);
        this.searchTextValue = value;
      },
    },
    selectedOptions: {
      get() {
        return this.selectedOptionsList;
      },
    },
    availableOptions() {
      if (this.searchText === "") {
        return this.availableOptionsList.filter((el) => {
          return (
            this.internalSelectedList.filter((internalElement) => {
              return (
                el.id === internalElement.id ||
                el.id === this.nonValidAvailableOption
              );
            }).length === 0
          );
        });
      } else {
        return this.availableOptionsList.filter((item) => {
          return (
            item.displayName
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) &&
            !this.selectedOptions.some(function (field) {
              return field.id === item.id;
            })
          );
        });
      }
    },
  },
};
</script>
