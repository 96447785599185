import axios from "axios";
import { constants } from "../../EndPointsAndConstants";
import { makeToast } from "../mixin/basic";
import Vue from "vue";

const state = {
  menus: [],
};

const getters = {
  getMenus: (state) => {
    return state.menus;
  },
};

const actions = {
  fetchMenus({ commit }) {
    return axios
      .get(constants.endPoints.MENUS_BASE)
      .then((response) => {
        if (response.data) {
          Vue.$log.debug(response.data);
          commit("SET_MENUS", response.data);
        }
      })
      .catch((response) => {
        Vue.$log.error("Fetch menus not working");
      });
  },
  addMenu({ commit }, { vm, menu }) {
    return axios
      .post(constants.endPoints.MENUS_BASE, menu)
      .then((response) => {
        if (response.data) {
          Vue.$log.debug(response.data);
          makeToast(
            vm,
            vm.$t("toasts.menu.menu") +
              menu.displayName +
              vm.$t("toasts.menu.added"),
            "success"
          );
        }
      })
      .catch((response) => {
        console.error(response);
      });
  },
};

const mutations = {
  SET_MENUS(state, menus) {
    state.menus = menus;
  },
  ADD_MENU(state, menu) {
    state.menus.push(menu);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
