import axios from "axios";
import constants from "../../EndPointsAndConstants";
import { makeToast, sanitiseLink } from "../mixin/basic";
import { DateTime } from "luxon";
import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";
import { containsNoNulls } from "../../Helper";
import endPointsAndConstants from "../../EndPointsAndConstants";

const state = {
  all: {},
  schedules: [],
  activeSchedule: {},
  customParameters: {},
  customParameterChangeSet: new Map(),
  selectedApplication: {
    id: null,
    name: "Service",
    displayName: "Service",
    actionOnNoHit: false,
    timeZone: null,
    triggers: [],
    applicationCalendar: null,
    parentCalendarRanksHighest: true,
    useDefaultsForCloseAction: null,
    useDefaultsForOpenAction: null,
    actionOnTrue: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      prompts: [],
    },
    actionOnFalse: {
      fk_ActionType: "DISCONNECT",
      prompts: [],
      useDefault: false,
    },
    defaultTransferAction: {
      fk_ActionType: "DISCONNECT",
      value: "",
      useDefault: false,
      prompts: [],
    },
    defaultMenuAction: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      prompts: [],
      fk_Menu: "",
    },
    defaultApplicationAction: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      fk_Application: "",
      prompts: [],
    },
    defaultDisconnectAction: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      prompts: [],
    },
    defaultScriptAction: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      fk_Script: "",
      prompts: [],
    },
    _links: [
      {
        rel: "self",
        href: "http://localhost:8080/api/applications/4",
        hreflang: null,
        media: null,
        title: null,
        type: null,
        deprecation: null,
      },
      {
        rel: "ApplicationCalendar",
        href: "http://localhost:8080/api/calendar/2",
        hreflang: null,
        media: null,
        title: null,
        type: null,
        deprecation: null,
      },
    ],
  },
  adhocPrompt: {
    prompts: [],
    type: null,
    fromDate: null,
    endDate: null,
    fromTime: null,
    endTime: null,
  },
  inheritedApplications: [],
  organizationApplication: {
    id: null,
    name: "Service",
    displayName: "Service",
    actionOnNoHit: false,
    timeZone: null,
    triggers: [],
    applicationCalendar: null,
    parentCalendarRanksHighest: true,
    useDefaultsForCloseAction: null,
    useDefaultsForOpenAction: null,
    actionOnTrue: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      prompts: [],
    },
    actionOnFalse: {
      fk_ActionType: "DISCONNECT",
      prompts: [],
      useDefault: false,
    },
    defaultTransferAction: {
      fk_ActionType: "DISCONNECT",
      value: "",
      useDefault: false,
      prompts: [],
    },
    defaultMenuAction: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      prompts: [],
      fk_Menu: "",
    },
    defaultApplicationAction: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      fk_Application: "",
      prompts: [],
    },
    defaultDisconnectAction: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      prompts: [],
    },
    defaultScriptAction: {
      fk_ActionType: "DISCONNECT",
      useDefault: false,
      fk_Script: "",
      prompts: [],
    },
    _links: [
      {
        rel: "self",
        href: "http://localhost:8080/api/applications/4",
        hreflang: null,
        media: null,
        title: null,
        type: null,
        deprecation: null,
      },
      {
        rel: "ApplicationCalendar",
        href: "http://localhost:8080/api/calendar/2",
        hreflang: null,
        media: null,
        title: null,
        type: null,
        deprecation: null,
      },
    ],
  },
  allTriggers: []
};

const getters = {
  getField,
  getSelectedApplication(state) {
    return state.selectedApplication;
  },
  getOrganizationApplication(state) {
    return state.organizationApplication;
  },
  getSelectedApplicationID(state) {
    return state.selectedApplication.id;
  },
  getSchedules(state) {
    return state.schedules;
  },
  getSpecialSchedules(state) {
    return state.schedules.filter((schedule) => {
      return (
        (schedule.recurring != null && schedule.recurring === true) ||
        (schedule.startDate != null && schedule.endDate != null)
      );
    });
  },
  getActiveSchedule(state) {
    return state.activeSchedule;
  },
  getParameters(state) {
    return state.customParameters;
  },
  getWeeklySchedules(state) {
    return state.schedules.filter((schedule) => {
      return schedule.fk_DayOfWeek != null;
    });
  },
  getSelectedAdhoc(state) {
    return state.adhocPrompt;
  },
  getInheritingApplications(state) {
    return state.inheritedApplications;
  },
  getAllTriggers(state) {
    return state.allTriggers;
  }
};
const actions = {
  getActiveScheduleNow({ commit, state }) {
    Vue.$log.debug("marking current timestamp");
    let promise = axios.get(
      constants.endPoints.APPLICATIONS_BASE +
        state.selectedApplication.id +
        constants.endPoints.APPLICATIONS_ACTIVE_SCHEDULE +
        constants.endPoints.APPLICATIONS_ACTIVE_SCHEDULE_NOW
    );
    promise.then((response) => {
      commit("SET_ACTIVE_SCHEDULE", response.data);
    });
    return promise;
  },
  getActiveScheduleAtTimestamp({ commit, state }, timestamp) {
    Vue.$log.debug("marking " + timestamp);
    Vue.$log.debug(
      "marking " + DateTime.fromSeconds(timestamp).toFormat("yyyy-MM-dd HH:mm")
    );
    let promise = axios.get(
      constants.endPoints.APPLICATIONS_BASE +
        state.selectedApplication.id +
        constants.endPoints.APPLICATIONS_ACTIVE_SCHEDULE +
        timestamp
    );
    promise.then((response) => {
      commit("SET_ACTIVE_SCHEDULE", response.data);
    });
    return promise;
  },
  fetchSelectedApplication({ commit, dispatch }, id) {
    Vue.$log.debug(id);
    let promise = axios.get(constants.endPoints.APPLICATIONS_BASE + id);
    return promise.then((response) => {
      commit("SET_SELECTED_APPLICATION", response.data.subject);
      Vue.$log.debug(
        "got application, saving without permissions",
        response.data
      );
    });
  },
  fetchApplicationOrganization({ commit, state }) {
    if (state.selectedApplication.fk_ApplicationOrg) {
      let promise = axios.get(
        constants.endPoints.APPLICATIONS_BASE +
          state.selectedApplication.fk_ApplicationOrg
      );
      promise.then((response) => {
        Vue.$log.debug(
          "got ORGANIZATION, saving without permissions",
          response.data
        );
        commit("SET_APPLICATION_ORGANIZATION", response.data.subject);
      });
      return promise;
    } else {
      commit("SET_APPLICATION_ORGANIZATION", null);
    }
  },
  fetchSchedulesForApplication({ commit, state }) {
    let promise = axios.get(
      constants.endPoints.APPLICATIONS_BASE +
        state.selectedApplication.id +
        constants.endPoints.APPLICATIONS_SCHEDULES
    );
    promise
      .then((response) => {
        commit("SET_SCHEDULES", response.data);
        Vue.$log.debug("got application schedules -->", response.data);
      })
      .catch((response) => {
        Vue.$log.error(response);
      });
    return promise;
  },
  fetchCustomParametersForApplication({ commit, state }) {
    let promise = axios.get(
      constants.endPoints.APPLICATIONS_BASE +
        state.selectedApplication.id +
        constants.endPoints.PARAMETERS
    );
    promise.then((response) => {
      commit("SET_CUSTOM_PARAMETERS", response.data);
      Vue.$log.debug("got Custom Parameters: ", response.data);
    });
    return promise;
  },
  saveApplication({ commit, state }, { vm }) {
    Vue.$log.debug("saving application", state.selectedApplication);
    axios
      .put(
        constants.endPoints.APPLICATIONS_BASE + state.selectedApplication.id,
        state.selectedApplication
      )
      .then((response) => {
        commit("SET_SELECTED_APPLICATION", response.data);
        makeToast(
          vm,
          vm.$t("toasts.application.applicationSaveSuccessful"),
          "success"
        );
      })
      .catch((error) => {
        if(error.response.status === 409){
          makeToast(
              this,
              vm.$t("applicationPage.duplicateError"),
              "danger"
          );
        } else {
          makeToast(
              vm,
              vm.$t("toasts.application.applicationSaveFailed"),
              "danger"
          );
        }
      });
  },
  saveParameters({ state }, vm) {
    let parameterArray = [];
    Vue.$log.debug(state.customParameterChangeSet);
    state.customParameterChangeSet.forEach((v, k) => {
      parameterArray.push(v);
    });
    Vue.$log.debug("array >>> ", parameterArray);
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //constants.endPoints.APPLICATIONS_BASE + state.selectedApplication.id + constants.endPoints.PARAMETERS,
    axios
      .put(
        constants.endPoints.APPLICATIONS_BASE +
          state.selectedApplication.id +
          constants.endPoints.PARAMETERS,
        parameterArray,
        axiosConfig
      )
      .then((__) => {
        makeToast(
          vm,
          vm.$t("toasts.application.parameterSaveSuccessful"),
          "success"
        );
      })
      .catch((__) => {
        makeToast(
          vm,
          vm.$t("toasts.application.parameterSaveFailed"),
          "danger"
        );
      });
  },
  savePrompts({ state }, { vm, promptList, linkName }) {
    //Fatten new prompts with action key
    promptList.forEach((possibleSlimPrompt) => {
      if (!possibleSlimPrompt.hasOwnProperty("FK_Action")) {
        Vue.$log.debug("fattening prompt ", possibleSlimPrompt);
        possibleSlimPrompt.FK_Action = null;
      }
    });
    Vue.$log.debug("was asked to save prompts will post towards ", linkName);
    let link = sanitiseLink(state.selectedApplication._links[linkName]);
    let promise = axios.post(link, promptList);
    promise
      .then((__) => {
        makeToast(
          vm,
          vm.$t("toasts.application.promptsSaveSuccessful"),
          "success"
        );
      })
      .catch((__) => {
        makeToast(vm, vm.$t("toasts.application.promptsSaveFailed"), "danger");
      });
    return promise;
  },
  removeTrigger({ state, commit }, { vm, trigger }) {
    let promise = new Promise(function (resolve) {
      let index = state.selectedApplication.triggers.findIndex(
        (triggerToExamine) => triggerToExamine === trigger
      );
      commit("REMOVE_TRIGGERS_APPLICATION", index);
      makeToast(vm, vm.$t("toasts.application.triggerRemoved"), "warning");
      resolve(state.selectedApplication);
    });
    return promise;
  },

  addTrigger({ state, commit }, { vm, trigger }) {
    return new Promise(function (resolve) {
      commit("ADD_TRIGGERS_APPLICATION", trigger);

      makeToast(vm, vm.$t("toasts.application.triggerAdded"), "warning");
      resolve(state.selectedApplication);
    });
  },
  deleteDisableSchedule({ dispatch }, { vm, schedule, application }) {
    Vue.$log.debug("schedule are ", schedule);
    let promise = axios.delete(
      constants.endPoints.APPLICATIONS_BASE +
        application.id +
        constants.endPoints.APPLICATIONS_SCHEDULE +
        schedule.id
    );
    promise.then((__) => {
      dispatch("fetchSchedulesForApplication");
      Vue.$log.debug("Schedule have been removed");
      makeToast(vm, vm.$t("toasts.application.scheduleRemoved"), "success");
    });
    return promise;
  },
  addSchedule({ dispatch, state }, { vm, schedule }) {
    Vue.$log.debug("schedule are", schedule);
    if (containsNoNulls(schedule)) {
      let promise = axios.post(
        constants.endPoints.APPLICATIONS_BASE +
          state.selectedApplication.id +
          constants.endPoints.APPLICATIONS_SCHEDULE,
        schedule
      );
      promise
        .then((__) => {
          dispatch("fetchSchedulesForApplication").then((__) => {
            makeToast(
              vm,
              vm.$t("toasts.application.scheduleSaveSuccessful"),
              "success"
            );
          });
        })
        .catch((__) => {
          Vue.$log.error(
            "Could not post: ",
            schedule,
            "Response was claimed as: ",
            __.response
          );

          makeToast(
            vm,
            vm.$t("toasts.application.scheduleSaveFailed"),
            "danger"
          );
        });
      return promise;
    } else {
      makeToast(vm, vm.$t("toasts.application.missingAction"), "danger");
    }
  },
  addWeeklyScheduleBulk({ dispatch, state }, { vm, days, schedule }) {
    Vue.$log.debug("schedule are", days, schedule);
    schedule.days = days.days;
    Vue.$log.debug("schedule are", schedule);
    if (containsNoNulls(schedule)) {
      let promise = axios.post(
          constants.endPoints.APPLICATIONS_BASE +
          state.selectedApplication.id +
          constants.endPoints.APPLICATIONS_SCHEDULEBULK_NO_SLASH + '/',
          schedule
      );
      promise
          .then((__) => {
            dispatch("fetchSchedulesForApplication").then((__) => {
              makeToast(
                  vm,
                  vm.$t("toasts.application.scheduleSaveSuccessful"),
                  "success"
              );
            });
          })
          .catch((__) => {
            Vue.$log.error(
                "Could not post: ",
                schedule,
                "Response was claimed as: ",
                __.response
            );

            makeToast(
                vm,
                vm.$t("toasts.application.schedulesSaveFailed"),
                "warning"
            );
            dispatch("fetchSchedulesForApplication").then((__) => {});
          });
      return promise;
    } else {
      makeToast(vm, vm.$t("toasts.application.missingAction"), "danger");
    }
  },
  saveSchedule({ dispatch, state }, { vm, schedule }) {
    Vue.$log.debug("schedule are", schedule);
    let promise = axios.put(
      constants.endPoints.APPLICATIONS_BASE +
        state.selectedApplication.id +
        constants.endPoints.APPLICATIONS_SCHEDULE,
      schedule
    );
    promise.then((__) => {
      dispatch("fetchSchedulesForApplication").then((__) => {
        makeToast(vm, vm.$t("toasts.application.scheduleSaved"), "success");
      });
    });
    return promise;
  },
  parameterChanged({ commit }, { parameterGroupRefId, parameter, value }) {
    Vue.$log.debug(
      "updating >>" +
        parameterGroupRefId +
        "-" +
        parameter.id +
        " >>" +
        parameter +
        " >>" +
        value
    );
    let changeset = {};
    changeset.parameterGroupRefId = parameterGroupRefId;
    changeset.id = parameter.id;
    changeset.values = [];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        changeset.values.push(item.id.toString());
      });
    } else {
      if (typeof value === "object" && value) {
        changeset.values.push(value.id.toString());
      } else {
        if (value) {
          changeset.values.push(value);
        } else {
          changeset.values = null;
        }
      }
    }

    commit("ADD_PARAMETER_CHANGE_SET", {
      id: parameterGroupRefId + "-" + parameter.id,
      change: changeset,
    });
  },
  fetchAdhocForApplication({ commit }, id) {
    let promise = axios.get(
      constants.endPoints.APPLICATIONS_BASE +
        id +
        "/" +
        constants.endPoints.APPLICATIONS_ADHOC_PROMPT
    );
    promise.then((response) => {
      Vue.$log.debug(
        "Fetch Adhoc For Application Response.data: ",
        response.data
      );
      commit("SET_ADHOC_PROMPT", response.data);
      return response.data;
    });
    return promise;
  },
  updateAdhocPrompt({ commit }, { vm, id, adhocPrompt }) {
    Vue.$log.debug("before: ", adhocPrompt);

    if (adhocPrompt.type === "Disable") {
      adhocPrompt.fromDate = null;
      adhocPrompt.fromTime = null;
      adhocPrompt.endDate = null;
      adhocPrompt.endTime = null;
    }

    if (adhocPrompt.type === "ActivForEver") {
      let today = DateTime.local();
      adhocPrompt.fromDate = today.toFormat(constants.DATE_FORMAT);
      adhocPrompt.fromTime = today.toFormat(constants.TIME_FORMAT);
      adhocPrompt.endDate = null;
      adhocPrompt.endTime = null;
    }

    if (adhocPrompt.type === "ActiveRestOfDay") {
      let today = DateTime.local();
      adhocPrompt.fromDate = today.toFormat(constants.DATE_FORMAT);
      adhocPrompt.fromTime = today.toFormat(constants.TIME_FORMAT);

      let targetDay = DateTime.local()
        .plus({ days: 1 })
        .set({ hour: 0, minute: 0, second: 0 });

      adhocPrompt.endDate = targetDay.toFormat(constants.DATE_FORMAT);
      adhocPrompt.endTime = targetDay.toFormat(constants.TIME_FORMAT);
    }

    if (adhocPrompt.type === "ActiveSpecial") {
      let tempFromDate = adhocPrompt.fromDate;
      let tempEndDate = adhocPrompt.endDate;
      let tempFromTime = adhocPrompt.fromTime;
      let tempEndTime = adhocPrompt.endTime;
      adhocPrompt.fromDate = DateTime.fromISO(tempFromDate)
        .toISODate()
        .toString();
      adhocPrompt.endDate = DateTime.fromISO(tempEndDate)
        .toISODate()
        .toString();
      adhocPrompt.fromTime = DateTime.fromISO(tempFromTime)
        .toFormat(constants.TIME_FORMAT)
        .toString();
      adhocPrompt.endTime = DateTime.fromISO(tempEndTime)
        .toFormat(constants.TIME_FORMAT)
        .toString();
    }
    Vue.$log.debug("after: ", adhocPrompt);
    let promise = axios.put(
      constants.endPoints.APPLICATIONS_BASE +
        id +
        "/" +
        constants.endPoints.APPLICATIONS_ADHOC_PROMPT,
      adhocPrompt
    );
    promise
      .then((response) => {
        commit("SET_ADHOC_PROMPT", response.data);
        Vue.$log.debug(response.data);
        makeToast(
          vm,
          vm.$t("toasts.application.adhocUpdateSuccess"),
          "success"
        );
      })
      .catch((response) => {
        makeToast(vm, vm.$t("toasts.application.adhocUpdatedFailed"), "danger");
      });

    return promise;
  },
  fetchInheritingActions({ commit }, applicationID) {
    let data;
    let promise = axios.get(
      constants.endPoints.APPLICATIONS_BASE +
        constants.endPoints.INHERITED_ACTION_APPLICATIONS +
        applicationID
    );
    let succesfulPromise = promise.then((response) => {
      data = response.data;
      commit("SET_INHERITED_APPLICATIONS", response.data);
    });
    promise.catch((error) => {
      Vue.$log.debug(error);
    });
    return promise;
  },
  fetchOrgReferences({ state }, targetOrg) {
    if (!targetOrg) {
      return null;
    }
    Vue.$log.debug("commence finding for " + targetOrg);
    let promises = [];
    let promiseSchedules = axios
      .get(
        constants.endPoints.APPLICATIONS_BASE +
          targetOrg +
          constants.endPoints.APPLICATIONS_SCHEDULES
      )
      .then((response) => {
        let found = response.data.find(
          (item) => item.action.fk_Application === state.selectedApplication.id
        );
        if (found) {
          return false;
        } else {
          return true;
        }
      })
      .catch((error) => {
        makeToast(this, error, "danger");
        Vue.$log.error(error);
      });

    let promiseGeneralSettings = axios
      .get(constants.endPoints.APPLICATIONS_BASE + targetOrg)
      .then((response) => {
        let notFound;
        Vue.$log.debug(response.data);
        if (
          response.data.subject.actionOnFalse.fk_Application ===
            state.selectedApplication.id ||
          response.data.subject.actionOnTrue.fk_Application ===
            state.selectedApplication.id ||
          response.data.subject.defaultApplicationAction.fk_Application ===
            state.selectedApplication.id
        ) {
          notFound = false;
        } else {
          notFound = true;
        }
        return notFound;
      })
      .catch((error) => {
        makeToast(this, error, "danger");
        Vue.$log.error(error);
      });

    promises.push(promiseSchedules);
    promises.push(promiseGeneralSettings);

    let okFunction = function (result) {
      let search = result.find((item) => item === false);
      if (search === false) {
        return false;
      } else {
        return true;
      }
    };
    let errorFunction = function () {
      Vue.$log.error("Something went wrong - references");
    };

    let status = Promise.all(promises).catch(errorFunction).then(okFunction);
    return status;
  },
  fetchAllTriggers({commit}){
    axios
        .get(constants.endPoints.APPLICATIONS_BASE + constants.endPoints.APPLICATIONS_TRIGGERS)
        .then(response => {
          commit("SET_ALL_TRIGGERS", response.data);
        }).catch(error => {
      makeToast(
          this,
          "trigger fetch failed",
          "danger"
      );
    })
  }
};

// mutations
/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  updateField,

  SET_ACTIVE_SCHEDULE(state, schedule) {
    state.activeSchedule = schedule;
  },
  ADD_PARAMETER_CHANGE_SET(state, { id, change }) {
    state.customParameterChangeSet.set(id, change);
    Vue.$log.debug("Changeset are >>", [...state.customParameterChangeSet]);
  },
  SET_APPLICATION_ORGANIZATION(state, application) {
    state.organizationApplication = application;
  },
  SET_SELECTED_APPLICATION(state, application) {
    state.selectedApplication = application;
  },
  SET_SCHEDULES(state, schedules) {
    state.schedules = schedules;
  },
  INIT_TRIGGERS_APPLICATION(state) {
    state.selectedApplication.triggers = [];
  },
  ADD_TRIGGERS_APPLICATION(state, trigger) {
    if (state.selectedApplication.triggers === null) {
      state.selectedApplication.triggers = [];
    }
    state.selectedApplication.triggers.push(trigger);
  },
  REMOVE_TRIGGERS_APPLICATION(state, triggerIndex) {
    state.selectedApplication.triggers.splice(triggerIndex, 1);
    if (state.selectedApplication.triggers.entries() === 0) {
      state.selectedApplication.triggers = null;
    }
    Vue.$log.debug(state.selectedApplication.triggers);
  },
  ADD_SCHEDULE(state, schedule) {
    state.schedules.push(schedule);
  },
  SET_CUSTOM_PARAMETERS(state, customParameters) {
    state.customParameters = customParameters;
    state.customParameterChangeSet = new Map();
  },
  SET_ADHOC_PROMPT(state, adhocPrompt) {
    Vue.$log.debug("Setting adhoc prompt: ", adhocPrompt);
    state.adhocPrompt = adhocPrompt;
  },
  SET_CLOSED_ACTION(state, action) {
    state.selectedApplication.actionOnFalse = action;
  },
  SET_OPEN_ACTION(state, action) {
    state.selectedApplication.actionOnTrue = action;
  },
  SET_INHERITED_APPLICATIONS(state, references) {
    state.inheritedApplications = references;
  },
  SET_ALL_TRIGGERS(state, triggers){
    state.allTriggers = triggers;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
