import axios from "axios";
import { makeToast, sanitiseLink } from "../mixin/basic";
import EndPointsAndConstants from "../../EndPointsAndConstants";

const state = {
  calendars: [],
};

const getters = {
  getCalendars: (state) => {
    return state.calendars;
  },
};

const actions = {
  fetchCalendars({ commit }) {
    return axios.get("/api/calendars/public").then((response) => {
      if (response.data) {
        commit("SET_CALENDARS", response.data);
      }
    });
  },

  addCalendar({ commit }, { vm, calendar }) {
    axios
      .post(EndPointsAndConstants.endPoints.CALENDAR_BASE, calendar)
      .then((response) => {
        Vue.$log.debug("Adding calendar");
        commit("ADD_CALENDAR", response.data);
        makeToast(
          vm,
          vm.$t("toasts.calendar.calendarSavedSuccessful"),
          "success"
        );
      })
      .catch((response) => {
        Vue.$log.error("Calendar could not be added");
        makeToast(vm, vm.$t("toasts.calendar.calendarSavedFailed"), "warning");
      });
  },
};

const mutations = {
  SET_CALENDARS(state, calendars) {
    state.calendars = calendars;
  },
  ADD_CALENDAR(state, calendar) {
    state.calendars.push(calendar);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
