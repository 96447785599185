<template>
  <b-table
    striped
    hover
    outlined
    :items="internalItems"
    :fields="internalFields"
  >
    <template v-slot:cell(show_details)="row">
      <b-button @click="row.toggleDetails" class="mr-2" size="sm">
        {{ row.detailsShowing ? "Hide" : "Show" }} Details
      </b-button>
    </template>

    <template v-slot:row-details="row">
      <b-card>
        <b-row>
          <b-col cols="10">
            <ScheduleForm
              :showAddButton="false"
              :showDisableButton="true"
              :schedule-type="1"
              :id="row.item.rawItem.id"
              :schedule="row.item.rawItem"
              @updated="
                (updatedItem) => {
                  row.item.schedule = updatedItem;
                }
              "
            >
            </ScheduleForm>
          </b-col>
          <!--          <b-col cols="1">-->
          <!--            <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>-->
          <!--          </b-col>-->
          <b-col cols="1">
            <b-button
              size="sm"
              @click="updateSchedule(row)"
              title="Save schedule"
              v-b-tooltip.hover
            >
              <font-awesome-icon icon="save" />
            </b-button>
          </b-col>
          <b-col cols="1">
            <b-button
              @click="deleteSchedule(row)"
              class="mr-1"
              size="sm"
              title="Delete schedule"
              v-b-tooltip.hover
            >
              <font-awesome-icon icon="trash" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </b-table>
</template>

<script>
import ScheduleForm from "../common/ScheduleForm";
import Vue from "vue";

export default {
  name: "ScheduleTable",
  components: { ScheduleForm },
  props: {
    scheduleList: { type: Array, default: () => [] },
    fields: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  methods: {
    /*
            Eventually change to an actual Vue Filter
            Figure out what day format should be used. Full name or short name
             */
    deleteSchedule(row) {
      this.$emit("DeleteSchedule", row.item.schedule);
    },
    updateSchedule(row) {
      let updatedRawItem = Object.assign({}, row);
      Vue.$log.debug("UpdatedRawItem: ", updatedRawItem);

      if (row.item.schedule) {
        updatedRawItem = Object.assign({}, row.item.schedule);
        Vue.$log.debug("ROW: ", row);
      }

      this.$emit("UpdateSchedule", updatedRawItem);
    },
  },
  computed: {
    internalItems() {
      let wrappedSchedules = [];
      this.scheduleList.forEach((item) => {
        wrappedSchedules.push({ rawItem: item });
      });
      Vue.$log.debug(wrappedSchedules);
      return wrappedSchedules;
    },
    internalFields() {
      let wrappedFields = [];
      this.fields.forEach((item) => {
        if (item instanceof String && item != "show_details") {
          wrappedFields.push("rawItem." + item);
        } else {
          if (item.key != "show_details") {
            let wrappedItem = Object.assign({}, item);
            wrappedItem.key = "rawItem." + item.key;
            wrappedFields.push(wrappedItem);
          }
        }
      });
      wrappedFields.push("show_details");
      Vue.$log.debug(wrappedFields);
      return wrappedFields;
    },
  },
};
</script>
