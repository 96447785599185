import { commit, make } from "vuex-pathify";
import axios from "axios";
import constants from "../../EndPointsAndConstants";
import { makeToast } from "../mixin/basic";
import Vue from "vue";

const state = {
  menu: {
    id: null,
    name: null,
    description: null,
    displayName: null,
    retryMax: null,
    timeout: null,
    timeoutMax: null,
    wrongInputMax: null,
    menuEventPrompts: [],
    menuEvent: [],
  },
};

const getters = {
  getMenu: (state) => {
    return state.menu;
  },
  getMenuInfo: (state) => {
    var info = {
      id: state.menu.id,
      name: state.menu.name,
      description: state.menu.description,
      retryMax: state.menu.retryMax,
      timeout: state.menu.timeout,
      timeoutMax: state.menu.timeoutMax,
      wrongInputMax: state.menu.wrongInputMax,
      displayName: state.menu.displayName,
    };
    return info;
  },
  getMenuEventPrompts: (state) => {
    return state.menu.menuEventPrompts;
  },
  getMenuActions: (state) => {
    return state.menu.menuActions;
  },
  getIntroPrompts: (state) => {
    const found = state.menu.menuEventPrompts.find(
      (element) => element.event === "Intro"
    );
    return found.prompts;
  },
  getInitPrompts: (state) => {
    let prompts = [];
    const found = state.menu.menuEvent.find(
      (element) => element.event === "Init"
    );
    if (found != null && found != "undefined" && found.prompts != "undefined") {
      prompts = found.prompts;
    }
    return prompts;
  },
  getValidPrompts: (state) => {
    const found = state.menu.menuEventPrompts.find(
      (element) => element.event === "Valid"
    );
    return found.prompts;
  },
  getTimeoutPrompts: (state) => {
    const found = state.menu.menuEventPrompts.find(
      (element) => element.event === "Timeout"
    );
    return found.prompts;
  },
  getWrongInputPrompts: (state) => {
    const found = state.menu.menuEventPrompts.find(
      (element) => element.event === "WrongInput"
    );
    return found.prompts;
  },
  getNotValidPrompts: (state) => {
    const found = state.menu.menuEventPrompts.find(
      (element) => element.event === "NotValid"
    );
    return found.prompts;
  },
  getMainPrompts: (state, getters) => (eventName) => {
    let prompts = [];
    const found = state.menuEventForUpdate.find(
      (element) => element.event === eventName
    );
    if (found != null && found != "undefined" && found.prompts != "undefined") {
      prompts = found.prompts;
    }
    return prompts;
  },
  getAllDefaultEventNames: (state) => {
    let names = [
      "Init",
      "Intro",
      "Retry",
      "Timeout",
      "WrongInput",
      "NotValid",
      "Valid",
    ];
    return names;
  },
  findMenuEventByName: (state) => (eventName) => {
    let defaultEvent = {
      event: eventName,
      prompts: [],
      action: { fk_ActionType: "DISCONNECT", prompts: [] },
    };
    let foundEvent = state.menuEventForUpdate.find(
      (menuEvent) => menuEvent.event.toLowerCase() === eventName.toLowerCase()
    );
    if (foundEvent != null) {
      return foundEvent;
    } else {
      return defaultEvent;
    }
  },
};

const actions = {
  saveMenuEventByName({ commit, state }, { eventName, action }) {
    let eventForUpdate = {
      event: eventName,
      action: action,
    };

    commit("UPDATE_MENU_EVENT_FOR_UPDATE", eventForUpdate);
  },
  saveMainPrompts({ commit, state }, { eventName, prompts }) {
    let eventForUpdate = {
      event: eventName,
      prompts: prompts,
    };
    commit("UPDATE_MENU_EVENT_FOR_UPDATE", eventForUpdate);
  },
  fetchMenu({ commit }, id) {
    let promise = axios
      .get(constants.endPoints.MENUS_BASE + id)
      .then((response) => {
        if (response.data) {
          commit("SET_MENU", response.data);
          commit("DO_CHANGE_FOR_UPDATE_LATER", response.data);
        }
      });
    return promise;
  },
  deleteMenu({ store, commit, dispatch }, { vm, menu }) {
    let okFunction = function () {
      dispatch("changes/setUnsavedChanges", false, { root: true });
    };

    return dispatch("deleteMenuBackend", {
      vm: vm,
      menuId: menu.id,
    }).then(okFunction);
  },
  updateMenu(
    { store, commit, dispatch },
    { vm, menu, menuEventToUpdate, menuEventToDelete }
  ) {
    let menuToSend = Object.assign({}, menu);
    delete menuToSend.menuEvent;
    let menuEventsToUpdateFullObject = state.menuEventForUpdate.filter(
      (menuEvent) => menuEventToUpdate.includes(menuEvent.event)
    );

    if (
      !menuEventsToUpdateFullObject.some(
        (object) => object.event === "WrongInputMax"
      )
    ) {
      menuToSend.wrongInputMax = null;
    }
    if (
      !menuEventsToUpdateFullObject.some(
        (object) => object.event === "TimeoutMax"
      )
    ) {
      menuToSend.timeoutMax = null;
    }
    if (
      !menuEventsToUpdateFullObject.some(
        (object) => object.event === "RetryMax"
      )
    ) {
      menuToSend.retryMax = null;
    }

    let dispatches = [];
    dispatches.push(
      dispatch("updateMenuEventBackend", {
        menuId: menu.id,
        event: menuEventsToUpdateFullObject,
      })
    );
    dispatches.push(
      dispatch("updateMenuGeneralBackend", {
        menu: menuToSend,
      })
    );
    menuEventToDelete.forEach((menuEvent) => {
      dispatches.push(
        dispatch("deleteMenuEventBackend", {
          menuId: menu.id,
          event: menuEvent,
        })
      );
    });
    let okFunction = function () {
      dispatch("changes/setUnsavedChanges", false, { root: true });
      makeToast(
        vm,
        vm.$t("toasts.menu.saveMenuSuccess") + new Date().toString(),
        "success"
      );
    };
    let errorFunction = function () {
      makeToast(
        vm,
        vm.$t("toasts.menu.saveMenuDanger") + new Date().toString(),
        "danger"
      );
    };

    Promise.all(dispatches).then(okFunction).catch(errorFunction);
  },
  updateMenuEventBackend({ commit }, { menuId, event }) {
    let promise = axios.put(
      constants.endPoints.MENUS_BASE + menuId + constants.endPoints.MENUS_EVENT,
      event
    );
    return promise;
  },
  deleteMenuEventBackend({ commit }, { menuId, event }) {
    let promise = axios.delete(
      constants.endPoints.MENUS_BASE + menuId + "/event/" + event
    );
    return promise;
  },

  updateMenuGeneralBackend({ commit }, { menu }) {
    let promise = axios.post(constants.endPoints.MENUS_BASE + menu.id, menu);
    return promise;
  },

  deleteMenuBackend({ commit }, { vm, menuId, event }) {
    let promise = axios
      .delete(constants.endPoints.MENUS_BASE + menuId)
      .then((__) => {
        makeToast(
          vm,
          vm.$t("toasts.menu.deleteMenuEventSuccess") + new Date().toString(),
          "success",
          true
        );
      })
      .catch((__) => {
        makeToast(
          vm,
          vm.$t("toasts.menu.deleteMenuEventDanger") + new Date().toString(),
          "danger",
          true
        );
      });
    return promise;
  },
  updateStoreForMaxData({ commit, dispatch, state }, { optionName, option }) {
    switch (optionName) {
      case "RetryMax":
        commit("");
        state.menu.retryMax = option.inputField;
        break;
      case "RetryMax":
        state.menu.timeoutMax = option.inputField;
        break;
      case "RetryMax":
        state.menu.wrongInputMax = option.inputField;
        break;
      default:
        break;
    }
  },
  initializeEmptyPrompts({ commit }) {
    commit("INITIALIZE_EMPTY_PROMPTS");
  },
};
const mutations = {
  ...make.mutations(state),

  DO_CHANGE_FOR_UPDATE_LATER(state, menu) {
    state.menuEventForUpdate = menu.menuEvent;
  },
  UPDATE_MENU_EVENT_FOR_UPDATE(state, menuEvent) {
    let objectToFind = null;
    for (let i = 0; i < state.menuEventForUpdate.length; i++) {
      if (
        state.menuEventForUpdate[i].event.toLowerCase() ===
        menuEvent.event.toLowerCase()
      ) {
        objectToFind = state.menuEventForUpdate[i];
        break;
      }
    }
    let indexNumber = -1;
    if (objectToFind != null) {
      indexNumber = state.menuEventForUpdate.indexOf(objectToFind);
    }
    if (indexNumber != -1) {
      state.menuEventForUpdate[indexNumber] = menuEvent;
    } else {
      state.menuEventForUpdate.push(menuEvent);
    }
  },
  INITIALIZE_EMPTY_PROMPTS(state) {
    let foundInit = state.menu.menuEvent.find(
      (event) => event.event === "Init"
    );
    if (!foundInit) {
      Vue.$log.debug("DID FIND INIT: " + foundInit);
      state.menu.menuEvent.push({ event: "Init", prompts: [] });
    }

    let foundIntro = state.menu.menuEvent.find(
      (event) => event.event === "Intro"
    );
    if (!foundIntro) {
      Vue.$log.debug("DID FIND INTRO: " + foundIntro);
      state.menu.menuEvent.push({ event: "Intro", prompts: [] });
    }

    let foundRetry = state.menu.menuEvent.find(
      (event) => event.event === "Retry"
    );
    if (!foundRetry) {
      Vue.$log.debug("DID FIND RETRY: " + foundRetry);
      state.menu.menuEvent.push({ event: "Retry", prompts: [] });
    }

    let foundTimeout = state.menu.menuEvent.find(
      (event) => event.event === "Timeout"
    );
    if (!foundTimeout) {
      Vue.$log.debug("DID FIND TIMEOUT: " + foundTimeout);
      state.menu.menuEvent.push({ event: "Timeout", prompts: [] });
    }

    let foundWrongInput = state.menu.menuEvent.find(
      (event) => event.event === "WrongInput"
    );
    if (!foundWrongInput) {
      Vue.$log.debug("DID FIND WRONGINPUT: " + foundWrongInput);
      state.menu.menuEvent.push({ event: "WrongInput", prompts: [] });
    }

    let foundNotValid = state.menu.menuEvent.find(
      (event) => event.event === "NotValid"
    );
    if (!foundNotValid) {
      Vue.$log.debug("DID FIND NOTVALID: " + foundNotValid);
      state.menu.menuEvent.push({ event: "NotValid", prompts: [] });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
