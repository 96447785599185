<template>
  <b-card no-body>
    <b-tab
      active
      lazy
      active-tab-class="font-weight-bold text-success"
      class="text-success font-weight-bold"
    >
      <ListWithSearchField
        :fields="fields"
        :fields-for-csv="this.pageVariables.fieldsForCsv"
        :filter-function="this.filterApplications"
        :filter-on="this.pageVariables.filterOn"
        :item-provider="getApplicationsAndExpandWithRowVariant"
        :update-key="this.pageVariables.updateTableKey"
        @rowSelected="(item) => rowSelected(item)"
        :search-text="$t('applicationPage.applicationSearchField')"
        :title="$t('applicationPage.applications')"
        :is-busy="this.pageVariables.isLoading"
      >
        <template v-slot:additionalSearch>
          <b-form-radio-group
            @input="updateTable"
            class="ml-1"
            id="radio-group-2"
            label="Show"
            v-model="pageVariables.applicationType"
          >
            <b-form-radio
              name="Applications"
              value="application"
              data-ignore-change
              >{{ $t("applicationPage.applications") }}</b-form-radio
            >
            <b-form-radio
              name="Organizations"
              value="organisation"
              data-ignore-change
              >{{ $t("applicationPage.organizations") }}</b-form-radio
            >
            <b-form-radio name="All" value="all" data-ignore-change>{{
              $t("applicationPage.all")
            }}</b-form-radio>
          </b-form-radio-group>
        </template>
        <template
          v-slot:additionalContent
          v-if="
            this.getPermissions().application.permission ===
            this.getPERMISSION_TYPES().ALL
          "
        >
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <b-form
              class="ml-1 mb-2 p-3 border shadow rounded"
              fluid
              inline
              @submit.prevent="handleSubmit(createNewApplication)"
            >
              <legend
                data-test="createApplicationTitle"
                slot="label"
                class="font-weight-bold text-success"
              >
                {{ $t("applicationPage.createApplication") }}
              </legend>
              <ValidationProvider
                :rules="'required|min:1|max:' + fieldLength"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  :label="$t('applicationPage.createApplicationFieldName')"
                  label-class="ml-1 align-self-start"
                >
                  <b-input
                      id="nameInput"
                      data-test="input-text-applicationName"
                    class="ml-1"
                    :placeholder="
                      $t('applicationPage.createApplicationFieldName')
                    "
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="restVariables.application.name"
                  />
<!--                  <b-form-invalid-feedback v-if="restVariables.application.name.length > 0" tag="span">{{-->
<!--                    errors[0]-->
<!--                  }}</b-form-invalid-feedback>-->
                  <b-tooltip
                      v-if="errors[0]"
                      :show="errors[0] != null"
                      target="nameInput"
                      variant="danger"
                  >{{ errors[0] }}</b-tooltip>
                </b-form-group>
              </ValidationProvider>
              <ValidationProvider
                :rules="'required|min:1|max:' + fieldLength"
                v-slot="{ valid, errors }"
              >
                <b-form-group
                  :label="
                    $t('applicationPage.createApplicationFieldDisplayName')
                  "
                  label-class="ml-1"
                  class="align-self-start"
                >
                  <b-input
                      id="displayInput"
                    data-test="input-text-applicationDisplayName"
                    class="ml-1"
                    :placeholder="
                      $t('applicationPage.createApplicationFieldDisplayName')
                    "
                    :aria-label="
                      $t('applicationPage.createApplicationFieldDisplayName')
                    "
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="restVariables.application.displayName"
                  />
<!--                  <b-form-invalid-feedback v-if="restVariables.application.displayName.length > 0" tag="span">{{-->
<!--                    errors[0]-->
<!--                  }}</b-form-invalid-feedback>-->
                  <b-tooltip
                      v-if="errors[0]"
                      :show="errors[0] != null"
                      target="displayInput"
                      variant="danger"

                  >{{ errors[0] }}</b-tooltip>
                </b-form-group>
              </ValidationProvider>
              <b-form class="align-self-start ml-2">
                <label>{{ $t("applicationPage.organization") }}</label>
                <b-checkbox
                  data-test="input-check-organisation"
                  v-model="restVariables.application.organization"
                />
              </b-form>
              <b-button
                id="createNewApplication"
                data-test="btn-createNewApplication"
                type="submit"
                class="ml-1 align-self-end"
              >
                <font-awesome-icon icon="plus" />


              </b-button>
            </b-form>
          </ValidationObserver>
        </template>
      </ListWithSearchField>
    </b-tab>
  </b-card>
</template>

<script>
import axios from "axios";
import router from "../router";
import constants from "../EndPointsAndConstants";
import { mapActions, mapGetters } from "vuex";
import ListWithSearchField from "../components/common/ListWithSearchField";
import Vue from "vue";
import { makeToast } from "../store/mixin/basic";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Application",
  components: { ListWithSearchField, ValidationProvider, ValidationObserver },
  data() {
    return {
      restVariables: {
        application: {
          name: "",
          displayName: "",
          organization: false,
        },
      },
      pageVariables: {
        applicationType: "all",
        updateTableKey: 0,
        filterOn: ["displayName", "triggers", "isOrg"],
        fieldsForCsv: [
          "applicationId",
          "name",
          "displayName",
          "actionOnNoHit",
          "timeZone",
          "triggers",
          "applicationCalendar",
          "parentCalendarRanksHighest",
        ],
        isLoading: true,
      },
      noTimeZone: true,
      fieldLength: this.$maxLength,
    };
  },

  methods: {
    ...mapActions("applications", ["fetchApplications"]),
    ...mapGetters("applications", ["getApplications"]),
    ...mapGetters("authentication", ["getPermissions"]),
    ...mapGetters("language", ["getLanguage"]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    getPERMISSION_TYPES() {
      return constants.PERMISSION_TYPES;
    },

    getApplicationsAndExpandWithRowVariant() {
      let expandedItems = [];
      for (let application of this.getApplications()) {
        let expandedApplication = Object.assign({}, application);
        if (expandedApplication.organization) {
          expandedApplication._rowVariant = "warning";
        }
        switch (this.pageVariables.applicationType) {
          case "all":
            expandedItems.push(expandedApplication);
            break;
          case "organisation":
            if (expandedApplication.organization === true) {
              expandedItems.push(expandedApplication);
            }
            break;
          case "application":
            if (!expandedApplication.organization === true) {
              expandedItems.push(expandedApplication);
            }
            break;
          default:
          // code block
        }
      }
      return expandedItems;
    },
    filterApplications(item, filter) {
      const partialTriggerValue = (element) =>
        element.includes(filter.toLowerCase());
      if (
        item.displayName.toLowerCase().includes(filter.toLowerCase()) ||
        item.triggers.some(partialTriggerValue)
      ) {
        return true;
      }
      return false;
    },
    rowSelected(item) {
      router.push({
        name: "applicationDetails",
        params: { id: item.id },
      });
    },
    fetchAndRedraw() {
      this.pageVariables.isLoading = true;
      return this.fetchApplications().then((__) => {
        this.updateTable();
        this.pageVariables.isLoading = false;
      });
    },
    updateTable() {
      Vue.$log.debug("should update");
      this.pageVariables.updateTableKey += 1;
    },
    createNewApplication() {
      axios
        .post(constants.endPoints.APPLICATIONS_BASE, {
          name: this.restVariables.application.name,
          displayName: this.restVariables.application.displayName,
          organization: this.restVariables.application.organization,
        })
        .then((__) => {
          this.setUnsavedChanges(false);
        })
        .then((data) => {
          this.fetchAndRedraw();
          this.restVariables.application.displayName = "";
          this.restVariables.application.name = "";
          this.restVariables.application.organization = false;
          this.pageVariables.updateTableKey += 1;
          // this.$root.$bvToast.show('successToast', {default: "yes"});
          makeToast(
            this,
            this.$t("toasts.application.addedApplicationSuccess"),
            "success"
          );
        })
        .then(
          requestAnimationFrame(() => {
            this.$refs.observer.reset();
          })
        ).catch((error) => {
          if (error.response.status === 409){
            makeToast(
                this,
                this.$t("applicationPage.duplicateError"),
                "danger"
            );
          }
        });
    },
  },
  created() {
    this.fetchAndRedraw();
    document.title = this.$t("pageTitles.applications");
  },
  mounted() {
    // this.pageVariables.fields = [{key: "name", label: this.language.applicationName, thStyle: "width: 30%"},{key: "displayName", label: this.language.applicationDisplayName, thStyle: "width: 30%"}, {key: "triggers", label: this.language.triggers, thStyle: "width: 30%"},{key: "timeZone", label: this.language.timeZone, thStyle: "width: 10%"}]
  },
  computed: {
    language: function () {
      return this.getLanguage();
    },
    fields: function () {
      let fields = [
        {
          key: "name",
          label: this.$t("applicationPage.applicationName"),
          thStyle: "width: 30%",
        },
        {
          key: "displayName",
          label: this.$t("applicationPage.applicationDisplayName"),
          thStyle: "width: 30%",
        },
        {
          key: "triggers",
          label: this.$t("applicationPage.triggers"),
          thStyle: "width: 30%",
        },
        {
          key: "timeZone",
          label: this.$t("applicationPage.timeZone"),
          thStyle: "width: 10%",
        },
      ];
      if (this.noTimeZone) {
        let index = fields.findIndex((field) => field.key === "timeZone");
        fields.splice(index, 1);
      }
      return fields;
    },
  },
};
</script>
