<template>
  <b-card no-body>
    <b-tab
      active
      lazy
      active-tab-class="font-weight-bold text-success"
      class="text-success font-weight-bold"
    >
      <ListWithSearchField
        :title="$t('menuPage.menus')"
        :update-key="this.pageVariables.updateTableKey"
        :fields="this.fields"
        :fields-for-csv="this.pageVariables.fieldsForCsv"
        :filter-on="this.pageVariables.filterOn"
        :item-provider="this.getMenus"
        :search-text="$t('menuPage.menuSearchField')"
        @rowSelected="(item) => onRowSelected(item)"
        :is-busy="this.pageVariables.isLoading"
      >
        <template
          v-slot:additionalContent
          v-if="
            this.getPermissions().menu.permission ===
            this.getPERMISSION_TYPES().ALL
          "
        >
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <b-form-group class=" " inline>
              <b-form
                class="ml-1 mb-2"
                inline
                @submit.prevent="handleSubmit(createMenu(restVariables.menu))"
              >
                <legend
                  data-test="createMenuTitle"
                  slot="label"
                  class="font-weight-bold text-success"
                >
                  {{ $t("menuPage.createMenu") }}
                </legend>
                <ValidationProvider
                  :rules="'required|min:1|max:' + pageVariables.fieldLength"
                  v-slot="{ valid, errors }"
                >
                  <b-form-group
                    :label="$t('menuPage.createMenuFieldName')"
                    label-class="ml-1"
                  >
                    <b-input
                        id="nameInput"
                      data-test="input-text-menuName"
                      class="ml-1"
                      :placeholder="$t('menuPage.createMenuFieldName')"
                      v-model="restVariables.menu.name"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-tooltip
                        v-if="errors[0]"
                        :show="errors[0] != null"
                        target="nameInput"
                        variant="danger"

                    >{{ errors[0] }}</b-tooltip>
<!--                    <b-form-invalid-feedback v-if="restVariables.menu.name.length > 0" tag="span">{{-->
<!--                      errors[0]-->
<!--                    }}</b-form-invalid-feedback>-->
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  :rules="'required|min:1|max:' + pageVariables.fieldLength"
                  v-slot="{ valid, errors }"
                >
                  <b-form-group
                    :label="$t('menuPage.createMenuFieldDisplayName')"
                    label-class="ml-1"
                    class="align-self-center"
                  >
                    <b-input
                        id="displayInput"
                      data-test="input-text-menuDisplayName"
                      class="ml-1"
                      :placeholder="$t('menuPage.createMenuFieldDisplayName')"
                      :aria-label="$t('menuPage.createMenuFieldDisplayName')"
                      v-model="restVariables.menu.displayName"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                    <b-tooltip
                        v-if="errors[0]"
                        :show="errors[0] != null"
                        target="displayInput"
                        variant="danger"

                    >{{ errors[0] }}</b-tooltip>
<!--                    <b-form-invalid-feedback v-if="restVariables.menu.displayName.length > 0" tag="span">{{-->
<!--                      errors[0]-->
<!--                    }}</b-form-invalid-feedback>-->
                  </b-form-group>
                </ValidationProvider>
                <b-button
                  data-test="btn-createMenu"
                  @click="createMenu(restVariables.menu)"
                  class="ml-1 align-self-end"
                >
                  <font-awesome-icon icon="plus" />
                </b-button>
              </b-form>
            </b-form-group>
          </ValidationObserver>
        </template>
      </ListWithSearchField>
    </b-tab>
  </b-card>
</template>

<script>
import ListWithSearchField from "../components/common/ListWithSearchField";
import { mapGetters, mapActions, mapState } from "vuex";
import NewMenu from "../components/menu/NewMenu";
import constants from "../EndPointsAndConstants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Menus",
  components: { ListWithSearchField },
  data() {
    return {
      restVariables: {
        menu: {
          name: "",
          displayName: "",
          retryMax: 3,
          timeout: 10,
          timeoutMax: 3,
          wrongInputMax: 3,
          menuEvent: [
            {
              event: "RetryMax",
              prompts: [],
              action: {
                fk_ActionType: "DISCONNECT",
              },
            },
          ],
        },
      },
      pageVariables: {
        updateTableKey: 0,
        filterOn: ["displayName"],
        fieldsForCsv: ["displayName", "name"],
        fieldLength: this.$maxLength,
        isLoading: false
      },
    };
  },
  computed: {
    ...mapState("menus", []),
    fields: function () {
      return [
        {
          key: "name",
          label: this.$t("menuPage.menuName"),
        },
        {
          key: "displayName",
          label: this.$t("menuPage.menuDisplayName"),
        }
      ];
    },
  },
  methods: {
    ...mapActions("menus", ["fetchMenus", "addMenu"]),
    ...mapGetters("menus", ["getMenus"]),
    ...mapGetters("authentication", ["getPermissions"]),
    ...mapActions("changes", ["setUnsavedChanges"]),
    getPERMISSION_TYPES() {
      return constants.PERMISSION_TYPES;
    },
    onRowSelected(selectedItem) {
      this.$router.push({
        name: "menuDetail",
        params: { idProp: String(selectedItem.id) },
      });
    },

    createMenu(menu) {
      this.addMenu({ vm: this, menu: Object.assign({}, menu) }).then((__) => {
        menu.name = "";
        menu.displayName = "";
        this.pageVariables.isLoading = true;
        this.fetchMenus().then((__) => {
          this.pageVariables.updateTableKey += 1;
          this.setUnsavedChanges(false);
          this.$refs.observer.reset();
          this.pageVariables.isLoading = false;
        });
      });
    },
  },
  created() {
    this.pageVariables.isLoading = true;
    this.fetchMenus().then((__) => {
      this.pageVariables.updateTableKey += 1;
      this.pageVariables.isLoading = false;
    });
    document.title = this.$t("pageTitles.menus");
  },
};
</script>
